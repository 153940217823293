import React from 'react'
import { connect, FormikContextType, FormikErrors } from 'formik'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import flatMap from 'lodash/flatMap'

import { notification } from 'shared/notification'

export const flattenErrors = <T extends unknown>(errors: FormikErrors<T>): string[] => {
    return flatMap(Object.values(errors), value => {
        return isObject(value) ? flattenErrors(value) : value
    }).filter(isString)
}

type Props = {
    title?: string
    formik: FormikContextType<unknown>
}
const FormikValidationErrorNotification: React.FC<Props> = ({ title, formik }): null => {
    // Unique id to track errors for one form only.
    // Used to hide old errors when new are ready and old notification is still up.
    const toastId = React.useRef<number>(Math.random())

    React.useEffect(() => {
        if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
            notification.dismiss(toastId.current)
            notification.error(
                <>
                    {title && <h3>{title}</h3>}
                    <ul>
                        {flattenErrors(formik.errors).map((error, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </>,
                { toastId: toastId.current },
            )
        }

        // we omit the following:
        // formik.errors, title
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.submitCount, formik.isSubmitting, formik.isValid])

    return null
}

export default connect(FormikValidationErrorNotification) as React.FC<Omit<Props, 'formik'>>
