import { MyIDUser } from '@genome-web-forms/common/auth'
import { primeTRSCache } from 'shared/hooks/useTRS'
import { PromiseAllSettled } from 'shared/util/PromiseAllSettled'

class BackendPrimerClass {
    async prime(user: MyIDUser): Promise<any> {
        // delay the priming requests, so that other requests have higher priority
        await new Promise(resolve => setTimeout(resolve, 1000))
        return await PromiseAllSettled([primeTRSCache(user)])
    }
}

export default new BackendPrimerClass()
