import React from 'react'

export function useContextHelper<T>(context: React.Context<T>, name = context.displayName): T {
    const contextInstance = React.useContext(context)

    if (!contextInstance) {
        throw new Error(
            `Context ${
                name ?? String(context)
            } is not available, make sure you use are calling it as a child of the context provider`,
        )
    }

    return contextInstance
}
