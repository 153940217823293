import React from 'react'

import styled from 'shared/theme'
import Container from 'shared/components/Container'
import { inputStyle } from 'shared/components/Input/index'
import { ReactComponent as SearchIcon } from 'shared/components/Icons/search-icon.svg'
import { ReactComponent as CloseIcon } from 'shared/components/Icons/close-icon.svg'
import { nativeInputValueSetter } from 'shared/util/nativeInputValueSetter'

const InputContainer = styled(Container)`
    position: relative;
`

const Input = styled.input`
    ${inputStyle}
    width: 20.8125rem;
    margin-left: 1.25rem;
    padding-right: 2.625rem;
`

const SearchIconStyled = styled(SearchIcon)`
    position: absolute;
    right: 0.625rem;
    top: 0.75rem;
`

const ClearIconStyled = styled(CloseIcon)`
    cursor: pointer;
    margin-left: 0.35rem;
    padding: 0.35rem;
`

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['input']> & {
    hideClearButton?: boolean
}

const SearchInput: React.FC<Props> = ({ hideClearButton = false, ...props }) => {
    const input = React.useRef<HTMLInputElement>(null!)
    const hasValue = !!props.value

    return (
        <>
            <InputContainer>
                <Input placeholder="Search" {...props} ref={input} />
                <SearchIconStyled />
            </InputContainer>

            {hasValue && !hideClearButton ? (
                <ClearIconStyled
                    onClick={() => {
                        nativeInputValueSetter(input.current, '')

                        const ev = document.createEvent('HTMLEvents')
                        ev.initEvent('input', /* bubbles */ true)
                        input.current.dispatchEvent(ev)
                    }}
                />
            ) : null}
        </>
    )
}

export default SearchInput
