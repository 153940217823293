import React from 'react'
import Checkbox from './Checkbox'
import { ColumnInstance } from 'react-table'
import { DataItem } from 'shared/components/Table'
import { EMPTY_SENTINEL } from './index'
import without from 'lodash/without'

export type ColumnInstanceForCheckboxList<D extends DataItem> = Pick<
    ColumnInstance<D>,
    'filterValue' | 'setFilter'
>

export type CheckboxListProps<D extends DataItem> = {
    visibleItems: string[]
    allItems: string[]
    column: ColumnInstanceForCheckboxList<D>
}

function CheckboxList<D extends DataItem>({
    column,
    visibleItems,
    allItems,
}: CheckboxListProps<D>): React.ReactElement {
    const { setFilter } = column
    const filterValue: undefined | string[] = column.filterValue

    return (
        <>
            {visibleItems.map(item => {
                const checked = !filterValue || filterValue.includes(item)

                return (
                    <Checkbox
                        role="checkbox"
                        aria-checked={checked}
                        key={item}
                        checked={checked}
                        onClick={event => {
                            const selectOnlyCurrent = event.ctrlKey || event.metaKey

                            if (selectOnlyCurrent) {
                                return setFilter([item])
                            }

                            if (!filterValue) {
                                return setFilter(without(allItems, item))
                            }

                            const newFilterValue = filterValue.includes(item)
                                ? filterValue.filter(value => value !== item)
                                : [...filterValue, item]

                            setFilter(newFilterValue)
                        }}
                        title={item === EMPTY_SENTINEL ? '(empty)' : item}
                    />
                )
            })}
        </>
    )
}

export default CheckboxList
