import { Row, SortByFn } from 'react-table'
import warning from 'tiny-warning'
import { compare } from 'natural-orderby'

const compareFn = compare()

export const sortTypes: Record<string, SortByFn<any>> = {
    // overwrite the default alphanumeric sort with natural-sort
    alphanumeric: (
        a: Row<Record<string, unknown>>,
        b: Row<Record<string, unknown>>,
        columnId: string,
    ) => {
        return compareFn(a.values[columnId], b.values[columnId])
    },

    // allow sorting of arrays of values
    array: (a: Row<Record<string, unknown>>, b: Row<Record<string, unknown>>, id: string) => {
        if (!Array.isArray(a.values[id]) || !Array.isArray(b.values[id])) {
            warning(true, `<Table> cannot perform array sort for column "${id}"`)
            return 0
        }

        const aa: string[] = a.values[id].sort(compare())
        const bb: string[] = b.values[id].sort(compare())

        return compareFn(aa.join(), bb.join())
    },
}
