import { Tabs as RTabs } from '@reach/tabs'

import styled from 'shared/theme'

export const Tabs = styled(RTabs)`
    [data-reach-tab] {
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: ${props => props.theme.colors.textSecondary};
        position: relative;
        padding: 0.5rem 0;
        border-bottom: 0;
        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }
    [data-reach-tab][data-selected] {
        opacity: 1;
        color: ${props => props.theme.colors.textPrimary};
        text-shadow: 0.0313rem 0 ${({ theme }) => theme.colors.textPrimary};
    }
    [data-selected],
    [data-reach-tab-list] {
        &:after {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${props => props.theme.colors.primary};
            height: 0.25rem;
            border-radius: 1rem;
        }
    }
    [data-reach-tab-list] {
        position: relative;
        background: transparent;
        &:after {
            background-color: ${props => props.theme.colors.border};
            height: 1px;
            z-index: -1;
        }
    }
`

export { TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
