import fromPairs from 'lodash/fromPairs'

type SafeForFormikValue<T extends Record<string, any>> = T & { __brand: 'SAFE_FOR_FORMIK' }

export type SafeForFormik<T extends Record<string, any>> = {
    safe: SafeForFormikValue<T>
    originalToSafeKeyDict: Record<string, string>
    safeToOriginalKeyDict: Record<string, string>
    safeToOriginal: (safeData: SafeForFormikValue<T>) => T
}

/**
 * Because Formik tries to be smart about dots in Field names and
 * treats them as lodash-like dot paths, we need to replace them.
 * This is a utility function that given a `data` object like:
 *   {
 *      "http://example.com": "value"
 *   }
 * returns:
 *   {
 *      safe: {
 *         "http://example-com": "value"
 *      },
 *
 *      safeToOriginal: (safeData) => T,
 *
 *      originalToSafeKeyDict: {
 *          "http://example.com": "http://example-com"
 *      },
 *
 *      safeToOriginalKeyDict: {
 *          "http://example-com": "http://example.com"
 *      },
 *   }
 */

export function safeFormikData<T extends Record<string, any>>(data: T): SafeForFormik<T> {
    const originalToSafeKeyDict = fromPairs(
        Object.keys(data).map(key => [key, safeFormikName(key)]),
    )
    const safeToOriginalKeyDict = fromPairs(
        Object.keys(data).map(key => [safeFormikName(key), key]),
    )
    const safe = fromPairs(
        Object.keys(data).map(key => [originalToSafeKeyDict[key], data[key]]),
    ) as SafeForFormikValue<T>

    const safeToOriginal = (safeData: T): T => {
        return fromPairs(
            Object.keys(safeToOriginalKeyDict).map(safeKey => {
                const originalKey = safeToOriginalKeyDict[safeKey]
                return [originalKey, safeData[safeKey]]
            }),
        ) as T
    }

    return {
        safe,
        originalToSafeKeyDict,
        safeToOriginalKeyDict,
        safeToOriginal,
    }
}

/**
 * Convert a potentially unsafe attribute name with dots into one without dots.
 * To be used with data generated by `safeFormikData()`
 */
export const safeFormikName = (str: string): string => {
    const REPLACEMENT = '-'
    return str.replace(/\./g, REPLACEMENT)
}
