import React from 'react'
import { Formik } from 'formik'

import { generateDisneyId } from 'shared/hooks/useDisneyId'
import {
    FeatureStoryline,
    FeatureStorylineFormValue,
    FeatureStorylineFormSchema,
} from 'codecs/storyline/FeatureStoryline'
import { emptyOnSubmitting } from 'shared/util/EmptyYupSchema'

import { useStorylinesContext } from './index'
import StorylineFormNode from './StorylineFormNode'

export const StorylineForm: React.FC<{
    storyline: FeatureStoryline | null
    index?: number
}> = ({ storyline, index }): React.ReactElement => {
    const { save } = useStorylinesContext()

    const storylineFormValue: FeatureStorylineFormValue = React.useMemo(() => {
        return storyline
            ? storyline
            : ({
                  storylineId: generateDisneyId(),
                  storylineComponent: null,
                  storylineLogLine: '',
                  storylineArchetypes: [],
                  storylineLeadCharacter: [],
                  storyRoles: [],
              } as FeatureStorylineFormValue)
    }, [storyline])

    const [isSubmitting, setIsSubmitting] = React.useState(false)

    return (
        <Formik
            initialValues={storylineFormValue}
            validationSchema={() => {
                return emptyOnSubmitting(FeatureStorylineFormSchema)(isSubmitting)
            }}
            onSubmit={storyline => {
                save(storyline as FeatureStoryline)
            }}
        >
            {formikProps => {
                if (formikProps.isSubmitting !== isSubmitting) {
                    setIsSubmitting(formikProps.isSubmitting)
                }

                return <StorylineFormNode index={index} {...formikProps} />
            }}
        </Formik>
    )
}
