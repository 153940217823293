import React from 'react'
import { useField, useFormikContext } from 'formik'
import { ArcValue, ArcOption, ARC_OPTIONS } from 'codecs/Arc'
import { ValueType } from 'react-select'
import invariant from 'tiny-invariant'
import { FormikSelect, FormikSelectProps } from 'shared/components/Select/FormikSelect'
import { useEventCallback } from 'shared/hooks/useEventCallback'

type FormikArcSelectProps = {
    availableArcs: ArcOption[]
} & Omit<FormikSelectProps<ArcOption>, 'options' | 'getOptionLabel' | 'getOptionValue'>
export const FormikArcSelect: React.FC<FormikArcSelectProps> = ({
    availableArcs,
    ...props
}): React.ReactElement => {
    const { name } = props
    const [{ value }] = useField<ArcValue>(props.name)
    const { setFieldValue } = useFormikContext<any>()

    const onChange = useEventCallback((arcOption: ValueType<ArcOption>) => {
        invariant(arcOption)
        invariant(!Array.isArray(arcOption))
        setFieldValue(name, (arcOption as ArcOption).value)
    })
    return (
        <FormikSelect<ArcOption>
            {...props}
            options={availableArcs}
            value={ARC_OPTIONS.find(arcOption => arcOption.value === value)}
            isSearchable={false}
            onChange={onChange}
        />
    )
}

export default FormikArcSelect
