import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { AuthDispatchContext, actions as authActions } from 'auth/Auth'

import styled from 'shared/theme'

import { AuthContext } from 'auth/Auth'
import LoginButton from 'auth/LoginButton'
import { homeLink, Login } from 'routing/routes'

import PageLayout from 'pages/util/PageLayout'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import MyIDLogo from './MyID.svg'
import Loader from 'shared/components/Loader'

const LoginContainer = styled(Container)`
    max-width: 22.5rem;
    text-align: center;
`

type NoSessionProps = { returnTo?: string }
const NoSession: React.FC<NoSessionProps> = ({ returnTo }): React.ReactElement => {
    return (
        <>
            <Text size={'5'} my={3}>
                No MyID session...
            </Text>

            <LoginButton returnTo={returnTo} />
        </>
    )
}

const SessionCheck: React.FC = (): React.ReactElement => {
    const auth = React.useContext(AuthContext)
    const { returnTo, forceRelogin } = useParams<Login>()
    const authDispatch = React.useContext(AuthDispatchContext)
    React.useEffect(() => {
        if (forceRelogin) {
            authDispatch(authActions.signout())
        }
    }, [forceRelogin, authDispatch])

    const renderOption = (): React.ReactElement => {
        if (auth.authenticationPending) {
            return (
                <Text size={'5'} my={3}>
                    Waiting for backend authentication...
                    <Loader size="small" center />
                </Text>
            )
        }

        if (!auth.signedIn || forceRelogin) {
            return <NoSession returnTo={returnTo} />
        }

        return <Redirect to={homeLink()} />
    }

    return (
        <PageLayout>
            <LoginContainer
                className="session no-session"
                alignItems="stretch"
                flexDirection="column"
                mt={128}
                mx={'auto'}
            >
                <img
                    style={{ margin: 'auto' }}
                    alt="MyID Logo"
                    width="201"
                    height="77"
                    src={MyIDLogo}
                />

                {renderOption()}
            </LoginContainer>
        </PageLayout>
    )
}

export default SessionCheck
