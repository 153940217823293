import React from 'react'

const USE_PURE_FN_TAG = 'usePureFnTag'

export type PureFn<T> = T & {
    readonly _tag: typeof USE_PURE_FN_TAG
}

/**
 * Produce a function that will not cause rerenders
 * The given function MUST be a pure function that has no external dependencies
 */
export function usePureFn<T extends (...args: any[]) => any>(fn: T): PureFn<T> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pfn = React.useCallback(fn, []) as PureFn<T>
    ;(pfn as any)._tag = USE_PURE_FN_TAG
    return pfn
}

export function isPureFn<T>(u: unknown): u is PureFn<T> {
    return typeof u === 'function' && (u as any)._tag === USE_PURE_FN_TAG
}
