import React from 'react'
import FormikSelect, { FormikSelectProps } from 'shared/components/Select/FormikSelect'
import { useField } from 'formik'
import { usePureFn } from 'shared/hooks/usePureFn'
import invariant from 'tiny-invariant'

type WeightOption = { value: string; label: string }

const WEIGHT_OPTIONS: WeightOption[] = generateWeightOptions()

type StorylineWeightProps = Omit<
    FormikSelectProps<WeightOption>,
    'options' | 'getOptionLabel' | 'getOptionValue'
>
export const StorylineWeight: React.FC<StorylineWeightProps> = (props): React.ReactElement => {
    const [{ value }, , { setValue }] = useField<string>(props.name)

    return (
        <FormikSelect<WeightOption>
            placeholder="Select weight"
            {...props}
            onChange={usePureFn(option => {
                invariant(option)
                invariant(!Array.isArray(option))
                setValue(option.value)
            })}
            value={WEIGHT_OPTIONS.find(wo => wo.value === value)}
            options={WEIGHT_OPTIONS}
        />
    )
}

export default StorylineWeight

function generateWeightOptions(): WeightOption[] {
    const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const NUMERIC_VARIATIONS = [2, 3, 4, 5]
    const variations: WeightOption[] = []
    // All letters (A-Z)
    for (const letter of ALPHABET) {
        variations.push({ value: letter, label: letter })
    }
    // Letter variations (A2,A3,A4,A5,B2,B3,B4,B5,...,Z5)
    for (const letter of ALPHABET) {
        for (const num of NUMERIC_VARIATIONS) {
            const alphanum = letter + num
            variations.push({ value: alphanum, label: alphanum })
        }
    }
    return variations
}
