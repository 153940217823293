import React from 'react'
import { mediumDateFormatter } from 'shared/mediumDateFormatter'

type AnyTag = string | React.FC<never>

type MediumDateProps<Tag extends AnyTag> = {
    date: Date
    as?: Tag
} & (Tag extends keyof JSX.IntrinsicElements
    ? JSX.IntrinsicElements[Tag]
    : Tag extends React.ComponentType<infer Props>
    ? Props & JSX.IntrinsicAttributes
    : never)

export default function MediumDate<Tag extends AnyTag = 'span'>({
    date,
    as,
    ...props
}: MediumDateProps<Tag>): React.ReactElement {
    return React.createElement(as ?? 'span', { title: date.toUTCString(), ...props } as any, [
        mediumDateFormatter.format(date),
    ])
}
