import React from 'react'
import { CellProps } from 'react-table'
import { RouteLinkCell, OptionalCell } from 'shared/components/Table'
import { AbridgedEpisode } from 'model/Episode'
import { episodeLink } from 'routing/routes'

const EpisodeLinkCell: React.FC<CellProps<AbridgedEpisode>> = props => {
    const episode = props.row.original
    return (
        <RouteLinkCell to={episodeLink(episode)}>
            <OptionalCell {...props} />
        </RouteLinkCell>
    )
}

export default EpisodeLinkCell
