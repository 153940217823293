import { useInterpret } from '@xstate/react'
import { useAuthContext } from 'auth/Auth'
import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { createReactContextHelpers } from 'xstate-helpers/react/createReactContextHelpers'
import { createLockedTitlesMachine } from './lockedTitles.machine'

export const {
    Provider: LockedTitlesProvider,
    useActor: useLockedTitlesActor,
    useInterpreter: useLockedTitlesInterpreter,
    useSelector: useLockedTitlesSelector,
} = createReactContextHelpers('LockedTitles', () => {
    const auth = useAuthContext()
    const handleError = useErrorHandler()
    const service = useInterpret(
        () =>
            createLockedTitlesMachine({
                handleCriticalError: (_, e) => handleError(e.data),
            }),
        {
            devTools: false,
        },
    )

    React.useEffect(() => {
        service.send({ type: 'SET_USER', user: auth.user })
    }, [service, auth.user])

    return service
})

export function useRefreshLockedTitles(): () => void {
    const service = useLockedTitlesInterpreter()
    return React.useCallback(() => service.send({ type: 'REFRESH' }), [service])
}

export default LockedTitlesProvider
