import { useUser } from 'auth/Auth'
import { useInterpret } from '@xstate/react'
import { createAssignmentsMachineFactory } from './createAssignment.machine'
import { createReactContextHelpers } from 'xstate-helpers/react/createReactContextHelpers'

export const {
    Provider: CreateAssignmentsProvider,
    useActor,
    useSelector,
    useInterpreter,
} = createReactContextHelpers('CreateAssignment', () => {
    const user = useUser()
    return useInterpret(() => createAssignmentsMachineFactory({ user }), {
        devTools: true,
    })
})
