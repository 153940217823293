import React from 'react'
import noop from 'lodash/noop'

/**
 * Given a list of object of params and their names or keys, this hook warns
 * if they change more than `maxAllowedChanges` times.
 *
 * Only executed in development environment.
 */
export function useStableParamsWarningImplementation<T extends Record<string, any> | any[]>(
    params: T,
    paramNames: T extends any[] ? readonly string[] : readonly (keyof T)[],
    contextName: string,
    maxAllowedChanges = 0,
): void {
    let deps: any[]
    if (Array.isArray(params)) {
        if (params.length !== paramNames.length) {
            throw new Error(
                'useStableParamsWarning was given list of params and paramNames of unequal length',
            )
        }
        deps = params as any[]
    } else {
        deps = paramNames.map(k => (params as any)[k])
    }

    const previousDeps = React.useRef<any[]>(deps)
    const timesChanged = React.useRef(0)

    if (deps.length !== previousDeps.current.length) {
        throw new Error('useStableParamsWarning: Number of deps changed!')
    }

    React.useEffect(() => {
        if (timesChanged.current >= maxAllowedChanges + 1) {
            let changed: string[] = []
            {
                let i = -1
                while (i++ < deps.length - 1) {
                    if (deps[i] !== previousDeps.current[i]) {
                        changed.push(paramNames[i])
                    }
                }
            }

            const message = `Following params of "${contextName}" change more often than expected: ${changed.join(
                ', ',
            )}`

            console.warn(message)

            // You can use 'pause on caught exceptions.'
            try {
                throw Error(message)
            } catch {}
        }

        timesChanged.current++
        previousDeps.current = deps

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}

/**
 * Given a list of object of params and their names or keys, this hook warns
 * if they change more than `maxAllowedChanges` times.
 *
 * Only executed in development environment.
 */
export const useStableParamsWarning =
    process.env.NODE_ENV !== 'development'
        ? (noop as typeof useStableParamsWarningImplementation)
        : useStableParamsWarningImplementation
