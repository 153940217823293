import React from 'react'
import styled from 'styled-components'

import Container from 'shared/components/Container'
import Text from 'shared/components/Text'

import { ReactComponent as CheckMark } from './check-mark-icon.svg'

const CheckboxWrap = styled(Container)<{ disabled?: boolean }>`
    margin: 1rem 1rem 0rem 0rem;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

const Checked = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 2px;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
`

const Unchecked = styled(Checked)`
    border: 1px solid ${props => props.theme.colors.gray};
    background-color: ${props => props.theme.colors.white};
    &:hover {
        border-color: ${props => props.theme.colors.primary};
    }
`

const Disabled = styled(Checked)`
    border: 0;
    background-color: ${props => props.theme.colors.border};
`

type CheckboxProps = React.PropsWithoutRef<JSX.IntrinsicElements['div']> & {
    title?: string
    checked?: boolean
    disabled?: boolean
}

const Checkbox: React.FC<CheckboxProps> = props => {
    const { checked, onClick, title, disabled, ...restProps } = props

    return (
        <CheckboxWrap disabled={disabled} onClick={disabled ? undefined : onClick} {...restProps}>
            {disabled ? (
                <Disabled />
            ) : checked ? (
                <Checked>
                    <CheckMark />
                </Checked>
            ) : (
                <Unchecked />
            )}
            {title ? (
                <Text size="5" pl="1">
                    {title}
                </Text>
            ) : null}
        </CheckboxWrap>
    )
}

export default Checkbox
