import * as t from 'io-ts'
import { nullable } from 'codecs/util/nullable'

export const CastMemberCodec = t.type({
    talent: nullable(
        t.type({
            talentId: t.string,
            talentName: t.string,
        }),
    ),
    role: nullable(
        t.type({
            roleId: t.string,
            roleLabel: t.string,
        }),
    ),

    character: nullable(
        t.type({
            characterId: t.string,
            characterName: t.string,
        }),
    ),
})

export type CastMember = t.TypeOf<typeof CastMemberCodec>
