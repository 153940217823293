import React from 'react'
import ReactTooltip from 'react-tooltip'
import useHover from '@react-hook/hover'

import styled from 'shared/theme'
import Container from 'shared/components/Container'
import { useFormattedWIPInfo } from 'shared/resource/WIPInfo'

import { ReactComponent as LockClosed } from './lock-closed-icon.svg'
import { ReactComponent as LockOpened } from './lock-opened-icon.svg'
import { Lock, lockMatches } from '@genome-web-forms/common/lock'
import { useUser } from 'auth/Auth'

//Issue: https://github.com/wwayne/react-tooltip/issues/588
const ReactTooltipWrapper = styled.div`
    & {
        .__react_component_tooltip {
            position: fixed;
            opacity: 0;
        }
        .__react_component_tooltip.show {
            opacity: 1;
            border-radius: 0.25rem;
            background-color: ${({ theme }) => theme.colors.white};
            border: 0.0625rem solid ${({ theme }) => theme.colors.border};
            padding: 1rem;
        }
    }
`

const TooltipTitle = styled(Container)`
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    text-shadow: none;
    padding-bottom: 0.25rem;
    color: ${props => props.theme.colors.textPrimary};
`
const TooltipValue = styled(Container)`
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    text-shadow: none;
    color: ${props => props.theme.colors.textSecondary};
`

type LockStatus = 'free' | 'editing' | 'ownedByMe' | 'ownedByOther'
type LockClosedIconProps = { status: Omit<LockStatus, 'editing'> }

export const LockClosedIcon = styled(LockClosed).attrs<LockClosedIconProps>(
    ({ theme, status }) => ({
        fill:
            status === 'free'
                ? theme.colors.border
                : status === 'ownedByMe'
                ? theme.colors.success
                : theme.colors.warning,
    }),
)<LockClosedIconProps>``

export const LockOpenedIcon = styled(LockOpened).attrs(({ theme }) => ({
    fill: theme.colors.success,
}))``

export const LockIcon = React.forwardRef<SVGSVGElement, { dataFor?: string; status: LockStatus }>(
    ({ dataFor, status }, forwardedRef) => {
        return status === 'editing' ? (
            <LockOpenedIcon ref={forwardedRef} data-tip="" data-for={dataFor} />
        ) : (
            <LockClosedIcon ref={forwardedRef} data-tip="" status={status} data-for={dataFor} />
        )
    },
)

// we need consistent ids for the lock icon tooltip, so we generate it here
let id = 0
const genId = (): number => ++id
const useLockIconId = (): string => {
    return `lock-icon-` + String(React.useState(genId)[0])
}

const LockIconWithTooltip: React.FC<{
    editing?: boolean
    lock?: Lock | null
}> = ({ editing, lock }) => {
    const iconRef = React.useRef(null)
    const isHovering = useHover(iconRef)
    const user = useUser()
    const id = useLockIconId()

    const status: LockStatus = lock
        ? lockMatches(lock, user)
            ? editing
                ? 'editing'
                : 'ownedByMe'
            : 'ownedByOther'
        : 'free'

    return (
        <>
            <LockIcon ref={iconRef} status={status} dataFor={id} />
            {isHovering && <LockTooltip id={id} lock={lock} />}
        </>
    )
}

const LockTooltip: React.FC<{ id: string; lock?: Lock | null }> = ({ lock, id }) => {
    const lockData = useFormattedWIPInfo(lock)

    if (!lockData) return null

    return (
        <ReactTooltipWrapper>
            <ReactTooltip
                id={id}
                place="top"
                multiline={true}
                getContent={() => (
                    <Container flexDirection="column">
                        <Container flexDirection="column" mb={1}>
                            <TooltipTitle>Locked by:</TooltipTitle>
                            <TooltipValue>{lockData.lockedBy}</TooltipValue>
                        </Container>
                        {lockData.lockedOn && (
                            <Container flexDirection="column">
                                <TooltipTitle>Locked on:</TooltipTitle>
                                <TooltipValue>{lockData.lockedOn}</TooltipValue>
                            </Container>
                        )}
                    </Container>
                )}
            />
        </ReactTooltipWrapper>
    )
}

export default LockIconWithTooltip
