import React from 'react'
import { FormikTRSSelect, ImplementerFormikTRSSelectProps } from 'shared/components/TRS/TRSSelect'
import { useTRSSimpleFind, TRS_STORY_ARCHETYPES } from 'shared/hooks/useTRS'
import { StorylineAttribute } from 'codecs/storyline/StorylineAttribute'
import { usePureFn } from 'shared/hooks/usePureFn'

export type StorylineArchetypeSelectProps = ImplementerFormikTRSSelectProps<StorylineAttribute>
export const StorylineArchetypeSelect: React.FC<StorylineArchetypeSelectProps> = (
    props,
): React.ReactElement => {
    const { terms } = useTRSSimpleFind(TRS_STORY_ARCHETYPES)

    return (
        <FormikTRSSelect<StorylineAttribute>
            placeholder="Select storyline archetypes"
            {...props}
            terms={terms}
            termToOption={usePureFn(({ id, label }) => ({ id, label }))}
            isMulti={true}
            getOptionValue={usePureFn(({ id }) => id)}
            getOptionLabel={usePureFn(({ label }, term) => (term ? term.label : label))}
        />
    )
}

export default StorylineArchetypeSelect
