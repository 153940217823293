import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'shared/theme'
import { StyledArrow } from 'shared/components/ToggleButton'
import Text from 'shared/components/Text'
import Optional from 'shared/components/Optional'

import { AbridgedSeason } from 'model/Season'
import { AbridgedEpisode } from 'model/Episode'
import { seasonLink } from 'routing/routes'
import SeasonEpisodes from './SeasonEpisodes'

import { ReactComponent as LinkIcon } from './SeasonLink.svg'

const SeasonContainer = styled.div<{ isExpanded: boolean }>`
    margin: ${props => (props.isExpanded ? '-0.5rem -1rem' : 0)};
    cursor: pointer;
    padding: 0.5rem 1rem;
`
const SeasonHeader = styled.div<{ isExpanded: boolean }>`
    display: flex;
    background-color: ${props => (props.isExpanded ? props.theme.colors.activeField : 'auto')};
    padding: ${props => (props.isExpanded ? '0.5rem 1rem' : 0)};
`
const ToggleArrow = styled(StyledArrow)`
    align-self: center;
`
const SeasonTitleWrap = styled.div<{ isExpanded: boolean }>`
    flex-grow: 1;
`
const SeasonTitle = styled(Text)<{ isExpanded: boolean }>`
    color: ${props => (props.isExpanded ? props.theme.colors.primary : 'auto')};
`
const SeasonLinkIcon = styled(LinkIcon)`
    padding-left: 0.3125rem;
`

type SeasonProps = {
    onHeaderClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    isExpanded: boolean
    title?: string
    season: AbridgedSeason
    episodes: AbridgedEpisode[]
}

const Season: React.FC<SeasonProps> = ({
    onHeaderClick,
    isExpanded,
    title,
    season,
    episodes,
}): React.ReactElement => {
    return (
        <SeasonContainer isExpanded={isExpanded} onClick={onHeaderClick}>
            <SeasonHeader isExpanded={isExpanded}>
                <SeasonTitleWrap isExpanded={isExpanded}>
                    <SeasonTitle
                        size={isExpanded ? '4' : '5'}
                        variant="primary"
                        isExpanded={isExpanded}
                    >
                        <Optional value={title} />
                        {isExpanded && season.seasonId !== '###unknown_season###' ? (
                            <Link to={seasonLink(season)}>
                                <SeasonLinkIcon />
                            </Link>
                        ) : null}
                    </SeasonTitle>
                </SeasonTitleWrap>

                <ToggleArrow toggled={isExpanded ? 'true' : undefined} />
            </SeasonHeader>

            {isExpanded && <SeasonEpisodes {...{ episodes }} />}
        </SeasonContainer>
    )
}

export default Season
