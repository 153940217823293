import React from 'react'
import Button, { ButtonProps } from 'shared/components/Button'

import { buildMyIdOAuthString } from 'auth/buildMyIdOAuthString'

type Props = ButtonProps & {
    returnTo?: string
}
const LoginButton: React.FC<Props> = ({ returnTo, ...props }): React.ReactElement => {
    const [submitting, setSubmitting] = React.useState(false)

    const handleClick = React.useCallback(() => {
        setSubmitting(true)
        // redirect to MyID OAuth
        window.location.assign(buildMyIdOAuthString(returnTo))
    }, [returnTo])

    return (
        <Button
            {...props}
            size={'large'}
            disabled={submitting}
            isLoading={submitting}
            onClick={handleClick}
        >
            Log in
        </Button>
    )
}

export default LoginButton
