import React from 'react'
import TextualButton from 'shared/components/TextualButton'

import { AuthDispatchContext, actions as authActions } from 'auth/Auth'

const LogoutButton = React.forwardRef<
    HTMLButtonElement,
    React.PropsWithoutRef<JSX.IntrinsicElements['button']>
>((props, ref) => {
    const authDispatch = React.useContext(AuthDispatchContext)

    return (
        <TextualButton
            {...props}
            variant="secondary"
            size="6"
            ref={ref}
            onClick={() => {
                authDispatch(authActions.signout())
            }}
        >
            {props.children || 'Logout'}
        </TextualButton>
    )
})

export default LogoutButton
