/**
 * https://github.com/cypress-io/cypress/blob/develop/packages/driver/src/dom/elements.ts#L65
 *
 * Necesarry because React overwrites Input.value setter
 */
export function nativeInputValueSetter(el: HTMLInputElement, value: string): void {
    // @ts-ignore
    descriptor('HTMLInputElement', 'value').set!.call(el, value)
}

const descriptor = <T extends keyof Window, K extends keyof Window[T]>(
    klass: T,
    prop: K,
): PropertyDescriptor => {
    // @ts-ignore
    const descriptor = Object.getOwnPropertyDescriptor(window[klass].prototype, prop)

    if (descriptor === undefined) {
        throw new Error(
            `Error, could not get property descriptor for ${klass}  ${prop}. This should never happen`,
        )
    }

    return descriptor
}
