import * as t from 'io-ts'
import { request } from '@genome-web-forms/common/api'
import { MyIDUser, PERMISSION_DEBUG } from '@genome-web-forms/common/auth'
import { lockMatches } from '@genome-web-forms/common/lock'
import { LockedTitle } from 'model/LockedTitle'
import partition from 'lodash/partition'
import { WIP, WIPCodec } from 'model/WIP'
import { createMachine, assign, StateMachine, InterpreterFrom } from 'xstate'
import { authGWF } from 'api/auth'
import { hasPermission } from 'shared/components/UserHasPermission'
import config from 'shared/config'
import { releaseWIPLock } from 'api/wip'
import invariant from 'tiny-invariant'
import { queryClient } from 'shared/queryClient'

export type LockedTitlesService = InterpreterFrom<ReturnType<typeof createLockedTitlesMachine>>
export interface LockedTitlesContext {
    user: MyIDUser | undefined
    wips: WIP[]
    releasingWIPs: WIP[]
    myLockedTitles: LockedTitle[]
    otherLockedTitles: LockedTitle[]
    _refreshRequested?: number
    _refreshExecuted?: number
}
export type LockedTitlesEvent =
    | {
          type: 'REFRESH'
      }
    | {
          type: 'RELEASE'
          wips: WIP[]
      }
    | {
          type: 'SET_USER'
          user: MyIDUser | undefined
      }

export type LockedTitlesState = {
    value: 'refreshing' | 'idle' | 'releasing'
    context: LockedTitlesContext
}

type LockedTitlesConfig = {
    handleCriticalError: (ctx: LockedTitlesContext, e: { type: 'ERROR'; data: Error }) => void
}
export const createLockedTitlesMachine = (
    config: LockedTitlesConfig,
): StateMachine<LockedTitlesContext, any, LockedTitlesEvent, LockedTitlesState> =>
    createMachine<LockedTitlesContext, LockedTitlesEvent, LockedTitlesState>(
        {
            id: 'lockedTitlesMachine',
            initial: 'idle',
            context: {
                user: undefined,
                wips: [],
                releasingWIPs: [],
                myLockedTitles: [],
                otherLockedTitles: [],
            },
            on: {
                SET_USER: [
                    {
                        cond: (_, e) => !!e.user,
                        actions: 'setUser',
                        target: 'refreshing',
                    },
                    {
                        cond: (_, e) => !e.user,
                        actions: 'clear',
                        target: 'idle',
                    },
                ],
                REFRESH: { actions: 'setRefreshRequested' },
                RELEASE: { target: 'releasing', actions: 'setReleasingWips' },
            },
            states: {
                idle: {
                    always: {
                        cond: 'shouldRefresh',
                        target: 'refreshing',
                    },
                },
                refreshing: {
                    entry: 'setRefreshTime',
                    invoke: {
                        id: 'fetch-locked-titles',
                        src: async ctx => (ctx.user ? fetchLockedTitles(ctx.user) : []),
                        onDone: {
                            target: 'idle',
                            actions: ['updateDataFromBackend', 'partitionTitles'],
                        },
                        onError: { actions: 'handleCriticalError' },
                    },
                },
                releasing: {
                    invoke: {
                        id: 'release-wips',
                        src: (ctx, e) => {
                            invariant(e.type === 'RELEASE')
                            return Promise.allSettled(
                                e.wips.map(wip =>
                                    releaseWIPLock({
                                        user: ctx.user!,
                                        wip,
                                    }),
                                ),
                            )
                        },
                        onDone: [
                            {
                                target: 'idle',
                                actions: ['removeReleasedWips', 'partitionTitles'],
                            },
                        ],
                    },
                },
            },
        },
        {
            actions: {
                handleCriticalError: config.handleCriticalError as any,
                setUser: assign({ user: (_, e: any) => e.user }),
                clear: assign<LockedTitlesContext, LockedTitlesEvent>({
                    wips: [],
                    myLockedTitles: [],
                    otherLockedTitles: [],
                    releasingWIPs: [],
                }),

                updateDataFromBackend: assign({ wips: (_, e: any) => e.data }),
                partitionTitles: assign(ctx => {
                    const lockedTitles: LockedTitle[] = []
                    for (let wip of ctx.wips) {
                        let title = lockedTitles.find(title => title.resourceId === wip.resourceId)
                        if (!title) {
                            title = {
                                title: wip.title,
                                resourceId: wip.resourceId,
                                cwmClassType: wip.cwmClassType,
                                wips: [],
                            }
                            lockedTitles.push(title)
                        }
                        title.wips.push(wip)
                    }
                    const [myLockedTitles, otherLockedTitles] = partition(lockedTitles, t =>
                        t.wips.some(wip => lockMatches(wip.state, ctx.user)),
                    )

                    return { myLockedTitles, otherLockedTitles }
                }),
                setReleasingWips: assign({
                    releasingWIPs: (_, e) => {
                        invariant(e.type === 'RELEASE')
                        return e.wips
                    },
                }),
                setRefreshRequested: assign({
                    _refreshRequested: _ => +new Date(),
                }),
                setRefreshTime: assign({
                    _refreshExecuted: _ => +new Date(),
                }),
                removeReleasedWips: assign({
                    wips: (ctx, e: any) => {
                        // e.data is Promise.allSettled() result
                        const res: PromiseSettledResult<any>[] = e.data
                        const successfullyReleased = ctx.releasingWIPs.filter(
                            (_, i) => res[i].status === 'fulfilled',
                        )
                        return ctx.wips.filter(wip => !successfullyReleased.includes(wip))
                    },
                    releasingWIPs: _ => [],
                }),
            },
            guards: {
                shouldRefresh: ctx =>
                    typeof ctx._refreshRequested === 'number' &&
                    ctx._refreshRequested > (ctx._refreshExecuted || 0),
            },
        },
    )

async function fetchLockedTitles(user: MyIDUser): Promise<WIP[]> {
    const hasDebug = hasPermission(user, PERMISSION_DEBUG)
    const url = hasPermission(user, PERMISSION_DEBUG)
        ? `${config.urlGWF}/locked/all/wip`
        : `${config.urlGWF}/locked/singleUser/wip`
    return queryClient.fetchQuery(['locked-titles', user.email, hasDebug], () =>
        request(t.array(WIPCodec), authGWF(user!, { url })),
    )
}
