import React from 'react'
import { CharacterWithPortrayal } from 'codecs/Character'
import { orderBy } from 'natural-orderby'

import SearchInput from 'shared/components/SearchInput'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import Table, { CheckboxFilter } from 'shared/components/Table'
import { Column, CellProps } from 'react-table'
import { PillsWrapper, Pill } from 'shared/components/Table/TablePills'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import { useCharacters } from 'shared/characters/CharactersProvider'

const columns: Column<CharacterWithPortrayal>[] = [
    {
        Header: 'Character',
        accessor: c => c.characterName,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    {
        Header: 'Talent',
        accessor: character => character.portrayedBy.map(talent => talent.talentName),
        sortType: 'array',
        Filter: CheckboxFilter,
        filter: 'checkbox',
        Cell: ({ row: { original: character } }: CellProps<CharacterWithPortrayal>) => {
            const portrayedBy = React.useMemo(
                () => orderBy(character.portrayedBy, t => t.talentName),
                [character.portrayedBy],
            )

            return (
                <PillsWrapper>
                    {portrayedBy.map(talent => (
                        <Pill key={talent.talentId}>{talent.talentName}</Pill>
                    ))}
                </PillsWrapper>
            )
        },
    },
]
const initialTableState = {
    sortBy: [{ id: 'Character' }],
}

const Characters: React.FC = (): React.ReactElement => {
    const [searchText, setSearchText] = React.useState('')
    const characters = useCharacters<CharacterWithPortrayal>()

    return (
        <>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Characters
                    </Text>
                    <SearchInput value={searchText} onChange={e => setSearchText(e.target.value)} />
                </TableHeaderContent>
            </Container>

            <Table
                {...{
                    data: characters,
                    columns,
                    searchText,
                    initialState: initialTableState,
                }}
                emptyMessage="No characters added yet"
            />
        </>
    )
}

export default React.memo(Characters)
