import React from 'react'
import UnavailableText from 'shared/components/UnavailableText'
import MediumDate from 'shared/components/MediumDate'

type DateInput = Date | null | undefined

type Props = {
    date: DateInput
}

const FormattedDate: React.FC<Props> = ({ date }) => {
    if (!date) {
        return <UnavailableText>Unavailable</UnavailableText>
    }
    return <MediumDate date={date} />
}

type ReleaseDateProps =
    | { releaseDate: DateInput; originalPremiereDate?: DateInput }
    | { releaseDate?: DateInput; originalPremiereDate: DateInput }

export const ReleaseDate: React.FC<ReleaseDateProps> = props => {
    const releaseDateToRender = props.releaseDate || props.originalPremiereDate
    return <FormattedDate date={releaseDateToRender} />
}

export default FormattedDate
