import config from 'shared/config'
import * as t from 'io-ts'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import { queryClient } from 'shared/queryClient'

export type ResourceType = 'feature' | 'season' | 'episode' | 'series'
export interface ResourceRequest {
    resourceType: ResourceType
    resourceId: string
    user: MyIDUser
}

export type fetchResourceConfig<C extends t.Mixed> = ResourceRequest & {
    codec: C
}

export function fetchResource<C extends t.Mixed>({
    codec,
    resourceId,
    user,
    resourceType,
}: fetchResourceConfig<C>): Promise<t.TypeOf<C>> {
    return queryClient.fetchQuery(['resource', resourceType, resourceId, user.email], () =>
        request(
            codec,
            authGWF(user, {
                url: `${config.urlGWF}/creativework/${resourceType}/${encodeURIComponent(
                    resourceId,
                )}`,
            }),
        ),
    )
}
