import * as t from 'io-ts'
import fromPairs from 'lodash/fromPairs'

import { LockCodec } from '@genome-web-forms/common/lock'
import nullable from 'codecs/util/nullable'

export const PUBLISH_WORKFLOW_STATUS_RUNNING = 'RUNNING'
export const PUBLISH_WORKFLOW_STATUS_FAILED = 'FAILED'
export const PUBLISH_WORKFLOW_STATUS_SUCCEEDED = 'SUCCEEDED'
export const PUBLISH_WORKFLOW_STATUS_TIMED_OUT = 'TIMED_OUT'
export const PUBLISH_WORKFLOW_STATUS_ABORTED = 'ABORTED'
export const PUBLISH_WORKFLOW_STATUS_UNKNOWN = 'UNKNOWN'
export const PUBLISH_WORKFLOW_STATUS_SKIPPED = 'SKIPPED'
export const PublishWorkflowStatusValueCodec = t.keyof({
    [PUBLISH_WORKFLOW_STATUS_RUNNING]: null,
    [PUBLISH_WORKFLOW_STATUS_ABORTED]: null,
    [PUBLISH_WORKFLOW_STATUS_FAILED]: null,
    [PUBLISH_WORKFLOW_STATUS_TIMED_OUT]: null,
    [PUBLISH_WORKFLOW_STATUS_ABORTED]: null,
    [PUBLISH_WORKFLOW_STATUS_SUCCEEDED]: null,
    [PUBLISH_WORKFLOW_STATUS_UNKNOWN]: null,
    [PUBLISH_WORKFLOW_STATUS_SKIPPED]: null,
})

export const PublishWorkflowStatusCodec = t.type({
    status: PublishWorkflowStatusValueCodec,
    executionArn: t.string,
    executionArnBase64: t.string,
    name: t.string,
    startDate: t.string,
    stateMachineArn: t.string,
    stopDate: nullable(t.string),
    targetResourceId: t.string,
})
export type PublishWorkflowStatus = t.TypeOf<typeof PublishWorkflowStatusCodec>

const PublishWorkflowComponentResultCodec = t.type({
    status: t.keyof({
        [PUBLISH_WORKFLOW_STATUS_FAILED]: null,
        [PUBLISH_WORKFLOW_STATUS_SUCCEEDED]: null,
        [PUBLISH_WORKFLOW_STATUS_UNKNOWN]: null,
        [PUBLISH_WORKFLOW_STATUS_SKIPPED]: null,
    }),
    messages: t.array(t.string),
    errors: t.array(
        t.type({
            message: t.string,
            errorContext: t.array(t.UnknownRecord),
        }),
    ),
})
export interface ErrorContext {
    [K: string]: any
}
export const PUBLISH_WORKFLOW_COMPONENTS = ['storylines', 'relationships', 'metadata'] as const
export type PublishWorkflowComponent = typeof PUBLISH_WORKFLOW_COMPONENTS[number]
export const PublishWorkflowFulfilledCodec = t.type({
    workflowFulfilledResponse: t.type(
        fromPairs(PUBLISH_WORKFLOW_COMPONENTS.map(k => [k, PublishWorkflowComponentResultCodec])),
    ),
})
export type PublishWorkflowFulfilled = t.TypeOf<typeof PublishWorkflowFulfilledCodec>

export const PublishWorkflowErrorLockCodec = t.type({
    workflowErrorLockedResponse: LockCodec,
})
export type PublishWorkflowErrorLock = t.TypeOf<typeof PublishWorkflowErrorLockCodec>

export const PublishWorkflowErrorGenericCodec = t.type({
    workflowErrorResponse: t.UnknownRecord,
})
export type PublishWorkflowErrorGeneric = t.TypeOf<typeof PublishWorkflowErrorGenericCodec>

export const PublishWorkflowPendingCodec = t.type({
    workflowPendingResponse: t.type({
        dateStarted: t.string,
    }),
})
export type PublishWorkflowPending = t.TypeOf<typeof PublishWorkflowPendingCodec>

export const PublishWorkflowCombinedStatusCodec = t.union([
    PublishWorkflowErrorGenericCodec,
    PublishWorkflowErrorLockCodec,
    PublishWorkflowPendingCodec,
    PublishWorkflowFulfilledCodec,
])
export type PublishWorkflowCombinedStatus = t.TypeOf<typeof PublishWorkflowCombinedStatusCodec>
