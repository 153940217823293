import React from 'react'
import * as s from './schema'
import { Op } from './Op'

type DiffValueProps<T> = {
    diff: s.ValueDiff<T> | s.TextDiff
    map?: (v: T) => any
}
export function DiffValue<T>({ diff, map }: DiffValueProps<T>): React.ReactElement {
    if (map) {
        if (!(diff instanceof s.ValueDiff)) {
            throw new Error(`Cannot map DiffValue for: ${JSON.stringify(diff)}`)
        }

        diff = s.mapValue(diff as s.ValueDiff<T>, map)
    }

    if (!(diff instanceof s.ValueDiff) && !(diff instanceof s.TextDiff)) {
        throw new Error(`Cannot render DiffValue for: ${JSON.stringify(diff)}`)
    }

    if (diff instanceof s.TextDiff) {
        return (
            <>
                {diff.ops.map((op, i) => {
                    return (
                        <Op key={String(i)} type={op.type}>
                            {op.value}
                        </Op>
                    )
                })}
            </>
        )
    }

    if (diff.changed) {
        const ops = diff.ops as [s.DiffOpRemove<T>, s.DiffOpAdd<T>]
        return (
            <>
                <Op type={'remove'}>{ops[0].value}</Op> <Op type={'add'}>{ops[1].value}</Op>
            </>
        )
    }

    const ops = diff.ops as s.DiffOpSame<T>
    return <Op type={'same'}>{ops.value}</Op>
}
