import isEqual from 'lodash/isEqual'
import flatten from 'lodash/flatten'
import fromPairs from 'lodash/fromPairs'
import { diffArrays, diffWords, diffWordsWithSpace } from 'diff'
import { orderBy } from 'natural-orderby'

export class Schema<A, O = any> {
    readonly _A!: A
    constructor(readonly diff: (a: A, b: A) => Diff<A, O>) {}
}
export interface AnySchema extends Schema<any, any> {}

export type DiffOf<T> = T extends AnySchema
    ? ReturnType<T['diff']>
    : T extends (infer I)[]
    ? ArrayDiff<I>
    : T extends ValueObjectBrand
    ? ValueDiff<T>
    : T extends object
    ? { [K in keyof T]: DiffOf<T[K]> }
    : T extends string
    ? ValueDiff<string> | TextDiff
    : ValueDiff<T>

export class ValueSchema<A> extends Schema<ValueBranded<A>, ValueDiffOps<A>> {
    _tag: 'ValueSchema' = 'ValueSchema'
    constructor() {
        super(valueDiff)
    }
}
export const value = <A>(): ValueSchema<A> => new ValueSchema<A>()

export class TextSchema extends Schema<string, TextDiffOps> {
    _tag: 'TextSchema' = 'TextSchema'
    constructor(readonly options: { diffWhitespace?: boolean } = {}) {
        super((a, b) => {
            const diff = !!options.diffWhitespace ? diffWordsWithSpace : diffWords

            return new TextDiff(
                diff(a, b).map(change => {
                    if (change.added) {
                        return diffOpAdd(change.value)
                    } else if (change.removed) {
                        return diffOpRemove(change.value)
                    } else {
                        return diffOpSame(change.value)
                    }
                }),
            )
        })
    }
}
export const text = (options: { diffWhitespace?: boolean } = {}): TextSchema => {
    return new TextSchema(options)
}

export class IgnoreSchema<A = any> extends Schema<A, ValueDiffOps<A>> {
    readonly _tag: 'IgnoreSchema' = 'IgnoreSchema'
    constructor() {
        super(valueSame)
    }
}
export const ignore = <A = any>(): IgnoreSchema<A> => new IgnoreSchema<A>()

export type Props = { [key: string]: AnySchema }
export interface SchemaC<P extends Props>
    extends InterfaceSchema<
        P,
        { [K in keyof P]: TypeOf<P[K]> },
        { [K in keyof P]: DiffOf<P[K]> }
    > {}
export class InterfaceSchema<P, A = any, O = any> extends Schema<A, O> {
    readonly _tag: 'InterfaceSchema' = 'InterfaceSchema'
    constructor(diff: InterfaceSchema<P, A, O>['diff'], readonly props: P) {
        super(diff)
    }
}
export const schema = <P extends Props>(props: P): SchemaC<P> => {
    const keys = Object.keys(props)
    const schemas = keys.map(key => props[key])
    const len = keys.length
    return new InterfaceSchema((a, b) => {
        let ops: { [key: string]: Diff<any, any> } = {}
        for (let i = 0; i < len; i++) {
            const key = keys[i]
            const schema = schemas[i]
            ops[key] = schema.diff(a[key], b[key])
        }
        return new SchemaDiff(ops as any)
    }, props)
}

export const isSchemaC = (u: unknown): u is InterfaceSchema<Props> => {
    return (u as any)._tag === 'InterfaceSchema'
}

export interface ArraySchemaC<S extends AnySchema>
    extends ArraySchema<S, Array<TypeOf<S>>, ArrayDiffOps<TypeOf<S>>[]> {}
export class ArraySchema<S, A = any, O = any> extends Schema<A, O> {
    readonly _tag: 'ArraySchema' = 'ArraySchema'
    constructor(diff: ArraySchema<S, A, O>['diff'], readonly schema: S) {
        super(diff)
    }
}
export const array = <S extends AnySchema>(
    schema: S,
    compareBy?: keyof TypeOf<S> | ((object: TypeOf<S>) => any),
    sortBy?: keyof TypeOf<S> | ((object: TypeOf<S>) => any),
): ArraySchemaC<S> => {
    const getKey: undefined | ((a: TypeOf<S>) => any) =
        typeof compareBy === 'string'
            ? (a: TypeOf<S>) => a[compareBy as string]
            : (compareBy as () => any)
    const comparator: (a: TypeOf<S>, b: TypeOf<S>) => boolean =
        typeof getKey === 'function'
            ? (a, b) => isEqual(getKey(a), getKey(b))
            : (a, b) => !schema.diff(a, b).changed

    return new ArraySchema((a, b) => {
        if (getKey) {
            a = orderBy(a, getKey)
            b = orderBy(b, getKey)
        }

        let ops = flatten<ArrayDiffOps<TypeOf<S>>>(
            diffArrays(a, b, {
                comparator,
            }).map(changes => {
                if (changes.added) {
                    return changes.value.map(v => diffOpAdd(v))
                } else if (changes.removed) {
                    return changes.value.map(v => diffOpRemove(v))
                } else {
                    if (isSchemaC(schema) && getKey) {
                        const ids = changes.value.map(getKey)
                        const as = ids.map<TypeOf<S>>(id => {
                            return a.find(v => id === getKey(v))
                        })
                        const bs = ids.map<TypeOf<S>>(id => {
                            return b.find(v => id === getKey(v))
                        })
                        return as.map((av, i) => {
                            const bv = bs[i]
                            if (isEqual(av, bv)) {
                                return diffOpSame(av) as any
                            }
                            return schema.diff(av, bv) as any
                        })
                    }

                    return changes.value.map(v => diffOpSame(v))
                }
            }),
        )

        ops = sortBy
            ? orderBy(ops, op => {
                  const value: TypeOf<S> = op.value
                  return typeof sortBy === 'string' ? value[sortBy] : (sortBy as any)(value)
              })
            : ops

        return new ArrayDiff(ops)
    }, schema)
}

// --------------------------------------------------------

export abstract class Diff<A, O> {
    readonly _A!: A
    constructor(readonly ops: O) {}
    abstract _tag: any
    abstract get changed(): boolean
    abstract get value(): A
}
export interface AnyDiff extends Diff<any, any> {}
export type TypeOf<T extends { _A: any }> = T['_A']
export type OpsOf<T> = T extends { _O: any } ? T['_O'] : T extends { ops: any } ? T['ops'] : never

export interface ValueObjectBrand {
    readonly __ValueObject: unique symbol
}
export type ValueBranded<A> = A extends object ? A & ValueObjectBrand : A
type ValueDiffOps<A> = DiffOpSame<A> | [DiffOpRemove<A>, DiffOpAdd<A>]
export class ValueDiff<A> extends Diff<ValueBranded<A>, ValueDiffOps<A>> {
    _tag: 'ValueDiff' = 'ValueDiff'
    get changed(): boolean {
        return Array.isArray(this.ops)
    }
    get value(): ValueBranded<A> {
        return Array.isArray(this.ops) ? this.ops[1].value : (this.ops.value as any)
    }
}

export const isValueDiff = <A>(u: unknown): u is ValueDiff<A> => {
    return (u as any)?._tag === 'ValueDiff'
}
export const valueDiff = <A>(a: A, b: A): ValueDiff<A> => {
    return new ValueDiff(
        isEqual(a, b) ? new DiffOpSame(a) : [new DiffOpRemove(a), new DiffOpAdd(b)],
    )
}
export const mapValue = <A, B>(diffA: ValueDiff<A>, f: (a: A) => B): ValueDiff<B> => {
    if (diffA.changed) {
        const [removed, added] = diffA.ops as [DiffOpRemove<any>, DiffOpAdd<any>]
        return valueDiff(f(removed.value), f(added.value))
    } else {
        return valueSame(f((diffA.ops as DiffOpSame<any>).value))
    }
}
export const valueSame = <A>(a: A): ValueDiff<A> => {
    return valueDiff(a, a)
}

type TextDiffOps = (DiffOpAdd<string> | DiffOpRemove<string> | DiffOpSame<string>)[]
export class TextDiff extends Diff<string, TextDiffOps> {
    _tag: 'TextDiff' = 'TextDiff'
    get changed(): boolean {
        return this.ops.some(op => {
            return !isDiffOpSame(op)
        })
    }
    get value(): string {
        return this.ops
            .filter(op => !isDiffOpRemove(op))
            .map(op => op.value)
            .join('')
    }
}
export const isTextDiff = (u: unknown): u is TextDiff => {
    return u instanceof Diff && u._tag === 'TextDiff'
}

export class SchemaDiff<
    A extends {},
    O extends { [key: string]: AnyDiff } = DiffOf<A>,
> extends Diff<A, O> {
    _tag: 'SchemaDiff' = 'SchemaDiff'
    get changed(): boolean {
        return Object.keys(this.ops).some(key => {
            return (this.ops as any)[key].changed
        })
    }
    get value(): A {
        return fromPairs(
            Object.keys(this.ops).map(key => {
                const diff = (this.ops as any)[key] as AnyDiff
                return [key, diff.value]
            }),
        ) as A
    }
}
export const isSchemaDiff = (u: unknown): u is SchemaDiff<any> => {
    return (u as any)?._tag === 'SchemaDiff'
}

export type ArrayDiffOps<A> =
    | DiffOpAdd<A>
    | DiffOpRemove<A>
    | DiffOpSame<A>
    | (A extends object ? SchemaDiff<A> : never)
export class ArrayDiff<A> extends Diff<A[], ArrayDiffOps<A>[]> {
    _tag: 'ArrayDiff' = 'ArrayDiff'
    get changed(): boolean {
        return this.ops.some(op => {
            if (isSchemaDiff(op)) {
                return op.changed
            }
            return !isDiffOpSame(op)
        })
    }
    get value(): A[] {
        return this.ops.filter(op => !isDiffOpRemove(op)).map(op => op.value)
    }
}
export const isArrayDiff = <A>(u: unknown): u is ArrayDiff<A> => {
    return u instanceof Diff && u?._tag === 'ArrayDiff'
}

export type DiffOpType = 'add' | 'remove' | 'same'
export abstract class DiffOpBase<T = unknown> {
    abstract readonly type: DiffOpType
    constructor(public readonly value: T) {}
}
export const isDiffOp = <A>(u: unknown): u is DiffOpAdd<A> | DiffOpRemove<A> | DiffOpSame<A> => {
    return u instanceof DiffOpBase
}
export class DiffOpAdd<T> extends DiffOpBase<T> {
    readonly type: 'add' = 'add'
    static is<T>(u: unknown): u is DiffOpAdd<T> {
        return u instanceof DiffOpBase && u.type === 'add'
    }
}
export const diffOpAdd = <A>(a: A): DiffOpAdd<A> => new DiffOpAdd(a)
export const isDiffOpAdd = <A>(u: unknown): u is DiffOpAdd<A> => {
    return u instanceof DiffOpBase && u.type === 'add'
}
export class DiffOpRemove<T> extends DiffOpBase<T> {
    readonly type: 'remove' = 'remove'
}
export const diffOpRemove = <A>(a: A): DiffOpRemove<A> => new DiffOpRemove(a)
export const isDiffOpRemove = <A>(u: unknown): u is DiffOpRemove<A> => {
    return u instanceof DiffOpBase && u.type === 'remove'
}
export class DiffOpSame<T> extends DiffOpBase<T> {
    readonly type: 'same' = 'same'
}
export const diffOpSame = <A>(a: A): DiffOpSame<A> => new DiffOpSame(a)
export const isDiffOpSame = <A>(u: unknown): u is DiffOpSame<A> => {
    return u instanceof DiffOpBase && u.type === 'same'
}
