import React from 'react'

import { useOnClickOutsideRef } from 'shared/hooks/useOnClickOutside'
import useEventListener from 'shared/hooks/useEventListener'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { notification } from 'shared/notification'
import styled from 'shared/theme'
import { createMachine } from 'xstate'
import { useMachine } from '@xstate/react'

const StyledDiv = styled.div`
    position: absolute;
    input,
    input:hover,
    input:focus,
    input:active {
        background: transparent;
        border: 0;
        border-style: none;
        border-color: transparent;
        outline: none;
        outline-offset: 0;
        box-shadow: none;
    }
`

type copyToClipboardEvent = { type: 'CLICK' } | { type: 'CLICK_OUTSIDE' } | { type: 'COPY' }
type copyToClipboardTypestate = {
    value: 'readonly' | 'copy' | 'copy.notCopied' | 'copy.copied'
    context: never
}
const copyToClipboardMachine = createMachine<{}, copyToClipboardEvent, copyToClipboardTypestate>({
    initial: 'readonly',
    states: {
        readonly: {
            on: {
                CLICK: 'copy',
            },
        },
        copy: {
            on: {
                CLICK_OUTSIDE: 'readonly',
            },
            initial: 'notCopied',
            states: {
                notCopied: {
                    on: {
                        COPY: 'copied',
                    },
                },
                copied: {},
            },
        },
    },
})

type CopyTextProps = { text: string } & React.HTMLProps<HTMLDivElement>
export const CopyText: React.FC<CopyTextProps> = ({ text, children, ...props }) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const buttonRef = React.useRef<HTMLButtonElement>(null)
    const [state, send] = useMachine(copyToClipboardMachine)

    // close on clicking outside the panel
    const panelRef = useOnClickOutsideRef<HTMLDivElement>(
        useEventCallback(() => send('CLICK_OUTSIDE')),
    )
    // close on window resize
    useEventListener('resize', () => send('CLICK_OUTSIDE'))

    // select input text to prepare for copy
    React.useEffect(() => {
        if (!state.matches('copy')) {
            return
        }
        if (inputRef.current) {
            inputRef.current.focus()
            inputRef.current.select()
        }
        if (buttonRef.current) {
            buttonRef.current.scrollTo()
        }
    }, [state])

    return (
        <>
            <div {...props} onClick={() => send('CLICK')}>
                {text}
            </div>
            {state.matches('copy') && (
                <StyledDiv ref={panelRef} {...(props as any)}>
                    <input readOnly value={text} ref={inputRef} />
                    <button
                        ref={buttonRef}
                        title="Copy to clipboard"
                        onClick={() => copyToClipboard(() => send('COPY'))}
                    >
                        {state.matches('copy.copied') ? 'COPIED!' : 'COPY'}
                    </button>
                </StyledDiv>
            )}
        </>
    )
}

export default CopyText

export const copyToClipboard = (onCopy?: () => void): void => {
    try {
        // copy to clipboard
        const copy = document.execCommand('copy')
        if (copy) {
            onCopy && onCopy()
        } else {
            showCopyError()
        }
    } catch (err) {
        showCopyError()
    }

    //////////////////////

    function showCopyError(): void {
        // TODO Use messaging component
        notification.warn('Please press Ctrl/Cmd+C to copy')
    }
}
