import React from 'react'
import * as y from 'yup'
import { notification } from 'shared/notification'

export function warnOnValidationErrors<T extends unknown>(
    title: string,
    schema: y.ObjectSchema<any>,
    object: T,
): void {
    schema.validate(object, { abortEarly: false }).catch(e => {
        notification.warn(renderNotificationContents(title, e))
    })
}

const renderNotificationContents = (
    title: string,
    error: y.ValidationError,
): React.ReactElement => {
    return (
        <>
            <h3>{title}</h3>
            <ul>
                {error.errors.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index + error}>{error}</li>
                ))}
            </ul>
        </>
    )
}
