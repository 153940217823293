import * as t from 'io-ts'
import { MetadataCodec, Metadata } from 'model/metadata/Metadata'
import { FeatureStorylineCodec, FeatureStoryline } from 'codecs/storyline/FeatureStoryline'
import { EpisodeStorylineCodec, EpisodeStoryline } from 'codecs/storyline/EpisodeStoryline'
import { RelationshipCodec, Relationship } from 'codecs/Relationship'
import { CWMClassTypeAnyCodec } from '@genome-web-forms/common/model/CWMClassType'
import { nullable } from 'codecs/util/nullable'
import { fromNullable } from 'codecs/util/fromNullable'
import { LockCodec } from '@genome-web-forms/common/lock'

export type WIPDataType = 'metadata' | 'relationships' | 'storylines'

export type WIPDataForType = {
    metadata: Metadata
    relationships: Array<Relationship>
    storylines: Array<FeatureStoryline> | Array<EpisodeStoryline>
}

const COMMON = {
    resourceId: t.string,
    title: t.string,
    cwmClassType: CWMClassTypeAnyCodec,
    state: nullable(LockCodec),
}
export const WIPMetadataCodec = t.type({
    ...COMMON,
    dataType: t.literal('metadata'),
    metadata: fromNullable(MetadataCodec, {
        displayGenre: null,
        otherGenres: [],
        attributes: {},
        locations: [],
    }),
})
export interface WIPMetadata extends t.TypeOf<typeof WIPMetadataCodec> {}

export const WIPStorylinesCodec = t.type({
    ...COMMON,
    dataType: t.literal('storylines'),
    storylines: fromNullable(
        t.union([t.array(EpisodeStorylineCodec), t.array(FeatureStorylineCodec)]),
        [],
    ),
})
export interface WIPStorylines extends t.TypeOf<typeof WIPStorylinesCodec> {}

export const WIPRelationshipsCodec = t.type({
    ...COMMON,
    dataType: t.literal('relationships'),
    relationships: fromNullable(t.array(RelationshipCodec), []),
})
export interface WIPRelationships extends t.TypeOf<typeof WIPRelationshipsCodec> {}

export type WIP = WIPMetadata | WIPRelationships | WIPStorylines
export const WIPCodec: t.Type<WIP> = t.union([
    WIPMetadataCodec,
    WIPStorylinesCodec,
    WIPRelationshipsCodec,
])

export function getData<T extends WIPDataForType[keyof WIPDataForType]>(
    wip: WIP,
    dataType?: WIPDataType,
): T {
    dataType = dataType ?? wip.dataType
    if (wip.dataType !== dataType) {
        throw new Error(
            `Cannot extract data from wip, mismatched type. ` +
                `WIP has type "${wip.dataType}" but we tried to extract "${dataType}"`,
        )
    }

    return (wip as any)[dataType]
}
