import { useRef, useLayoutEffect, useEffect, EventHandler, SyntheticEvent } from 'react'

type UseEventListener = <T extends SyntheticEvent<any>>(
    eventName: string,
    handler: EventHandler<T>,
    element?: HTMLElement | typeof window,
) => void

const useEventListener: UseEventListener = (eventName, handler, element = window): void => {
    const savedHandler = useRef(handler)

    useLayoutEffect(() => {
        savedHandler.current = handler
    })

    useEffect(() => {
        const eventListener = (event: any): void => savedHandler.current(event)

        element.addEventListener(eventName, eventListener)

        return () => {
            element.removeEventListener(eventName, eventListener)
        }
    }, [eventName, element])
}

export default useEventListener
