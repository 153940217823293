import React from 'react'

export function useScrollToSelf(ref: React.RefObject<HTMLElement>, shouldScroll: boolean): void {
    const hasScrolled = React.useRef(false)
    React.useEffect(() => {
        if (ref.current && shouldScroll && !hasScrolled.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
            hasScrolled.current = true
        }
    }, [ref, shouldScroll])
}
