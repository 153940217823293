import { BroadcastChannel } from 'broadcast-channel'
import FastMutex from 'vendor/FastMutex'
import { delay } from 'shared/util/delay'
import Debug from 'debug'
import { isTagged } from '@genome-web-forms/common/util'

const debug = Debug('gwf:TabExclusiveLock')

export type Message = MessageQuery | MessageQueryResponse | MessageAquire

export type MessageAquire = {
    type: 'AQUIRE'
    token: string
    lockId: string
}
export type MessageQuery = {
    type: 'QUERY'
    token: string
}
export type MessageQueryResponse = {
    type: 'QUERY_RESPONSE'
    token: string
    lockId: string
}

export type AccessRevokedCallback = (msg: MessageAquire) => void

const LOCK_BROADCAST_CHANNEL = 'gwf-title-lock'

/**
 * This class provides a way to communicate a lock of a resource between browser tabs.
 *
 * The idea is that when we try to aquire a resource, the following steps are taken:
 *
 *  - A mutex is locked so that only we can broadcast QUERY and AQUIRE messages.
 *  The only thing other browsers are allowed to message is QUERY_RESPONSE messages.
 *
 *  - We broadcast QUERY "is someone else currently holding this resource"
 *  - We wait for 1 second to accumulate responses. If someone else is holding the resource, we bail
 *  - If no one else is holding the resource, we insure we hold it by broadcasting an AQUIRE message
 *
 *  - We release the mutex
 *
 *
 * Additionally, when we receive an AQUIRE with a resource we're currently holding,
 * an accessRevokedCallback() function will be called
 */
export class TabExclusiveLock {
    private mutex: FastMutex
    private channel: BroadcastChannel
    private lockId: null | string = null

    constructor(public accessRevokedCallback?: AccessRevokedCallback) {
        this.mutex = new FastMutex()
        this.channel = new BroadcastChannel(LOCK_BROADCAST_CHANNEL, { webWorkerSupport: false })

        this.channel.addEventListener('message', this.handleMessage)
    }

    private handleMessage = (msg: Message): void => {
        debug('receiv', msg.type, { msg })

        switch (msg.type) {
            case 'QUERY': {
                if (this.lockId) {
                    this.postMessage({
                        type: 'QUERY_RESPONSE',
                        token: msg.token,
                        lockId: this.lockId,
                    })
                }
                break
            }

            case 'AQUIRE': {
                if (msg.lockId === this.lockId && this.accessRevokedCallback) {
                    debug('access revoked')
                    this.accessRevokedCallback(msg)
                }
                break
            }
        }
    }

    aquire(lockId: string): Promise<void> {
        const checkExclusivity = async (): Promise<void> => {
            let successfulClaim = true
            const token = this.lockId + ':' + randomToken(10)

            const handleQueryResponse = (msg: Message): void => {
                if (msg.type === 'QUERY_RESPONSE' && msg.token === token && msg.lockId === lockId) {
                    successfulClaim = false
                }
            }
            this.channel.addEventListener('message', handleQueryResponse)

            // query other tabs for their current locks
            await this.postMessage({ type: 'QUERY', token })

            // wait for responses from other tabs for 1 second
            await delay(1000)

            debug('Status of claim after wait time:', successfulClaim)

            this.channel.removeEventListener('message', handleQueryResponse)
            if (successfulClaim) {
                this.lockId = lockId
                this.postMessage({ type: 'AQUIRE', token, lockId: this.lockId })
            } else {
                throw new TabLockAquireError(
                    'You are already editing this title in another browser tab.',
                )
            }
        }

        debug('Attempting to aquire lock for', lockId)

        return this.mutex
            .lock(lockId)
            .then(checkExclusivity)
            .finally(() => this.mutex.release(lockId))
    }

    destroy(): void {
        this.release()
        this.accessRevokedCallback = undefined
        this.channel.close()
        debug('destroy')
    }

    release(): void {
        if (this.lockId) {
            debug('Releasing lock for:', this.lockId)
            this.lockId = null
        }
    }

    private postMessage(msg: Message): Promise<void> {
        debug('postMessage', msg.type, { msg })
        return this.channel.postMessage(msg)
    }
}

/**
 *  https://stackoverflow.com/a/1349426/3443137
 */
function randomToken(length: number): string {
    if (!length) length = 5
    let text = ''
    const possible = 'abcdefghijklmnopqrstuvwxzy0123456789'

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
}

export class TabLockAquireError extends Error {
    readonly _tag: 'TabLockAquireError' = 'TabLockAquireError'
    constructor(message?: string) {
        super(message)
        Object.setPrototypeOf(this, TabLockAquireError.prototype)
    }
}

export const isTabLockAquireError = isTagged<TabLockAquireError>('TabLockAquireError')
