import { WorkflowPriority } from '@genome-web-forms/server'

export const WorkflowPriorities: WorkflowPriority = Object.keys(
    WorkflowPriority,
) as unknown as WorkflowPriority

export const WorkflowPriorityOptions = [
    { value: WorkflowPriority.LOW, label: 'Low Priority' },
    { value: WorkflowPriority.MEDIUM, label: 'Medium Priority' },
    { value: WorkflowPriority.HIGH, label: 'High Priority' },
]
