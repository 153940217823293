import React from 'react'
import { titleCase } from 'shared/util/titleCase'

import { WorkflowError } from 'codecs/publish-workflow/parse'
import { notification, NotificationDefinition } from '../notification'

import styled, { theme } from 'shared/theme'

const ResultHolder = styled.div`
    color: #fefefe;

    ul {
        margin: 0;
        margin-top: 0.5rem;
        list-style-type: '- ';
        padding-inline-start: 0.75rem;
    }
`

const Container = styled.div`
    ${ResultHolder} + ${ResultHolder} {
        margin-top: 0.75rem;
    }
`

// we must use the `theme` directly instead of through `props.theme`, because toasts are rendered
// outside the <ThemeProvider> context, and the theme is not available on `props`
const Errors = styled.div`
    padding: 0.85rem 1.2rem;
    background-color: ${theme.colors.danger};
`
const Success = styled.div`
    padding: 0.85rem 1.2rem;
    background-color: ${theme.colors.success};
`
const MAX_ERRORS_TO_SHOW = 5

export const buildWorkflowErrorNotification = (error: WorkflowError): NotificationDefinition => {
    const errors = error.errors.map(error => {
        const errorsLength = error.errors.length
        return (
            <ResultHolder key={error.component}>
                <h3>{titleCase(error.component)} - not published. Errors:</h3>
                <ul>
                    {error.errors.slice(0, MAX_ERRORS_TO_SHOW).map(({ message }, i) => (
                        <li key={message + String(i)}>{message}</li>
                    ))}
                </ul>
                {errorsLength > MAX_ERRORS_TO_SHOW && (
                    <p>{errorsLength - MAX_ERRORS_TO_SHOW} additional errors not shown</p>
                )}
            </ResultHolder>
        )
    })

    const unknowns = error.unknowns.map(unknown => {
        const errorsLength = unknown.messages.length
        return (
            <ResultHolder key={unknown.component}>
                <h3>{titleCase(unknown.component)} - not published, with UNKNOWN status.</h3>
                <ul>
                    {unknown.messages.slice(0, MAX_ERRORS_TO_SHOW).map((message, i) => (
                        <li key={message + String(i)}>{message}</li>
                    ))}
                </ul>
                {errorsLength > MAX_ERRORS_TO_SHOW && (
                    <p>{errorsLength - MAX_ERRORS_TO_SHOW} additional errors not shown</p>
                )}
            </ResultHolder>
        )
    })

    const success = error.success.map(success => (
        <ResultHolder key={success.component}>
            <h3>{titleCase(success.component)} - successfully published!</h3>
        </ResultHolder>
    ))

    return {
        content: (
            <Container>
                <Errors>
                    {errors}
                    {unknowns}
                </Errors>
                <Success>{success}</Success>
            </Container>
        ),
        className: 'workflow-error-notification',
    }
}
export const workflowErrorNotification = (error: WorkflowError): void => {
    const { content, ...options } = buildWorkflowErrorNotification(error)

    notification(content, options)
}
