import React from 'react'
import styled from 'shared/theme'
import Button, { ButtonProps } from '../Button'

export type LinkButtonProps = {
    href: string
    target: '_self' | '_blank' | '_top' | '_parent'
    rel: string
}

const StyledButton = styled(Button)`
    width: 100%;
    height: 100%;
`

const LinkButton: React.FC<ButtonProps & LinkButtonProps> = ({
    href,
    target = '_self',
    children,
    rel,
    ...restProps
}): React.ReactElement => {
    return (
        <a href={href} target={target} rel={rel}>
            <StyledButton {...restProps}>{children}</StyledButton>
        </a>
    )
}

export default LinkButton
