import * as t from 'io-ts'
import * as y from 'yup'
import * as s from 'shared/diff/schema'
import identity from 'lodash/identity'

import { CharacterCodec, CharacterFormSchema } from 'codecs/Character'
import { AttributeValueCodec, AttributeValueFormSchema } from 'codecs/Attribute'
import { ArcValueCodec, ARC_VALUES } from 'codecs/Arc'
import { orderBy } from 'natural-orderby'
import { matchTypeOrSubtype } from 'shared/components/Storyrole/matchTypeOrSubtype'

export const StoryRoleAttributeCodec = t.type({
    arc: ArcValueCodec,
    type: t.string,
    typeId: t.string,
    values: t.array(AttributeValueCodec),
})

export const StoryRoleCodec = t.type({
    storyRoleId: t.string,
    attributes: t.array(StoryRoleAttributeCodec),
    character: t.array(CharacterCodec),
})

export type StoryRoleAttribute = t.TypeOf<typeof StoryRoleAttributeCodec>
export type StoryRole = t.TypeOf<typeof StoryRoleCodec>

/////////////////////////////////////////////
// Constants
/////////////////////////////////////////////

export const STORYROLE_TYPE_CHARACTER_ARCHETYPE = {
    typeId: 'http://data.disney.com/resources/CharacterArchetypes',
    type: 'Character Archetypes',
} as const

export const STORYROLE_TYPE_TRAITS = {
    typeId: 'http://data.disney.com/resources/CharacterPersonalityTraits',
    type: 'Character Personality Traits',
} as const

export const STORYROLE_TYPE_MOTIVATIONS = {
    typeId: 'http://data.disney.com/resources/Motivations',
    type: 'Motivations',
} as const

export type STORYROLE_TYPE =
    | typeof STORYROLE_TYPE_MOTIVATIONS
    | typeof STORYROLE_TYPE_TRAITS
    | typeof STORYROLE_TYPE_CHARACTER_ARCHETYPE

const StoryRoleAttributeDiffSchema = s.schema({
    arc: s.value<string>(),
    labels: s.array(s.value<string>(), identity),
})
export type DiffableStoryRoleAttribute = s.TypeOf<typeof StoryRoleAttributeDiffSchema>
export const StoryRoleDiffSchema = s.schema({
    storyRoleId: s.ignore<string>(),
    character: s.value<string>(),
    characterArchetypes: s.array(StoryRoleAttributeDiffSchema, 'arc'),
    motivations: s.array(StoryRoleAttributeDiffSchema, 'arc'),
    traits: s.array(StoryRoleAttributeDiffSchema, 'arc'),
})
export type DiffableStoryRole = s.TypeOf<typeof StoryRoleDiffSchema>

export const toDiffableStoryRole = (sr: StoryRole): DiffableStoryRole => {
    return {
        storyRoleId: sr.storyRoleId,
        character: sr.character[0]?.characterName ?? '<<Empty>>',
        characterArchetypes: toStorRoleAttributeDiffSchema(
            sr.attributes,
            STORYROLE_TYPE_CHARACTER_ARCHETYPE,
        ),
        motivations: toStorRoleAttributeDiffSchema(sr.attributes, STORYROLE_TYPE_MOTIVATIONS),
        traits: toStorRoleAttributeDiffSchema(sr.attributes, STORYROLE_TYPE_TRAITS),
    }
}

const toStorRoleAttributeDiffSchema = (
    attributes: StoryRoleAttribute[],
    type: STORYROLE_TYPE,
): DiffableStoryRoleAttribute[] => {
    return attributes.filter(matchTypeOrSubtype(type)).map(attr => {
        return {
            arc: attr.arc,
            labels: orderBy(attr.values.map(v => v.attributeLabel)),
        }
    })
}

/////////////////////////////////////////////
// YUP VALIDATION
/////////////////////////////////////////////

export const StorylineArcAttributeFormSchema = y
    .object({
        arc: y
            .string()
            .oneOf(ARC_VALUES as any)
            .required(),
        type: y.string().required(),
        typeId: y.string().required(),
        values: y
            .array()
            .defined()
            .of(AttributeValueFormSchema)
            .test('has-value', 'Values are missing', function (v) {
                return v && v.length
                    ? true
                    : this.createError({
                          message: `${this.parent.type} is Missing`,
                      })
            }),
    })
    .defined()

export const StoryRoleFormSchema = y
    .object({
        storyRoleId: y.string().required(),
        attributes: y
            .array()
            .defined()
            .of(StorylineArcAttributeFormSchema)
            .test('character-archetype', 'Character Archetype is Missing', v => {
                return (
                    Array.isArray(v) &&
                    v.some(v => v.typeId === STORYROLE_TYPE_CHARACTER_ARCHETYPE.typeId)
                )
            }),
        character: y.array().of(CharacterFormSchema).required().max(1).label('Character'),
    })
    .defined()

export type StoryRoleFormValue = y.TypeOf<typeof StoryRoleFormSchema>
