import React from 'react'
import flatMap from 'lodash/flatMap'
import { fuzzyGetter } from 'shared/util/fuzzyGetter'
import { searchPredicate } from 'shared/util/searchPredicate'
import isString from 'lodash/isString'

export type PrecompiledSearchItem<T> = {
    item: T
    index: number
    precompiledSearchString: string
}

export function usePrecompiledSearchMemoAndFilter<T>(
    items: T[],
    paths: string[][],
    searchText: string,
): T[] {
    const precompiled = usePrecompiledSearchMemo(items, paths)
    return React.useMemo(() => {
        return precompiled.filter(precomiledSearchPredicate(searchText)).map(psi => psi.item)
    }, [precompiled, searchText])
}

/**
 * Memoize the precompilation of search for items, and update
 * only when "items" change. This assumes that "paths" never change.
 *
 * If your use case requires "paths" to change, use precompileSearch() with
 * React.useMemo() manually.
 */
export function usePrecompiledSearchMemo<T>(
    items: T[],
    paths: string[][],
): PrecompiledSearchItem<T>[] {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => precompileSearch(items, paths), [items])
}
export const PRECOMPILED_SEPARATOR = '\u00a0'

/**
 * Given an array of items and array of paths into the items,
 * return a new object holding the origina item, its original index
 * and a "precompiledSearchString" prop. It contains the resolved
 * paths data, filtered to strings only, and
 */
export function precompileSearch<T>(items: T[], paths: string[][]): PrecompiledSearchItem<T>[] {
    return items.map((item, index) => ({
        item,
        index,
        precompiledSearchString: flatMap(paths, path =>
            fuzzyGetter<T, (string | undefined)[]>(item, path),
        )
            .filter(isString)
            .join(PRECOMPILED_SEPARATOR),
    }))
}

/**
 * Usage:
 *
 *   let precompiledSearchItems: PrecompiledSearchItem<T>[]
 *   precompiledSearchItems.filter(precomiledSearchPredicate(searchText))
 *
 */
export function precomiledSearchPredicate<T>(
    searchText: string,
): (item: PrecompiledSearchItem<T>) => boolean {
    const predicate = searchPredicate(searchText)

    return function ({ precompiledSearchString }: PrecompiledSearchItem<T>) {
        return predicate(precompiledSearchString)
    }
}
