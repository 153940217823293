import * as y from 'yup'
import * as t from 'io-ts'

export const StorylineAttributeCodec = t.type({
    id: t.string,
    label: t.string,
})
export type StorylineAttribute = t.TypeOf<typeof StorylineAttributeCodec>

/////////////////////////////////////////////
// YUP VALIDATION
/////////////////////////////////////////////

export const StorylineAttributeFormSchema = y
    .object({
        id: y.string().required(),
        label: y.string().required(),
    })
    .defined()
