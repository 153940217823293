import React from 'react'
import PageLayout from 'pages/util/PageLayout'

const NotFound: React.FC = (): React.ReactElement => {
    return (
        <PageLayout>
            <h1>Not found</h1>
        </PageLayout>
    )
}

export default NotFound
