import escapeRegExp from 'lodash/escapeRegExp'

/**
 * Given a search string, return a predicate that can be used to filter a string
 * value against the search string. All parts of the search string must match,
 * but case and word order is ignored.
 *
 * Originally this was written with str.toLowerCase() + str.indexOf(), but
 * /search/i.test(text) is faster. This also means input data needs no preparation
 *
 * https://jsperf.com/match-vs-lower-and-indexof
 * http://jsben.ch/0cukx
 */
export function searchPredicate(searchText: string): (string: string) => boolean {
    /* eslint-disable no-var */

    // This is the neat version, but we need this code to be as fast as possible, so....
    //
    // const searchTextParts = searchText
    //     .split(/\s+/)
    //     // only keep non-empty strings
    //     .filter(Boolean)
    //     .map(s => new RegExp(escapeRegExp(s), 'i'))

    // manual loops, here we go!
    var searchTextSplit = searchText.split(/\s+/)
    var searchTextParts: RegExp[] = []
    var index = -1
    var length = searchTextSplit.length
    while (index++ < length) {
        if (searchTextSplit[index]) {
            searchTextParts.push(new RegExp(escapeRegExp(searchTextSplit[index]), 'i'))
        }
    }

    var searchTextPartsLength = searchTextParts.length
    return function (stringValue) {
        var index = -1
        while (index++ < searchTextPartsLength - 1) {
            if (!searchTextParts[index].test(stringValue)) {
                return false
            }
        }

        return true
    }
}
