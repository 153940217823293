import React from 'react'

import { useLockedTitlesActor } from 'shared/LockedTitles/LockedTitlesProvider'
import Text from 'shared/components/Text'
import Loader from 'shared/components/Loader'
import styled, { theme } from 'shared/theme'

const StyledBubble = styled.div`
    background-color: ${theme.colors.danger};
    border-radius: 50%;
    min-width: 1.5rem;
    min-height: 1.5rem;
    position: absolute;
    right: -10px;
    bottom: -4px;
`

const StyledText = styled(Text).attrs({
    size: '6',
})`
    color: ${theme.colors.white};
`

const NotificationBubble: React.FC = () => {
    const [state] = useLockedTitlesActor()
    const myLockedTitlesCount = state.context.myLockedTitles.length

    return myLockedTitlesCount ? (
        <StyledBubble>
            {state.matches('idle') ? (
                <StyledText>{myLockedTitlesCount}</StyledText>
            ) : (
                <Loader center size={24} color="white" />
            )}
        </StyledBubble>
    ) : null
}

export default NotificationBubble
