import React from 'react'

import styled from 'shared/theme'
import Text from 'shared/components/Text'
import Table from 'shared/components/Table'
import Container from 'shared/components/Container'
import { Column, CellProps } from 'react-table'
import { useActor } from './CreateAssignmentProvider'
import { Request } from './createAssignment.machine'
import CreateAssignmentsStepper from './CreateAssignmentsStepper'
import { TASK_TYPE_OPTIONS_ALL } from 'model/workflow/TaskType'
import Loader from 'shared/components/Loader'
import Button from 'shared/components/Button'
import { ReactComponent as CheckIconBase } from 'shared/components/CheckboxRadioInput/Checkbox/icon.svg'
import capitalize from 'lodash/capitalize'
import words from 'lodash/words'
import { Link } from 'react-router-dom'
import { listWorkflowAssignmentLink } from 'routing/routes'

const Wrapper = styled(Container)`
    max-width: 70%;
    margin: 40px auto 0;
`

const Wrapper2 = styled(Container)`
    margin: 40px auto 0px;
    max-width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`

const CheckIcon = styled(CheckIconBase)`
    path {
        stroke: ${({ theme }) => theme.colors.primary};
    }
    width: 3.75rem;
    height: 3.75rem;
    vertical-align: middle;
    margin-bottom: -0.125rem;
`
const columns: Column<Request>[] = [
    {
        disableSortBy: true,
        Header: 'Title',
        accessor: 'resourceTitle',
        width: 1000,
    },
    {
        id: 'task',
        Header: 'Task',
        Cell: ({ row: { original } }: CellProps<Request>) => (
            <span style={{ whiteSpace: 'nowrap' }}>
                {TASK_TYPE_OPTIONS_ALL.find(o => o.value === original.workflowConfig.task)?.label ??
                    (original.workflowConfig.task as string)}
            </span>
        ),
        width: 160,
    },
    {
        id: 'status',
        Header: 'Status',
        disableSortBy: true,
        Cell: ({
            row: {
                original: { _status },
            },
        }: CellProps<Request>) => (
            <div style={{ display: 'flex' }}>
                <>
                    <Text
                        variant={
                            _status === 'error'
                                ? 'danger'
                                : _status === 'success'
                                ? 'success'
                                : 'default'
                        }
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {words(_status).map(capitalize).join(' ')}
                    </Text>
                    {_status === 'pending' && <Loader size="tiny" style={{ paddingTop: '5px' }} />}
                </>
            </div>
        ),
        width: 60,
    },
]

const AssignmentsCreationStatus: React.FC = () => {
    const [state, send] = useActor()
    const requests = state.context.requests

    return (
        <>
            <CreateAssignmentsStepper activeStep={3} />

            {state.matches('success') && (
                <Wrapper2>
                    <CheckIcon />

                    <Container mt={1}>
                        <Text size="2" as="h1" variant="primary">
                            Success
                        </Text>
                    </Container>

                    <Container mt={5}>
                        <Button type="button" onClick={() => send({ type: 'RESET' })}>
                            Create another workflow
                        </Button>
                        &nbsp; &nbsp;
                        <Link to={listWorkflowAssignmentLink()}>
                            <Text size="2">Workflow Home</Text>
                        </Link>
                    </Container>

                    <Container mt={5}></Container>
                </Wrapper2>
            )}

            <Wrapper flexDirection="column" justifyContent="center">
                {state.matches('create-assignments') && (
                    <Container>
                        <Text size="1" as="h1">
                            Creating workflow assignments...
                        </Text>
                    </Container>
                )}

                {state.matches('error') && (
                    <Container>
                        <Text variant="danger">
                            There was an error trying to create the workflow assignments
                        </Text>
                        <Button
                            variant="danger"
                            size="small"
                            type="button"
                            onClick={() => send({ type: 'RETRY_CREATION' })}
                        >
                            Retry creation
                        </Button>
                    </Container>
                )}

                <Table columns={columns} data={requests} />
            </Wrapper>
        </>
    )
}

export default AssignmentsCreationStatus
