import React from 'react'

import { Series as SeriesLocation } from 'routing/routes'
import { useParams } from 'react-router'

import { Series, SeriesCodec } from 'model/Series'
import { useUser } from 'auth/Auth'
import { fetchResource } from 'api/fetch'

import { createResourceMachine } from 'shared/resource/resource.machine'
import { useErrorHandler } from 'react-error-boundary'
import Loader from 'shared/components/Loader'
import Header from 'shared/components/Header'
import HeaderEditing from 'shared/components/Header/Editing'

import SeriesNode from 'pages/Series/SeriesNode'
import { useMachine } from '@xstate/react'
import { ResourceMachineServiceContext } from 'shared/resource/ResourceMachineProvider'
import { useRefreshLockedTitles } from 'shared/LockedTitles/LockedTitlesProvider'

import { metadataMergeAutofill } from 'model/metadata/Metadata'
import { metadataFetchAutofill } from 'api/autofill/metadataFetchAutofill'

const SeriesPage: React.FC = (): React.ReactElement => {
    const { seriesId } = useParams<SeriesLocation>()
    const handleError = useErrorHandler()
    const refreshLockedTitles = useRefreshLockedTitles()

    const user = useUser()
    const [state, , service] = useMachine(
        () =>
            createResourceMachine<Series>({
                resourceId: seriesId,
                resourceType: 'series',
                user,
                wips: ['metadata'],
                fetchFn: ctx => fetchResource({ codec: SeriesCodec, ...ctx }),
                handleCriticalError: (_, e: any) => handleError(e.data),
                refreshLockedTitles,
                autofill: {
                    metadata: {
                        fetch: ctx => metadataFetchAutofill(ctx),
                        merge: metadataMergeAutofill,
                    },
                },
            }),
        {
            devTools: true,
        },
    )

    if (state.matches('loading')) {
        return (
            <>
                <Header />
                <Loader center size="normal" />
            </>
        )
    }

    if (state.matches('404')) {
        return (
            <>
                <Header />
                <h1>404: Not Found</h1>
            </>
        )
    }

    return (
        <ResourceMachineServiceContext.Provider value={service}>
            <HeaderEditing />
            <SeriesNode />
        </ResourceMachineServiceContext.Provider>
    )
}

export default SeriesPage
