import React from 'react'
import { ActionType, createAction, getType } from 'typesafe-actions'

import { Relationship } from 'codecs/Relationship'
import Text from 'shared/components/Text'
import { bindActionCreators, BoundActionCreators } from 'shared/util/bindActionCreators'
import Button from 'shared/components/Button'
import Container from 'shared/components/Container'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import { useContextHelper } from 'shared/hooks/useContextHelper'

import RelationshipForm from './RelationshipForm'
import RelationshipsList from './RelationshipsList'
import {
    useFormControlsEnabled,
    useViewMode,
    getWIPData,
    useWIPRelationshipsActor,
} from 'shared/resource/ResourceMachineProvider'

/**
 * RelationshipsContext contains RelationshipsState + bound action creators for the
 * relationships reducer
 */
export const RelationshipsContext = React.createContext<
    RelationshipsState & BoundActionCreators<typeof actions> & { formControlsEnabled: boolean }
>(null!)
RelationshipsContext.displayName = 'Relationships'

// eslint-disable-next-line
export function useRelationshipsContext() {
    return useContextHelper(RelationshipsContext, `RelationshipsContext`)
}

export const actions = {
    setList: createAction('SET_LIST')<Relationship[]>(),
    createNew: createAction('CREATE_NEW')(),
    edit: createAction('EDIT')<Pick<Relationship, 'relationshipId'>>(),
    save: createAction('SAVE')<Relationship>(),
    remove: createAction('REMOVE')<Pick<Relationship, 'relationshipId'>>(),
    cancelEditCreate: createAction('CANCEL_EDIT_CREATE')<Pick<Relationship, 'relationshipId'>>(),
    savePerformed: createAction('SAVE_PERFORMED')(),
}

type RelationshipsState = {
    relationships: Relationship[]
    isCreatingNew: boolean
    editIdentifier: string | null
    shouldSave: boolean
}

const reducer = (
    state: RelationshipsState,
    action: ActionType<typeof actions>,
): RelationshipsState => {
    switch (action.type) {
        case getType(actions.setList): {
            return {
                ...state,
                relationships: action.payload,
            }
        }
        case getType(actions.createNew): {
            return {
                ...state,
                isCreatingNew: true,
            }
        }

        case getType(actions.edit): {
            const { relationshipId } = action.payload
            return {
                ...state,
                editIdentifier: relationshipId,
            }
        }

        case getType(actions.save): {
            const payloadRelationship = action.payload
            const isEditing = state.editIdentifier === payloadRelationship.relationshipId

            return isEditing
                ? {
                      ...state,
                      editIdentifier: null,
                      // find by relationshipId and replace
                      relationships: state.relationships.map(stateRelationship =>
                          stateRelationship.relationshipId === payloadRelationship.relationshipId
                              ? payloadRelationship
                              : stateRelationship,
                      ),
                      shouldSave: true,
                  }
                : {
                      ...state,
                      isCreatingNew: false,
                      relationships: state.relationships.concat(payloadRelationship),
                      shouldSave: true,
                  }
        }

        case getType(actions.remove): {
            const { relationshipId: payloadRelationshipId } = action.payload

            return {
                ...state,
                editIdentifier: null,
                relationships: state.relationships.filter(
                    ({ relationshipId: stateRelationshipId }) =>
                        stateRelationshipId !== payloadRelationshipId,
                ),
                shouldSave: true,
            }
        }

        case getType(actions.cancelEditCreate): {
            const { relationshipId } = action.payload
            const isEditing = state.editIdentifier === relationshipId
            return isEditing
                ? {
                      ...state,
                      editIdentifier: null,
                  }
                : {
                      ...state,
                      isCreatingNew: false,
                  }
        }
        case getType(actions.savePerformed): {
            return {
                ...state,
                shouldSave: false,
            }
        }
    }
}

const Relationships: React.FC = (): React.ReactElement => {
    const formControlsEnabled = useFormControlsEnabled('relationships')

    const [wipState, send] = useWIPRelationshipsActor()
    const relationships = getWIPData(useViewMode(), wipState)

    const [state, dispatch] = React.useReducer(reducer, {
        relationships,
        isCreatingNew: false,
        editIdentifier: null,
        shouldSave: false,
    })

    // when actor relationships change, we need to update our state relationships
    React.useEffect(() => {
        dispatch(actions.setList(relationships))
    }, [relationships])

    // when state.shouldSave is set to true, we fire of a request for update
    // to the data manager
    React.useEffect(() => {
        const { shouldSave, relationships } = state
        if (!shouldSave) {
            return
        }

        send({
            type: 'UPDATE',
            data: relationships,
        })

        dispatch(actions.savePerformed())
    }, [send, state])

    // The context we provide for relationships is our state + bound action creators.
    const relationshipsContextValue = React.useMemo<
        React.ContextType<typeof RelationshipsContext>
    >(() => {
        return {
            ...state,
            ...bindActionCreators(actions, dispatch),
            formControlsEnabled,
        }
    }, [formControlsEnabled, state])

    return (
        <RelationshipsContext.Provider value={relationshipsContextValue}>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Relationships{' '}
                    </Text>
                </TableHeaderContent>

                <OptionalAddNewRelationshipButton />
            </Container>

            <OptionalNewRelationshipForm />

            <RelationshipsList />
        </RelationshipsContext.Provider>
    )
}

/**
 * Render when we're in edit mode and creating a new relationship
 */
const OptionalNewRelationshipForm: React.FC = (): React.ReactElement | null => {
    const { isCreatingNew, formControlsEnabled } = useRelationshipsContext()

    if (!formControlsEnabled || !isCreatingNew) {
        return null
    }

    return <RelationshipForm relationship={null} />
}

/**
 * Render when we're in edit mode
 */
const OptionalAddNewRelationshipButton: React.FC = (): React.ReactElement | null => {
    const { formControlsEnabled, createNew, isCreatingNew } = useRelationshipsContext()

    if (!formControlsEnabled) {
        return null
    }

    return (
        <Button disabled={isCreatingNew} variant="primary" onClick={() => createNew()}>
            + Add Relationship
        </Button>
    )
}

export default React.memo(Relationships)
