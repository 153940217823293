import React from 'react'
import {
    getType,
    ActionType,
    createAction,
    PayloadActionCreator,
    EmptyActionCreator,
    EmptyAction,
    PayloadAction,
} from 'typesafe-actions'
import { bindActionCreators, BoundActionCreators } from 'shared/util/bindActionCreators'
import { EpisodeStoryline } from 'codecs/storyline/EpisodeStoryline'
import { FeatureStoryline } from 'codecs/storyline/FeatureStoryline'

export type StorylineBase = FeatureStoryline | EpisodeStoryline

export type Actions<T extends StorylineBase> = {
    setList: PayloadActionCreator<'SET_LIST', T[]>
    createNew: EmptyActionCreator<'CREATE_NEW'>
    edit: PayloadActionCreator<'EDIT', T>
    save: PayloadActionCreator<'SAVE', T>
    remove: PayloadActionCreator<'REMOVE', Pick<T, 'storylineId'>>
    cancelEditCreate: PayloadActionCreator<'CANCEL_EDIT_CREATE', Pick<T, 'storylineId'>>
    savePerformed: EmptyActionCreator<'SAVE_PERFORMED'>
}

export type Action<T extends StorylineBase> =
    | EmptyAction<'CREATE_NEW'>
    | EmptyAction<'SAVE_PERFORMED'>
    | PayloadAction<'SET_LIST', T[]>
    | PayloadAction<'EDIT', T>
    | PayloadAction<'SAVE', T>
    | PayloadAction<'REMOVE', Pick<T, 'storylineId'>>
    | PayloadAction<'CANCEL_EDIT_CREATE', Pick<T, 'storylineId'>>

export function getActions<T extends StorylineBase>(): Actions<T> {
    return {
        setList: createAction('SET_LIST')<T[]>(),
        createNew: createAction('CREATE_NEW')(),
        edit: createAction('EDIT')<T>() as PayloadActionCreator<'EDIT', T>,
        save: createAction('SAVE')<T>() as PayloadActionCreator<'SAVE', T>,
        remove: createAction('REMOVE')<Pick<T, 'storylineId'>>(),
        cancelEditCreate: createAction('CANCEL_EDIT_CREATE')<Pick<T, 'storylineId'>>(),
        savePerformed: createAction('SAVE_PERFORMED')(),
    }
}

export type StorylinesState<T> = {
    storylines: T[]
    isCreatingNew: boolean
    editIdentifier: string | null
    lastInsertedStorylineIdentifier: string | null
    shouldSave: boolean
}

type StorylinesContext<T extends StorylineBase> = React.Context<
    StorylinesState<T> & BoundActionCreators<Actions<T>> & { formControlsEnabled: boolean }
>

export const createStorylineContext = <T extends StorylineBase>(): StorylinesContext<T> => {
    const context = React.createContext<React.ContextType<StorylinesContext<T>>>(null!)
    context.displayName = 'Storylines'
    return context
}

const createReducer =
    <T extends StorylineBase>() =>
    (state: StorylinesState<T>, action: ActionType<Actions<T>>): StorylinesState<T> => {
        const actions = getActions<T>()

        switch (action.type) {
            case getType(actions.setList): {
                return state.storylines === action.payload
                    ? state
                    : { ...state, storylines: action.payload }
            }
            case getType(actions.createNew): {
                return { ...state, isCreatingNew: true }
            }
            case getType(actions.edit): {
                const { storylineId } = action.payload
                return { ...state, editIdentifier: storylineId }
            }
            case getType(actions.save): {
                const payloadStoryline = action.payload
                const isEditing = state.editIdentifier === payloadStoryline.storylineId

                return isEditing
                    ? {
                          ...state,
                          editIdentifier: null,
                          // find by relationshipId and replace
                          storylines: state.storylines.map(stateStoryline =>
                              stateStoryline.storylineId === payloadStoryline.storylineId
                                  ? payloadStoryline
                                  : stateStoryline,
                          ),
                          shouldSave: true,
                      }
                    : {
                          ...state,
                          isCreatingNew: false,
                          storylines: state.storylines.concat(payloadStoryline),
                          shouldSave: true,
                          lastInsertedStorylineIdentifier: payloadStoryline.storylineId,
                      }
            }

            case getType(actions.remove): {
                const { storylineId: payloadStorylineId } = action.payload

                return {
                    ...state,
                    editIdentifier: null,
                    storylines: state.storylines.filter(
                        ({ storylineId: stateStorylineID }) =>
                            stateStorylineID !== payloadStorylineId,
                    ),
                    shouldSave: true,
                }
            }

            case getType(actions.cancelEditCreate): {
                const { storylineId } = action.payload
                const isEditing = state.editIdentifier === storylineId
                return isEditing
                    ? { ...state, editIdentifier: null }
                    : { ...state, isCreatingNew: false }
            }

            case getType(actions.savePerformed): {
                return { ...state, shouldSave: false }
            }
        }
    }

export function useStorylinesReducer<T extends StorylineBase>(
    initializerArg: StorylinesState<T>,
): [StorylinesState<T>, BoundActionCreators<Actions<T>>] {
    const reducer = createReducer<T>()
    const [state, dispatch] = React.useReducer(reducer, initializerArg)
    const [actions] = React.useState(() => bindActionCreators(getActions<T>(), dispatch))

    return [state, actions]
}
