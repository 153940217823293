import React from 'react'

type AnyTag = string | React.FC<never>

type AnyTagProps<Tag extends AnyTag> = {
    as?: Tag
} & (Tag extends keyof JSX.IntrinsicElements
    ? JSX.IntrinsicElements[Tag]
    : Tag extends React.ComponentType<infer Props>
    ? Props & JSX.IntrinsicAttributes
    : never)

export default function NoWrap<Tag extends AnyTag = 'span'>({
    children,
    as,
    ...props
}: AnyTagProps<Tag> & { children?: React.ReactNode }): React.ReactElement {
    return React.createElement(
        as ?? 'span',
        { style: { whiteSpace: 'nowrap', ...(props as any).style }, ...props } as any,
        children,
    )
}
