import React from 'react'

import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import EmptyIcon from './empty-icon.svg'

type Props = {
    title: string
    subtitle?: string
}

const Empty: React.FC<Props> = ({ title, subtitle }): React.ReactElement => {
    return (
        <Container flexDirection="column" alignItems="center">
            <img src={EmptyIcon} alt={title} />
            <Text size="1" as="h2" mt="3" mb="2">
                {title}
            </Text>
            {subtitle && (
                <Text variant="secondary" size="4" as="h3" mb="2">
                    {subtitle}
                </Text>
            )}
        </Container>
    )
}

export default Empty
