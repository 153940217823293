import * as t from 'io-ts'
import { orderBy } from 'natural-orderby'
import * as s from 'shared/diff/schema'
import nullable from './util/nullable'

export const LocationBaseCodec = t.type({
    locationId: t.string,
    locationName: t.string,
})
export interface LocationBase extends t.TypeOf<typeof LocationBaseCodec> {}
export const locationBase = (name: string): LocationBase => {
    return {
        locationName: name,
        locationId: `http://example.com/${name}`,
    }
}

export const LocationCodec = t.type({
    ...LocationBaseCodec.props,
    suggestionScore: nullable(t.number),
    __ap_locations_heirarchy: nullable(t.array(LocationBaseCodec)),
})
export interface Location extends t.TypeOf<typeof LocationCodec> {}

// Utils

export const addReactSelectTitle = (
    location: Location,
): Location & { reactSelectTitle: string | null } => {
    return {
        ...location,
        reactSelectTitle:
            location.__ap_locations_heirarchy &&
            location.__ap_locations_heirarchy.map(l => l.locationName).join(' -> '),
    }
}

export const location = (name: string, rest?: Partial<Location>): Location => {
    return {
        ...locationBase(name),
        suggestionScore: null,
        __ap_locations_heirarchy: null,
        ...rest,
    }
}

// Diffs

export const LocationsDiffSchema = s.schema({
    Locations: s.array(s.value<string>()),
})
export type DiffableLocations = s.TypeOf<typeof LocationsDiffSchema>
export const toDiffableLocations = ({
    locations,
}: {
    locations: Location[]
}): DiffableLocations => {
    return {
        Locations: orderBy(locations.map(l => l.locationName)),
    }
}
