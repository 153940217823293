import Text from 'shared/components/Text'
import { Link } from 'react-router-dom'
import styled from 'shared/theme'
import Container from 'shared/components/Container'

import SearchInput from './SearchInput'
import SearchResultTitle from './SearchResultTitle'

export { SearchInput, SearchResultTitle }

export const PageContainer = styled(Container).attrs({
    flexDirection: 'column',
})`
    margin: 40px auto 0;
    max-width: 80%;
`

export const WorkflowSearchContainer = styled(Container).attrs({
    flexDirection: 'column',
})`
    margin: 40px auto 0;
`

export const SearchContainer = styled(Container).attrs({
    flexDirection: 'column',
})`
    margin: 40px auto 0;
    max-width: 70%;
`
export const SearchForm = styled.form`
    position: relative;
    display: flex;
`

export const SearchResultLink = styled(Link)`
    text-decoration: none;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    font-weight: 400;
`

export const SearchResultsHeader = styled.div`
    display: flex;
    align-items: baseline;
`
export const SearchHeaderText = styled(Text)`
    margin-bottom: 0.25rem;
`
