import { Either, left, right } from 'fp-ts/lib/Either'
import { isTagged } from '@genome-web-forms/common/util'

import {
    PublishWorkflowFulfilled,
    PUBLISH_WORKFLOW_STATUS_SUCCEEDED,
    PUBLISH_WORKFLOW_STATUS_FAILED,
    PUBLISH_WORKFLOW_STATUS_UNKNOWN,
    PUBLISH_WORKFLOW_COMPONENTS,
    PublishWorkflowComponent,
    ErrorContext,
} from './PublishWorkflow'

export type WorkflowSuccess = {
    _tag: 'WorkflowSuccess'
    errors: never[]
    unknowns: never[]
    success: WorkflowPartialSuccess[]
}
export const isWorkflowSuccess = isTagged<WorkflowSuccess>('WorkflowSuccess')

export type WorkflowErrorInstance<T extends ErrorContext = ErrorContext> = {
    message: string
    errorContext: T[]
}
export type WorkflowPartialError<T extends ErrorContext = ErrorContext> = {
    component: PublishWorkflowComponent
    messages: string[]
    errors: WorkflowErrorInstance<T>[]
}
type WorkflowPartialSuccess = {
    component: PublishWorkflowComponent
}
type WorkflowPartialUnknown = {
    component: PublishWorkflowComponent
    messages: string[]
}

export type WorkflowError = {
    _tag: 'WorkflowError'
    errors: WorkflowPartialError[]
    success: WorkflowPartialSuccess[]
    unknowns: WorkflowPartialUnknown[]
}
export const isWorkflowError = isTagged<WorkflowError>('WorkflowError')

/**
 * A fullfilled workflow does not mean a successful workflow.
 *
 * We need to parse the workflow and extract any errors that might be present
 */
export const parseWorkflowFulfilled = (
    workflow: PublishWorkflowFulfilled,
): Either<WorkflowError, WorkflowSuccess> => {
    const response = workflow.workflowFulfilledResponse

    const errors: WorkflowPartialError[] = []
    const success: WorkflowPartialSuccess[] = []
    const unknowns: WorkflowPartialUnknown[] = []

    for (const component of PUBLISH_WORKFLOW_COMPONENTS) {
        const res = response[component]

        if (res.status === PUBLISH_WORKFLOW_STATUS_FAILED) {
            errors.push({
                component,
                messages: res.messages,
                errors: res.errors.map(({ message, errorContext }) => ({
                    message,
                    errorContext,
                })),
            })
        }

        if (res.status === PUBLISH_WORKFLOW_STATUS_SUCCEEDED) {
            success.push({ component })
        }

        if (res.status === PUBLISH_WORKFLOW_STATUS_UNKNOWN) {
            unknowns.push({ component, messages: res.messages })
        }
    }

    return errors.length > 0 || unknowns.length > 0
        ? left({ _tag: 'WorkflowError', errors, success, unknowns })
        : right({
              _tag: 'WorkflowSuccess',
              success,
              errors: [],
              unknowns: [],
          })
}
