import * as y from 'yup'
import uniqBy from 'lodash/uniqBy'
import type { ValueIteratee } from 'lodash'

y.addMethod<y.ArraySchema<any>>(y.array, 'uniqBy', yupUniqBy)

function yupUniqBy<T extends y.AnySchema>(
    this: y.ArraySchema<T>,
    iteratee: ValueIteratee<T | null | undefined>,
    message = 'The array must not contain the same values',
): y.ArraySchema<T> {
    return this.test('uniqBy', message, (value: y.TypeOf<T>[] | null | undefined) => {
        return (value && value.length) === uniqBy(value, iteratee).length
    })
}

declare module 'yup' {
    interface ArraySchema<T> {
        uniqBy: (iteratee: ValueIteratee<y.TypeOf<T>>, message?: string) => y.ArraySchema<T>
    }
}
