import React from 'react'
import { generateIdentifier } from 'genome-identifier'

export function useDisneyId(): [string, () => void] {
    const [uuid, setUUID] = React.useState(generateDisneyId())

    const generateNew = React.useCallback(() => {
        setUUID(generateDisneyId())
    }, [])

    return [uuid, generateNew]
}

export function generateDisneyId(): string {
    return generateIdentifier()
}
