import * as t from 'io-ts';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';

var PERMISSION_WRITE = 'WRITE';
var PERMISSION_READ = 'READ';
var PERMISSION_PUBLISH = 'PUBLISH';
var PERMISSION_DEBUG = 'DEBUG';
var PERMISSION_APPROVE = 'APPROVE TAGGING QUALITY';
var RoleCodec = t.type({
  name: t.string,
  functionalAbilities: t.array(t.type({
    name: t.string
  }))
});
var MyIDUserCodec = t.strict({
  'relationship.employeeId': t.string,
  'relationship.employeeNumber': t.string,
  given_name: t.string,
  family_name: t.string,
  email: t.string,
  roles: t.union([t.undefined, t.array(RoleCodec)]),
  'trs-authz-token': t.string,
  'cwr-api-authz-token': t.string,
  'x-gsso-myid': t.string,
  'x-gsso-myid-userdata': t.string
});
var extractPermissions = function extractPermissions(user) {
  var roles = user.roles || [];
  return uniq(flatMap(roles, function (role) {
    return role.functionalAbilities.map(function (fa) {
      return fa.name;
    });
  }));
};
var rollbarUser = function rollbarUser(u) {
  return {
    id: u['relationship.employeeId'],
    email: u.email,
    name: "".concat(u.given_name, "} ").concat(u.family_name),
    permissions: extractPermissions(u)
  };
};

export { MyIDUserCodec, PERMISSION_APPROVE, PERMISSION_DEBUG, PERMISSION_PUBLISH, PERMISSION_READ, PERMISSION_WRITE, extractPermissions, rollbarUser };
