import React from 'react'

import { TableRowComponentProps } from 'react-table'
import { StoryRole } from 'codecs/storyline/StoryRole'

import ToggleButton from 'shared/components/ToggleButton'
import { TableRow, TableCell } from 'shared/components/Table'

import { ButtonContainer } from './StoryRolesTableRow/ButtonContainer'
import { hasStoryRoleErrors } from './StoryRolesTableRow/hasStoryRoleErrors'
import { StoryRolesList } from './StoryRolesTableRow/StoryRolesList'
import { usePublishErrorManager } from 'shared/resource/ResourceMachineProvider'

export function StoryRolesTableRow<S extends { storyRoles: StoryRole[] }>(
    props: TableRowComponentProps<S> & {
        onStoryRoleClick?: (storyRole: StoryRole) => void
    },
): React.ReactElement {
    const publishErrorManager = usePublishErrorManager()
    const {
        allColumns: { length: colSpan },
        row,
        row: {
            original: storyline,
            original: { storyRoles },
        },
    } = props
    return (
        <TableRow>
            <TableCell
                style={{ padding: 0 }}
                {...{
                    colSpan,
                    removeTopBorderRadius: true,
                    alignWithPreviousRow: true,
                }}
            >
                <ButtonContainer>
                    <ToggleButton
                        onClick={() => row.toggleRowExpanded()}
                        toggled={row.isExpanded}
                        variant={
                            hasStoryRoleErrors(publishErrorManager, storyline)
                                ? 'danger'
                                : undefined
                        }
                    >
                        {row.isExpanded ? 'Hide Story Roles' : 'Show Story Roles'}
                    </ToggleButton>
                </ButtonContainer>
                {row.isExpanded ? (
                    <StoryRolesList
                        onStoryRoleClick={props.onStoryRoleClick}
                        storyRoles={storyRoles}
                    />
                ) : null}
            </TableCell>
        </TableRow>
    )
}
