import React from 'react'
import { Formik, FormikProps } from 'formik'
import ErrorMessage from 'shared/components/ErrorMessage'
import { transparentize } from 'polished'
import FormikValidationErrorNotification from 'shared/components/FormikValidationErrorNotification'

import {
    Relationship,
    RelationshipCodec,
    RelationshipFormSchema,
    RelationshipFormValue,
} from 'codecs/Relationship'
import { PropsToJSON } from 'shared/components/dev/PropsToJSON'

import styled from 'shared/theme'
import { useOnClickOutsideRef } from 'shared/hooks/useOnClickOutside'
import CellLabel from 'shared/components/CellLabel'
import Button from 'shared/components/Button'
import TextualButton from 'shared/components/TextualButton'
import { ReactComponent as TrashCanIcon } from 'shared/components/Icons/trash-can-icon.svg'
import Container from 'shared/components/Container'
import { generateDisneyId } from 'shared/hooks/useDisneyId'
import { Pill } from 'shared/components/Table/TablePills'
import { AlertDialog, AlertDialogLabel, AlertDialogButtons } from 'shared/components/AlertModal'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import DeleteButton from 'shared/components/DeleteButton'
import { useRelationshipsContext } from './index'
import PublishErrors from 'shared/components/PublishErrors'

import RelationshipAttributesForm from './RelationshipAttributesForm'
import RelationshipCharactersSelect from './RelationshipCharactersSelect'
import { hasDuplicateCharacterPairs } from './hasDuplicateCharacterPairs'
import { usePublishErrorManager } from 'shared/resource/ResourceMachineProvider'

const PublishErrorsStryled = styled(PublishErrors)`
    margin-top: 0.5rem;
`

export const RelationshipForm: React.FC<{
    relationship: Relationship | null
}> = ({ relationship }): React.ReactElement => {
    const { relationships, save } = useRelationshipsContext()
    const isCreatingNewRelationship = relationship === null

    const initialValues: RelationshipFormValue = React.useMemo(
        () =>
            relationship || {
                relationshipId: generateDisneyId(),
                characters: [],
                attributes: [],
            },
        [relationship],
    )

    const validate = React.useCallback(
        (values: RelationshipFormValue) => {
            const hasDuplicate = hasDuplicateCharacterPairs(
                values,
                relationships,
                isCreatingNewRelationship,
            )
            if (hasDuplicate) {
                return {
                    characters: 'A relationship between those character already exists',
                }
            }

            if (!RelationshipCodec.is(values)) {
                return {
                    general: 'Bad form data, cannot convert to backend format!',
                }
            }
        },
        [relationships, isCreatingNewRelationship],
    )

    return (
        <Formik<RelationshipFormValue>
            initialValues={initialValues}
            validationSchema={RelationshipFormSchema}
            validate={validate}
            onSubmit={relationship => {
                save(relationship as Relationship)
            }}
            component={RelationshipFormNode}
        />
    )
}

const RelationshipFormNode: React.FC<FormikProps<RelationshipFormValue>> = ({
    errors,
    values: relationshipValue,
    submitForm,
}) => {
    const { editIdentifier, cancelEditCreate, remove } = useRelationshipsContext()
    const isRelationshipNew = editIdentifier !== relationshipValue.relationshipId

    const [confirmDeleteModalVisible, showConfirmDeleteModal] = React.useState(false)
    const publishErrors = usePublishErrorManager().getErrors(
        (u): u is Relationship => u.relationshipId === editIdentifier,
    )

    const ref = useOnClickOutsideRef<HTMLTableRowElement>(
        useEventCallback(function conditionalAutosave() {
            /**
             * Do not trigger autosave on click outside:
             *  - when the deletion confirm modal is open
             *    (clicks on it are considered outside the form)
             *  - when we're creating a new relationship
             *    (use the save/cancel buttons instead)
             */
            if (confirmDeleteModalVisible || isRelationshipNew) {
                return
            }

            submitForm()
        }),
    )

    return (
        <StyledContainer ref={ref} hasErrors={publishErrors.length > 0}>
            <FormikValidationErrorNotification title="Relationship errors" />

            <RelationshipTableLayout>
                <div>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Character 1
                    </CellLabel>

                    <RelationshipCharactersSelect
                        name="characters[0]"
                        placeholder="Select first character"
                    />
                    <ErrorMessage name="characters" />
                    <ErrorMessage name="general" />
                </div>

                <div>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Character 2
                    </CellLabel>

                    <RelationshipCharactersSelect
                        name="characters[1]"
                        placeholder="Select second character"
                    />
                </div>

                <div>
                    {!isRelationshipNew && (
                        <DeleteRelationshipButton
                            onDelete={() => remove(relationshipValue)}
                            confirmDeleteModalVisible={confirmDeleteModalVisible}
                            showConfirmDeleteModal={showConfirmDeleteModal}
                        />
                    )}

                    <RelationshipAttributesForm />
                    <ErrorMessage name="attributes" />
                </div>

                <PublishErrorsStryled errors={publishErrors} />
            </RelationshipTableLayout>

            <ButtonsContaier>
                <TextualButton
                    mr="1"
                    onClick={() => {
                        cancelEditCreate(relationshipValue)
                    }}
                >
                    Cancel
                </TextualButton>

                <Button
                    onClick={() =>
                        // hack for proper formik states
                        Promise.resolve().then(() => submitForm())
                    }
                >
                    {isRelationshipNew ? 'Add' : 'Save'}
                </Button>
            </ButtonsContaier>

            <PropsToJSON errors={errors} />
            <PropsToJSON values={relationshipValue} />
        </StyledContainer>
    )
}

type DeleteRelationshipButtonProps = {
    onDelete: () => void
    confirmDeleteModalVisible: boolean
    showConfirmDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
}
/**
 * Render when we're editing an existing relationship
 */
const DeleteRelationshipButton: React.FC<DeleteRelationshipButtonProps> = ({
    onDelete,
    confirmDeleteModalVisible,
    showConfirmDeleteModal,
}): React.ReactElement | null => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    return (
        <>
            <DeleteButton style={{ marginTop: '3px' }} onClick={() => showConfirmDeleteModal(true)}>
                <TrashCanIcon />
            </DeleteButton>

            {confirmDeleteModalVisible && (
                <AlertDialog leastDestructiveRef={cancelRef}>
                    <AlertDialogLabel>Delete this relationship?</AlertDialogLabel>

                    <AlertDialogButtons>
                        <Button variant="danger" onClick={onDelete}>
                            Delete
                        </Button>

                        <TextualButton
                            onClick={() => showConfirmDeleteModal(false)}
                            ref={cancelRef}
                            variant="primary"
                        >
                            Cancel
                        </TextualButton>
                    </AlertDialogButtons>
                </AlertDialog>
            )}
        </>
    )
}

export const RelationshipTableLayout = styled.div<{ hasHover?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 0.5rem;
    padding: 0 1rem;
    ${props =>
        props.hasHover &&
        `
            &:hover {background-color: ${props.theme.colors.activeField};}
            &:hover ${Pill} {background-color: ${props.theme.colors.border};}
        `}
`

const StyledContainer = styled.div<{ hasErrors?: boolean }>`
    background-color: ${props =>
        props.hasErrors ? props.theme.colors.backgroundError : props.theme.colors.activeField};
    box-shadow: 0 4px 8px 0 ${props => transparentize('0.7', props.theme.colors.textSecondary)};
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: 5px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    width: 100%;
`

const ButtonsContaier = styled(Container).attrs({
    justifyContent: 'flex-end',
    p: '2',
})`
    margin-top: 1rem;
    border-top: 1px solid ${props => props.theme.colors.border};
`

export default RelationshipForm
