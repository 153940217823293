import { MyIDUser } from '@genome-web-forms/common/auth'
import { AxiosRequestConfigExtended } from '@genome-web-forms/common/api'

export function authGWF<DataSent = any>(
    user: MyIDUser,
    config: AxiosRequestConfigExtended<DataSent>,
): AxiosRequestConfigExtended {
    return {
        ...config,
        headers: {
            ...(config.headers || {}),
            'x-gsso-myid': user['x-gsso-myid'],
            'x-gsso-myid-userdata': user['x-gsso-myid-userdata'],
        },
    }
}

export function authCWR<DataSent = any>(
    user: MyIDUser,
    config: AxiosRequestConfigExtended<DataSent>,
): AxiosRequestConfigExtended {
    return {
        ...config,
        headers: {
            ...(config.headers || {}),
            Authorization: 'BEARER ' + user['cwr-api-authz-token'],
        },
    }
}
