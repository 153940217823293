import React from 'react'

import styled from 'shared/theme'
import PadlockIcon from 'shared/components/Icons/PadlockIcon/padlock-icon.svg'
import Container from 'shared/components/Container'
import { StyledHeader } from 'shared/components/Header/index'
import { HeaderNav } from 'shared/components/Header/index'
import UserMenu from 'shared/components/UserMenu'
import { useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import PrivateEyeIcon from 'shared/components/Icons/PrivateEye/private-eye.svg'
import TextualButton from 'shared/components/TextualButton'
import UserHasPermission from 'shared/components/UserHasPermission'
import { PERMISSION_DEBUG } from '@genome-web-forms/common/auth'
import Button from 'shared/components/Button'
import Logo from './Logo'

export const HeaderUpperLip = styled.div`
    align-self: flex-start;
    position: absolute;
    background-color: ${props => props.theme.colors.textSecondary};
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0.25rem 1.5rem;
    color: ${props => props.theme.colors.white};
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
`

export const StyledIcon = styled.img`
    position: relative;
    top: 1px;
    margin-right: 0.5rem;
`
export const Close = styled(TextualButton)`
    color: ${props => props.theme.colors.white};
    margin-right: 1.5rem;
`

const HeaderEditing: React.FC = () => {
    const [state, send] = useResourceMachine()

    // preview
    if (state.matches({ idle: 'preview' })) {
        const isPreviewDiff = state.matches({ idle: { preview: 'diff' } })
        return (
            <StyledHeader variant="preview">
                <Logo inControlMode />
                <HeaderUpperLip>
                    <StyledIcon
                        src={PrivateEyeIcon}
                        alt="preview"
                        width="24"
                        height="20"
                        style={{ marginBottom: '-2px' }}
                    />
                    Preview mode
                    <UserHasPermission permission={PERMISSION_DEBUG}>
                        <Button
                            variant="outlineNoBorders"
                            onClick={() =>
                                send({
                                    type: 'SET_VIEW_MODE',
                                    viewMode: isPreviewDiff ? 'previewBase' : 'previewDiff',
                                })
                            }
                        >
                            {isPreviewDiff ? 'hide diff' : 'show diff'}
                        </Button>
                    </UserHasPermission>
                </HeaderUpperLip>

                <Container flexDirection="row" alignItems="center">
                    <Close onClick={() => send({ type: 'SET_VIEW_MODE', viewMode: 'readonly' })}>
                        Close
                    </Close>
                    <UserMenu disableLogout={true} />
                </Container>
            </StyledHeader>
        )
    }

    // default simple nav
    if ((['aquiringLocks', { idle: 'readonly' }] as const).some(state.matches)) {
        return (
            <StyledHeader variant="normal">
                <Logo />
                <HeaderNav />
            </StyledHeader>
        )
    }

    // editing nav
    return (
        <StyledHeader variant="edit">
            <Logo inControlMode />
            <HeaderUpperLip>
                <StyledIcon src={PadlockIcon} alt="padlock" />
                Edit mode. The production is locked
            </HeaderUpperLip>
            <Container flexDirection="row">
                <UserMenu disableLogout={true} />
            </Container>
        </StyledHeader>
    )
}

export default React.memo(HeaderEditing)
