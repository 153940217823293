import React from 'react'
import { useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import styled from 'styled-components'
import Button from 'shared/components/Button'

const PaddedButton = styled(Button)`
    padding: 0.375rem 1.25rem;
`

const PreviewControl: React.FC = () => {
    const [state, send] = useResourceMachine()

    return state.matches({ idle: 'readonly' }) ? (
        <PaddedButton
            title="Preview the changes in the scratch database"
            variant="outlineNoBorders"
            onClick={() => send({ type: 'SET_VIEW_MODE', viewMode: 'preview' })}
        >
            Preview
        </PaddedButton>
    ) : null
}

export default PreviewControl
