import React from 'react'

import { isAxiosError } from '@genome-web-forms/common/error'
import { isCodecError } from '@genome-web-forms/common/error'

export function HumanizeError({ children: error }: { children: Error }): JSX.Element {
    if (isCodecError(error)) {
        return <>The API returned data that cannot be parsed.</>
    } else if (isAxiosError(error)) {
        const { data } = error.response || {}
        return (
            <>
                Network Error: {error.message}
                {typeof data === 'string' && (
                    <>
                        <br />
                        {data}
                    </>
                )}
            </>
        )
    } else {
        return <>{error.name + ': ' + error.message.split('\n')[0]}</>
    }
}

export default HumanizeError
