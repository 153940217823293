import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import * as t from 'io-ts'
import { WIP, WIPCodec, WIPDataForType, WIPDataType } from 'model/WIP'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import config from 'shared/config'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { ResourceType } from 'api/fetch/fetchResource'
import { queryClient } from 'shared/queryClient'

const ResourceTypeToLockType: { [K in ResourceType]: string } = {
    feature: 'features',
    series: 'series',
    season: 'NOT_APPLICABLE',
    episode: 'episodes',
}

/**
 * react-query query identifier for wips
 */
const wipsIdentifier = (resourceId: string): [string, string] => ['wips', resourceId]

export async function fetchWIP({
    user,
    dataType,
    resourceId,
    resourceType,
}: {
    user: MyIDUser
    dataType: WIPDataType
    resourceId: string
    resourceType: ResourceType
}): Promise<WIP | undefined> {
    const wips = await queryClient.fetchQuery(wipsIdentifier(resourceId), () =>
        request(
            t.array(WIPCodec),
            authGWF(user, {
                url: `${config.urlGWF}/${ResourceTypeToLockType[resourceType]}/${encodeURIComponent(
                    resourceId,
                )}/lock`,
            }),
        ),
    )

    return wips.find(wip => wip.dataType === dataType)
}

export async function updateWIP<T extends WIPDataForType[WIPDataType]>({
    wip,
    user,
    data,
}: {
    wip: WIP
    user: MyIDUser
    data: T
}): Promise<WIP> {
    const res = await request(
        WIPCodec,
        authGWF(user, {
            url: `${config.urlGWF}/${wip.dataType}/${encodeURIComponent(wip.resourceId)}/wip`,
            method: 'POST',
            data: WIPCodec.encode({
                ...wip,
                [wip.dataType]: data,
            }),
        }),
    )

    queryClient.invalidateQueries(wipsIdentifier(wip.resourceId))

    return res
}

export async function aquireWIPLock({
    user,
    resourceId,
    resourceType,
    dataType,
}: {
    user: MyIDUser
    resourceId: string
    resourceType: ResourceType
    dataType: WIPDataType
}): Promise<WIP> {
    const res = request(
        WIPCodec,
        authGWF(user, {
            method: 'POST',
            url: `${config.urlGWF}/${ResourceTypeToLockType[resourceType]}/${encodeURIComponent(
                resourceId,
            )}/lock?tabName=${dataType}`,
        }),
    )

    queryClient.invalidateQueries(wipsIdentifier(resourceId))

    return res
}

export async function releaseWIPLock({ user, wip }: { user: MyIDUser; wip: WIP }): Promise<WIP> {
    const res = request(
        WIPCodec,
        authGWF(user, {
            method: 'DELETE',
            url: `${config.urlGWF}/${getLockType(wip)}/${encodeURIComponent(
                wip.resourceId,
            )}/lock?tabName=${wip.dataType}`,
        }),
    )
    queryClient.invalidateQueries(wipsIdentifier(wip.resourceId))

    return res
}

const getLockType = (wip: WIP): string => {
    switch (true) {
        case cwm.isFeature(wip.cwmClassType):
            return 'features'
        case cwm.isSeries(wip.cwmClassType):
            return 'series'
        case cwm.isEpisode(wip.cwmClassType):
            return 'episodes'
    }
    throw new Error(`Cannot get lock type for cwm class type "${wip.cwmClassType}"`)
}
