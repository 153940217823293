import styled from 'shared/theme'

import Button from 'shared/components/Button'

export const RowContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 26px;
    column-gap: 0.5rem;
    align-items: flex-start;
    margin-bottom: 0.5rem;
`
export const LabelContainer = styled(RowContainer)`
    margin-bottom: 0;
`

export const ClearButton = styled.button.attrs({
    type: 'button',
})`
    width: 26px;
    line-height: 3rem;
    border: 0;
    background: transparent;
    color: ${props => props.theme.colors.textPrimary};
    cursor: pointer;
`
export const AddButton = styled(Button).attrs({
    type: 'button',
    variant: 'outline',
})`
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
`
