import styled from 'shared/theme'
import * as s from 'shared/diff/schema'

export const ArcContainer = styled.div<{ diffType?: s.DiffOpType }>`
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: auto min-content;
    ${props =>
        props.diffType === 'add'
            ? `background-color: ${props.theme.colors.backgroundDiffAdd};`
            : props.diffType === 'remove'
            ? `background-color: ${props.theme.colors.backgroundDiffRemove};
                    text-decoration: line-through;`
            : null}
`
