import React from 'react'

import { useFormikContext } from 'formik'

type AutosaveProps<T> = {
    /**
     * The save function to execute on data change.
     * Defaults to FormikContext.submitForm
     */
    onChange?: (values: T) => void
}
export function Autosave<T>({ onChange }: AutosaveProps<T>): null {
    const { submitForm, values } = useFormikContext<T>()
    const lastSavedRef = React.useRef(values)

    React.useEffect(() => {
        if (lastSavedRef.current !== values) {
            lastSavedRef.current = values
            onChange ? onChange(values) : submitForm()
        }
    }, [onChange, submitForm, values])

    return null
}

export default Autosave
