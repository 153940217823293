import * as t from 'io-ts';

var LockCodec = t.type({
  lockedByUser: t.string,
  lockedDateTime: t.string,
  lockedByFirstName: t.string,
  lockedByLastName: t.string
});
function lockMatches(lockState, user) {
  return !!user && !!lockState && lockState.lockedByUser === user['relationship.employeeId'];
}

export { LockCodec, lockMatches };
