import { Theme } from 'shared/theme'

export type PropsWithTheme<Props = {}, Theme = {}> = Props & {
    theme: Theme
}
type OptionFn<Props extends PropsWithTheme> = (props: Props) => string
type OptionValue<Props extends PropsWithTheme> = string | OptionFn<Props>

/**
 * Utility type for an object with style options
 *
 * @param {string} K Union type of keys, like 'primary' | 'secondary'
 * @param {object} OwnProps The unique props for the styled component,
 *      to be made available for use inside the styled definition
 * @param {object} T The theme to use for adding theme props completion
 *      inside the styled definition, defaults to `import { Theme } from 'shared/theme`
 */
export type Options<
    K extends string,
    OwnProps extends object = {},
    T extends object = Theme,
> = Record<K | 'default', OptionValue<PropsWithTheme<OwnProps, T>>>

/**
 * Utility function for using options defined with the Options type
 *
 * Usage:
 * ```
 * type Sizes = 'small' | 'big'
 * const sizes: Options<Size> = {
 *   small: 'font-size: 1rem',
 *   big: props => `font-size: ${props.theme.fontSizes.big}`,
 *   default: 'font-size: 1rem',
 * }
 * type OwnProps = {
 *   size: Size
 * }
 * const Text = styled.span<OwnProps & DefaultProps>`
 *   ${withOption<OwnProps, typeof sizes>(sizes, 'size')}
 * `
 * ```
 *
 * @param {Options} Options object
 * @param {string} field from Props to use
 * @return (props) => string
 */
export const withOption =
    <Props extends object, O extends Options<any, any, any>>(field: keyof Props, options: O) =>
    (props: Props): string => {
        const optionKey = props[field]
        if (isKeyofOptions(optionKey, options)) {
            const option = props[field] ? options[optionKey] : options['default']
            return (isOptionFn<Props>(option) ? option(props) : option) + ';'
        }

        throw new Error(
            `Option "props[${field}]" => "${
                props[field]
            }" does not exist in option keys "${JSON.stringify(Object.keys(options))}."`,
        )
    }

/**
 * check if an option is of type OptionFn
 */
function isOptionFn<Props>(option: unknown): option is OptionFn<PropsWithTheme<Props>> {
    return typeof option === 'function'
}

/**
 * Check that a key belongs to a set of options.
 * Consider an undefined key to always belong, because in that case we use Options['default']
 */
function isKeyofOptions<O extends Options<any>>(key: unknown, options: O): key is keyof O {
    return key === undefined || (options && options[key as string] !== undefined)
}
