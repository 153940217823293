import React from 'react'
import styled from 'shared/theme'

import dateParseISO from 'date-fns/parseISO'
import dateFormat from 'date-fns/format'
import dateIsValid from 'date-fns/isValid'

import config from 'shared/config'

import Text from 'shared/components/Text'

const VersionText = styled(Text)`
    position: absolute;
    bottom: 0;
    right: 20px;
    font-size: 0.625rem;
`

const DATE_FORMAT = 'yyyy-MM-dd'

const Version = (): React.ReactElement | null => {
    const dateRaw = process.env.REACT_APP_BUILD_DATE as string
    if (!dateRaw) {
        return null
    }

    const date = dateParseISO(dateRaw)
    const gitHash = process.env.REACT_APP_BUILD_GIT_HASH
    const version = process.env.REACT_APP_VERSION
    if (!dateIsValid(date) || !gitHash) {
        return null
    }

    // Represent config as:
    // ```
    // key1 = value1
    // key2 = value2
    // ```
    const conf = { ...config, gitHash }
    const configInfo = Object.keys(conf)
        .sort()
        .map(key => {
            return `${key} = ${conf[key as keyof typeof config]}`
        })
        .join('\n')

    const short_hash = gitHash.substring(0, 7)

    return (
        <VersionText size="6">
            <span title={configInfo}>
                Version{' '}
                {version && version !== 'NOVERSION'
                    ? version.replace('-', '.') + ' (' + short_hash + ')'
                    : short_hash}
            </span>
            @<span title={date.toString()}>{dateFormat(date, DATE_FORMAT)}</span>
        </VersionText>
    )
}

export default Version
