import React from 'react'

import { useContextHelper } from 'shared/hooks/useContextHelper'

import { EpisodeStoryline, EpisodeStorylineFormSchema } from 'codecs/storyline/EpisodeStoryline'
import Container from 'shared/components/Container'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import {
    useStorylinesReducer,
    createStorylineContext,
} from 'shared/storylines/hooks/useStorylinesReducer'
import { warnOnValidationErrors } from 'shared/helper/warnOnValidationErrors'

import StorylinesList from './StorylinesList'
import { StorylineForm } from './StorylineForm'
import {
    useFormControlsEnabled,
    useViewMode,
    getWIPData,
    useWIPStorylinesActor,
} from 'shared/resource/ResourceMachineProvider'

const StorylinesContext = createStorylineContext<EpisodeStoryline>()

// eslint-disable-next-line
export function useStorylinesContext() {
    return useContextHelper(StorylinesContext, 'StorylinesContext')
}

const Storylines: React.FC = (): React.ReactElement => {
    const [wipState, send] = useWIPStorylinesActor<EpisodeStoryline[]>()
    const formControlsEnabled = useFormControlsEnabled('storylines')
    const storylines = getWIPData(useViewMode(), wipState)

    const [state, actions] = useStorylinesReducer<EpisodeStoryline>({
        storylines,
        isCreatingNew: false,
        editIdentifier: null,
        shouldSave: false,
        lastInsertedStorylineIdentifier: null,
    })

    // when props storylines change, we need to update our state storylines
    React.useEffect(() => {
        actions.setList(storylines)
    }, [actions, storylines])

    // The context we provide is our state + bound action creators.
    const storylinesContextValue = React.useMemo(() => {
        return {
            ...state,
            ...actions,
            save: (s: EpisodeStoryline) => {
                warnOnValidationErrors('Storyline warnings', EpisodeStorylineFormSchema, s)
                return actions.save(s)
            },
            formControlsEnabled,
        }
    }, [formControlsEnabled, actions, state])

    // when state.shouldSave is set to true, we fire of a request for update
    // to the data manager
    React.useEffect(() => {
        const { shouldSave, storylines } = state
        if (!shouldSave) {
            return
        }

        send({
            type: 'UPDATE',
            data: storylines,
        })

        actions.savePerformed()
    }, [send, state, actions])

    return (
        <StorylinesContext.Provider value={storylinesContextValue}>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Storylines
                    </Text>
                </TableHeaderContent>

                <OptionalAddNewStorylineButton />
            </Container>

            <OptionalNewStorylineForm />

            <StorylinesList />
        </StorylinesContext.Provider>
    )
}

const OptionalAddNewStorylineButton: React.FC = (): React.ReactElement | null => {
    const { formControlsEnabled, isCreatingNew, createNew } = useStorylinesContext()

    if (!formControlsEnabled) {
        return null
    }

    return (
        <Button disabled={isCreatingNew} variant="primary" onClick={() => createNew()}>
            + Add Storyline
        </Button>
    )
}

const OptionalNewStorylineForm: React.FC = (): React.ReactElement | null => {
    const { formControlsEnabled, isCreatingNew } = useStorylinesContext()

    if (!formControlsEnabled || !isCreatingNew) {
        return null
    }

    return <StorylineForm storyline={null} />
}

export default Storylines
