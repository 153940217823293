import { Comment } from 'model/workflow/Comment'
import { createMachine, assign, InterpreterFrom, send } from 'xstate'
import { createModel } from 'xstate/lib/model'
import { ResourceType } from 'api/fetch/fetchResource'
import { invariantEvent } from 'xstate-helpers/events'
import {
    deleteComment,
    editComment,
    createComment,
    createCommentsObserver,
} from '../../api/workflow/comments'

export type CommentService = InterpreterFrom<ReturnType<typeof createCommentsMachine>>

type CommentsContext = {
    comments: Comment[]
    isOpen: boolean
    newCommentContent: string
    resourceType: ResourceType
    resourceId: string
    error?: Error
}
type CommentsEvent =
    | { type: 'TOGGLE_OPEN' }
    | { type: 'LOAD_RETRY' }
    | { type: 'ERROR'; data: Error }
    | { type: 'NEW_COMMENT_INPUT'; newCommentContent: string }
    | { type: 'CREATE_COMMENT' }
    | { type: 'UPDATE_COMMENTS'; comments: Comment[] }

const commentsModel = createModel<CommentsContext, CommentsEvent>({
    comments: [],
    isOpen: false,
    newCommentContent: '',
    resourceType: null!,
    resourceId: null!,
})

type CommentsTypestate = {
    value: 'loading' | 'errorLoading' | 'idle' | 'creating-new-comment'

    context: CommentsContext
}

const toggleOpenAction = commentsModel.assign(ctx => {
    return { isOpen: !ctx.isOpen }
}, 'TOGGLE_OPEN')
const newCommentInputAction = commentsModel.assign((_, { newCommentContent }) => {
    return { newCommentContent }
}, 'NEW_COMMENT_INPUT')

type createCommentsMachineConfig = {
    resourceId: string
    resourceType: ResourceType
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createCommentsMachine = (config: createCommentsMachineConfig) => {
    return createMachine<CommentsContext, CommentsEvent, CommentsTypestate>(
        {
            id: 'comments-machine',
            context: {
                ...commentsModel.initialContext,
                resourceType: config.resourceType,
                resourceId: config.resourceId,
            },
            initial: 'loading',
            invoke: {
                id: 'comments-query-observer',
                src: () => (sendBack, receive) => {
                    let obs = createCommentsObserver(config)
                    let unsub = obs.subscribe(result => {
                        if (result.status === 'success') {
                            sendBack({ type: 'UPDATE_COMMENTS', comments: result.data })
                        }
                        if (result.status === 'error') {
                            sendBack({ type: 'ERROR', data: result.error as any })
                        }
                    })

                    receive(event => event.type === 'REFETCH' && obs.refetch())

                    return unsub
                },
            },
            on: {
                TOGGLE_OPEN: {
                    actions: toggleOpenAction,
                },
                NEW_COMMENT_INPUT: {
                    actions: newCommentInputAction,
                },
                UPDATE_COMMENTS: { actions: 'updateCommentsAction' },
            },
            states: {
                loading: {
                    entry: send('REFETCH', { to: 'comments-query-observer' }),
                    on: {
                        UPDATE_COMMENTS: {
                            target: 'idle',
                            actions: 'updateCommentsAction',
                        },
                        ERROR: {
                            target: 'errorLoading',
                            actions: 'assignError',
                        },
                    },
                },
                errorLoading: {
                    on: {
                        TOGGLE_OPEN: {
                            actions: [toggleOpenAction, 'resetError'],
                            target: 'loading',
                        },
                        LOAD_RETRY: {
                            actions: 'resetError',
                            target: 'loading',
                        },
                        UPDATE_COMMENTS: {
                            target: 'idle',
                            actions: 'updateCommentsAction',
                        },
                    },
                },
                idle: {
                    on: {
                        CREATE_COMMENT: {
                            cond: ctx => ctx.newCommentContent !== '',
                            target: 'creating-new-comment',
                        },
                    },
                },
                'creating-new-comment': {
                    invoke: {
                        id: 'create-new-comment-request',
                        src: ctx => createComment(config, ctx.newCommentContent),
                        onDone: { target: 'idle' },
                        onError: {
                            target: 'idle',
                            actions: assign((_, e) => ({ error: e.data })),
                        },
                    },
                    exit: 'clearNewCommentContent',
                },
            },
        },
        {
            actions: {
                // escalateError: sendParent((_, e: any) => ({ type: 'ERROR', data: e.data })),
                updateCommentsAction: assign<CommentsContext, CommentsEvent>((_ctx, e) => {
                    invariantEvent(e, 'UPDATE_COMMENTS')
                    const { comments } = e

                    return { comments }
                }),
                clearNewCommentContent: assign<CommentsContext, CommentsEvent>({
                    newCommentContent: '',
                }),

                assignError: assign({
                    error: (_, e: any) => e.data,
                }),
                resetError: assign<CommentsContext, CommentsEvent>({
                    error: undefined,
                }),
            },
        },
    )
}

type SingleCommentState = {
    value: 'idle' | 'editing' | 'saving'
    context: SingleCommentContext
}

type SingleCommentContext = {
    isOwnComment: boolean
    editContent: string
    comment: Comment
}

type SingleCommentEvent =
    | {
          type: 'EDIT_START'
      }
    | {
          type: 'EDIT_CANCEL'
      }
    | {
          type: 'EDIT_COMMIT'
      }
    | {
          type: 'UPDATE_COMMENT'
          comment: Comment
      }
    | {
          type: 'EDIT_COMMENT_INPUT'
          editContent: string
      }
    | {
          type: 'DELETE_COMMENT'
      }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const singleCommentMachineFactory = (
    config: createCommentsMachineConfig & { isOwnComment: boolean },
    comment: Comment,
) => {
    return createMachine<SingleCommentContext, SingleCommentEvent, SingleCommentState>(
        {
            id: `comment-${comment.id}`,
            context: {
                isOwnComment: config.isOwnComment,
                editContent: comment.content,
                comment: comment,
            },
            initial: 'idle',
            on: {
                UPDATE_COMMENT: {
                    actions: assign((_, { comment }) => ({ comment })),
                },
                DELETE_COMMENT: {
                    actions: ctx => {
                        deleteComment(config, ctx.comment)
                    },
                },
            },
            states: {
                idle: {
                    on: {
                        EDIT_START: {
                            target: 'editing',
                            actions: assign(ctx => ({ editContent: ctx.comment.content })),
                            cond: 'isOwnComment',
                        },
                    },
                },
                editing: {
                    on: {
                        EDIT_CANCEL: 'idle',
                        EDIT_COMMIT: 'saving',
                        EDIT_COMMENT_INPUT: {
                            actions: assign((_, { editContent }) => ({ editContent })),
                        },
                    },
                },
                saving: {
                    invoke: {
                        src: ctx => editComment(config, comment, ctx.editContent),
                        onDone: 'idle',
                    },
                },
            },
        },
        {
            guards: {
                isOwnComment: ctx => ctx.isOwnComment,
            },
        },
    )
}
