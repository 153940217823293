import React from 'react'

import FormikSelect, { FormikSelectProps } from 'shared/components/Select/FormikSelect'
import { usePureFn } from 'shared/hooks/usePureFn'
import { EpisodeStoryline, STORYLINE_TYPES } from 'codecs/storyline/EpisodeStoryline'

type TypeOption = NonNullable<EpisodeStoryline['type']>

type StorylineTypeProps = Omit<
    FormikSelectProps<TypeOption>,
    'options' | 'getOptionLabel' | 'getOptionValue'
>

export const StorylineType: React.FC<StorylineTypeProps> = (props): React.ReactElement => {
    return (
        <FormikSelect<TypeOption>
            placeholder="Select type"
            {...props}
            options={STORYLINE_TYPES}
            getOptionValue={usePureFn((option: TypeOption) => option.typeId)}
            getOptionLabel={usePureFn((option: TypeOption) => option.typeLabel)}
        />
    )
}

export default StorylineType
