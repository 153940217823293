import React from 'react'
import uniqueId from 'lodash/uniqueId'

import { useField } from 'formik'

import { Props, Wrapper, Content, Label, Radio, TextWrapper } from './common'

const RadioInput: React.FC<Props & { value: string }> = ({ children, ...rest }) => {
    const [field, meta] = useField({ ...rest, type: 'radio' })
    const [id] = React.useState(() => uniqueId('radio-'))

    return (
        <Wrapper>
            <Content error={!!meta.error}>
                <Radio type="radio" id={id} {...field} {...rest} />
                <Label htmlFor={id} error={!!meta.error} />
                <label htmlFor={id}>
                    <TextWrapper>{children}</TextWrapper>
                </label>
            </Content>
        </Wrapper>
    )
}

export default RadioInput
