import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import styled, { css } from 'shared/theme'

const Container = styled.div`
    display: flex;
    align-items: center;
`

const BreadcrumbWrapper = styled.div``

const BreadcrumbsTextStyle = css`
    font-size: 0.9375rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${props => props.theme.colors.textSecondary};
`

const BreadcrumbText = styled.span`
    ${BreadcrumbsTextStyle}
`

export const StyledLink = styled(Link)<LinkProps>`
    ${BreadcrumbsTextStyle}
    &:hover {
        color: ${props => props.theme.colors.primary};
    }
`

const Separator = styled.span`
    padding: 0 0.3125rem;
    color: ${props => props.theme.colors.textSecondary};
`

export type Breadcrumb = {
    title: string
    link?: string
}

type Props = {
    items: Breadcrumb[]
}

const Breadcrumbs: React.FC<Props> = ({ items }) => {
    return (
        <Container>
            {items.map((item, index) => {
                const { title, link = '' } = item
                const key = title + index.toString()
                const hasNextElement = index < items.length - 1
                const hasLink = !!link

                return (
                    <BreadcrumbWrapper key={key}>
                        {hasLink ? (
                            <StyledLink to={link}>{title}</StyledLink>
                        ) : (
                            <BreadcrumbText>{title}</BreadcrumbText>
                        )}
                        {hasNextElement ? <Separator>&gt;</Separator> : null}
                    </BreadcrumbWrapper>
                )
            })}
        </Container>
    )
}

export default Breadcrumbs
