import React from 'react'
import { Column, CellProps } from 'react-table'

import { EpisodeStoryline } from 'codecs/storyline/EpisodeStoryline'

import styled from 'shared/theme'
import StorylineArchetypes from 'shared/components/StorylineArchetypes'
import Table, { CheckboxFilter } from 'shared/components/Table'

import { useStorylinesContext } from './index'
import { EmptyStorylines } from 'shared/storylines/EmptyStorylines'
import { StorylineRow } from './StorylineRow'
import { usePublishErrorManager } from 'shared/resource/ResourceMachineProvider'

const WeightContainer = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 19px;
    border: 1px solid ${props => props.theme.colors.border};
    display: flex;
    align-items: center;
    justify-content: center;
`

const columns: Column<EpisodeStoryline>[] = [
    {
        Header: 'Weight',
        accessor: s => s.storylineWeight,
        Cell: ({ value }: CellProps<EpisodeStoryline, EpisodeStoryline['storylineWeight']>) => {
            return <WeightContainer>{value}</WeightContainer>
        },
        width: 10,
    },
    {
        Header: 'Logline',
        accessor: e => e.storylineLogLine,
        filter: 'checkbox',
    },
    {
        Header: 'Description',
        accessor: e => e.description,
        width: 300,
    },
    {
        Header: 'Type',
        accessor: s => s.type?.typeLabel,
        Filter: CheckboxFilter,
        filter: 'checkbox',
        width: 60,
    },
    {
        Header: 'Storyline Archetype',
        accessor: ({ storylineArchetypes }) => {
            return storylineArchetypes.map(sa => sa.label)
        },
        Cell: ({
            row: {
                original: { storylineArchetypes },
            },
        }: CellProps<EpisodeStoryline>) => {
            return <StorylineArchetypes {...{ storylineArchetypes }} />
        },
        sortType: 'array',
        filter: 'checkbox',
        Filter: CheckboxFilter,
        width: 100,
    },
]

const initialTableState = {
    sortBy: [{ id: 'Weight' }],
}

export const StorylinesList: React.FC = () => {
    const publishErrorManager = usePublishErrorManager()
    const { formControlsEnabled, storylines, edit, lastInsertedStorylineIdentifier } =
        useStorylinesContext()

    return (
        <Table<EpisodeStoryline>
            {...{
                data: storylines,
                initialState: initialTableState,
                columns,
                RowComponent: StorylineRow,
                onRowClick: ({ row: { original: storyline } }) => {
                    if (formControlsEnabled) {
                        edit(storyline)
                    }
                },
                highlightRow: ({
                    row: {
                        original: { storylineId },
                    },
                }) => {
                    return storylineId === lastInsertedStorylineIdentifier
                },
                scrollToRow: ({
                    row: {
                        original: { storylineId },
                    },
                }) => {
                    return storylineId === lastInsertedStorylineIdentifier
                },
                getRowProps: ({ original: { storylineId } }) => ({
                    clickable: formControlsEnabled,
                    hasErrors:
                        formControlsEnabled &&
                        publishErrorManager.hasErrors(
                            (u): u is EpisodeStoryline => u.storylineId === storylineId,
                        ),
                }),
                getCellProps: ({ original: { storyRoles } }) => ({
                    removeBottomBorderRadius: !!storyRoles.length,
                }),
            }}
            emptyMessage={<EmptyStorylines />}
        />
    )
}

export default StorylinesList
