import React from 'react'
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString'
import { pipe } from 'fp-ts/pipeable'
import { getOrElseW } from 'fp-ts/Either'

import { Lock, lockMatches } from '@genome-web-forms/common/lock'
import { useUser } from 'auth/Auth'
import Loader from 'shared/components/Loader'
import LockIcon from 'shared/components/Icons/LockIcon'
import { useWIPActor } from './ResourceMachineProvider'
import { WIPDataType } from 'model/WIP'
import { WIPMachineActor } from './WIP.machine'

export type CustomWIPComponentProps = {
    lock: Lock | null | undefined
    state: WIPMachineActor<any>['state']
}

export type WIPInfoProps = {
    dataType: WIPDataType
    Component?: React.FC<CustomWIPComponentProps>
}

export function WIPInfo({ dataType, Component }: WIPInfoProps): React.ReactElement {
    const [state] = useWIPActor(dataType)
    const lock = state.context.wip?.state

    if (Component) return <Component lock={lock} state={state} />

    return <WIPInfoIcon state={state} />
}

export const WIPInfoIcon: React.FC<{ state: WIPMachineActor<any>['state'] }> = ({ state }) => {
    const { loading, attemptingLockAquire, loaded, editing, releasing } = useWIPStateHelper(state)
    const lock = state.context.wip?.state

    return (
        <>
            {loading && <Loader size="tiny" />}
            {attemptingLockAquire && <Loader size="tiny" />}
            {loaded && <LockIcon lock={lock} />}
            {editing && (releasing ? <Loader size="tiny" /> : <LockIcon editing lock={lock} />)}
        </>
    )
}

type WIPStateHelper = {
    loading: boolean
    attemptingLockAquire: boolean
    editing: boolean
    loaded: boolean
    releasing: boolean
}

export const useWIPStateHelper = (state: WIPMachineActor<any>['state']): WIPStateHelper => ({
    loading: state.matches({ reading: 'loading' }),
    attemptingLockAquire: state.matches({ reading: 'attemptingLockAquire' }),
    loaded: state.matches({ reading: 'loaded' }),
    editing: state.matches('editing'),
    releasing: state.matches({ editing: 'releasing' }),
})

const dateFormatter = new Intl.DateTimeFormat([], {
    dateStyle: 'medium',
    timeStyle: 'medium',
} as any)

export const useFormattedWIPInfo = (
    lock?: Lock | null,
): null | { lockedBy: string; lockedOn: string | null } => {
    const user = useUser()

    if (!lock) return null

    const date = pipe(
        DateFromISOString.decode(lock.lockedDateTime),
        getOrElseW(() => undefined),
    )

    return {
        lockedBy:
            `${lock.lockedByFirstName} ${lock.lockedByLastName}` +
            (lockMatches(lock, user) ? ' (Me)' : ''),
        lockedOn: date ? dateFormatter.format(date) : null,
    }
}

export default WIPInfo
