import * as t from 'io-ts'
import * as s from 'shared/diff/schema'
import nullable from 'codecs/util/nullable'
import { fromNullable } from 'codecs/util/fromNullable'
import { LocationCodec, LocationsDiffSchema, toDiffableLocations } from 'codecs/Location'
import uniqBy from 'lodash/uniqBy'

import {
    RecordedAttributesCodec,
    AttributesDiffSchema,
    toDiffableAttributes,
    attributesMergeAutofill,
} from 'model/metadata/RecordedAttributes'
import {
    DisplayGenreCodec,
    OtherGenreCodec,
    GenreDiffSchema,
    toDiffableGenre,
} from 'model/metadata/Genre'

export const MetadataCodec = t.type({
    displayGenre: nullable(DisplayGenreCodec),
    otherGenres: fromNullable(t.array(OtherGenreCodec), []),
    attributes: nullable(RecordedAttributesCodec),
    locations: fromNullable(t.array(LocationCodec), []),
})
export interface Metadata extends t.TypeOf<typeof MetadataCodec> {}

export const MetadataAutofillCodec = t.type({
    displayGenre: t.array(DisplayGenreCodec),
    otherGenres: t.array(OtherGenreCodec),
    attributes: RecordedAttributesCodec,
    locations: fromNullable(t.array(LocationCodec), []),
})
export interface MetadataAutofill extends t.TypeOf<typeof MetadataAutofillCodec> {}

// utils
export const metadataMergeAutofill = (metadata: Metadata, autofill: MetadataAutofill): Metadata => {
    return {
        displayGenre: metadata.displayGenre ?? (autofill.displayGenre[0] || null),
        otherGenres: uniqBy(
            [...metadata.otherGenres, ...autofill.otherGenres],
            g => g.otherGenreId,
        ),
        attributes: attributesMergeAutofill(metadata.attributes, autofill.attributes),
        locations: uniqBy([...metadata.locations, ...autofill.locations], l => l.locationId),
    }
}

export const metadataIsEmpty = (metadata: Metadata): boolean => {
    if (
        [
            metadata.displayGenre,
            metadata.otherGenres.length,
            metadata.otherGenres.length,
            metadata.locations.length,
        ].some(Boolean)
    ) {
        return false
    }

    for (const type in metadata.attributes) {
        if (metadata.attributes[type].length) {
            return false
        }
    }

    return true
}

// diff
export const MetadataDiffSchema = s.schema({
    genres: GenreDiffSchema,
    attributes: AttributesDiffSchema,
    locations: LocationsDiffSchema,
})
export interface DiffableMetadata extends s.TypeOf<typeof MetadataDiffSchema> {}

export const toDiffableMetadata = (metadata: Metadata): DiffableMetadata => {
    return {
        genres: toDiffableGenre(metadata),
        attributes: toDiffableAttributes(metadata.attributes),
        locations: toDiffableLocations(metadata),
    }
}
