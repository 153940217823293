import * as t from 'io-ts'
import * as y from 'yup'
import * as s from 'shared/diff/schema'
import { fromNullable } from 'codecs/util/fromNullable'
import { ArcValueCodec, ARC_VALUES, compareByArc } from 'codecs/Arc'
import { orderBy } from 'natural-orderby'

import { AttributeValueCodec, AttributeValueFormSchema } from 'codecs/Attribute'
import { Character, CharacterCodec, CharacterFormSchema } from 'codecs/Character'
import 'shared/Yup.array.uniqBy.ts'

const RelationshipArcAttributeCodec = t.type({
    arc: ArcValueCodec,
    values: t.array(AttributeValueCodec),
})
export interface RelationshipArcAttribute extends t.TypeOf<typeof RelationshipArcAttributeCodec> {}

export const RelationshipCodec = t.type({
    relationshipId: t.string,
    characters: t.array(CharacterCodec),
    attributes: fromNullable(t.array(RelationshipArcAttributeCodec), []),
})
export interface Relationship extends t.TypeOf<typeof RelationshipCodec> {}

///////////////////////////
// Constants
///////////////////////////

export const TRS_RELATIONSHIP_TYPES = 'http://data.disney.com/resources/RelationshipTypes'

/////////////////////////////////////////////
// YUP VALIDATION
/////////////////////////////////////////////

const RelationshipArcAttributeFormSchema: y.SchemaOf<RelationshipArcAttribute> = y
    .object({
        arc: y
            .string()
            .oneOf(ARC_VALUES as any)
            .required(),
        values: y.array().required().of(AttributeValueFormSchema).min(1).label('Development Type'),
    })
    .required() as any

export interface RelationshipFormValue {
    relationshipId: string
    characters: Character[]
    attributes: RelationshipArcAttribute[]
}
export const RelationshipFormSchema: y.SchemaOf<RelationshipFormValue> = y
    .object({
        relationshipId: y.string().required(),
        characters: y
            .array()
            .defined()
            .of(CharacterFormSchema.required('Both characters must be selected'))
            .min(2, 'You must select at least 2 characters')
            .max(2)
            .label('Characters')
            .uniqBy(c => c && c.characterId, 'Character 1 and Character 2 should not be the same'),
        attributes: y
            .array()
            .defined()
            .of(RelationshipArcAttributeFormSchema)
            // eslint-disable-next-line no-template-curly-in-string
            .min(1, 'You must add at least one ${label}')
            .label('Development Type')
            .required(),
    })
    .defined()

///////////////////////////
// Constants
///////////////////////////

export const RelationshipDiffSchema = s.schema({
    relationshipId: s.ignore<string>(),
    character1: s.value<string>(),
    character2: s.value<string>(),
    relDevelopments: s.array(
        s.schema({
            arc: s.value<string>(),
            labels: s.array(s.value<string>()),
        }),
        'arc',
    ),
})
export type DiffableRelationship = s.TypeOf<typeof RelationshipDiffSchema>
export const toDiffableRelationship = ({
    relationshipId,
    characters,
    attributes,
}: Relationship): DiffableRelationship => {
    let charNames = characters.map(c => c.characterName)
    charNames = orderBy(charNames)
    return {
        relationshipId,
        character1: charNames[0] ?? '<<Empty>>',
        character2: charNames[1] ?? '<<Empty>>',
        relDevelopments: attributes
            .slice()
            .sort(compareByArc)
            .map(attr => ({
                arc: attr.arc,
                labels: orderBy(attr.values.map(({ attributeLabel }) => attributeLabel)),
            })),
    }
}
