import baseStyled, {
    ThemedStyledInterface,
    ThemeProvider as StyledThemeProvider,
    css as styledCss,
} from 'styled-components'
import Color from 'color'

export const css = styledCss
export const ThemeProvider = StyledThemeProvider
const baseColors = {
    blue: '#1E7CFF',
    darkBlue: '#111E3F',
    lightBlue: '#4B5D80',
    ultraLightBlue: '#E1E8F0',
    hawkesBlue: '#D1E4FF',
    gray: '#96A2B7',
    lightGray: '#CEDAE5',
    ultraLightGray: '#F5F7FC',
    yellow: '#FFB702',
    white: '#FFFFFF',
    black: '#000000',
    blackish: '#212529',
    lightRed: '#FCE8EA',
    purple: '#4439A4',
    purpleLight: '#D4C8FD',
    warning: '#FF623D',
    green: '#1EBD91',
}

export const theme = {
    space: [4, 8, 16, 24, 32, 64],
    colors: {
        primary: baseColors.blue,
        textPrimary: baseColors.darkBlue,
        textPrimaryDisabled: Color(baseColors.lightBlue).lightness(80).hex(),
        textSecondary: baseColors.lightBlue,
        activeField: baseColors.ultraLightBlue,
        tagHover: baseColors.hawkesBlue,
        border: baseColors.lightGray,
        borderActive: baseColors.blue,
        backgroundPrimary: baseColors.ultraLightGray,
        backgroundSecondary: baseColors.white,
        backgroundError: baseColors.lightRed,
        backgroundErrorHighlight: Color(baseColors.lightRed).lightness(80).hex(),
        backgroundDiffAdd: '#e6ffed',
        backgroundDiffRemove: '#ffeef0',
        backgroundGray: '#E7ECF2',
        white: baseColors.white,
        black: baseColors.black,
        blackish: baseColors.blackish,
        gray: baseColors.gray,
        warning: baseColors.warning,
        backgroundWarning: '#ffc107',
        danger: '#E71B36',
        success: baseColors.green,
        autofill: baseColors.purple,
        backgroundAutofill: baseColors.purpleLight,
    },
}

export type Theme = typeof theme

const styled = baseStyled as ThemedStyledInterface<Theme>
export default styled
