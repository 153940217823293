import React from 'react'
import * as t from 'io-ts'
import { Character, CharacterCodec } from 'codecs/Character'
import { useContextHelper } from 'shared/hooks/useContextHelper'
import { useQuery } from 'react-query'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import { useUser } from 'auth/Auth'
import config from 'shared/config'

type CharactersContextValue<C extends Character = Character> = C[]
const CharactersContext = React.createContext<CharactersContextValue>([])
CharactersContext.displayName = 'Characters'
export const useCharacters = <C extends Character>(): C[] => {
    return useContextHelper(CharactersContext, 'CharactersContext') as C[]
}

type CharactersProviderProps = {
    characters?: Character[]
    resourceId: string
}
export const CharactersProvider: React.FC<CharactersProviderProps> = ({
    characters,
    resourceId,
    children,
}) => {
    const user = useUser()
    const { data } = useQuery<Character[]>(
        ['characters', resourceId],
        () => {
            return request(
                t.array(t.exact(CharacterCodec)),
                authGWF(user, {
                    url: `${config.urlGWF}/lists/characters/${encodeURIComponent(resourceId)}`,
                }),
            )
        },
        {
            enabled: !characters,
            initialData: characters || [],
        },
    )

    return <CharactersContext.Provider value={data!}>{children}</CharactersContext.Provider>
}
export default CharactersProvider
