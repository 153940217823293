import React from 'react'
import { space, SpaceProps } from 'styled-system'

import styled, { css } from 'shared/theme'
import { Options, withOption } from 'shared/option'
import { Weight, weights } from 'shared/components/Text'

type OwnProps = React.PropsWithRef<JSX.IntrinsicElements['button']> &
    SpaceProps & {
        variant?: Variant
        weight?: Weight
        size?: Size
    }

export type Size = '1' | '2' | '3' | '4' | '5' | '6'
const sizes: Options<Size> = {
    1: 'font-size: 2.25rem; line-height: 2.5rem',
    2: 'font-size: 1.75rem; line-height: 3rem',
    3: 'font-size: 1.375rem; line-height: 1.5rem',
    4: 'font-size: 1.125rem; line-height: 1.5rem',
    5: 'font-size: 0.9375rem; line-height: 1.5rem',
    6: 'font-size: 0.875rem; line-height: 1.5rem;',
    default: '',
}

// Variants
export type Variant = 'primary' | 'secondary' | 'danger'
const primaryVariant = css`
    color: ${props => props.theme.colors.primary};
`

const secondaryVariant = css`
    color: ${props => props.theme.colors.textSecondary};
`
const dangerVariant = css`
    color: ${props => props.theme.colors.danger};
`

const disabledStyles = css`
    cursor: not-allowed;
    opacity: 0.4;
`

const TextualButton = styled.button<OwnProps>`
    border: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: transparent;
    cursor: pointer;

    ${props => {
        switch (props.variant) {
            case 'primary':
                return primaryVariant
            case 'secondary':
                return secondaryVariant
            case 'danger':
                return dangerVariant
            default:
                return primaryVariant
        }
    }}

    ${props => (props.disabled ? disabledStyles : '')}
    ${withOption<OwnProps, typeof weights>('weight', weights)}
    ${withOption<OwnProps, typeof sizes>('size', sizes)}
    ${space}
`

export default TextualButton
