import React from 'react'
import { Options, withOption } from 'shared/option'
import Text, { TextProps } from 'shared/components/Text'

import styled from 'shared/theme'

export type { Size } from 'shared/components/Text'

export type Variant = 'primary' | 'secondary' | 'danger' | 'warning' | 'success'
const variants: Options<Variant> = {
    primary: props => `
        color: #fff;
        background-color: ${props.theme.colors.primary};
    `,
    secondary: props => `
        color: #fff;
        background-color: ${props.theme.colors.textSecondary};
    `,
    danger: props => `
        color: #fff;
        background-color: ${props.theme.colors.danger};
    `,
    warning: props => `
        color: ${props.theme.colors.blackish};
        background-color: ${props.theme.colors.backgroundWarning};
    `,
    success: props => `
        color: #fff;
        background-color: ${props.theme.colors.success};
    `,
    default: props => `
        color: #fff;
        background-color: ${props.theme.colors.textSecondary};
    `,
}

type OwnProps = {
    variant?: Variant
}
const BadgeWrapper = styled.span<OwnProps>`
    display: inline-block;
    font-size: 75%;
    padding: 0.25em 0.4em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;

    ${withOption<OwnProps, typeof variants>('variant', variants)}
`

export type BadgeProps = OwnProps & Omit<TextProps, 'weight'>
const Badge: React.FC<BadgeProps> = ({ variant, ...props }) => {
    const badgeProps = { variant }

    return (
        <BadgeWrapper {...badgeProps}>
            <Text weight="bold" {...props} />
        </BadgeWrapper>
    )
}

export default Badge
