import { PublishWorkflowComponent, ErrorContext } from 'codecs/publish-workflow/PublishWorkflow'
import { WorkflowError, WorkflowErrorInstance } from 'codecs/publish-workflow/parse'
import flatMap from 'lodash/flatMap'

export class PublishErrorManager {
    private errors: WorkflowErrorInstance<any>[]
    constructor(public workflowError?: WorkflowError) {
        this.errors = workflowError ? flatMap(workflowError.errors, e => e.errors) : []
    }

    hasComponentErrors(component: PublishWorkflowComponent): boolean {
        return this.workflowError
            ? this.workflowError.errors.some(error => error.component === component)
            : false
    }

    getErrors<T extends ErrorContext>(
        predicate: (errorContext: ErrorContext) => errorContext is T,
    ): WorkflowErrorInstance<T>[] {
        return this.errors.filter(
            (e: WorkflowErrorInstance<ErrorContext>): e is WorkflowErrorInstance<T> =>
                e.errorContext.some(predicate),
        )
    }

    hasErrors<T extends ErrorContext>(
        predicate: (errorContext: ErrorContext) => errorContext is T,
    ): boolean {
        return this.errors.some(e => e.errorContext.some(predicate))
    }
}
