import React from 'react'
import { Episode, EpisodeJSONViewOmittedValues } from 'model/Episode'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import ResourceTabs from 'shared/components/Tabs/ResourceTabs'
import SaveIndicator from 'shared/components/SaveIndicator'
import PageTitle from 'shared/components/PageTitle'
import { seriesLink, seasonLink } from 'routing/routes'
import Breadcrumbs, { Breadcrumb } from 'shared/components/Breadcrumbs'
import Metadata from 'shared/components/Metadata'

import Cast from 'shared/components/Cast'

import MasterData from './MasterData'
import Storylines from './Storylines'
import {
    useIsSaving,
    usePreviewModeDiff,
    useResourceMachine,
} from 'shared/resource/ResourceMachineProvider'
import { flags } from 'shared/flags'
import Relationships from 'shared/components/Relationships'
import CheckIconGreen from 'shared/components/CheckIconGreen'
import RelationshipsDiff from 'shared/components/Relationships/RelationshipsDiff'
import EpisodeStorylinesDiff from './Storylines/EpisodeStorylinesDiff'
import WIPInfoAndAquire from 'shared/resource/WIPInfoAndAquire'
import { JSONViewer } from 'shared/components/JSONViewer'
import AllWorkflowControls from 'shared/components/Workflow/all-workflow-controls'
import PreviewControl from 'shared/components/PreviewControl'

const EpisodeNode = (): React.ReactElement => {
    const [state] = useResourceMachine<Episode>()
    const {
        context: { resource },
    } = state

    const publishErrorManager = state.context.publishErrorManager
    const previewModeDiff = usePreviewModeDiff()

    const {
        cwmClassTypeLabel,
        seriesCwmClassTypeLabel,
        static: { seriesId, seriesTitle, episodeNumber, seasonId, seasonTitle },
        episodeTitle,
        cast,
    } = resource

    function getBreadcrumbs(): Breadcrumb[] {
        let breadcrumbs: Breadcrumb[] = [
            { title: seriesCwmClassTypeLabel },
            { title: seriesTitle, link: seriesLink({ seriesId }) },
        ]

        if (seasonId) {
            breadcrumbs = breadcrumbs.concat([
                { title: 'Season' },
                {
                    title: seasonTitle ?? 'Unknown Season',
                    link: seasonLink({ seasonId }),
                },
            ])
        }

        return breadcrumbs.concat({
            title: `${cwmClassTypeLabel} ${episodeNumber || ''}`,
        })
    }

    return (
        <Container px="3" pb="3" pt="1" flexDirection="column">
            <Breadcrumbs items={getBreadcrumbs()} />
            <PageTitle title={episodeTitle} />
            {useIsSaving() && <SaveIndicator />}
            <Container flexDirection="row" justifyContent="space-between" alignItems="center">
                <Text size="1" as="h2">
                    {episodeTitle}{' '}
                    {state.matches({ idle: 'readonly' }) && (
                        <CheckIconGreen title="You are viewing published data" />
                    )}
                </Text>

                {flags.workflows && <AllWorkflowControls />}

                <PreviewControl />
            </Container>

            <ResourceTabs
                tabs={[
                    {
                        id: 'Primary Metadata',
                        panel: <MasterData />,
                    },
                    {
                        id: 'Descriptors',
                        label: <WIPInfoAndAquire title="Descriptors" dataType="metadata" />,
                        panel: <Metadata />,
                        hasErrors: publishErrorManager.hasComponentErrors('metadata'),
                    },
                    {
                        id: 'Storylines',
                        label: <WIPInfoAndAquire title="Storylines" dataType="storylines" />,
                        panel: previewModeDiff ? <EpisodeStorylinesDiff /> : <Storylines />,
                        hasErrors: publishErrorManager.hasComponentErrors('storylines'),
                    },
                    {
                        id: 'Relationships',
                        label: <WIPInfoAndAquire title="Relationships" dataType="relationships" />,
                        panel: previewModeDiff ? <RelationshipsDiff /> : <Relationships />,
                        hasErrors: publishErrorManager.hasComponentErrors('relationships'),
                    },
                    {
                        id: 'Cast',
                        panel: <Cast data={cast} />,
                    },
                    {
                        id: 'JSON',
                        panel: <JSONViewer json={resource} omit={EpisodeJSONViewOmittedValues} />,
                    },
                ]}
            />
        </Container>
    )
}

export default EpisodeNode
