import React from 'react'
import * as s from 'shared/diff/schema'

import { Pill } from 'shared/components/Table/TablePills'

type DiffArrayPrimitiveProps<T extends string | number> = {
    className?: string
    diff: s.ArrayDiff<T>
}
export function DiffArrayPrimitive<T extends string | number>({
    diff,
}: DiffArrayPrimitiveProps<T>): React.ReactElement {
    return (
        <div>
            {diff.ops.map((op, i) => {
                if (s.isSchemaDiff(op)) {
                    throw new Error(
                        `DiffArrayPrimitive cannot handle array that contains schema diffs: ${JSON.stringify(
                            op,
                        )}`,
                    )
                }

                return (
                    <Pill key={op.value + String(i)} diffType={op.type}>
                        {op.value}
                    </Pill>
                )
            })}
        </div>
    )
}
