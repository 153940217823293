import React from 'react'
import * as D from 'io-ts/lib/Decoder'
import { useUser } from 'auth/Auth'
import config from 'shared/config'
import { useDebounce } from 'shared/hooks/useDebounce'
import { useQuery, UseQueryResult } from 'react-query'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import {
    SearchResult,
    SearchResultPayload,
    SearchResultPayloadCodec,
} from 'model/search/SearchResult'
import { Workflow, WorkflowDec, WorkflowSearchRequest } from '@genome-web-forms/server'
import { flags } from 'shared/flags'

type useSearchOpts = {
    searchText?: string
    searchType?: string
}
type useSearchReturn = {
    setSearchText: (text: string) => void
    setSearchType: (type: string | undefined) => void
    searchType: string | undefined
    searchText: string
    lastSearchedText: string
    isLoading: boolean
    isFetching: boolean
    results: SearchResult[]
    totalHits: number
}
export const useSearch = (init: useSearchOpts = {}): useSearchReturn => {
    const user = useUser()
    const [searchText, setSearchText] = React.useState<string>(init.searchText ?? '')
    const [searchType, setSearchType] = React.useState<string | undefined>(init.searchType)
    const [lastSearchedText] = useDebounce(searchText, 300)

    let {
        isLoading,
        isFetching,
        data: baseData,
    } = useSearchQuery({
        user,
        type: searchType,
        text: lastSearchedText,
    })

    let resourceId: string[] = baseData?.results.map(result => result.id) || []
    resourceId.sort()
    const { data: allWorkflows = [] } = useQuery<Array<Workflow>>(
        ['search-workflows', resourceId],
        () => {
            if (!flags.workflows) return []
            if (resourceId.length === 0) return []
            return request(
                D.array(WorkflowDec),
                authGWF<WorkflowSearchRequest>(user, {
                    url: `${config.urlGWFWorkflows}/workflows/search`,
                    method: 'PUT',
                    data: { resourceId, completed: false },
                }),
            )
        },
        { initialData: [] },
    )

    const data = React.useMemo<typeof baseData>(() => {
        if (!baseData) return baseData

        baseData.results = baseData.results.map(result => {
            result.workflows = allWorkflows.filter(workflow => workflow.resourceId === result.id)
            return result
        })

        return baseData
    }, [baseData, allWorkflows])

    const { results, totalHits } = React.useMemo<SearchResultPayload>(
        () => (data ? data : { totalHits: 0, results: [] }),
        [data],
    )

    return {
        isLoading,
        isFetching,
        searchText,
        searchType,
        lastSearchedText,
        results,
        totalHits,
        setSearchType,
        setSearchText,
    }
}

type useSearchQueryArgs = {
    user: MyIDUser
    type: string | undefined
    text: string
}
const useSearchQuery = (args: useSearchQueryArgs): UseQueryResult<SearchResultPayload> => {
    return useQuery(['search', args.text, args.type ?? 'all'], () => executeSearch(args))
}
const executeSearch = async ({
    user,
    text,
    type,
}: useSearchQueryArgs): Promise<SearchResultPayload> => {
    let params: { text: string; type?: string } = { text }
    if (type) {
        params.type = type
    }

    // fast exit for empty searches
    if (!params.type && !params.text) {
        return { results: [], totalHits: 0 }
    }

    return request(
        SearchResultPayloadCodec,
        authGWF(user, {
            url: `${config.urlGWF}/search`,
            params,
            transformResponse,
        }),
    )

    // Search can have some bad data.
    // We want to handle the most egregious parts before they hit the codec,
    // omit them from what the user sees, and notify Rollbar instead
    function transformResponse(data: any): any {
        try {
            data = JSON.parse(data)
        } catch (e) {
            return data
        }

        let filtered =
            data.results?.filter(({ genomeObject }: any) => genomeObject.cwmClassType) || []
        if (filtered.length !== (data.results?.length || 0)) {
            let message = `There were some results omitted from the search because they do not contain "cwmClassType"`

            let count = data.results.length - filtered.length

            // intersection of data.results and filtered
            let bad = [data.results, filtered].reduce((a, b) =>
                a.filter((r: any) => !b.includes(r)),
            )
            console.warn(message, bad)
            if (process.env.NODE_ENV !== 'development') {
                window.Rollbar.warning(new Error(message), {
                    count,
                    bad_search_results: bad,
                })
            }

            // only pass on the filtered results
            data.results = filtered
        }

        return data
    }
}
