import startCase from 'lodash/startCase'

const forceLowerCase =
    /\b(a|abaft|about|above|afore|after|along|amid|among|an|apud|as|aside|at|atop|below|but|by|circa|down|for|from|given|in|into|lest|like|mid|midst|minus|near|next|of|off|on|onto|out|over|pace|past|per|plus|pro|qua|round|sans|save|since|than|thru|till|times|to|under|until|unto|up|upon|via|vice|with|worth|the|and|nor|or|yet|so)\b/i

const forceUpperCase = /\b(tv|id)\b/i

export function titleCase(string: string): string {
    return startCase(string)
        .replace(forceLowerCase, s => s.toLowerCase())
        .replace(forceUpperCase, s => s.toUpperCase())
}
