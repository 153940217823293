function customSerializeBase<T extends Record<string, any>>(
    data: T,
    serializeConfig: Partial<{ [K in keyof T]: false | 'set' | 'map' | ((value: T[K]) => any) }>,
): T {
    if (typeof data !== 'object' || data['toJSON']) {
        return data
    }

    ;(data as any)['toJSON'] = function () {
        const keys = Object.keys(this)
        let res = { ...this }
        for (let key of keys) {
            let config = serializeConfig[key]
            if (config === false) {
                res[key] = '...'
            } else if (config === 'set') {
                res[key] = [...res[key]]
            } else if (config === 'map') {
                res[key] = { ...res[key] }
            } else if (typeof config === 'function') {
                res[key] = config(this[key])
            } else {
                res[key] = this[key]
            }
        }

        return res
    }

    return data
}

export const customSerialize: typeof customSerializeBase =
    process.env.NODE_ENV === 'development' ? customSerializeBase : data => data
