import { request } from '@genome-web-forms/common/api'
import { authGWF } from 'api/auth'
import { MetadataAutofill, MetadataAutofillCodec } from 'model/metadata/Metadata'
import { WIPContext } from 'shared/resource/WIP.machine'
import invariant from 'tiny-invariant'
import config from 'shared/config'

export async function metadataFetchAutofill({
    user,
    resourceRadarId,
}: WIPContext<any>): Promise<MetadataAutofill> {
    invariant(resourceRadarId)
    return request(
        MetadataAutofillCodec,
        authGWF(user, {
            url: `${config.urlGWF}/auto_tag?radarId=${encodeURIComponent(resourceRadarId)}`,
        }),
    )
}
