const hostMatches = (...hosts: Array<string>): boolean => {
    try {
        return hosts.includes(window.location.hostname)
    } catch (e) {
        return false
    }
}

const LOCAL_DEV = process.env.NODE_ENV === 'development'

export const DEV = 'webforms.dev.genome.disney.com'
export const QA = 'webforms.qa.genome.disney.com'
export const STAGING = 'webforms.staging.genome.disney.com'
export const PROD = 'webforms.prod.genome.disney.com'

export const WF_DEV = 'cwr.metadatasb.dmed.technology'
export const WF_QA = 'cwr.metadataqa.dmed.technology'
export const WF_PROD = 'cwr.metadata.dmed.technology'

export const flags = {
    starSelected: true,
    workflows:
        LOCAL_DEV ||
        hostMatches(DEV) ||
        hostMatches(WF_DEV) ||
        hostMatches(WF_QA) ||
        hostMatches(WF_PROD),
    workflowsDelete:
        LOCAL_DEV ||
        hostMatches(DEV) ||
        hostMatches(WF_DEV) ||
        hostMatches(WF_QA) ||
        hostMatches(WF_PROD),
    comments: false,
}
