import React from 'react'
import { orderBy } from 'natural-orderby'

import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import { flattenHierarchy } from 'shared/util/flattenHierarchy'
import { useStableParamsWarning } from 'shared/hooks/useStableParamsWarning'
import { theme } from 'shared/theme'

import { Pill, PillsWrapper } from 'shared/components/Table/TablePills'

type TRSValuesForTermsProps<T> = {
    values: T[]
    terms: TaxonomyTerm[]
    valueToTermId: (value: T) => string
    getValueLabel: (value: T, term?: TaxonomyTerm) => string
}

const TRS_VALUE_STABLE_DEPS = ['terms', 'valueToTermId', 'getValueLabel'] as const

export function TRSValuesForTerms<T>(props: TRSValuesForTermsProps<T>): React.ReactElement {
    const { values, terms, valueToTermId, getValueLabel } = props
    useStableParamsWarning(props, TRS_VALUE_STABLE_DEPS, 'TRSValuesForTerms', 2)

    const rawTermsFlattened = React.useMemo(() => {
        return flattenHierarchy(terms)
    }, [terms])

    const valueMapper = React.useCallback(
        (value: T): TRSValueT & { id: string } => {
            const valueTermId = valueToTermId(value)
            const term = rawTermsFlattened.find(term => term.id === valueTermId)

            const notForTagging = rawTermsFlattened.length
                ? term
                    ? // not for tagging if TRS data loaded and marked as notForTagging
                      !!term.notForTagging
                    : // or if it doesn't exist in TRS anymore
                      false
                : false

            return {
                id: valueTermId,
                label: getValueLabel(value, term),
                notForTagging,
                preferredLabel:
                    term && term.notForTagging && term.preferredTerms && term.preferredTerms.length
                        ? term.preferredTerms[0].label
                        : null,
            }
        },
        [rawTermsFlattened, getValueLabel, valueToTermId],
    )

    const mappedValues = orderBy(values, getValueLabel).map(valueMapper)

    return (
        <PillsWrapper>
            {mappedValues.map(value => (
                <TRSValue key={value.id} value={value} as={Pill} />
            ))}
        </PillsWrapper>
    )
}

export type TRSValueT = {
    id?: string
    label: string
    notForTagging: boolean
    preferredLabel: null | string
}
export const TRSValue = React.memo(function ({
    value,
    as = React.Fragment,
}: {
    value: TRSValueT
    as?: React.StatelessComponent
}): React.ReactElement {
    const Component = as
    return value.notForTagging ? (
        value.preferredLabel ? (
            <Component>
                {value.label}{' '}
                <span
                    style={{ color: theme.colors.gray }}
                    title={`prefer: ${value.preferredLabel}`}
                >
                    (not for tagging)
                </span>
            </Component>
        ) : (
            // term is not present, or we don't have a preferred term
            <Component>
                {value.label} <span style={{ color: theme.colors.gray }}>(not for tagging)</span>
            </Component>
        )
    ) : (
        <Component>{value.label}</Component>
    )
})
