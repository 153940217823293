import React from 'react'
import styled from 'shared/theme'

import { options } from 'model/search/SearchOptions'

import Select from 'shared/components/Select'

type SearchTypeSelectProps = {
    onChange: (value: string | undefined) => void
    searchType: string | undefined
}

const SearchTypeSelect: React.FC<SearchTypeSelectProps> = (props): React.ReactElement => {
    const { onChange, searchType } = props

    return (
        <Select
            {...props}
            isMulti={false}
            name="search-type"
            options={options}
            value={options.find(o => o.value === searchType)}
            placeholder="Filter by type..."
            onChange={option => {
                onChange(option && (option as any).value)
            }}
        />
    )
}

export default styled(SearchTypeSelect)`
    display: flex;
    flex-basis: 180px;
    flex-direction: column;
`
