import React from 'react'

import Empty from 'shared/components/Empty'
import { useFormControlsEnabled } from 'shared/resource/ResourceMachineProvider'

export const EmptyStorylines: React.FC = (): React.ReactElement => {
    const formControlsEnabled = useFormControlsEnabled('storylines')
    const subtitle = formControlsEnabled
        ? 'Click “+Add storyline” to add a new storyline'
        : undefined

    return <Empty title="No storylines added yet" subtitle={subtitle} />
}
