import React from 'react'

import FormikSelect, { FormikSelectProps } from 'shared/components/Select/FormikSelect'

import { Character } from 'codecs/Character'
import { usePureFn } from 'shared/hooks/usePureFn'

export type FormikCharacterSelectProps = {
    name: string
    characters: Character[]
} & Omit<FormikSelectProps<Character>, 'options' | 'getOptionLabel' | 'getOptionValue'>
export const FormikCharacterSelect: React.FC<FormikCharacterSelectProps> = ({
    characters,
    ...props
}): React.ReactElement => {
    const options = React.useMemo(
        () =>
            characters
                // We can get characters that are { notForTagging } from MWD
                .filter(({ notForTagging = false }: any) => !notForTagging)
                // The selection options should only contain `characterId` and `characterName` props
                .map<Character>(({ characterId, characterName }) => ({
                    characterId,
                    characterName,
                })),
        [characters],
    )

    return (
        <FormikSelect<Character>
            {...props}
            options={options}
            getOptionValue={usePureFn(option => option.characterId)}
            getOptionLabel={usePureFn(option => option.characterName)}
        />
    )
}

export default FormikCharacterSelect
