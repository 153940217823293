import styled from 'shared/theme'
import {
    space,
    flex,
    justifyContent,
    alignItems,
    flexDirection,
    SpaceProps,
    FlexProps,
    JustifyContentProps,
    AlignItemsProps,
    FlexDirectionProps,
} from 'styled-system'

type Props = SpaceProps & FlexProps & JustifyContentProps & AlignItemsProps & FlexDirectionProps

const Container = styled.div<Props>`
    ${space}
    ${flex}
    ${justifyContent}
    ${alignItems}
    ${flexDirection}

    display: flex;
`

export default Container
