import React from 'react'
import { Column } from 'react-table'

import Table from 'shared/components/Table'
import EpisodeLinkCell from 'shared/components/Table/EpisodeLinkCell'
import Text from 'shared/components/Text'
import Container from 'shared/components/Container'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'

import { AbridgedEpisode } from 'model/Episode'

export const columns: Column<AbridgedEpisode>[] = [
    {
        id: 'airOrderNumber',
        Header: 'Air Order No',
        accessor: e => e.airOrderNumber,
        Cell: EpisodeLinkCell,
        width: 20,
        disableSortBy: true,
    },
    {
        id: 'episodeTitle',
        Header: 'Episode Title',
        accessor: e => e.episodeTitle,
        Cell: EpisodeLinkCell,
        disableSortBy: true,
    },
    {
        id: 'episodeNumber',
        Header: 'Production No',
        accessor: e => e.episodeNumber,
        Cell: EpisodeLinkCell,
        disableSortBy: true,
    },
    {
        id: 'foreignEpisodeNumber',
        Header: 'Foreign Episode No',
        accessor: e => e.foreignEpisodeNumber,
        Cell: EpisodeLinkCell,
        disableSortBy: true,
    },
]

const initialTableState = {
    sortBy: [{ id: 'airOrderNumber' }, { id: 'episodeNumber' }, { id: 'foreignEpisodeNumber' }],
}

export type EpisodesProps = {
    data: AbridgedEpisode[]
}
const Episodes: React.FC<EpisodesProps> = ({ data }): React.ReactElement => {
    return (
        <>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Episodes
                    </Text>
                </TableHeaderContent>
            </Container>

            <Table<AbridgedEpisode>
                {...{ data, columns, initialState: initialTableState }}
                getCellProps={() => ({
                    removePadding: true,
                })}
            />
        </>
    )
}

export default Episodes
