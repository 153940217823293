import React from 'react'
import { LinkProps } from 'react-router-dom'

import {
    useTable,
    useSortBy,
    useExpanded,
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    Column,
    TableOptions,
    TableRowComponentProps,
    CellProps,
    TableInstance,
} from 'react-table'

import isFunction from 'lodash/isFunction'

import Empty from 'shared/components/Empty'
import { useStableParamsWarning } from 'shared/hooks/useStableParamsWarning'
import Optional from 'shared/components/Optional'

import CheckboxListFilter, { checkboxFilterType } from './Filters/CheckboxListFilter'

import {
    TableEl,
    TableRow,
    HeaderRow,
    Tbody,
    Thead,
    Header,
    HeaderAlignComponent,
    TableCell,
    TableLinkCell,
    TableSortIndicator,
    HeaderContentWrap,
} from './styles'
import { filterTypes } from './filterTypes'
import { sortTypes } from './sortTypes'
import { globalFilterBySearchText } from './globalFilterBySearchText'

export {
    TableRow,
    TableCell,
    CheckboxListFilter as CheckboxFilter,
    checkboxFilterType as checkboxListFilter,
}

export function OptionalCell<D extends DataItem>({
    cell: { value },
}: CellProps<D>): React.ReactElement {
    return <Optional value={value} />
}

export function RouteLinkCell({
    children,
    ...linkProps
}: React.PropsWithChildren<LinkProps>): React.ReactElement {
    return <TableLinkCell {...linkProps}>{children}</TableLinkCell>
}

export type ColumnsFromData<T extends object[]> = Column<T[number]>[]

export type DataItem = object
type TableProps<D extends DataItem> = TableOptions<D> & {
    extraHooks?: ((...args: any[]) => void)[]
}
export function Table<D extends DataItem>(props: TableProps<D>): React.ReactElement {
    // for most params, warn after 1 change
    useStableParamsWarning(props, ['columns', 'hasRowHover'], '<Table>', 1)
    // for data, warn after 10 changes
    useStableParamsWarning(props, ['data'], '<Table>', 10)

    const {
        searchText,
        extraHooks = [],
        emptyMessage = false,
        RowComponent = RowComponentDefault,
        ...tableOptions
    } = props

    const tableInstance = useTable<D>(
        {
            filterTypes,
            globalFilter: globalFilterBySearchText,
            autoResetGlobalFilter: false,
            autoResetFilters: false,
            autoResetSortBy: false,
            autoResetExpanded: false,
            sortTypes,
            ...tableOptions,
        },
        ...[useFilters, useGlobalFilter, useSortBy, useExpanded, useFlexLayout, ...extraHooks],
    )

    const { setGlobalFilter } = tableInstance
    const { data } = tableOptions

    React.useEffect(() => {
        setGlobalFilter(searchText)
    }, [setGlobalFilter, searchText])

    if (data.length === 0 && emptyMessage) {
        return React.isValidElement(emptyMessage) ? emptyMessage : <Empty title={emptyMessage} />
    }

    return <TableNode {...{ tableInstance, RowComponent }} />
}

type TableNodeProps<D extends DataItem> = {
    tableInstance: TableInstance<D>
    RowComponent?: TableOptions<D>['RowComponent']
}

export function TableNode<D extends DataItem>({
    tableInstance,
    RowComponent = RowComponentDefault,
}: TableNodeProps<D>): React.ReactElement {
    const {
        rows,
        allColumns: { length: colSpan },
        headerGroups,
        getTableProps,
        getTableBodyProps,
    } = tableInstance
    return (
        <TableEl {...getTableProps()}>
            <Thead>
                {headerGroups.map(headerGroup => (
                    /* eslint-disable-next-line react/jsx-key */
                    <HeaderRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            /* eslint-disable-next-line react/jsx-key */
                            <Header {...column.getHeaderProps()}>
                                <HeaderContentWrap>
                                    <HeaderAlignComponent
                                        {...(column.getSortByToggleProps
                                            ? column.getSortByToggleProps()
                                            : {})}
                                    >
                                        {(column.getSortByToggleProps as
                                            | typeof column.getSortByToggleProps
                                            | undefined) && column.canSort !== false ? (
                                            <TableSortIndicator {...{ column }} />
                                        ) : null}
                                        <span>{column.render('Header')}</span>
                                    </HeaderAlignComponent>
                                    {column.canFilter && column.Filter
                                        ? column.render('Filter')
                                        : null}
                                </HeaderContentWrap>
                            </Header>
                        ))}
                    </HeaderRow>
                ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
                {rows.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={colSpan}>No matches</TableCell>
                    </TableRow>
                ) : (
                    rows.map((row, index) => {
                        tableInstance.prepareRow(row)
                        return (
                            // https://github.com/tannerlinsley/react-table/blob/4eb1720b99e669fa54beb149b353730ab47e1f68/src/makeDefaultPluginHooks.js#L36
                            <RowComponent
                                key={`row_${row.id}`}
                                {...{ ...tableInstance, row, index }}
                            />
                        )
                    })
                )}
            </Tbody>
        </TableEl>
    )
}

const defaultPropGetter = (): object => ({})

export const RowComponentDefault = <D extends DataItem>(
    tableInstanceWithRow: TableRowComponentProps<D>,
): React.ReactElement => {
    const {
        row,
        hasRowHover = true,
        onRowClick,
        highlightRow,
        scrollToRow,
        getRowProps = defaultPropGetter,
        getCellProps = defaultPropGetter,
    } = tableInstanceWithRow

    const hasHover: boolean = isFunction(hasRowHover)
        ? hasRowHover(tableInstanceWithRow)
        : hasRowHover
    const highlight = isFunction(highlightRow) ? highlightRow(tableInstanceWithRow) : false
    const scrollToSelf = isFunction(scrollToRow) ? scrollToRow(tableInstanceWithRow) : false

    return (
        <TableRow
            {...{ clickable: !!onRowClick }}
            {...row.getRowProps(getRowProps(row))}
            {...{ highlight, hasHover, scrollToSelf }}
            onClick={
                onRowClick
                    ? event =>
                          onRowClick({
                              ...tableInstanceWithRow,
                              event,
                          })
                    : undefined
            }
        >
            {row.cells.map(cell => {
                return (
                    /* eslint-disable-next-line react/jsx-key */
                    <TableCell {...cell.getCellProps(getCellProps(row, cell))}>
                        {cell.render('Cell')}
                    </TableCell>
                )
            })}
        </TableRow>
    )
}

export default Table
