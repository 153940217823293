import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import get from 'lodash/get'

type BaseTerm = { id: string; label: string }

/**
 * In TRS, there some terms have a `preferredTerms` property.
 *
 * When a TaxonomyTerm has a `preferredTerms`, we want to remove it
 * and use the `preferredTerms[0]` instead.
 *
 * It is expected that when a term has a preferredTerm, that preferredTerm will
 * be a sibling of the current term. That however is not guaranteed.
 *
 * In our implementation, we remove the original term, and if the preferredTerm
 * doesn't exist as a sibling of the original, we insert it.
 */
export const substitutePreferredTerms = (terms: readonly TaxonomyTerm[]): TaxonomyTerm[] => {
    let outputTerms: TaxonomyTerm[] = []

    for (const inputTerm of terms) {
        // copy so that we don't overwrite the original object
        const term = { ...inputTerm }

        // get the first preferred term (the only one we care about)
        const preferredTerm = get(term, ['preferredTerms', 0]) as BaseTerm | undefined

        if (preferredTerm) {
            // if there is a preferred term
            // and it's not a sibling of our original term
            if (!terms.find(t => t.id === preferredTerm.id)) {
                // add the preferred term
                outputTerms.push({ ...preferredTerm })
            }

            continue
        }

        // otherwise pass-through the term
        if (term.terms) {
            term.terms = substitutePreferredTerms(term.terms)
        }

        outputTerms.push(term)
    }

    return outputTerms
}
