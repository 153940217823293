import React from 'react'

import noop from 'lodash/noop'

import { StoryRole } from 'codecs/storyline/StoryRole'
import { StoryroleReadonly } from './StoryroleReadonly'
import { Grid, GridHeaderCell } from './Grid'
import {
    useFormControlsEnabled,
    usePublishErrorManager,
} from 'shared/resource/ResourceMachineProvider'

export function StoryRolesList({
    onStoryRoleClick = noop,
    storyRoles,
}: {
    onStoryRoleClick?: (storyRole: StoryRole) => void
    storyRoles: StoryRole[]
}): React.ReactElement {
    const formControlsEnabled = useFormControlsEnabled('storylines')
    const publishErrorManager = usePublishErrorManager()

    return (
        <>
            <Grid>
                <GridHeaderCell noBorder>Character Name</GridHeaderCell>
                <GridHeaderCell>Character Archetype</GridHeaderCell>
                <GridHeaderCell>Motivation</GridHeaderCell>
                <GridHeaderCell>Trait</GridHeaderCell>
            </Grid>
            {storyRoles.map(storyRole => (
                <StoryroleReadonly
                    clickable={formControlsEnabled}
                    onClick={() => onStoryRoleClick(storyRole)}
                    key={storyRole.storyRoleId}
                    storyRole={storyRole}
                    hasErrors={publishErrorManager.hasErrors(
                        (u): u is StoryRole => u.storyRoleId === storyRole.storyRoleId,
                    )}
                />
            ))}
        </>
    )
}
