/**
 * Build our own browser history object, so that we can programatically change
 * browser location.
 *
 * The reason for the weird require and type definitions is to make this work
 * with jest mocks (we use a memory history mock)
 */
import * as baseHistory from 'history'
import { History } from 'history'
// // eslint-disable-next-line @typescript-eslint/no-var-requires
// const baseHistory = require('../../../../node_modules/history')

const history: History = baseHistory.createBrowserHistory()

export default history
