import React from 'react'

import history from 'routing/history'
import { searchLink, Search as SearchLocation } from 'routing/routes'
import PageLayout from 'pages/util/PageLayout'
import { useSearch } from 'shared/search/useSearch'
import { SearchResult } from 'model/search/SearchResult'
import { routeTo } from 'model/search/routeTo'

import SearchTypeSelect from './SearchTypeSelect'
import { labelForValue } from 'model/search/SearchOptions'
import SearchKeyMenu from './SearchKeyMenu'

import Loader from 'shared/components/Loader'
import Table from 'shared/components/Table'
import { Column, CellProps } from 'react-table'
import Text from 'shared/components/Text'
import LockIcon from 'shared/components/Icons/LockIcon'
import Container from 'shared/components/Container'
import SearchRow from './SearchRow'

import {
    SearchContainer,
    SearchForm,
    SearchResultLink,
    SearchResultsHeader,
    SearchHeaderText,
    SearchResultTitle,
    SearchInput,
} from 'shared/components/search'

type SearchProps = {
    match: { params: SearchLocation }
}
const Search: React.FC<SearchProps> = ({
    match: {
        params: { q, type },
    },
}): React.ReactElement => {
    const {
        isLoading,
        isFetching,
        results,
        searchType,
        searchText,
        setSearchText,
        setSearchType,
        lastSearchedText,
    } = useSearch({
        searchText: q ?? '',
        searchType: type,
    })

    React.useEffect(() => {
        history.replace(
            lastSearchedText === ''
                ? searchLink({})
                : searchLink({ q: lastSearchedText, type: searchType }),
        )
    }, [lastSearchedText, searchType])

    React.useEffect(() => {
        if (q) setSearchText(q)
    }, [setSearchText, q])

    return (
        <PageLayout>
            <SearchContainer>
                <SearchForm>
                    <SearchTypeSelect
                        searchType={searchType}
                        onChange={searchType => setSearchType(searchType)}
                    />
                    <SearchInput
                        autoFocus
                        placeholder="Start typing to search"
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                        onReset={() => setSearchText('')}
                    />
                </SearchForm>

                <SearchKeyMenu />

                <SearchResultsHeader>
                    <Container>
                        <SearchHeaderText size="2" as="h2" mt="2" mr="2">
                            Search results
                        </SearchHeaderText>
                    </Container>
                    {!isLoading && lastSearchedText ? (
                        <Text size="5" as="p" my="0">
                            {results.length} matches for "{lastSearchedText}"
                            {searchType && ` of type ${labelForValue(searchType)}`}
                            {!isLoading && isFetching && ' (updating...)'}
                        </Text>
                    ) : null}
                </SearchResultsHeader>

                {isLoading ? (
                    <Loader center size="normal" />
                ) : results.length ? (
                    <Table
                        {...{
                            RowComponent: SearchRow,
                            data: results,
                            columns,
                        }}
                    />
                ) : null}
            </SearchContainer>
        </PageLayout>
    )
}

const columns: Column<SearchResult>[] = [
    {
        id: 'title',
        disableSortBy: true,
        Header: 'Title',
        Cell: ({ row: { original: result } }: CellProps<SearchResult>) => (
            <SearchResultLink to={routeTo(result)}>
                <SearchResultTitle {...{ result }} />
            </SearchResultLink>
        ),
        width: 1000,
    },
    {
        id: 'metadata',
        disableSortBy: true,
        Header: 'Metadata',
        accessor: r => r.genomeObject.lockStatuses?.metadata?.lockedDateTime,
        Cell: ({ row: { original: result } }: CellProps<SearchResult>) => {
            const lock = result.genomeObject.lockStatuses?.metadata

            return lock ? <LockIcon lock={lock} /> : <span title="Not locked">&mdash;</span>
        },
    },
    {
        id: 'storylines',
        disableSortBy: true,
        Header: 'Storylines',
        accessor: r => r.genomeObject.lockStatuses?.storylines?.lockedDateTime,
        Cell: ({ row: { original: result } }: CellProps<SearchResult>) => {
            const lock = result.genomeObject.lockStatuses?.storylines

            return lock ? <LockIcon lock={lock} /> : <span title="Not locked">&mdash;</span>
        },
    },
    {
        id: 'relationships',
        disableSortBy: true,
        Header: 'Relationships',
        accessor: r => r.genomeObject.lockStatuses?.relationships?.lockedDateTime,
        Cell: ({ row: { original: result } }: CellProps<SearchResult>) => {
            const lock = result.genomeObject.lockStatuses?.relationships

            return lock ? <LockIcon lock={lock} /> : <span title="Not locked">&mdash;</span>
        },
    },
    {
        id: 'type',
        Header: 'Type',
        disableSortBy: true,
        accessor: r => r.genomeObject.cwmClassTypeLabel,
        Cell: ({ row: { original: result } }: CellProps<SearchResult>) => (
            <Text size="5" variant="secondary" as="span" style={{ whiteSpace: 'nowrap' }}>
                {result.genomeObject.cwmClassTypeLabel}
            </Text>
        ),
    },
]

export default Search
