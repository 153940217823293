import { Row } from 'react-table'

import { searchPredicate } from 'shared/util/searchPredicate'
import { DataItem } from 'shared/components/Table'

const SEPARATOR = '\u00a0'

/**
 * A global filter that allows filtering by `searchText`
 *
 * It can filter column data where the column is either a string or an array of strings
 */
export function globalFilterBySearchText<D extends DataItem>(
    rows: Row<D>[],
    columnIds: any[],
    searchText: string | undefined,
): Row<D>[] {
    if (!searchText) {
        return rows
    }

    const predicate = searchPredicate(searchText)

    return rows.filter(row => {
        return columnIds.some(id => {
            const value = row.values[id]
            const stringValue: string =
                typeof value === 'string'
                    ? value
                    : (Array.isArray(value) ? value : [value])
                          .filter((u: unknown): u is string => typeof u === 'string')
                          .join(SEPARATOR)

            return predicate(stringValue)
        })
    })
}
