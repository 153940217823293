import React from 'react'

import Text from 'shared/components/Text'
import Table from 'shared/components/Table'
import SearchInput from 'shared/components/SearchInput'
import Container from 'shared/components/Container'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import { Series } from 'model/Series'
import * as cwm from '@genome-web-forms/common/model/CWMClassType'

import { useSeasonsTable } from './EpisodesTab/useSeasonsTable'
import { AbridgedSeason } from 'model/Season'
import { AbridgedEpisode } from 'model/Episode'
import SeasonEpisodes from 'pages/Season/Episodes'
import { useResourceMachineSelector } from 'shared/resource/ResourceMachineProvider'

type EpisodesTabProps = {
    seasons: AbridgedSeason[]
    episodes: AbridgedEpisode[]
}
const EpisodesTab: React.FC<EpisodesTabProps> = ({ seasons, episodes }): React.ReactElement => {
    const cwmClassType = useResourceMachineSelector<Series, Series['cwmClassType']>(
        React.useCallback(state => state.context.resource.cwmClassType, []),
    )
    const [searchText, setSearchText] = React.useState('')
    const { data, columns, initialState } = useSeasonsTable({
        seasons,
        episodes,
        searchText,
    })

    if (cwm.CWM_CLASS_TYPE_MINISERIES === cwmClassType) {
        return <SeasonEpisodes data={episodes} />
    }

    return (
        <>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Episodes
                    </Text>
                    <SearchInput value={searchText} onChange={e => setSearchText(e.target.value)} />
                </TableHeaderContent>
            </Container>

            <Table
                {...{ data, columns, initialState }}
                emptyMessage={episodes.length === 0 ? 'No episodes added yet' : 'No search matches'}
                getCellProps={() => ({
                    removePadding: true,
                })}
            />
        </>
    )
}

export default EpisodesTab
