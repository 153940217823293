import Text from 'shared/components/Text'
import styled from 'shared/theme'

export const ArcText = styled(Text).attrs<{}>({
    size: 5,
    variant: 'secondary',
})`
    padding: 0.25rem 0;
    text-transform: capitalize;
`

export default ArcText
