import copy from 'copy-to-clipboard'
import React, { useMemo } from 'react'
import ReactJson from 'react-json-view'
import { notification } from 'shared/notification'
import styled from 'shared/theme'
import Button from '../Button'
import Container from '../Container'
import Panel from '../Panel'
import lodashOmit from 'lodash/omit'

const StyledJSONArea = styled.div`
    width: 100%;
    resize: none;
    border-color: ${props => props.theme.colors.border};
    border-radius: 2px;
    height: calc(100vh - 312px);
    overflow-y: auto;
`
const StyledOptionButtons = styled.div`
    justify-content: space-between;
    flex-direction: row-reverse;
    display: flex;
    margin: 1rem 0;
`

export type JSONViewerProps = {
    json: any
    omit?: string[]
}

export const JSONViewer: React.FC<JSONViewerProps> = ({ json, omit = [] }): React.ReactElement => {
    const cleanJSON = useMemo(() => lodashOmit(json, omit), [json, omit])

    const onCopyJSONClick = () => {
        const copyStatusMessage = copy(JSON.stringify(json))
            ? 'Content was copied into clipboard'
            : 'An error has ocurred while copying content'
        notification.info(copyStatusMessage)
    }

    return (
        <Container px="1" pb="2" pt="2" flex="1" flexDirection="column">
            <Panel>
                <StyledJSONArea>
                    <ReactJson src={cleanJSON} />
                </StyledJSONArea>
            </Panel>
            <StyledOptionButtons>
                <Button type="button" onClick={onCopyJSONClick}>
                    Copy JSON
                </Button>
            </StyledOptionButtons>
        </Container>
    )
}
