import React from 'react'

import { Season as SeasonLocation } from 'routing/routes'
import { useParams } from 'react-router'

import SeasonNode from './SeasonNode'
import Loader from 'shared/components/Loader'
import Header from 'shared/components/Header'
import HeaderEditing from 'shared/components/Header/Editing'
import { ResourceMachineServiceContext } from 'shared/resource/ResourceMachineProvider'
import { useMachine } from '@xstate/react'
import { createResourceMachine } from 'shared/resource/resource.machine'
import { useErrorHandler } from 'react-error-boundary'
import { useUser } from 'auth/Auth'
import { fetchResource } from 'api/fetch'
import { Season, SeasonCodec } from 'model/Season'
import noop from 'lodash/noop'

const SeasonPage: React.FC = (): React.ReactElement => {
    const { seasonId } = useParams<SeasonLocation>()
    const handleError = useErrorHandler()

    const user = useUser()
    const [state, , service] = useMachine(
        () =>
            createResourceMachine<Season>({
                resourceId: seasonId,
                resourceType: 'season',
                user,
                wips: ['metadata'],
                fetchFn: ctx => fetchResource({ codec: SeasonCodec, ...ctx }),
                handleCriticalError: (_, e: any) => handleError(e.data),
                // seasons cannot be locked, no need to pass a real refresh function
                refreshLockedTitles: noop,
            }),
        {
            devTools: true,
        },
    )

    if (state.matches('loading')) {
        return (
            <>
                <Header />
                <Loader center size="normal" />
            </>
        )
    }

    if (state.matches('404')) {
        return (
            <>
                <Header />
                <h1>404: Not Found</h1>
            </>
        )
    }
    return (
        <ResourceMachineServiceContext.Provider value={service}>
            <HeaderEditing />
            <SeasonNode />
        </ResourceMachineServiceContext.Provider>
    )
}

export default SeasonPage
