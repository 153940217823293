import React from 'react'
import styled from 'shared/theme'

import TextualButton, { Variant } from 'shared/components/TextualButton'
import { ReactComponent as Arrow } from './arrow.svg'

export const StyledArrow = styled(Arrow)<{ toggled: 'true' | undefined }>`
    margin-left: 0.5rem;
    transform: ${props => (props.toggled ? 'rotate(180deg)' : 'rotate(0)')};
`
type Props = {
    toggled: boolean
    variant?: Variant
}
type ButtonProps = React.PropsWithRef<JSX.IntrinsicElements['button']> & Props

const ToggleButton: React.FC<ButtonProps> = ({
    children,
    toggled,
    variant,
    ...restProps
}): React.ReactElement => {
    return (
        <TextualButton variant={variant ?? 'secondary'} weight="bold" size="6" {...restProps}>
            {children}
            <StyledArrow toggled={toggled ? 'true' : undefined} />
        </TextualButton>
    )
}

export default ToggleButton
