import styled from 'shared/theme'

export const Grid = styled.div`
    display: grid;
    grid-gap: 1.5rem;
`
export const GridWithColumns = styled(Grid)`
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
    margin-top: 1.5rem;
    .sectionStart,
    .sectionStart + dd {
        margin-top: 1rem;
    }
`
