import { WIP, WIPDataForType, WIPDataType, getData } from 'model/WIP'
import { lockMatches } from '@genome-web-forms/common/lock'
import { StateMachine, createMachine, assign, sendParent, ActorRefFrom, sendUpdate } from 'xstate'
import { MyIDUser } from '@genome-web-forms/common/auth'
import invariant from 'tiny-invariant'
import { ResourceType } from 'api/fetch/fetchResource'
import { aquireWIPLock, fetchWIP, releaseWIPLock, updateWIP } from 'api/wip'
import { isTabLockAquireError, TabExclusiveLock } from 'shared/resource/TabExclusiveLock'
import isEqual from 'lodash/isEqual'

export interface WIPContext<T = unknown> {
    readonly resourceId: string
    readonly resourceType: ResourceType
    readonly resourceRadarId: string | null
    readonly dataType: WIPDataType
    readonly user: MyIDUser

    readonly data: T
    readonly readonlyData: T

    readonly releaseRequested: boolean
    readonly tabExclusiveLock: TabExclusiveLock

    readonly lastSavedData?: T

    readonly wip: WIP | undefined
}
interface WIPContextWithWIP<T> extends WIPContext<T> {
    wip: WIP
}

export type WIPState<T> =
    | {
          value:
              | 'reading'
              | { reading: { loaded: 'free' } }
              | { reading: 'loading' | 'loaded' | 'attemptingLockAquire' }
          context: WIPContext<T>
      }
    | {
          value:
              | { reading: { loaded: 'ownedByMe' | 'ownedByOther' } }
              | 'editing'
              | { editing: 'releasing' | 'idle' | 'updating' | 'released' }
              | 'autofill'
              | 'autofill.loading'
              | 'autofill.ready'
          context: WIPContextWithWIP<T>
      }

export type WIPEvent<T = unknown> =
    | { type: 'ERROR'; data: Error }
    | { type: 'UPDATE'; data: T }
    | { type: 'UPDATE_READONLY'; readonlyData: T }
    | { type: 'RELEASE' }
    | { type: 'ATTEMPT_AQUIRE' }
    | { type: 'AUTOFILL_START' }
    | { type: 'AUTOFILL_COMMIT' }
    | { type: 'AUTOFILL_CANCEL' }

type WIPConfig<K extends WIPDataType> = {
    readonlyData: any
    dataType: K
    resourceId: string
    resourceRadarId: string | null
    resourceType: ResourceType
    user: MyIDUser
    refreshLockedTitles: () => void
    autofill?: {
        fetch: (ctx: WIPContext<any>) => Promise<unknown>
        merge: (current: WIPDataForType[K], autofill: unknown) => WIPDataForType[K]
    }
}
export type WIPMachine<T = unknown> = StateMachine<WIPContext<T>, any, WIPEvent<T>, WIPState<T>>
export interface WIPMachineActor<T> extends ActorRefFrom<WIPMachine<T>> {}

export const createWIPMachine = <T extends WIPDataForType[keyof WIPDataForType]>({
    resourceId,
    resourceType,
    resourceRadarId,
    readonlyData,
    dataType,
    user,
    refreshLockedTitles,
    autofill,
}: WIPConfig<WIPDataType>): WIPMachine<T> => {
    invariant(readonlyData, 'Cannot create a WIP machine without readonly data')
    return createMachine<WIPContext<T>, WIPEvent<T>, WIPState<T>>(
        {
            id: `wip-${dataType}`,
            context: {
                readonlyData,
                data: readonlyData,
                releaseRequested: false,
                resourceId,
                resourceType,
                resourceRadarId,
                dataType,
                user,
                wip: undefined,
                tabExclusiveLock: new TabExclusiveLock(),
            },
            invoke: {
                // destroy the lock instance when the machine is destroyed
                id: 'tab-exclusive-lock-cleanup',
                src: ctx => () => {
                    return () => ctx.tabExclusiveLock.destroy()
                },
            },
            initial: 'reading',
            on: {
                ERROR: {
                    actions: 'escalateError',
                },
                UPDATE_READONLY: {
                    actions: assign({
                        readonlyData: (_, e) => e.readonlyData,
                    }),
                },
            },
            states: {
                reading: {
                    entry: sendUpdate(),
                    initial: 'initial',
                    states: {
                        initial: {
                            always: [
                                { target: 'loaded', cond: 'hasWipData' },
                                { target: 'loading' },
                            ],
                        },
                        loading: {
                            invoke: {
                                id: `${dataType}-query-current-status`,
                                src: ctx => fetchWIP(ctx),
                                onDone: {
                                    target: 'loaded',
                                    actions: assign({ wip: (_, e) => e.data }),
                                },
                                onError: { actions: 'escalateError' },
                            },
                        },
                        loaded: {
                            initial: 'initial',
                            states: {
                                initial: {
                                    always: [
                                        { target: 'ownedByMe', cond: 'ownedByMe' },
                                        {
                                            target: 'ownedByOther',
                                            cond: 'ownedByMeSomeone',
                                        },
                                        { target: 'free' },
                                    ],
                                },
                                free: {},
                                ownedByOther: {},
                                ownedByMe: {},
                            },
                        },
                        attemptingLockAquire: {
                            invoke: {
                                id: `${dataType}-attempt-aquire-lock`,
                                src: async ctx => {
                                    await ctx.tabExclusiveLock.aquire(
                                        ctx.resourceId + '-' + ctx.dataType,
                                    )
                                    return aquireWIPLock(ctx)
                                },
                                onDone: {
                                    actions: [
                                        sendStatusUpdate('aquired'),
                                        assign({ wip: (_, e) => e.data }),
                                        'setDataFromWIP',
                                        assign({ lastSavedData: ctx => ctx.data }),
                                        'refreshLockedTitles',
                                    ],
                                    target: '#editing',
                                },
                                onError: [
                                    {
                                        cond: (_, e) => isTabLockAquireError(e.data),
                                        actions: 'escalateError',
                                    },
                                    {
                                        target: 'loading',
                                        actions: sendStatusUpdate('failed'),
                                    },
                                ],
                            },
                        },
                    },
                    on: {
                        ATTEMPT_AQUIRE: '.attemptingLockAquire',
                    },
                },

                editing: {
                    id: 'editing',
                    on: {
                        UPDATE: {
                            actions: assign({
                                data: (_, e) => e.data,
                            }),
                        },
                        RELEASE: {
                            actions: assign({
                                releaseRequested: _ => true,
                            }),
                        },
                    },
                    entry: sendUpdate(),
                    initial: 'idle',
                    invoke: {
                        id: 'listen-for-tab-lock-access-loss',
                        src: 'tabLockAccessRevokedListener',
                    },
                    states: {
                        idle: {
                            on: {
                                AUTOFILL_START: {
                                    cond: 'autofillEnabled',
                                    target: '#autofill',
                                },
                            },
                            entry: sendUpdate(),
                            always: [
                                {
                                    cond: 'shouldUpdateBackend',
                                    target: 'updating',
                                },
                                {
                                    cond: 'isReleaseRequested',
                                    target: 'releasing',
                                },
                            ],
                        },
                        updating: {
                            entry: [
                                sendUpdate(),
                                assign({
                                    lastSavedData: ctx => ctx.data,
                                }),
                            ],
                            invoke: {
                                id: `${dataType}-update-wip`,
                                src: ctx => updateWIP(ctx as WIPContextWithWIP<T>),
                                onDone: {
                                    actions: assign({
                                        wip: (_, e) => e.data,
                                    }),
                                    target: 'idle',
                                },
                                onError: { actions: 'escalateError' },
                            },
                        },
                        releasing: {
                            entry: sendUpdate(),
                            invoke: {
                                id: `${dataType}-release-lock`,
                                src: ctx => {
                                    invariant(ctx.wip)
                                    ctx.tabExclusiveLock.release()
                                    return releaseWIPLock(ctx as WIPContextWithWIP<T>)
                                },
                                onDone: {
                                    actions: [
                                        assign({
                                            wip: _ => undefined,
                                            releaseRequested: _ => false,
                                        }),
                                        sendStatusUpdate('released'),
                                        'refreshLockedTitles',
                                    ],
                                    target: 'released',
                                },
                                onError: { actions: 'escalateError' },
                            },
                        },
                        released: {
                            type: 'final',
                        },
                    },
                    onDone: { target: 'reading' },
                },
                autofill: {
                    id: 'autofill',
                    on: {
                        AUTOFILL_COMMIT: { target: 'editing' },
                        AUTOFILL_CANCEL: { target: 'editing', actions: 'setDataFromWIP' },
                        RELEASE: {
                            target: 'editing',
                            actions: [
                                assign({
                                    releaseRequested: _ => true,
                                }),
                                'setDataFromWIP',
                            ],
                        },
                    },
                    initial: 'loading',
                    states: {
                        loading: {
                            invoke: {
                                src: ctx => {
                                    invariant(
                                        autofill,
                                        `autofillDataGetter() not given to WIP config function`,
                                    )
                                    return autofill.fetch(ctx)
                                },
                                onDone: {
                                    target: 'ready',
                                    actions: 'mergeAutofillData',
                                },
                                onError: { actions: 'escalateError' },
                            },
                        },
                        ready: {
                            on: {
                                UPDATE: {
                                    actions: assign({
                                        data: (_, e) => e.data,
                                    }),
                                },
                            },
                        },
                    },
                },
            },
        },
        {
            guards: {
                ownedByMe,
                ownedByMeSomeone,
                hasWipData: ctx => !!ctx.wip,
                isReleaseRequested: ctx => ctx.releaseRequested,
                shouldUpdateBackend: ctx => !isEqual(ctx.data, ctx.lastSavedData),
                autofillEnabled: ctx => (autofill && ctx.resourceRadarId ? true : false),
            },
            actions: {
                refreshLockedTitles,
                setDataFromWIP: assign({
                    data: ctx => {
                        invariant(ctx.wip, `Cannot set data to WIP data, it's empty`)
                        return getData(ctx.wip, ctx.dataType)
                    },
                }),
                setDataToReadonly: assign({
                    data: ctx => ctx.readonlyData,
                    lastSavedData: _ => undefined,
                }),
                escalateError: sendParent((_, e: any) => ({ type: 'ERROR', data: e.data })),
                mergeAutofillData: assign({
                    data: (ctx, e: any) => {
                        invariant(autofill)
                        return autofill.merge(ctx.data, e.data) as T
                    },
                }),
            },
            services: {
                tabLockAccessRevokedListener: ctx => send => {
                    ctx.tabExclusiveLock.accessRevokedCallback = () => {
                        send({
                            type: 'ERROR',
                            data: new Error('You started editing this title in another tab.'),
                        })
                    }
                    return () => {
                        ctx.tabExclusiveLock.accessRevokedCallback = undefined
                    }
                },
            },
        },
    )
}

const sendStatusUpdate = (status: 'aquired' | 'failed' | 'released') =>
    sendParent((ctx: WIPContext<any>, _: any) => ({
        type: 'WIP_SYNC_STATUS',
        data: {
            dataType: ctx.dataType,
            status,
        },
    }))

type Guard = (ctx: WIPContext) => boolean
const ownedByMeSomeone: Guard = ctx => !!ctx.wip?.state
const ownedByMe: Guard = ctx => ownedByMeSomeone(ctx) && lockMatches(ctx.wip!.state, ctx.user)
