import React from 'react'
import { Formik, Form } from 'formik'
import * as y from 'yup'
import ReactTooltip from 'react-tooltip'
import * as E from 'fp-ts/lib/Either'

import { authGWF } from 'api/auth'
import { axiosRequest } from '@genome-web-forms/common/api'
import { notification } from 'shared/notification'
import browserInfo from 'browser-info'
import config from 'shared/config'

import styled from 'shared/theme'
import { useUser } from 'auth/Auth'
import { useCommonUserData } from 'shared/hooks/useCommonUserData'
import { useBugReportContext } from 'shared/BugReport/BugReportProvider'
import {
    AlertDialog,
    AlertDialogLabel,
    AlertDialogDescription,
    AlertDialogButtons,
} from 'shared/components/AlertModal'
import { FormikInput } from 'shared/components/Input'
import ErrorMessage from 'shared/components/ErrorMessage'
import Button from 'shared/components/Button'
import Radio from 'shared/components/CheckboxRadioInput/Radio'
import Text from 'shared/components/Text'
import ImageDropZone from 'shared/components/ImageDropZone'
import TextualButton from 'shared/components/TextualButton'
import Container from 'shared/components/Container'

const RadioContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
`

const StyledReactTooltip = styled(ReactTooltip)`
    line-height: 1rem;
`

const RadioGroupErrorMessage = styled(ErrorMessage)`
    margin-top: 0;
    align-self: flex-start;
`

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
`

const ERROR_TYPE_PAGE_ERROR = 'pageError'
const ERROR_TYPE_PUBLISH_ERROR = 'publishError'
const ERROR_TYPE_MISSING_TERM = 'missingTerm'
const ERROR_TYPE_MISSING_METADATA = 'missingMetadata'
const ERROR_TYPE_OTHER = 'other'

const ERROR_TYPES = [
    ERROR_TYPE_PAGE_ERROR,
    ERROR_TYPE_PUBLISH_ERROR,
    ERROR_TYPE_MISSING_TERM,
    ERROR_TYPE_MISSING_METADATA,
    ERROR_TYPE_OTHER,
] as const

const FILE_SIZE = 25 * 1024 * 1024 // 25 megabytes

const FormSchema = y
    .object({
        text: y.string().required('This field is required'),
        summary: y.string().required('This field is required'),
        type: y.string().oneOf(ERROR_TYPES.slice()).required('You must select the bug type'),
        attachment: y.mixed().test('fileSize', 'File too large', value => {
            if (value) {
                return value.size <= FILE_SIZE
            }
            return true
        }),
    })
    .defined()
type BugReportValues = y.Asserts<typeof FormSchema>

export const ReportABugModal: React.FC = (): React.ReactElement => {
    const { setModalVisible } = useBugReportContext()
    const cancelRef = React.createRef<HTMLButtonElement>()
    const user = useUser()
    const commonData = useCommonUserData()

    const handleSubmit = React.useCallback(
        ({ text, summary, type, attachment }: BugReportValues) => {
            try {
                const { uuid } = window.Rollbar.info(
                    `Telemetry for Bug Report: ${text}`,
                    commonData,
                )
                commonData.telemetryUuid = uuid
            } catch (e) {}

            const formData = new FormData()
            formData.append('text', text)
            formData.append('summary', summary)
            formData.append('type', type)
            formData.append('data', JSON.stringify(commonData))
            if (attachment instanceof File) {
                formData.append('attachment', attachment, attachment.name)
            }

            const reqConfig = authGWF(user, {
                url: `${config.urlGWF}/bugreporting`,
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data;',
                },
            })

            return axiosRequest(reqConfig).then(
                E.fold(
                    () => {
                        notification.error('There was an error while submitting your bug report.')
                    },
                    () => {
                        notification.success('Bug Report sent succesfully.')
                        setModalVisible(false)
                    },
                ),
            )
        },
        [user, commonData, setModalVisible],
    )

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik
                initialValues={{ text: '', summary: '', type: undefined!, attachment: undefined }}
                validationSchema={FormSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <AlertDialogLabel>Report a Bug</AlertDialogLabel>
                        <AlertDialogDescription>
                            Please describe the bug you&apos;re experiencing as thoroughly as
                            possible:
                        </AlertDialogDescription>
                        <Container mt={3} mb="2" flexDirection="column">
                            <RadioContainer>
                                <Radio
                                    disabled={isSubmitting}
                                    value={ERROR_TYPE_PAGE_ERROR}
                                    name="type"
                                >
                                    <Text
                                        size="4"
                                        data-tip="When you attempt to access a title page <br /> (feature, series, season, episode), you encounter an error page."
                                    >
                                        Error Page
                                    </Text>
                                </Radio>
                                <Radio
                                    disabled={isSubmitting}
                                    value={ERROR_TYPE_PUBLISH_ERROR}
                                    name="type"
                                >
                                    <Text
                                        size="4"
                                        data-tip=" You are not able to publish the title,<br /> although all required metadata is available."
                                    >
                                        Publishing Error
                                    </Text>
                                </Radio>
                                <Radio
                                    disabled={isSubmitting}
                                    value={ERROR_TYPE_MISSING_TERM}
                                    name="type"
                                >
                                    <Text
                                        size="4"
                                        data-tip="A term (tag) is not available from a taxonomy drop-down."
                                    >
                                        Missing Term(s)
                                    </Text>
                                </Radio>
                                <Radio
                                    disabled={isSubmitting}
                                    value={ERROR_TYPE_MISSING_METADATA}
                                    name="type"
                                >
                                    <Text
                                        size="4"
                                        data-tip="Metadata previously entered is no longer available (genres, keywords, storylines, RDs)."
                                    >
                                        Missing Metadata
                                    </Text>
                                </Radio>
                                <Radio disabled={isSubmitting} value={ERROR_TYPE_OTHER} name="type">
                                    <Text
                                        size="4"
                                        data-tip="If the issue does not fit into any one of the categories above, please report it as 'other'."
                                    >
                                        Other
                                    </Text>
                                </Radio>
                                <StyledReactTooltip place="bottom" multiline={true} />
                            </RadioContainer>
                            <RadioGroupErrorMessage name="type" />
                        </Container>

                        <StyledDiv>
                            <FormikInput
                                style={{ width: '100%' }}
                                fastValidate
                                placeholder="Short summary of the problem"
                                disabled={isSubmitting}
                                name="summary"
                            />
                            <ErrorMessage name="summary" />
                        </StyledDiv>
                        <br />

                        <StyledDiv>
                            <FormikInput
                                fastValidate
                                disabled={isSubmitting}
                                name="text"
                                multiline
                                rows={5}
                                placeholder="Explain the problem in detail here"
                            />
                            <ErrorMessage name="text" />
                        </StyledDiv>

                        <ImageDropZone />

                        <UserInformation />

                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                type="submit"
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                            <TextualButton
                                onClick={() => setModalVisible(false)}
                                disabled={isSubmitting}
                                variant="primary"
                                size="5"
                                ref={cancelRef}
                            >
                                Close
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}
const Grid = styled(Container)`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 1.5rem;
`

const StyledContainer = styled(Container).attrs({
    alignItems: 'flex-start',
    flexDirection: 'column',
})``

const UserInformation: React.FC = () => {
    const [showMoreInformation, setShowMoreInformation] = React.useState(false)
    const user = useUser()
    const info = browserInfo()

    return (
        <>
            <TextualButton
                onClick={() => {
                    setShowMoreInformation(!showMoreInformation)
                }}
                type="button"
            >
                {showMoreInformation ? 'Less' : 'More'} information
            </TextualButton>
            {showMoreInformation && (
                <Grid>
                    <StyledContainer>
                        <Text size="4" mb="1" weight="bold">
                            User:
                        </Text>
                        <Text size="5" weight="bold">
                            Name:
                        </Text>
                        <Text size="5" variant="secondary">
                            {user.given_name} {user.family_name}
                        </Text>
                        <Text size="5" weight="bold">
                            Email:
                        </Text>
                        <Text size="5" variant="secondary">
                            {user.email}
                        </Text>
                    </StyledContainer>
                    <StyledContainer>
                        <Text size="4" mb="1" weight="bold">
                            System:
                        </Text>
                        <Text size="5" weight="bold">
                            OS:
                        </Text>
                        <Text size="5" variant="secondary">
                            {info.os}
                        </Text>
                    </StyledContainer>
                    <StyledContainer>
                        <Text size="4" mb="1" weight="bold">
                            Browser:
                        </Text>
                        <Text size="5" weight="bold">
                            Name:
                        </Text>
                        <Text size="5" variant="secondary">
                            {info.name}
                        </Text>
                        <Text size="5" weight="bold">
                            Version:
                        </Text>
                        <Text size="5" variant="secondary">
                            {info.version}
                        </Text>
                    </StyledContainer>
                </Grid>
            )}
        </>
    )
}

export default ReportABugModal
