import * as t from 'io-ts'
import { nullable } from 'codecs/util/nullable'

export const CrewMemberCodec = t.type({
    talent: nullable(
        t.type({
            talentId: t.string,
            talentName: t.string,
        }),
    ),
    role: nullable(
        t.type({
            roleId: t.string,
            roleLabel: t.string,
        }),
    ),
})

export type CrewMember = t.TypeOf<typeof CrewMemberCodec>
