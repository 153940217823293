import styled from 'shared/theme'
import { space, SpaceProps } from 'styled-system'

import { Options, withOption } from 'shared/option'

export type Size = 'auto' | '1' | '2' | '3' | '4' | '5' | '6'
const sizes: Options<Size> = {
    1: 'font-size: 2.25rem; line-height: 2.5rem',
    2: 'font-size: 1.75rem; line-height: 3rem',
    3: 'font-size: 1.375rem; line-height: 1.5rem',
    4: 'font-size: 1.125rem; line-height: 1.5rem',
    5: 'font-size: 0.9375rem; line-height: 1.5rem',
    6: 'font-size: 0.875rem; line-height: 1.5rem;',
    auto: '',
    default: '',
}

export type Variant = 'primary' | 'secondary' | 'danger' | 'warning' | 'success' | 'default'
const variants: Options<Variant> = {
    primary: props => `color: ${props.theme.colors.textPrimary}`,
    secondary: props => `color: ${props.theme.colors.textSecondary}`,
    danger: props => `color: ${props.theme.colors.danger}`,
    warning: props => `color: ${props.theme.colors.warning}`,
    success: props => `color: ${props.theme.colors.success}`,
    default: '',
}

export type Weight = 'regular' | 'bold'
export const weights: Options<Weight> = {
    regular: 'font-weight: 400',
    bold: 'font-weight: 600',
    default: 'font-weight: 400',
}

interface OwnProps {
    size?: Size
    variant?: Variant
    weight?: Weight
}

export type TextProps = OwnProps & SpaceProps

const Text = styled.span<TextProps>`
    ${space}
    ${withOption<OwnProps, typeof sizes>('size', sizes)}
    ${withOption<OwnProps, typeof variants>('variant', variants)}
    ${withOption<OwnProps, typeof weights>('weight', weights)}
`

export default Text
