import React from 'react'

import { ReactComponent as CheckIconBase } from 'shared/components/CheckboxRadioInput/Checkbox/icon.svg'
import styled from 'shared/theme'

const CheckIconStyled = styled(CheckIconBase)`
    path {
        stroke: green;
    }
`

export const CheckIconGreen: React.FC<React.ComponentProps<typeof CheckIconBase>> = (
    props,
): React.ReactElement | null => {
    return <CheckIconStyled width="24" height="24" {...(props as any)} />
}

export default CheckIconGreen
