import React from 'react'

import { Episode as EpisodeLocation } from 'routing/routes'
import { useParams } from 'react-router'
import { Episode, EpisodeCodec } from 'model/Episode'
import { useUser } from 'auth/Auth'
import { fetchResource } from 'api/fetch'

import { createResourceMachine } from 'shared/resource/resource.machine'
import { useErrorHandler } from 'react-error-boundary'
import Loader from 'shared/components/Loader'
import Header from 'shared/components/Header'
import HeaderEditing from 'shared/components/Header/Editing'

import EpisodeNode from 'pages/Episode/EpisodeNode'
import { useMachine } from '@xstate/react'
import CharactersProvider from 'shared/characters/CharactersProvider'
import { ResourceMachineServiceContext } from 'shared/resource/ResourceMachineProvider'
import { useRefreshLockedTitles } from 'shared/LockedTitles/LockedTitlesProvider'

import { metadataMergeAutofill } from 'model/metadata/Metadata'
import { metadataFetchAutofill } from 'api/autofill/metadataFetchAutofill'

const EpisodePage: React.FC = (): React.ReactElement => {
    const { episodeId } = useParams<EpisodeLocation>()
    const refreshLockedTitles = useRefreshLockedTitles()
    const handleError = useErrorHandler()
    const user = useUser()
    const [state, , service] = useMachine(
        () =>
            createResourceMachine<Episode>({
                resourceId: episodeId,
                resourceType: 'episode',
                user,
                wips: ['metadata', 'storylines', 'relationships'],
                fetchFn: ctx => fetchResource({ codec: EpisodeCodec, ...ctx }),
                handleCriticalError: (_, e: any) => handleError(e.data),
                refreshLockedTitles,
                autofill: {
                    metadata: {
                        fetch: ctx => metadataFetchAutofill(ctx),
                        merge: metadataMergeAutofill,
                    },
                },
            }),
        {
            devTools: true,
        },
    )

    if (state.matches('loading')) {
        return (
            <>
                <Header />
                <Loader center size="normal" />
            </>
        )
    }

    if (state.matches('404')) {
        return (
            <>
                <Header />
                <h1>404: Not Found</h1>
            </>
        )
    }

    return (
        <ResourceMachineServiceContext.Provider value={service}>
            <CharactersProvider resourceId={episodeId}>
                <HeaderEditing />
                <EpisodeNode />
            </CharactersProvider>
        </ResourceMachineServiceContext.Provider>
    )
}

export default EpisodePage
