import React from 'react'
import { withRouter, Redirect, RouteProps } from 'react-router-dom'
import * as r from 'fp-ts-routing'
import { History, Location } from 'history'

import { AuthContext } from 'auth/Auth'
import { auth, Location as RouteLocation } from './routes'
import { matchPath, LocationMatch, TypedRouteProps } from './TypedRoute'
import { hasPermission } from 'shared/components/UserHasPermission'

export interface Props<L extends RouteLocation> extends RouteProps {
    history: History<any>
    location: Location<any>
    match: LocationMatch<L>
    parser?: r.Parser<L>
    computedMatch?: LocationMatch<L>
    authenticatingComponent?: React.ComponentType
    notAuthorizedComponent?: React.ComponentType<{ permissions: string[] }>
}

const TypedSwitchBase: React.FC<Props<RouteLocation>> = (props): React.ReactElement | null => {
    const authValue = React.useContext(AuthContext)
    const { location, match: outerMatch, authenticatingComponent, notAuthorizedComponent } = props

    let match: LocationMatch<RouteLocation> | null = null
    let element: React.ReactElement | null = null
    let childIsPrivate = false
    let permissions: string[] = []

    React.Children.forEach(props.children, child => {
        if (match === null && React.isValidElement(child)) {
            element = child

            const props = child.props as TypedRouteProps
            const { parser } = props
            match = parser
                ? matchPath(location.pathname, location.search, {
                      parser,
                  })
                : outerMatch
            childIsPrivate = props.isPrivate ?? false
            permissions = props.permissions ?? []
        }
    })

    return match
        ? childIsPrivate && !authValue.signedIn
            ? authValue.authenticationPending
                ? authenticatingComponent
                    ? React.createElement(authenticatingComponent)
                    : null
                : React.createElement(Redirect, {
                      to: auth.loginLink({
                          returnTo: location.pathname + location.search,
                      }),
                  })
            : permissions.every(permission => hasPermission(authValue.user, permission))
            ? React.cloneElement(element!, {
                  location,
                  computedMatch: match as LocationMatch<RouteLocation>,
              })
            : notAuthorizedComponent
            ? React.createElement(notAuthorizedComponent, { permissions })
            : null
        : null
}

const TypedSwitch = withRouter(TypedSwitchBase)

export default React.memo(TypedSwitch)
