import React from 'react'

import styled from 'shared/theme'
import Text from 'shared/components/Text'
import { WorkflowErrorInstance } from 'codecs/publish-workflow/parse'
import { ErrorContext } from 'codecs/publish-workflow/PublishWorkflow'

const PublishErrorsContainer = styled.div`
    grid-column: 1 / -1;
`
const StyledList = styled.ul`
    margin: 0.5rem 1rem;
`

type PublishErrorsProps<T extends ErrorContext> = {
    errors: WorkflowErrorInstance<T>[]
    className?: string
}
export function PublishErrors<T extends ErrorContext>({
    errors,
    className,
}: PublishErrorsProps<T>): React.ReactElement | null {
    if (errors.length === 0) {
        return null
    }

    return (
        <PublishErrorsContainer {...{ className }}>
            <Text size="4" variant="danger">
                Publish Errors:
            </Text>
            <StyledList>
                {errors.map(({ message }, i) => (
                    <li key={message + String(i)}>
                        <Text size="6" variant="danger">
                            {message}
                        </Text>
                    </li>
                ))}
            </StyledList>
        </PublishErrorsContainer>
    )
}

export default PublishErrors
