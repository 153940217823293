import React from 'react'
import UnavailableText from 'shared/components/UnavailableText'

type OptionalProps = {
    value: string[] | string | null | undefined
}
const Optional: React.FC<OptionalProps> = ({ value }): React.ReactElement => {
    if (Array.isArray(value)) {
        value = value.length ? value.join(', ') : null
    }

    return <>{value ?? <UnavailableText>Unavailable</UnavailableText>}</>
}
export default Optional
