import React from 'react'
import { ActionType, getType, createAction } from 'typesafe-actions'
import * as E from 'fp-ts/lib/Either'
import { useContextHelper } from 'shared/hooks/useContextHelper'
import { notification } from 'shared/notification'
import storage from 'auth/Storage'
import config from 'shared/config'
import { requestTask } from '@genome-web-forms/common/api'
import { MyIDUser, MyIDUserCodec, rollbarUser } from '@genome-web-forms/common/auth'
import BackendPrimer from 'shared/BackendPrimer'
import { isAxiosError } from '@genome-web-forms/common/error'
import qs from 'query-string'

import isString from 'lodash/isString'
import history from 'routing/history'
import { History } from 'history'

const debug = require('debug')('gwf.auth') // eslint-disable-line

interface AuthenticationPending {
    authenticationPending: true
    signedIn: null
    user: null
    token: null
}

interface NotSignedIn {
    authenticationPending: false
    signedIn: false
    user: null
    token: null
}

interface SignedIn {
    authenticationPending: false
    signedIn: true
    user: MyIDUser
    token: string
}

type AuthValueActual = AuthenticationPending | NotSignedIn | SignedIn

// pretend user always exists for the benefit of context users
export type AuthValue =
    | {
          authenticationPending: true
          signedIn: undefined
          user: MyIDUser
      }
    | {
          authenticationPending: false
          signedIn: false
          user: MyIDUser
      }
    | {
          authenticationPending: false
          signedIn: true
          user: MyIDUser
      }

export const actions = {
    setAuthenticationPending: createAction('AUTH/SET_AUTHENTICATION_PENDING')(),
    signin: createAction('AUTH/SIGNIN')<{
        token: string
        user: MyIDUser
    }>(),
    signout: createAction('AUTH/SIGNOUT')(),
}

const reducer = (_: AuthValueActual, action: ActionType<typeof actions>): AuthValueActual => {
    switch (action.type) {
        case getType(actions.setAuthenticationPending): {
            return {
                authenticationPending: true,
                signedIn: null,
                user: null,
                token: null,
            }
        }

        case getType(actions.signin): {
            const { user, token } = action.payload

            window.Rollbar.configure({ payload: { person: rollbarUser(user) } })

            BackendPrimer.prime(user)

            storage.setToken(token)

            return {
                authenticationPending: false,
                signedIn: true,
                user,
                token,
            }
        }

        case getType(actions.signout): {
            window.Rollbar.configure({ payload: { person: { id: null } } })

            storage.clearToken()

            return {
                authenticationPending: false,
                signedIn: false,
                user: null,
                token: null,
            }
        }
    }
}

export const AuthContext = React.createContext<AuthValue>(null as any)
AuthContext.displayName = 'Auth'
export const AuthDispatchContext = React.createContext<React.Dispatch<ActionType<typeof actions>>>(
    null as any,
)
AuthDispatchContext.displayName = 'AuthDispatch'
export function useAuthContext(): AuthValue {
    return useContextHelper(AuthContext, 'AuthContext')
}
export function useUser(): MyIDUser {
    const { user } = useAuthContext()
    if (!user) {
        throw new Error(
            `MyIDUser not available. You're probably using "useUser()" in a context where unauthenticated users have access.`,
        )
    }

    return user
}

type MyIDHashResponse = E.Either<
    { error: string; error_description?: string },
    { access_token?: string }
>
export function consumeMyIDHash(history: History): MyIDHashResponse {
    const hash = history.location.hash

    const parsed = qs.parse(hash)
    debug('parsed hash', parsed)

    const { error, error_description, access_token, state } = parsed
    if (isString(error)) {
        return E.left({
            error,
            error_description: error_description as string | undefined,
        })
    }

    let returnTo: string | undefined
    try {
        returnTo = JSON.parse(state as string).returnTo
    } catch {
        returnTo = qs.parse(history.location.search).returnTo as string | undefined
    }

    // go to returnTo url if present
    if (returnTo) {
        history.replace(returnTo)
    } else {
        // remove #hash from window.location
        history.replace({
            ...history.location,
            hash: '',
        })
    }

    if (!isString(access_token)) {
        return E.right({})
    }

    return E.right({ access_token })
}

const DEV_FAST_AUTH_KEY = 'gef-fast-auth-value'
declare global {
    // eslint-disable-next-line no-var
    var __EARLY_AUTH_FETCH: Promise<unknown> | undefined
}

type AuthProps = {
    history?: History
}
const Auth: React.FC<AuthProps> = ({ children, history: outerHistory }): React.ReactElement => {
    const [authValue, dispatch] = React.useReducer(reducer, {
        authenticationPending: true,
        signedIn: null,
        user: null,
        token: null,
    })

    React.useEffect(() => {
        // fast auth for dev
        if (process.env.NODE_ENV === 'development') {
            try {
                const v = JSON.parse(window.localStorage.getItem(DEV_FAST_AUTH_KEY)!)
                if (v.signedIn) {
                    return dispatch(actions.signin(v))
                }
            } catch (e) {}
        }
        const accessDataEither = consumeMyIDHash(outerHistory ?? history)
        debug('accessData', accessDataEither)

        if (E.isLeft(accessDataEither)) {
            const { error, error_description } = accessDataEither.left

            notification.error(
                <>
                    <strong>MyID authentication failed!</strong>
                    <br />
                    <strong>{error}:</strong> {error_description}
                </>,
            )

            return dispatch(actions.signout())
        }
        const access_token = accessDataEither.right.access_token

        const earlyFetch = window.__EARLY_AUTH_FETCH
        if (!access_token && earlyFetch) {
            window.__EARLY_AUTH_FETCH = undefined
            earlyFetch
                .then(MyIDUserCodec.decode)
                .then(parseUser)
                .catch(() => dispatch(actions.signout()))

            return void 0
        }

        const token = access_token || storage.getToken() || ''

        requestTask(MyIDUserCodec, {
            url: config.urlAuth,
            headers: {
                'x-gsso-myid': token,
            },
        })().then(parseUser)

        let stopped = false
        return () => {
            stopped = true
        }
        ///////////////////////////////

        function parseUser(maybeUser: E.Either<any, MyIDUser>): void {
            if (stopped) {
                return
            }
            if (E.isRight(maybeUser)) {
                const user = maybeUser.right

                if (!user.roles) {
                    notification.error(
                        <>
                            The user {user['relationship.employeeId']} does not have acces to Genome
                            Web Forms.
                            <br />
                            If you believe this is a mistake, contact your administrator about
                            adding Keystone Roles for Genome Web Forms access to the user{' '}
                            {user['relationship.employeeId']}.
                        </>,
                    )

                    return dispatch(actions.signout())
                }

                return dispatch(actions.signin({ token: user['x-gsso-myid'], user }))
            }

            const error = maybeUser.left

            if (isAxiosError(error)) {
                dispatch(actions.signout())
                return void 0
            }

            throw error
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (process.env.NODE_ENV === 'development') {
        ;(window as any)._authValue = authValue
        // eslint-disable-next-line
        React.useEffect(() => {
            try {
                window.localStorage.setItem(DEV_FAST_AUTH_KEY, JSON.stringify(authValue))
            } catch (e) {}
        }, [authValue])
    }

    return (
        <AuthContext.Provider value={authValue as AuthValue}>
            <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
        </AuthContext.Provider>
    )
}

export default Auth
