import React from 'react'

import styled from 'shared/theme'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import { ReactComponent as Warning } from './warning.svg'

const StyledWarning = styled(Warning)`
    fill: ${props => props.theme.colors.danger};
    max-width: 20px;
`

type Props = { title: string }

const DuplicateDataWarning: React.FC<Props> = ({ title }): React.ReactElement => {
    return (
        <Container alignItems="center">
            <StyledWarning />
            <Text variant="danger" size="5" as="p" pl="1">
                There are multiple {title} in this data set!
            </Text>
        </Container>
    )
}

export default DuplicateDataWarning
