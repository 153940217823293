import React from 'react'

import Text from 'shared/components/Text'
import DuplicateDataWarning from 'shared/components/DuplicateDataWarning'

type Props = {
    values: string[]
    title: string
}

const InvalidDataRenderer: React.FC<Props> = ({ values, title }): React.ReactElement => {
    const lastIndex = values.length - 1

    return (
        <>
            {values.map((v, index) =>
                index === 0 ? (
                    `${v}, `
                ) : (
                    <Text key={String(index)} variant="danger" size="5" as="span">
                        {v}
                        {index !== lastIndex ? ', ' : ''}
                    </Text>
                ),
            )}
            <DuplicateDataWarning title={title} />
        </>
    )
}

export default InvalidDataRenderer
