import * as t from 'io-ts'
import { either } from 'fp-ts/lib/Either'
import isDate from 'date-fns/isDate'
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'
import dateFormat from 'date-fns/format'

const ENCODE_DATE_FORMAT = 'yyyy-MM-dd'
const DECODE_DATE_REGEX = /^(\d{4}-\d{2}-\d{2})Z?$/

export interface DateFromXDSC extends t.Type<Date, string, unknown> {}

export const DateFromXDS: DateFromXDSC = new t.Type<Date, string, unknown>(
    'Date',
    (u): u is Date => isDate(u),
    (u, c) =>
        either.chain(t.string.validate(u, c), u => {
            const match = DECODE_DATE_REGEX.exec(u)
            if (!match) {
                return t.failure(u, c)
            }

            const date = parse(match[1], ENCODE_DATE_FORMAT, new Date())

            return isValid(date) ? t.success(date) : t.failure(u, c)
        }),
    a => dateFormat(a, ENCODE_DATE_FORMAT),
)
