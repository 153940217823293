import React from 'react'
import styled from 'shared/theme'

const parser: DOMParser = new DOMParser()
const HIGHLIGHT_NODE_NAME = 'EM'

const HighlightText = styled.span.attrs({ role: 'mark' })`
    font-weight: 600;
`

type Props = {
    highlight: string
    title: string
}
const Highlight: React.FC<Props & JSX.IntrinsicElements['span']> = ({
    highlight,
    title,
    ...props
}): React.ReactElement => {
    const strippedHighlight = React.useMemo(() => stripHtml(highlight), [highlight])
    const isValidHighlight = strippedHighlight === title
    if (isValidHighlight) {
        return <span {...props}>{renderHighlight(highlight)}</span>
    }

    const pos = title.indexOf(strippedHighlight)
    if (pos === -1) {
        return <span {...props}>{title}</span>
    }
    const modifiedTitle =
        title.substring(0, pos) + highlight + title.substring(pos + strippedHighlight.length)

    return <span {...props}>{renderHighlight(modifiedTitle)}</span>
}

export default Highlight

function stripHtml(html: string): string {
    const htmlDoc = parser.parseFromString(html, 'text/html')
    return htmlDoc.body.textContent || ''
}

function renderHighlight(highlight: string): (string | JSX.Element)[] {
    const htmlDoc = parser.parseFromString(highlight, 'text/html')
    const textElements = Array.from(htmlDoc.body.childNodes)

    return textElements.map((element, i) => {
        const textContent = element.textContent || ''
        const hasHighlight = element.nodeName === HIGHLIGHT_NODE_NAME

        return hasHighlight ? (
            <HighlightText key={textContent + String(i)}>{textContent}</HighlightText>
        ) : (
            textContent
        )
    })
}
