import React from 'react'
import { Link } from 'react-router-dom'
import noop from 'lodash/noop'

import { useBugReportContext } from 'shared/BugReport/BugReportProvider'
import { Menu, MenuButton, MenuList, MenuItem } from 'shared/components/Menu'
import { ReactComponent as UserIcon } from 'shared/components/Menu/user-profile-icon.svg'
import {
    adminCreateWorkflowAssignmentLink,
    adminCreateWorkflowReportink,
    adminListWorkflowAssignmentLink,
    listWorkflowAssignmentLink,
    lockedTitlesLink,
} from 'routing/routes'
import LogoutButton from 'auth/LogoutButton'
import NotificationBubble from 'shared/components/LockedTitles/NotificationBubble'

import styled from 'shared/theme'
import VisuallyHidden from '@reach/visually-hidden'
import { useHasPermission } from '../UserHasPermission'
import { flags } from 'shared/flags'

const StyledButton = styled(MenuButton)`
    position: relative;
`

const UserMenu: React.FC<{ disableLogout?: boolean }> = ({
    disableLogout = false,
}): React.ReactElement => {
    const { setModalVisible } = useBugReportContext()

    const isAdmin = useHasPermission('DEBUG')
    const canWrite = useHasPermission('WRITE')
    const canPublish = useHasPermission('PUBLISH')
    const canApproveTagging = useHasPermission('APPROVE TAGGING QUALITY')

    const canUseWorkflow = isAdmin || canWrite || canPublish || canApproveTagging

    return (
        <Menu>
            <StyledButton>
                <VisuallyHidden>User menu</VisuallyHidden>
                <UserIcon aria-hidden />
                <NotificationBubble aria-hidden />
            </StyledButton>

            <MenuList>
                {flags.workflows && canUseWorkflow && (
                    <MenuItem to={listWorkflowAssignmentLink()} as={Link} onSelect={noop}>
                        Workflow Home
                    </MenuItem>
                )}

                {flags.workflows && isAdmin && (
                    <>
                        <MenuItem
                            to={adminCreateWorkflowAssignmentLink()}
                            as={Link}
                            onSelect={noop}
                        >
                            Create Workflow Assignment
                        </MenuItem>

                        <MenuItem to={adminCreateWorkflowReportink()} as={Link} onSelect={noop}>
                            Create Workflow Report
                        </MenuItem>

                        <MenuItem to={adminListWorkflowAssignmentLink()} as={Link} onSelect={noop}>
                            Workflow Admin
                        </MenuItem>
                        <hr />
                    </>
                )}

                <MenuItem to={lockedTitlesLink()} as={Link} onSelect={noop}>
                    Locked titles
                </MenuItem>
                <MenuItem onSelect={() => setModalVisible(true)}>Report a Bug</MenuItem>
                <MenuItem as={LogoutButton} disabled={disableLogout} onSelect={noop}>
                    Logout
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default UserMenu
