import React from 'react'

import styled from 'shared/theme'
import Container from 'shared/components/Container'
import Loader from 'shared/components/Loader'
import Text from 'shared/components/Text'

const LoaderWrap = styled(Container)`
    z-index: 1;
    position: fixed;
    left: 50%;
    bottom: ${props => props.theme.space[3]}px;
    border-radius: 25px;
    padding: 0 20px 0 10px;
    transform: translateX(-50%);
    background-color: ${props => props.theme.colors.backgroundSecondary};
    border: 1px solid ${props => props.theme.colors.border};
    box-shadow: 0 4px 8px 0 rgba(75, 93, 128, 0.3);
`

const StyledLoader = styled(Loader).attrs(props => ({
    color: props.theme.colors.textSecondary,
}))``

const SaveIndicator = (): React.ReactElement | null => {
    return (
        <LoaderWrap flexDirection="row" alignItems="center">
            <StyledLoader size="small" />{' '}
            <Text variant="secondary" size="6">
                Saving...
            </Text>
        </LoaderWrap>
    )
}

export default SaveIndicator
