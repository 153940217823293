import React from 'react'

import { SerieJSONViewOmittedValues, Series } from 'model/Series'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import SaveIndicator from 'shared/components/SaveIndicator'
import PageTitle from 'shared/components/PageTitle'
import Breadcrumbs from 'shared/components/Breadcrumbs'
import ResourceTabs from 'shared/components/Tabs/ResourceTabs'
import Cast from 'shared/components/Cast'
import Metadata from 'shared/components/Metadata'
import { flags } from 'shared/flags'
import MasterData from './MasterData'
import EpisodesTab from './EpisodesTab'
import { useIsSaving, useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import CheckIconGreen from 'shared/components/CheckIconGreen'
import WIPInfoAndAquire from 'shared/resource/WIPInfoAndAquire'
import { JSONViewer } from 'shared/components/JSONViewer'
import AllWorkflowControls from 'shared/components/Workflow/all-workflow-controls'
import PreviewControl from 'shared/components/PreviewControl'

const SeriesNode = (): React.ReactElement => {
    const [state] = useResourceMachine<Series>()
    const {
        context: { resource },
    } = state

    const { cwmClassTypeLabel, seasons, episodes, seriesTitle, cast } = resource
    const { publishErrorManager } = state.context

    return (
        <Container px="3" pb="3" pt="1" flexDirection="column">
            <Breadcrumbs items={[{ title: cwmClassTypeLabel }]} />
            <PageTitle title={seriesTitle} />
            {useIsSaving() && <SaveIndicator />}

            <Container flexDirection="row" justifyContent="space-between" alignItems="center">
                <Text size="1" as="h2">
                    {seriesTitle}{' '}
                    {state.matches({ idle: 'readonly' }) && (
                        <CheckIconGreen title="You are viewing published data" />
                    )}
                </Text>

                {flags.workflows && <AllWorkflowControls />}

                <PreviewControl />
            </Container>

            <ResourceTabs
                tabs={[
                    {
                        id: 'Primary Metadata',
                        panel: <MasterData />,
                    },
                    {
                        id: 'Descriptors',
                        label: <WIPInfoAndAquire title="Descriptors" dataType="metadata" />,
                        panel: <Metadata />,
                        hasErrors: publishErrorManager.hasComponentErrors('metadata'),
                    },
                    {
                        id: 'Episodes',
                        panel: <EpisodesTab {...{ seasons, episodes }} />,
                    },
                    {
                        id: 'Cast',
                        panel: <Cast data={cast} />,
                    },
                    {
                        id: 'JSON',
                        panel: <JSONViewer json={resource} omit={SerieJSONViewOmittedValues} />,
                    },
                ]}
            />
        </Container>
    )
}

export default SeriesNode
