import qs from 'query-string'
import config from 'shared/config'

class Nonce {
    last: number | null = null
    repeat = 0

    get(): string {
        let now = 100 * +new Date()

        if (now === this.last) {
            this.repeat++
        } else {
            this.repeat = 0
            this.last = now
        }

        return String(now + this.repeat)
    }
}

const nonce = new Nonce()

export function buildMyIdOAuthString(returnTo?: string): string {
    // the code below will be optimized away in a production build to:
    // process.env.PUBLIC_URL inlined
    const inferredHost = `${window.location.protocol}//${window.location.host}`
    const host = process.env.PUBLIC_URL || inferredHost

    const state = JSON.stringify({ returnTo })

    return (
        'https://idp.myid.disney.com/as/authorization.oauth2?' +
        qs.stringify({
            state,
            client_id: config.myIdClientId,
            response_type: 'id_token token',
            redirect_uri: host,
            nonce: nonce.get(),
            scope: SCOPE,
        })
    )
}

// predefined myid constants
const SCOPE =
    'openid profile email id.uuid relationship.title relationship.employeeNumber relationship.employeeGroup relationship.location relationship.businessUnitCode relationship.employeeId relationship.personnelSubArea relationship.managementLevel relationship.costCenter relationship.employeeSubGroup relationship.businessArea relationship.siteCode relationship.function relationship.job relationship.departmentNumber relationship.employeeType relationship.personnelArea relationship.position relationship.salariedCode relationship.employeeStatus relationship.organizationalUnit relationship.payrollArea relationship.companyCode'
