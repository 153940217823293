import React from 'react'

import UnavailableText from 'shared/components/UnavailableText'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'

import { Genre, DisplayGenre } from 'model/metadata/Genre'

import { FormikSelect } from 'shared/components/Select/FormikSelect'
import { usePureFn } from 'shared/hooks/usePureFn'
import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import { useDisplayGenres } from 'shared/hooks/useTRS'
import { useField } from 'formik'

type DisplayGenreSelectProps = {
    name: string
    formControlsEnabled: boolean
}
export const DisplayGenreSelect: React.FC<DisplayGenreSelectProps> = ({
    name,
    formControlsEnabled,
}): React.ReactElement => {
    const [{ value }] = useField<Genre['displayGenre']>(name)

    const { loaded, data: options } = useDisplayGenres()

    const getOptionValue = usePureFn((genre: DisplayGenre) => genre.displayGenreId)

    const getOptionLabel = usePureFn((genre: DisplayGenre, term?: TaxonomyTerm) =>
        term ? term.label : genre.displayGenreLabel,
    )

    function render(): React.ReactElement {
        if (!formControlsEnabled && !value) {
            return <UnavailableText>Unavailable</UnavailableText>
        }

        return (
            <FormikSelect<DisplayGenre>
                {...{
                    name,
                    isMulti: false,
                    isClearable: true,
                    options,
                    isLoading: !loaded,
                    getOptionValue,
                    getOptionLabel,
                    isDisabled: !formControlsEnabled,
                    placeholder: 'Display Genre',
                }}
            />
        )
    }

    return (
        <Container mb="1" flexDirection="column">
            <Text size="5" weight="bold" as="h5">
                Display Genre
            </Text>

            {render()}
        </Container>
    )
}

export default DisplayGenreSelect
