import React from 'react'
import { ErrorMessage as ErrorMessageBase, ErrorMessageProps } from 'formik'
import { space, SpaceProps } from 'styled-system'
import styled from 'shared/theme'

export const ErrorMessageText = styled.span<SpaceProps>`
    display: block;
    color: ${props => props.theme.colors.danger};
    margin-top: 0.75rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    ${space}
`
type Props = ErrorMessageProps & SpaceProps

const ErrorMessage: React.FC<Props> = ({ name, className, ...props }): React.ReactElement => {
    return (
        <ErrorMessageBase name={name} {...props}>
            {(msg: string | string[]) =>
                typeof msg === 'string' ? (
                    <ErrorMessageText {...{ className }}>{msg}</ErrorMessageText>
                ) : Array.isArray(msg) ? (
                    msg
                        .filter(msg => typeof msg === 'string')
                        .map(m => (
                            <ErrorMessageText key={m} {...{ className }}>
                                {m}
                            </ErrorMessageText>
                        ))
                ) : null
            }
        </ErrorMessageBase>
    )
}

export default ErrorMessage
