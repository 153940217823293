import React from 'react'
import Highlight from 'shared/search/Highlight'
import Text from 'shared/components/Text'
import { SearchResult, getTitle } from 'model/search/SearchResult'
type SearchResultTitleProps = {
    result: SearchResult
}
export const SearchResultTitle: React.FC<SearchResultTitleProps> = ({
    result: { genomeObject },
}): React.ReactElement => (
    <Text size="5">
        <Highlight title={getTitle(genomeObject)} highlight={genomeObject.highlight} />
    </Text>
)

export default SearchResultTitle
