import React from 'react'
import { TableState } from 'react-table'

import without from 'lodash/without'
import { Column, CellProps } from 'react-table'

import Season from './Season'

import { usePrecompiledSearchMemoAndFilter } from 'shared/precompileSearch'
import { AbridgedSeason } from 'model/Season'
import { AbridgedEpisode } from 'model/Episode'
const UNKNOWN_SEASON_SENTINEL = '###unknown_season###'
const UNKNOWN_SEASON: AbridgedSeason = {
    seasonId: UNKNOWN_SEASON_SENTINEL,
    seasonTitle: 'Unknown season',
    seasonNumber: null,
}
export type RenderSeason = {
    season: AbridgedSeason
    episodes: AbridgedEpisode[]
    isExpanded: boolean
}

type useSeasonsTableOptions = {
    seasons: AbridgedSeason[]
    episodes: AbridgedEpisode[]
    searchText: string
}

const initialState = {
    sortBy: [{ id: 'season' }],
}

export function useSeasonsTable({ seasons, episodes, searchText }: useSeasonsTableOptions): {
    data: RenderSeason[]
    columns: Column<RenderSeason>[]
    initialState: Partial<TableState<RenderSeason>>
} {
    const [expandedSeasons, setExpandedSeasons] = React.useState<string[]>([])

    const filteredEpisodes = usePrecompiledSearchMemoAndFilter<AbridgedEpisode>(
        episodes,
        [
            ['episodeTitle'],
            ['seasonNumber'],
            ['airOrderNumber'],
            ['episodeNumber'],
            ['foreignEpisodeNumber'],
        ],
        searchText,
    )

    const filteredSeasons = useFilteredSeasons(seasons, filteredEpisodes)

    const data = React.useMemo<RenderSeason[]>(() => {
        return filteredSeasons.map(season => {
            const { seasonId } = season
            const isExpanded = searchText !== '' || expandedSeasons.includes(seasonId)

            const episodes = filteredEpisodes.filter(
                e =>
                    e.seasonId === seasonId ||
                    (seasonId === UNKNOWN_SEASON_SENTINEL && e.seasonId == null),
            )
            return {
                season,
                episodes,
                isExpanded,
            }
        })
    }, [filteredSeasons, expandedSeasons, filteredEpisodes, searchText])

    const SeasonCell = React.useMemo(() => makeSeasonCell(setExpandedSeasons), [])

    const columns = React.useMemo<Column<RenderSeason>[]>(
        () => [{ Header: 'Season', accessor: 'season', Cell: SeasonCell }],
        [SeasonCell],
    )

    return { data, columns, initialState }
}

function useFilteredSeasons(
    seasons: AbridgedSeason[],
    episodes: AbridgedEpisode[],
): AbridgedSeason[] {
    // attach the unknown season to the seasons
    seasons = React.useMemo<AbridgedSeason[]>(() => [UNKNOWN_SEASON, ...seasons], [seasons])
    return React.useMemo(() => {
        // create a set of all season ids that are present in our episodes list
        const s = new Set<string>(episodes.map(e => e.seasonId ?? UNKNOWN_SEASON_SENTINEL))
        // and filter the season list by them
        return seasons.filter(({ seasonId }) => s.has(seasonId))
    }, [seasons, episodes])
}

function makeSeasonCell(
    setExpandedSeasons: React.Dispatch<React.SetStateAction<string[]>>,
): React.FC<CellProps<RenderSeason>> {
    return ({ row: { original } }) => {
        const {
            season,
            season: { seasonId },
        } = original

        const title = makeSeasonTitle(season)

        return (
            <Season
                {...original}
                title={title}
                onHeaderClick={() => {
                    setExpandedSeasons(expandedSeasons => {
                        return expandedSeasons.includes(seasonId)
                            ? without(expandedSeasons, seasonId)
                            : [...expandedSeasons, seasonId]
                    })
                }}
            />
        )
    }
}

/**
 * Either return the season title, or season number, or "Unknown Season" if matching sentinel,
 * or undefined
 */
function makeSeasonTitle({
    seasonId,
    seasonTitle,
    seasonNumber,
}: AbridgedSeason): string | undefined {
    return (
        seasonTitle ??
        (seasonNumber
            ? `Season ${seasonNumber}`
            : seasonId === UNKNOWN_SEASON_SENTINEL
            ? 'Unknown season'
            : undefined)
    )
}
