import React from 'react'

const ListSectionDivider: React.FC = () => (
    <>
        <dt></dt>
        <dd></dd>
    </>
)

export default ListSectionDivider
