import {
    Menu as RMenu,
    MenuButton as RMenuButton,
    MenuList as RMenuList,
    MenuItem as RMenuItem,
} from '@reach/menu-button'
import '@reach/menu-button/styles.css'

import styled from 'shared/theme'

export const DropdownMenu = styled(RMenu)``
export const DropdownMenuButton = styled(RMenuButton)`
    border: 0;
    margin: 0.5rem;
    padding: 0.375rem 1.25rem;
    min-width: 24px;
    min-height: 24px;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 4px;
    background-color: ${props => props.theme.colors.backgroundGray};
`

export const DropdownMenuList = styled(RMenuList)`
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(75, 93, 128, 0.3);
    padding: 0;
`

export const DropdownMenuItem = styled(RMenuItem)`
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    padding: 0.5rem 1.5rem;
    color: ${props => props.theme.colors.textPrimary};
    &[data-selected] {
        background-color: ${props => props.theme.colors.border};
        color: ${props => props.theme.colors.textPrimary};
    }
`

export const DropdownMenuItemSeparator = styled(RMenuItem)`
    padding: 0.5rem;
    pointer-events: none;
    color: ${props => props.theme.colors.textPrimary};
    background: ${props => props.theme.colors.backgroundPrimary};
`
