import React from 'react'
import qs from 'query-string'
import history from 'routing/history'
import { useParams } from 'react-router'
import Container from 'shared/components/Container'

import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'shared/components/Tabs'

import styled from 'shared/theme'

export const NavTabWrapper = styled(Tab)<{ title?: string }>`
    &:before {
        font-weight: 600;
        content: attr(title);
        visibility: hidden;
    }
`

export const NavTab = styled.div<{ hasErrors?: boolean }>`
    position: absolute;
    top: 0.5rem;
    color: ${props => (props.hasErrors ? props.theme.colors.danger : 'inherit')};
`

export const LabelWithLock: React.FC<{
    title: string
    hasErrors?: boolean
    onDoubleClick?: React.HTMLProps<HTMLButtonElement>['onDoubleClick']
}> = ({ title, hasErrors, children, onDoubleClick }) => (
    <NavTabWrapper onDoubleClick={onDoubleClick} title={title} style={{ paddingRight: '1.25rem' }}>
        <NavTab hasErrors={hasErrors} style={{ display: 'flex' }}>
            <Container>{title}</Container>
            <Container alignItems="center">{children}</Container>
        </NavTab>
    </NavTabWrapper>
)

export type ResourceTab = {
    id: string
    label?: string | React.ReactElement
    panel: React.ReactNode
    hasErrors?: boolean
}

type ResourceTabsProps = { tabs: ResourceTab[] }

/**
 * A tablist that can be routed to and changing a tab reflects in history.location
 */
export default function ResourceTabs<Location extends { section?: string }>({
    tabs,
}: ResourceTabsProps): React.ReactElement {
    const section = useParams<Location>().section
    const idx = tabs.findIndex(({ id }) => id === section)

    const onTabChange = React.useCallback(
        (idx: number) => {
            const section = tabs[idx].id
            history.replace({
                pathname: history.location.pathname,
                search: '?' + qs.stringify({ section }),
            })
        },
        [tabs],
    )

    return (
        <Tabs index={idx > -1 ? idx : 0} onChange={onTabChange}>
            <TabList>
                {tabs.map(({ id, label, hasErrors }) =>
                    React.isValidElement(label) ? (
                        React.cloneElement(label, { key: id, hasErrors })
                    ) : (
                        <NavTabWrapper key={id} title={id}>
                            <NavTab hasErrors={hasErrors}>{label ?? id}</NavTab>
                        </NavTabWrapper>
                    ),
                )}
            </TabList>
            <TabPanels>
                {tabs.map(({ id, panel }) => (
                    <TabPanel key={id}>{panel}</TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    )
}
