import styled from 'shared/theme'

const DeleteButton = styled.button`
    position: absolute;
    right: 1em;
    top: 0.5em;
    border: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;
`

export default DeleteButton
