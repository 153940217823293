import React from 'react'

import styled from 'shared/theme'
import LoadingSVG from './LoadingSVG'

type TextualSize = 'small' | 'normal' | 'large' | 'tiny' | 'smedium'
export type Size = number | TextualSize

interface OwnProps {
    size?: Size
    color?: string
    center?: boolean
    inline?: boolean
}

const sizes: Record<TextualSize, number> = {
    tiny: 24,
    small: 36,
    smedium: 72,
    normal: 100,
    large: 200,
}

type Props = OwnProps & { className?: string; style?: React.CSSProperties; 'data-testid'?: string }

const LoaderContainer = styled.div<{ center?: boolean; inline?: boolean }>`
    display: flex;
    ${props => props.inline && 'display: inline-flex;'}
    ${props => props.center && 'justify-content: center;'}
`

const Loader: React.FC<Props> = ({ size, color, ...rest }): React.ReactElement => {
    const loaderSize = size ? (typeof size === 'number' ? size : sizes[size]) : 'normal'

    return (
        <LoaderContainer {...rest}>
            <div
                style={{
                    display: 'inline-block',
                    width: `${loaderSize}px`,
                    height: `${loaderSize}px`,
                }}
            >
                <LoadingSVG color={color} />
            </div>
        </LoaderContainer>
    )
}

export default Loader
