import React from 'react'
import { FormikTRSSelect, ImplementerFormikTRSSelectProps } from 'shared/components/TRS/TRSSelect'
import { useTRSSimpleFind } from 'shared/hooks/useTRS'
import { StorylineAttribute } from 'codecs/storyline/StorylineAttribute'
import { usePureFn } from 'shared/hooks/usePureFn'

const TRS_STORYLINE_COMPONENTS = 'http://data.disney.com/resources/StorylineComponents'

type StorylineComponentSelectProps = ImplementerFormikTRSSelectProps<StorylineAttribute>
export const StorylineComponentSelect: React.FC<StorylineComponentSelectProps> = (
    props,
): React.ReactElement => {
    const { terms } = useTRSSimpleFind(TRS_STORYLINE_COMPONENTS)

    return (
        <FormikTRSSelect<StorylineAttribute>
            placeholder="Select storyline component"
            {...props}
            terms={terms}
            termToOption={usePureFn(({ id, label }) => ({ id, label }))}
            isMulti={false}
            isSearchable={false}
            getOptionValue={usePureFn(({ id }) => id)}
            getOptionLabel={usePureFn(({ label }, term) => (term ? term.label : label))}
        />
    )
}

export default StorylineComponentSelect
