import {
    CREATIVE_WORK_REVIEW_AVAILABLE,
    CREATIVE_WORK_QA_AVAILABLE,
    CREATIVE_WORK_TAGGING_AVAILABLE,
    Workflow,
} from '@genome-web-forms/server'
import { useHasPermission } from 'shared/components/UserHasPermission'

export const useSelfAssign = (workflow: Workflow): boolean => {
    const isPublishAvailable =
        useHasPermission('PUBLISH') && workflow.state === CREATIVE_WORK_QA_AVAILABLE
    const isReviewAvailableSA =
        useHasPermission('APPROVE TAGGING QUALITY') &&
        workflow.state === CREATIVE_WORK_REVIEW_AVAILABLE
    const isTaggingAvailable =
        useHasPermission('WRITE') && workflow.state === CREATIVE_WORK_TAGGING_AVAILABLE
    const isReviewAvailable = useHasPermission('DEBUG')

    return isPublishAvailable || isReviewAvailableSA || isReviewAvailable || isTaggingAvailable
}
