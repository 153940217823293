import * as t from 'io-ts'
import { either } from 'fp-ts/lib/Either'
import uniqBy from 'lodash/uniqBy'

export class ArrayToUniqueType<
    C extends t.Any,
    i extends (a: t.TypeOf<C>) => any,
    A = any,
    O = A,
    I = unknown,
> extends t.Type<A, O, I> {
    readonly _tag: 'ArrayToUniqueType' = 'ArrayToUniqueType'
    constructor(
        name: string,
        is: ArrayToUniqueType<C, i, A, O, I>['is'],
        validate: ArrayToUniqueType<C, i, A, O, I>['validate'],
        encode: ArrayToUniqueType<C, i, A, O, I>['encode'],
        readonly codec: C,
        readonly iteratee: i,
    ) {
        super(name, is, validate, encode)
    }
}

export interface ArrayToUniqueC<C extends t.Mixed, i extends (a: t.TypeOf<C>) => any>
    extends ArrayToUniqueType<C, i, t.TypeOf<C>[], t.OutputOf<C>[], unknown> {}

/**
 * Converts an array of possibly non-unique values to array of only unique values.
 *
 * This is a destructive operation and probably not well suited for io-ts.
 * Use with caution!
 *
 * @param codec - The io-ts codec to use for the individual items
 * @param iteratee - The iteratee invoked per element. Defaults to the identity function
 */
export const ArrayToUnique = <C extends t.Mixed, i extends (a: t.TypeOf<C>) => any>(
    codec: C,
    iteratee: i = t.identity as i,
    name = `ArrayToUnique<${codec.name}, ${iteratee === t.identity ? 'identity()' : iteratee}>`,
): ArrayToUniqueC<C, i> =>
    new ArrayToUniqueType(
        name,
        (u): u is t.TypeOf<C>[] =>
            t.UnknownArray.is(u) && u.every(codec.is) && isUnique(u, iteratee),
        (u, c) =>
            either.map(t.array(codec).validate(u, c), array => {
                const unique = uniqBy(array, iteratee)
                const allUnique = unique.length === array.length
                if (!allUnique && process.env.NODE_ENV === 'development') {
                    console.error(`${name} omitted duplicate items from array:`, array)
                }
                return allUnique ? array : unique
            }),
        codec.encode === t.identity ? t.identity : a => a.map(codec.encode),
        codec,
        iteratee,
    )

const isUnique = <C extends t.Mixed>(
    array: t.TypeOf<C>[],
    iteratee: (a: t.TypeOf<C>) => any = t.identity,
): boolean => {
    return uniqBy(array, iteratee).length === array.length
}
