import React from 'react'
import { WIPDataType } from 'model/WIP'
import {
    useLockedWIPs,
    useResourceMachine,
    useResourceWorkflows,
} from 'shared/resource/ResourceMachineProvider'
import Button from 'shared/components/Button'
import TextualButton from 'shared/components/TextualButton'
import { Formik, Form } from 'formik'
import Container from 'shared/components/Container'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import capitalize from 'lodash/capitalize'
import { ReleaseWIPsModal } from './ReleaseWIPsControl'

import {
    AlertDialog,
    AlertDialogLabel,
    AlertDialogButtons,
    AlertDialogDescription,
} from 'shared/components/AlertModal'

import WIPInfo from './WIPInfo'
import {
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
} from '@genome-web-forms/server'
import { DropdownMenuItem } from 'shared/components/DropdownMenu'
import PublishControl from 'shared/components/Header/PublishControl'

const workflowTasks = [
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
]

export const StartEditingControl: React.FC = () => {
    const [showModal, setShowModal] = React.useState(false)
    const lockedDataTypes = useLockedWIPs().map(wip => wip.dataType)
    const [state, send] = useResourceMachine()

    const [showReleaseModal, setShowReleaseModal] = React.useState(false)

    const workflows = useResourceWorkflows()

    const hideEdit = React.useMemo(() => {
        const workflowsTaskSet = new Set<string>()
        workflows.forEach(w => workflowsTaskSet.add(w.workflowConfig.task))
        return workflowTasks.every(wt => workflowsTaskSet.has(wt))
    }, [workflows])

    return (
        <>
            {<PublishControl />}

            {!(['aquiringLocks', { idle: 'readonly' }] as const).some(state.matches) && (
                <DropdownMenuItem onSelect={() => setShowReleaseModal(true)}>
                    Release
                </DropdownMenuItem>
            )}

            {showReleaseModal && <ReleaseWIPsModal closeModal={() => setShowModal(false)} />}

            {lockedDataTypes.length ? (
                <DropdownMenuItem
                    onSelect={() => send({ type: 'START_EDITING', selection: lockedDataTypes })}
                    disabled={state.matches('aquiringLocks')}
                >
                    Continue Editing
                </DropdownMenuItem>
            ) : (
                <>
                    {!hideEdit && (
                        <DropdownMenuItem disabled={hideEdit} onSelect={() => setShowModal(true)}>
                            Start Editing
                        </DropdownMenuItem>
                    )}
                    {showModal && <StartEditingModal closeModal={() => setShowModal(false)} />}
                </>
            )}
        </>
    )
}

const StartEditingModal: React.FC<{ closeModal: Function }> = ({ closeModal }) => {
    const [state, send] = useResourceMachine()
    const cancelRef = React.createRef<HTMLButtonElement>()

    const workflows = useResourceWorkflows()

    const isProcessing = state.matches('aquiringLocks')

    const availableTypes = state.context.wips
        .filter(ref => ref.state.matches('reading'))
        .map(ref => ref.state.context.dataType)

    const checkboxList = state.context.wips.map(ref => {
        const dataType = ref.state.context.dataType

        const isDisabledByWorkflow = (() => {
            const dataTypeWorkflow =
                dataType === 'metadata'
                    ? dataType
                    : WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS
            const workflowsTaskSet = new Set<string>()
            workflows.forEach(w => workflowsTaskSet.add(w.workflowConfig.task))
            return workflowsTaskSet.has(dataTypeWorkflow)
        })()

        return (
            <CheckboxInput
                key={dataType}
                name="selection"
                value={dataType}
                disabled={
                    isProcessing ||
                    ref.state.matches({ reading: { loaded: 'ownedByOther' } }) ||
                    isDisabledByWorkflow
                }
            >
                <Container alignItems="center">
                    <span>{capitalize(dataType)}:</span>
                    <WIPInfo dataType={dataType} />
                </Container>
            </CheckboxInput>
        )
    })

    const initialValues: { selection: WIPDataType[] } = { selection: [] }

    const onSubmit = ({ selection }: { selection: WIPDataType[] }): void => {
        send({ type: 'START_EDITING', selection })
    }

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {({ values, setValues }) => (
                    <Form>
                        <AlertDialogLabel>
                            Which tabs do you want to lock for editing?
                        </AlertDialogLabel>
                        <AlertDialogDescription>Select the tabs to edit:</AlertDialogDescription>
                        <Container mt={3} mb="2" flexDirection="column">
                            {checkboxList}

                            {!workflows.length && (
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        variant="outline"
                                        type="button"
                                        size="small"
                                        disabled={isProcessing}
                                        onClick={() =>
                                            setValues({
                                                selection: [...availableTypes],
                                            })
                                        }
                                    >
                                        Select All
                                    </Button>
                                </div>
                            )}
                        </Container>

                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={values.selection.length < 1 || isProcessing}
                                isLoading={isProcessing}
                            >
                                Start Editing
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                                disabled={isProcessing}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

export default StartEditingControl
