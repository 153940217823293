import React from 'react'

interface Props {
    color?: string
    svgProps?: {
        width?: string
        height?: string
    }
}

const LoadingSVG: React.FC<Props> = ({ color = 'inherit', svgProps }): React.ReactElement => {
    const loaderInnerElements = Array.from({ length: 12 }, (_, index) => {
        return (
            <g transform={`rotate(${index * 30} 50 50)`} key={index}>
                <rect x="48" y="24" rx="0" ry="0" width="4" height="12" fill={color}>
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin={`-${1 - index / 12}`}
                        repeatCount="indefinite"
                    />
                </rect>
            </g>
        )
    })

    return (
        <svg
            width="100%"
            height="100%"
            viewBox={`0 0 100 100`}
            preserveAspectRatio="xMidYMid"
            {...svgProps}
        >
            {loaderInnerElements}
        </svg>
    )
}

export default LoadingSVG
