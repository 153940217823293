import React from 'react'
import { Column } from 'react-table'

import EpisodeLinkCell from 'shared/components/Table/EpisodeLinkCell'
import Table from 'shared/components/Table'

import styled from 'shared/theme'

import { AbridgedEpisode } from 'model/Episode'

const EpisodesContainer = styled.div`
    table {
        border-collapse: collapse;
    }
    table thead {
        background-color: ${props => props.theme.colors.activeField};
    }
    table thead th {
        color: ${props => props.theme.colors.textSecondary};
        font-weight: 400;
    }
    table tbody tr:last-of-type td {
        border-bottom: 0;
    }
    table tbody td {
        &:first-child {
            border-left: 0;
        }
        &:last-child {
            border-right: 0;
        }
    }
`

const columns: Column<AbridgedEpisode>[] = [
    {
        id: 'airOrderNumber',
        Header: 'Air Order No',
        accessor: e => e.airOrderNumber,
        Cell: EpisodeLinkCell,
        disableSortBy: true,
        width: 10,
    },
    {
        id: 'episodeTitle',
        Header: 'Episode Title',
        accessor: e => e.episodeTitle,
        Cell: EpisodeLinkCell,
        disableSortBy: true,
    },
    {
        id: 'episodeNumber',
        Header: 'Production No',
        accessor: e => e.episodeNumber,
        Cell: EpisodeLinkCell,
        disableSortBy: true,
    },
    {
        id: 'foreignEpisodeNumber',
        Header: 'Foreign Episode No',
        accessor: e => e.foreignEpisodeNumber,
        Cell: EpisodeLinkCell,
        disableSortBy: true,
    },
]

const initialState = {
    sortBy: [{ id: 'airOrderNumber' }, { id: 'episodeNumber' }, { id: 'foreignEpisodeNumber' }],
}

export type EpisodesProps = {
    episodes: AbridgedEpisode[]
}

const Episodes: React.FC<EpisodesProps> = ({ episodes }): React.ReactElement => {
    return (
        <EpisodesContainer>
            <Table<AbridgedEpisode>
                {...{ data: episodes, columns, initialState }}
                getCellProps={() => ({
                    removePadding: true,
                })}
            />
        </EpisodesContainer>
    )
}

export default Episodes
