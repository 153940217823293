import React from 'react'
import uniqueId from 'lodash/uniqueId'

import { useField } from 'formik'

import { Props, Wrapper, Content, Label, Checkbox, TextWrapper } from './common'
import { ReactComponent as Icon } from './icon.svg'

type CheckboxProps = Props & {
    value?: string
    checked?: boolean
}
const CheckboxInput: React.FC<CheckboxProps> = ({ children, checked, ...rest }) => {
    const [field, meta] = useField({ ...rest, type: 'checkbox' })
    const [id] = React.useState(() => uniqueId('checkbox-'))

    return (
        <Wrapper>
            <Content error={!!meta.error}>
                <Checkbox type="checkbox" id={id} {...field} {...rest} checked={checked} />
                <Label htmlFor={id} error={!!meta.error}>
                    <Icon />{' '}
                </Label>
                <label htmlFor={id}>
                    <TextWrapper>{children}</TextWrapper>
                </label>
            </Content>
        </Wrapper>
    )
}

export default CheckboxInput
