import React from 'react'

import styled from 'shared/theme'
import { ReactComponent } from './comment-icon.svg'

interface CommentIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    fill?: string
}

const CommentIconBase = styled(ReactComponent)<CommentIconProps>`
    path {
        fill: ${props => props.fill ?? props.theme.colors.gray};
    }
`
const CommentIcon = (props: CommentIconProps & { children?: number }): JSX.Element => {
    let { fill, children: count, ...rest } = props
    return (
        <div {...(rest as any)} style={{ cursor: 'pointer' }}>
            <CommentIconBase fill={props.fill} />
            <span>{count}</span>
        </div>
    )
}

export default CommentIcon
