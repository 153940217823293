import React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

import styled from 'shared/theme'
import Container from 'shared/components/Container'
import { inputStyle } from 'shared/components/Input'

import { ReactComponent as DatePickerIcon } from './datePicker.svg'

import 'react-datepicker/dist/react-datepicker.css'

const Wrapper = styled(Container)<{ error?: boolean; disabled?: boolean }>`
    width: 100%;
    .react-datepicker__input-container input {
        ${inputStyle};
        width: 100%;

        padding-right: 74px;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker__close-icon {
        padding: 0px 48px 0px 0px;
    }
    .react-datepicker__header {
        background-color: ${({ theme }) => theme.colors.activeField};
    }
    .react-datepicker,
    .react-datepicker__header {
        border-color: ${({ theme }) => theme.colors.border};
    }
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
        border-bottom-color: ${({ theme }) => theme.colors.activeField};
    }
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
        border-top-color: ${({ theme }) => theme.colors.border};
    }
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
        border-bottom-color: ${({ theme }) => theme.colors.border};
    }
    .react-datepicker__day:hover,
    && .react-datepicker__day--keyboard-selected:hover {
        background-color: #f0f0f0;
    }
    && .react-datepicker__day--selected {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
    }
    && .react-datepicker__day--keyboard-selected {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.textPrimary};
    }
    .react-datepicker__day,
    .react-datepicker__day-name,
    .react-datepicker__current-month {
        color: ${({ theme }) => theme.colors.textPrimary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous {
        border-right-color: ${({ theme }) => theme.colors.textPrimary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous:hover {
        border-right-color: ${({ theme }) => theme.colors.textSecondary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--next {
        border-left-color: ${({ theme }) => theme.colors.textPrimary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--next:hover {
        border-left-color: ${({ theme }) => theme.colors.textSecondary};
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous,
    .react-datepicker__navigation.react-datepicker__navigation--next {
        outline: none;
    }
`

const Icon = styled(DatePickerIcon)`
    pointer-events: none;
    position: absolute;
    right: 1rem;
    width: 1.125rem;
`

const DatePicker: React.FC<ReactDatePickerProps> = props => (
    <Wrapper alignItems="center" style={{ position: 'relative' }}>
        <ReactDatePicker
            isClearable
            placeholderText="yyyy-mm-dd"
            dateFormat="yyyy-MM-dd"
            {...props}
        />
        <Icon />
    </Wrapper>
)

export default DatePicker
