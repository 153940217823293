import { createMachine, assign } from 'xstate'
import { WIPMachineActor } from 'shared/resource/WIP.machine'
import { WIPDataType } from 'model/WIP'

export type SyncWIPsGuard = (ctx: SyncWIPsContext, e: any) => boolean

export interface SyncWIPsContext {
    wipRefs: {
        status: 'pending' | 'aquired' | 'failed' | 'released'
        ref: WIPMachineActor<any>
    }[]
}
export type SyncWIPsEvent = {
    type: 'WIP_SYNC_STATUS'
    data: {
        dataType: WIPDataType
        status: 'aquired' | 'failed' | 'released'
    }
}
export const syncWIPsMachine = createMachine<SyncWIPsContext, SyncWIPsEvent>(
    {
        strict: true,
        on: {
            WIP_SYNC_STATUS: { actions: 'syncWipStatus' },
        },
        initial: 'precondition',
        states: {
            precondition: {
                always: [
                    {
                        cond: 'allInStartCondition',
                        target: 'executing',
                    },
                    { target: 'failed' },
                ],
            },
            executing: {
                entry: 'sendSyncedSignal',
                always: [
                    {
                        cond: 'failedMachineExists',
                        target: 'cleanupAfterFailure',
                    },

                    {
                        cond: 'allInTargetCondition',
                        target: 'done',
                    },
                ],
            },
            cleanupAfterFailure: {
                entry: 'handleFailedMachines',
                always: {
                    cond: 'cleanupComplete',
                    target: 'failed',
                },
            },
            failed: { type: 'final', data: () => false },
            done: { type: 'final', data: () => true },
        },
    },
    {
        guards: {
            failedMachineExists: ctx =>
                // all machines executed
                ctx.wipRefs.every(wipRef => wipRef.status !== 'pending') &&
                // and at least one failed
                ctx.wipRefs.some(wipRef => wipRef.status === 'failed'),
        },
        actions: {
            syncWipStatus: assign((ctx, e) => ({
                wipRefs: ctx.wipRefs.map(wipRef => {
                    if (wipRef.ref.state.context.dataType === e.data.dataType) {
                        wipRef.status = e.data.status
                    }
                    return wipRef
                }),
            })),
        },
    },
)
