import React from 'react'
import * as s from 'shared/diff/schema'

import { TableRow, TableCell } from 'shared/components/Table'
import { TableRowComponentProps } from 'react-table'
import { DiffValue } from 'shared/diff/DiffValue'
import { DiffableStoryRole } from 'codecs/storyline/StoryRole'
import { Grid, GridHeaderCell } from 'shared/storylines/StoryRolesTableRow/Grid'
import { DevelopmentValue } from 'shared/diff/components/DevelopmentValue'
import { DevelopmentsDiff } from 'shared/diff/components/DevelopmentsDiff'
import Text from 'shared/components/Text'
import styled from 'shared/theme'
import { compareByArc } from 'codecs/Arc'
import { ButtonContainer } from 'shared/storylines/StoryRolesTableRow/ButtonContainer'
import ToggleButton from 'shared/components/ToggleButton'

export function StoryRolesForOp<T extends { storyRoles: DiffableStoryRole[] }>(
    props: TableRowComponentProps<s.ArrayDiffOps<T>>,
): React.ReactElement | null {
    const {
        row,
        allColumns: { length: colSpan },
        row: { original: op },
    } = props

    // do not render storyroles diff if there are no storyroles for this OP
    if (s.isSchemaDiff(op)) {
        if (!op.ops.storyRoles.ops.length) {
            return null
        }
    } else {
        if (!op.value.storyRoles.length) {
            return null
        }
    }

    const storyRoles = s.isSchemaDiff(op) ? (
        op.ops.storyRoles.ops.map(op => <StoryRoleArrayOp key={op.value.storyRoleId} op={op} />)
    ) : (
        <StoryRolesList storyRoles={op.value.storyRoles} diffType={op.type} />
    )

    return (
        <TableRow diffType={s.isSchemaDiff(op) ? 'same' : op.type}>
            <TableCell
                style={{ padding: 0 }}
                {...{
                    colSpan,
                    removeTopBorderRadius: true,
                    alignWithPreviousRow: true,
                }}
            >
                <ButtonContainer>
                    <ToggleButton onClick={() => row.toggleRowExpanded()} toggled={row.isExpanded}>
                        {row.isExpanded ? 'Hide Story Roles' : 'Show Story Roles'}
                    </ToggleButton>
                </ButtonContainer>
                {row.isExpanded && (
                    <>
                        <Grid>
                            <GridHeaderCell noBorder>Character Name</GridHeaderCell>
                            <GridHeaderCell>Character Archetype</GridHeaderCell>
                            <GridHeaderCell>Motivation</GridHeaderCell>
                            <GridHeaderCell>Trait</GridHeaderCell>
                        </Grid>
                        {storyRoles}
                    </>
                )}
            </TableCell>
        </TableRow>
    )
}

function StoryRoleArrayOp({ op }: { op: s.ArrayDiffOps<DiffableStoryRole> }): React.ReactElement {
    if (!s.isSchemaDiff(op)) {
        return <StoryRoleValue diffType={op.type} storyRole={op.value} />
    }

    return (
        <Grid>
            <CharacterName>
                <DiffValue diff={op.ops.character} />
            </CharacterName>
            <StyledDivWithBorder>
                <DevelopmentsDiff diff={op.ops.characterArchetypes} />
            </StyledDivWithBorder>
            <StyledDivWithBorder>
                <DevelopmentsDiff diff={op.ops.motivations} />
            </StyledDivWithBorder>
            <StyledDivWithBorder>
                <DevelopmentsDiff diff={op.ops.traits} />
            </StyledDivWithBorder>
        </Grid>
    )
}

type StoryRolesListProps = {
    diffType: s.DiffOpType
    storyRoles: DiffableStoryRole[]
}
const StoryRolesList: React.FC<StoryRolesListProps> = ({
    diffType,
    storyRoles,
}): React.ReactElement => {
    return (
        <>
            {storyRoles.map(storyRole => {
                return <StoryRoleValue {...{ diffType, storyRole }} key={storyRole.storyRoleId} />
            })}
        </>
    )
}

const StoryRoleValue: React.FC<{
    diffType?: s.DiffOpType
    storyRole: DiffableStoryRole
}> = ({ diffType = 'same', storyRole }): React.ReactElement => {
    const characterArchetypes = React.useMemo(
        () => storyRole.characterArchetypes.sort(compareByArc),
        [storyRole.characterArchetypes],
    )
    const motivations = React.useMemo(
        () => storyRole.motivations.sort(compareByArc),
        [storyRole.motivations],
    )
    const traits = React.useMemo(() => storyRole.traits.sort(compareByArc), [storyRole.traits])

    return (
        <Grid diffType={diffType}>
            <CharacterName>{storyRole.character}</CharacterName>
            <StyledDivWithBorder>
                {characterArchetypes.map((attr, i) => (
                    <DevelopmentValue {...{ attr, diffType }} key={attr.arc + String(i)} />
                ))}
            </StyledDivWithBorder>
            <StyledDivWithBorder>
                {motivations.map((attr, i) => (
                    <DevelopmentValue {...{ attr, diffType }} key={attr.arc + String(i)} />
                ))}
            </StyledDivWithBorder>
            <StyledDivWithBorder>
                {traits.map((attr, i) => (
                    <DevelopmentValue {...{ attr, diffType }} key={attr.arc + String(i)} />
                ))}
            </StyledDivWithBorder>
        </Grid>
    )
}
const CharacterName = styled(Text).attrs({
    size: '5',
})`
    padding: 0.75rem 1rem;
`
export const StyledDivWithBorder = styled.div`
    display: grid;
    grid-row-gap: 0.5rem;
    border-left: 1px solid ${props => props.theme.colors.border};
    padding: 0.5rem 1rem;
`
