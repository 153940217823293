import React from 'react'
import {
    isUpdatingWIP,
    ResourceContext,
    ResourceEvent,
    ResourceState,
    ViewMode,
} from './resource.machine'
import { useActor, useSelector } from '@xstate/react'
import { Interpreter, ActorRefFrom, EmittedFrom } from 'xstate'
import { useContextHelper } from 'shared/hooks/useContextHelper'
import { PublishErrorManager } from 'shared/resource/PublishErrorManager'
import invariant from 'tiny-invariant'
import { WIPMachineActor } from './WIP.machine'
import { WIPDataForType, WIPDataType, getData, WIP } from 'model/WIP'
import { useUser } from 'auth/Auth'
import { lockMatches } from '@genome-web-forms/common/lock'
import * as s from 'shared/diff/schema'
import { useDiff } from 'shared/hooks/useDiff'
import { assertNever } from 'shared/util/assertNever'
import { CommentService } from './comments.machine'
import { Workflow } from '@genome-web-forms/server'
import { useFullNameMyIDUser as setFullNameMyIDUser } from 'api/workflow/partialMyIDUsers'
type ResourceMachineServiceReactContext<T> = Interpreter<
    ResourceContext<T>,
    any,
    ResourceEvent,
    ResourceState<T>
>
export const ResourceMachineServiceContext = React.createContext<
    ResourceMachineServiceReactContext<any>
>(null!)
ResourceMachineServiceContext.displayName = 'ResourceMachineService'
export function useResourceMachineService<T>(): ResourceMachineServiceReactContext<T> {
    return useContextHelper(ResourceMachineServiceContext, 'ResourceMachineServiceContext')
}
// eslint-disable-next-line
export function useResourceMachine<T>() {
    return useActor(useResourceMachineService<T>())
}
// eslint-disable-next-line
export function useWIPRelationshipsActor() {
    return useWIPActor('relationships')
}
// eslint-disable-next-line
export function useWIPActorRef<
    K extends WIPDataType,
    T extends WIPDataForType[K] = WIPDataForType[K],
>(dataType: K) {
    const actorRef = useResourceMachineSelector<any, WIPMachineActor<T> | undefined>(
        React.useCallback(
            state =>
                state.context.wips.find(ref => ref.getSnapshot()!.context.dataType === dataType),
            [dataType],
        ),
    )
    invariant(actorRef, `No ${dataType} WIP exists`)
    return actorRef
}
// eslint-disable-next-line
export function useWIPActor<K extends WIPDataType, T extends WIPDataForType[K] = WIPDataForType[K]>(
    dataType: K,
) {
    return useActor(useWIPActorRef<K, T>(dataType))
}
// eslint-disable-next-line
export function useWIPStorylinesActor<T extends WIPDataForType['storylines']>() {
    return useWIPActor<'storylines', T>('storylines')
}
// eslint-disable-next-line
export function useWIPMetadataActor() {
    return useWIPActor('metadata')
}

export function getWIPData<T>(viewMode: ViewMode, state: EmittedFrom<WIPMachineActor<T>>): T {
    const context = state.context
    switch (viewMode) {
        case 'editing':
            return context.data
        case 'readonly':
            return context.readonlyData
        case 'preview':
            return context.wip ? (getData(context.wip) as any) : context.readonlyData
        default:
            assertNever(viewMode, `Got viewMode that is not parsable: ${JSON.stringify(viewMode)}`)
    }
}
export function useWIPDiff<K extends WIPDataType, S extends s.SchemaC<any> | s.ArraySchema<any>, D>(
    dataType: K,
    schema: S,
    toDiffable: (original: WIPDataForType[K]) => D,
): D extends any[] ? s.ArrayDiff<D[number]> : s.SchemaDiff<D> {
    const [state] = useWIPActor(dataType)
    const a = getWIPData('readonly', state)
    const b = getWIPData('preview', state)
    return useDiff<WIPDataForType[K], D, S>(schema, toDiffable, a as any, b as any)
}

export function useViewMode(): ViewMode {
    return useResourceMachineSelector<any, ViewMode>(
        React.useCallback(state => {
            switch (true) {
                case state.matches({ idle: 'readonly' }):
                    return 'readonly'
                case state.matches({ idle: 'preview' }):
                    return 'preview'
                default:
                    return 'editing'
            }
        }, []),
    )
}
export function useLockedWIPs(): WIP[] {
    const user = useUser()

    return useResourceMachineSelector(
        React.useCallback(
            (state: ResourceMachineServiceReactContext<any>['state']) =>
                state.context.wips
                    .map(ref => ref.getSnapshot()!.context.wip)
                    .filter<WIP>(Boolean as any)
                    .filter(wip => lockMatches(wip.state, user)),
            [user],
        ),
    )
}
export function usePreviewModeDiff(): boolean {
    return useResourceMachineSelector<any, boolean>(
        React.useCallback(state => state.matches({ idle: { preview: 'diff' } }), []),
    )
}
export function useIsSaving(): boolean {
    return useResourceMachineSelector(isUpdatingWIP)
}

export function useResourceMachineSelector<T, R>(
    selector: (emitted: ResourceMachineServiceReactContext<T>['state']) => R,
): R {
    return useSelector(useResourceMachineService<T>(), selector)
}

export function usePublishErrorManager(): PublishErrorManager {
    return useResourceMachineSelector<unknown, PublishErrorManager>(
        React.useCallback(state => state.context.publishErrorManager, []),
    )
}
export function useResourceWorkflows(): Workflow[] {
    return useResourceMachineSelector<unknown, Workflow[]>(
        React.useCallback(
            state => state.context.workflowRefs.map(ref => ref.getSnapshot()!.context.workflow),
            [],
        ),
    )
}

export function useResourceWorkflowsWithAsigneeFullName(): (Workflow & {
    asigneeFullName: string
})[] {
    return useResourceWorkflows().map(w => {
        const asigneeFullName = setFullNameMyIDUser(w?.assignee)
        return { ...w, asigneeFullName }
    })
}

export function useFormControlsEnabled(
    dataType: 'metadata' | 'storylines' | 'relationships',
): boolean {
    const resourceIsEditing = useResourceMachineSelector<any, boolean>(
        React.useCallback(state => state.matches('editing'), []),
    )
    const wipIsEditing = useSelector(
        useWIPActorRef(dataType),
        React.useCallback(
            (state: EmittedFrom<WIPMachineActor<any>>) =>
                state.matches('editing') || state.matches('autofill.ready'),
            [],
        ),
    )

    return resourceIsEditing && wipIsEditing
}
export function useCommentsActorRef(): ActorRefFrom<CommentService['machine']> {
    return useResourceMachineSelector(
        React.useCallback((state: ResourceMachineServiceReactContext<any>['state']) => {
            return state.children['metadata-comments']
        }, []),
    ) as any
}
