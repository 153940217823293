import React from 'react'

import { Attribute } from 'codecs/Attribute'
import { AttributeMeta } from 'model/metadata/RecordedAttributes'

import UnavailableText from 'shared/components/UnavailableText'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'

import { useTRSSimpleFind } from 'shared/hooks/useTRS'
import { TaxonomyTerm } from 'codecs/TRS/Taxonomy'
import { FormikTRSSelect } from 'shared/components/TRS/TRSSelect'
import { safeFormikName } from 'shared/util/formikSafe'
import { useField } from 'formik'
import { usePureFn } from 'shared/hooks/usePureFn'

type StaticAttributeSelectProps = {
    attributeMeta: AttributeMeta
    formControlsEnabled: boolean
}
const StaticAttributeSelect: React.FC<StaticAttributeSelectProps> = ({
    attributeMeta,
    formControlsEnabled,
}): React.ReactElement => {
    const { label } = attributeMeta

    return (
        <Container mb="1" flexDirection="column">
            <Text size="5" weight="bold" as="h5">
                {label}
            </Text>

            <StaticAttributeSelectActual {...{ attributeMeta, formControlsEnabled }} />
        </Container>
    )
}

const StaticAttributeSelectActual: React.FC<StaticAttributeSelectProps> = ({
    attributeMeta,
    formControlsEnabled,
}): React.ReactElement => {
    const { label, placeholder, type } = attributeMeta
    const name = 'attributes.' + safeFormikName(type)

    const { terms } = useTRSSimpleFind(type)
    const [{ value }] = useField<Attribute[]>(name)

    const termToOption = React.useCallback(
        (term: TaxonomyTerm): Attribute => ({
            attributeId: term.id,
            attributeLabel: term.label,
            attributeType: type,
            attributeTypeName: label,
            suggestionScore: null,
        }),
        [label, type],
    )
    const getOptionValue = usePureFn((attribute: Attribute) => attribute.attributeId)
    const getOptionLabel = usePureFn((attribute: Attribute, term?: TaxonomyTerm) =>
        term ? term.label : attribute.attributeLabel,
    )

    if (!formControlsEnabled && !value.length) {
        return <UnavailableText>Unavailable</UnavailableText>
    }

    return (
        <FormikTRSSelect<Attribute>
            {...{
                name,
                isMulti: true,
                terms,
                termToOption,
                getOptionValue,
                getOptionLabel,
                isDisabled: !formControlsEnabled,
                placeholder,
            }}
        />
    )
}

export default StaticAttributeSelect
