import React from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker from '.'
import { ReactDatePickerProps } from 'react-datepicker'

type FormikDatepickerProps = ReactDatePickerProps & {
    name: string
}

const FormikDatepicker = (props: FormikDatepickerProps): React.ReactElement => {
    const { setFieldValue } = useFormikContext()
    let [field] = useField(props.name)
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val)
            }}
        />
    )
}

export default FormikDatepicker
