import React from 'react'
import { ReactComponent as EditIcon } from './edit-icon.svg'
import styled from 'shared/theme'
import { useButton } from '@react-aria/button'

export default EditIcon

type EditIconButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'chidren'>
const EditIconButtonElement = styled(EditIcon)<EditIconButtonProps>`
    cursor: pointer;
`

export function EditIconButton(props: EditIconButtonProps): JSX.Element {
    const ref = React.useRef<HTMLButtonElement>()
    const { buttonProps } = useButton({ elementType: 'svg' }, ref as any)
    return <EditIconButtonElement {...buttonProps} {...(props as any)} />
}
