import React from 'react'
import * as s from 'shared/diff/schema'
import { Pill } from 'shared/components/Table/TablePills'
import { ArcContainer } from 'shared/components/ArcContainer'
import { ArcText } from 'shared/components/ArcText'
import { Op } from 'shared/diff/Op'

type DevelopmentValueProps = {
    diffType?: s.DiffOpType
    attr: { arc: string; labels: string[] }
}

export const DevelopmentValue: React.FC<DevelopmentValueProps> = ({
    diffType = 'same',
    attr: { arc, labels },
}): React.ReactElement => {
    return (
        <ArcContainer diffType={diffType}>
            <div>
                {labels.map((label, i) => {
                    return (
                        <Pill key={label + String(i)} diffType={diffType}>
                            {label}
                        </Pill>
                    )
                })}
            </div>
            <ArcText>
                <Op type={diffType}>{arc}</Op>
            </ArcText>
        </ArcContainer>
    )
}
