import { space, SpaceProps } from 'styled-system'
import styled from 'shared/theme'

type PanelProps = SpaceProps & {
    'gray-bg'?: boolean
}
const Panel = styled.div<PanelProps>`
    ${space}

    padding: 1.5rem;
    border: 1px solid ${props => props.theme.colors.border};
    background-color: ${props =>
        props['gray-bg']
            ? props.theme.colors.backgroundGray
            : props.theme.colors.backgroundSecondary};
    border-radius: 4px;
`

export default Panel
