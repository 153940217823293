import { useCreateWorkflowReport } from 'api/workflow/createReport'
import {
    CreateWorkflowReport as CreateWorkflowReportModel,
    CreateWorkflowReportValidationSchema,
} from 'model/workflow/CreateWorkflowReport'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import Button from 'shared/components/Button'
import CellLabel from 'shared/components/CellLabel'
import Container from 'shared/components/Container'
import FormikDatepicker from 'shared/components/DatePicker/FormikDatepicker'
import Header from 'shared/components/Header'
import LinkButton from 'shared/components/LinkButton'
import Text from 'shared/components/Text'
import { notification } from 'shared/notification'
import styled from 'shared/theme'
import ErrorMessage from 'shared/components/ErrorMessage'
import { subDays, set } from 'date-fns'

const CreateWorkflowWrapper = styled(Container)`
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    svg {
        height: 18px;
        width: 18px;
        padding-top: 1px;
    }
`

const CreateWorkflowReportFormRow = styled.div`
    display: flex;
    flex-direction: row;
`

const InputWrapper = styled.div`
    padding: 1.5rem 1rem 0 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 8px;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-;
    flex: 1;
    * > {
        margin: 0.5rem;
    }
`

const CreateWorkflowReport: React.FC = (): React.ReactElement => {
    const initialValues: { from: Date | undefined; to: Date | undefined } = {
        from: set(subDays(new Date(), 7), { hours: 0, minutes: 0, seconds: 0 }),
        to: set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
    }

    const { start, status, downloadURL, isLoading, error } = useCreateWorkflowReport()

    useEffect(() => {
        if (status?.status === 'inprogress')
            notification.info(
                `Waiting for backend to complete export.
        An email will be sent to you when the export is complete, with a download link. 
        You can wait here for the export to complete, or close this page and wait for the email.`,
            )
        if (status?.status === 'completed') notification.info('Your export is ready!')
        if (status?.status === 'error')
            notification.error('An error has ocurred while generating workflow report!')
    }, [status?.status])

    useEffect(() => {
        if (error)
            notification.error(
                `An error has ocurred while starting report generation: ${error.message}`,
            )
    }, [error])

    const onSubmit = (values: Partial<CreateWorkflowReportModel>) => start(values)

    return (
        <>
            <Header />
            <CreateWorkflowWrapper>
                <Text as="h1">Workflow Report</Text>
                <Formik
                    {...{ initialValues, onSubmit }}
                    validationSchema={CreateWorkflowReportValidationSchema}
                >
                    {({ values, setValues }) => (
                        <Form>
                            <CreateWorkflowReportFormRow>
                                <InputWrapper>
                                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                        From
                                    </CellLabel>

                                    <FormikDatepicker
                                        name="from"
                                        autoComplete="off"
                                        maxDate={values.to}
                                        selected={values.from ? new Date(values.from) : undefined}
                                        onChange={date => {
                                            const from = Array.isArray(date) ? date[0] : date
                                            setValues({
                                                ...values,
                                                from: from
                                                    ? set(from, {
                                                          hours: 0,
                                                          minutes: 0,
                                                          seconds: 0,
                                                      })
                                                    : undefined,
                                            })
                                        }}
                                    />
                                    <ErrorMessage name="from" />
                                </InputWrapper>

                                <InputWrapper>
                                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                        To
                                    </CellLabel>

                                    <FormikDatepicker
                                        name="to"
                                        autoComplete="off"
                                        minDate={values.from}
                                        selected={values.to ? new Date(values.to) : undefined}
                                        onChange={date => {
                                            const to = Array.isArray(date) ? date[0] : date
                                            setValues({
                                                ...values,
                                                to: to
                                                    ? set(to, {
                                                          hours: 23,
                                                          minutes: 59,
                                                          seconds: 59,
                                                      })
                                                    : undefined,
                                            })
                                        }}
                                    />
                                    <ErrorMessage name="to" />
                                </InputWrapper>
                            </CreateWorkflowReportFormRow>
                            <CreateWorkflowReportFormRow>
                                <ButtonWrapper>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Generate
                                    </Button>

                                    {!isLoading && status?.status === 'completed' && (
                                        <LinkButton
                                            href={downloadURL}
                                            target="_blank"
                                            rel="noreferrer"
                                            variant="primary"
                                            type="button"
                                        >
                                            Download excel file
                                        </LinkButton>
                                    )}
                                </ButtonWrapper>
                            </CreateWorkflowReportFormRow>
                        </Form>
                    )}
                </Formik>
            </CreateWorkflowWrapper>
        </>
    )
}

export default CreateWorkflowReport
