import React from 'react'
import { FormikArcSelect } from 'shared/components/Select/FormikArcSelect'
import { FieldArray, useField } from 'formik'
import {
    RowContainer,
    LabelContainer,
    ClearButton,
    AddButton,
} from 'shared/components/ArcAttributes'
import { AttributeValue } from 'codecs/Attribute'
import { FormikTRSSelect, ImplementerFormikTRSSelectProps } from 'shared/components/TRS/TRSSelect'
import { ARC_OPTIONS } from 'codecs/Arc'
import { useTRSSimpleFind } from 'shared/hooks/useTRS'
import CellLabel from 'shared/components/CellLabel'
import { RelationshipFormValue, TRS_RELATIONSHIP_TYPES } from 'codecs/Relationship'
import ErrorMessage from 'shared/components/ErrorMessage'
import { usePureFn } from 'shared/hooks/usePureFn'

export const RelationshipAttributesForm: React.FC = (): React.ReactElement => {
    const [{ value: attributes }] = useField<RelationshipFormValue['attributes']>('attributes')

    const usedArcs = attributes.map(attribute => attribute.arc)
    const availableArcs = ARC_OPTIONS.filter(arcOption => !usedArcs.includes(arcOption.value))

    return (
        <FieldArray name="attributes">
            {({ push, remove }) => (
                <div>
                    <LabelContainer>
                        <CellLabel>Development Type</CellLabel>
                        <CellLabel>Arc</CellLabel>
                    </LabelContainer>
                    {attributes.map((attribute, index) => (
                        <RowContainer key={attribute.arc}>
                            <RelationshipAttributesSelect name={`attributes.${index}.values`} />

                            <FormikArcSelect
                                name={`attributes.${index}.arc`}
                                availableArcs={availableArcs}
                            />

                            <ClearButton type="button" onClick={() => remove(index)}>
                                &#10005;
                            </ClearButton>

                            <ErrorMessage name={`attributes.${index}.values`} />
                        </RowContainer>
                    ))}

                    <AddButton
                        disabled={availableArcs.length === 0}
                        onClick={() => {
                            const attribute: RelationshipFormValue['attributes'][number] = {
                                arc: availableArcs[0].value,
                                values: [],
                            }
                            push(attribute)
                        }}
                    >
                        + Add Development Type
                    </AddButton>
                </div>
            )}
        </FieldArray>
    )
}

export default RelationshipAttributesForm

type RelationshipAttributesSelectProps = ImplementerFormikTRSSelectProps<AttributeValue>
const RelationshipAttributesSelect: React.FC<RelationshipAttributesSelectProps> = ({
    ...props
}): React.ReactElement => {
    const { terms } = useTRSSimpleFind(TRS_RELATIONSHIP_TYPES)

    return (
        <FormikTRSSelect<AttributeValue>
            {...props}
            isMulti={true}
            terms={terms}
            termToOption={usePureFn(term => ({
                attributeId: term.id,
                attributeLabel: term.label,
            }))}
            getOptionValue={usePureFn(option => option.attributeId)}
            getOptionLabel={usePureFn((option, term) =>
                term ? term.label : option.attributeLabel,
            )}
        />
    )
}
