import { useParams } from 'react-router'

import { Location } from 'routing/routes'
import browserInfo from 'browser-info'
import { useUser } from 'auth/Auth'

type CommonUserData = {
    user: string
    url: string
    telemetryUuid?: string
    resource?: ResourceType
    browser: { os: string; name: string; version: string; fullVersion: string }
}

export function useCommonUserData(): CommonUserData {
    const browser = browserInfo()
    const params: any = useParams()
    const { 'relationship.employeeId': userId } = useUser()

    return {
        user: userId,
        url: document.location.href,
        resource: generateResourceObject(params),
        browser,
    }
}

type ResourceType = {
    type: string
    id: string
}

function generateResourceObject(routeParams: Location | undefined): ResourceType | undefined {
    switch (routeParams?._tag) {
        case 'Feature':
            return { type: 'feature', id: routeParams.featureId }
        case 'Episode':
            return {
                type: 'episode',
                id: routeParams.episodeId,
            }
        case 'Season':
            return { type: 'season', id: routeParams.seasonId }
        case 'Series':
            return { type: 'season', id: routeParams.seriesId }
    }
}
