import React from 'react'

import styled from 'shared/theme'
import {
    StoryRole,
    STORYROLE_TYPE_TRAITS,
    STORYROLE_TYPE_CHARACTER_ARCHETYPE,
    STORYROLE_TYPE_MOTIVATIONS,
} from 'codecs/storyline/StoryRole'

import StoryroleColumn from 'shared/components/Storyrole/StoryroleColumn'
import InvalidDataRenderer from 'shared/components/InvalidDataRenderer'
import Text from 'shared/components/Text'
import noop from 'lodash/noop'
import Optional from 'shared/components/Optional'
import { Grid } from './Grid'

export const StyledDivWithBorder = styled.div`
    display: grid;
    grid-row-gap: 0.5rem;
    border-left: 1px solid ${props => props.theme.colors.border};
    padding: 0.5rem 1rem;
`
export const CharacterName = styled(Text).attrs({
    size: '5',
})`
    padding: 0.75rem 1rem;
`

type StoryroleReadonlyProps = {
    storyRole: StoryRole
    onClick: () => void
    clickable?: boolean
    hasErrors?: boolean
}
export const StoryroleReadonly: React.FC<StoryroleReadonlyProps> = ({
    storyRole,
    onClick,
    clickable,
    hasErrors = false,
}): React.ReactElement => {
    const { character, attributes } = storyRole
    const isMultipleInavid = character.length > 1

    return (
        <Grid {...{ onClick: clickable ? onClick : noop, clickable, hasErrors }}>
            <CharacterName>
                {isMultipleInavid ? (
                    <InvalidDataRenderer
                        values={character.map(c => c.characterName)}
                        title="characters"
                    />
                ) : (
                    <Optional value={character.map(c => c.characterName)} />
                )}
            </CharacterName>
            <StyledDivWithBorder>
                <StoryroleColumn
                    attributes={attributes}
                    storyRoleType={STORYROLE_TYPE_CHARACTER_ARCHETYPE}
                />
            </StyledDivWithBorder>
            <StyledDivWithBorder>
                <StoryroleColumn
                    attributes={attributes}
                    storyRoleType={STORYROLE_TYPE_MOTIVATIONS}
                />
            </StyledDivWithBorder>
            <StyledDivWithBorder>
                <StoryroleColumn attributes={attributes} storyRoleType={STORYROLE_TYPE_TRAITS} />
            </StyledDivWithBorder>
        </Grid>
    )
}
