import React from 'react'

import { useContextHelper } from 'shared/hooks/useContextHelper'
import ReportABugModal from 'shared/components/ReportABug'

type BugReportState = {
    readonly modalVisible: boolean
    setModalVisible: (arg: boolean) => void
}

const BugReportContext = React.createContext<BugReportState>(null!)
BugReportContext.displayName = 'BugReport'

export function useBugReportContext(): BugReportState {
    return useContextHelper(BugReportContext, `BugReportContext`)
}

const BugReportProvider: React.FC = ({ children }): React.ReactElement => {
    const [modalVisible, setModalVisible] = React.useState(false)
    const value = React.useMemo(() => ({ modalVisible, setModalVisible }), [modalVisible])

    return (
        <BugReportContext.Provider value={value}>
            {modalVisible && <ReportABugModal />}
            {children}
        </BugReportContext.Provider>
    )
}

export default BugReportProvider
