export type FilterableTerm = {
    notForTagging?: boolean
    terms?: FilterableTerm[]
}

export function filterTaxonomyTerms(terms: undefined): undefined
/**
 * Filter out `notForTagging: true` terms:
 *  - Leaf `notForTagging: true` terms are removed
 *  - sub-category `notForTagging: true` are not removed, unless...
 *  - full trees of only `notForTagging: true` terms are removed
 */
export function filterTaxonomyTerms<T extends FilterableTerm>(terms: readonly T[]): T[]
export function filterTaxonomyTerms<T extends FilterableTerm>(
    terms: readonly T[] | undefined,
): T[] | undefined {
    if (!terms) {
        return undefined
    }

    let filteredItems: T[] = []

    for (const currentItem of terms) {
        currentItem.terms = filterTaxonomyTerms(currentItem.terms as T[])

        if (currentItem.notForTagging === false) {
            filteredItems.push(currentItem)
        } else if (hasTaggableItemInTree(currentItem.terms)) {
            filteredItems.push(currentItem)
        }
    }

    return filteredItems
}

export function hasTaggableItemInTree<T extends FilterableTerm>(terms?: T[]): boolean {
    return (terms || []).some(currentItem => {
        if (
            typeof currentItem.notForTagging === 'undefined' ||
            currentItem.notForTagging === false
        ) {
            return true
        }

        return hasTaggableItemInTree(currentItem.terms)
    })
}
