import styled from 'shared/theme'
import Color from 'color'

export type Type = 'danger' | 'warning'

export const Alert = styled.div.attrs(() => ({
    role: 'alert',
}))<{
    type?: Type
}>`
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    ${(props): string => {
        switch (props.type ?? 'danger') {
            case 'danger': {
                return `
                    color: ${Color(props.theme.colors.danger).saturate(30).lightness(40).hex()};
                    border-color: ${props.theme.colors.danger};
                    background-color: ${Color(props.theme.colors.danger).lightness(95).hex()};
                `
            }

            case 'warning': {
                return `
                    color: ${Color(props.theme.colors.warning).hex()};
                    border-color: ${props.theme.colors.warning};
                    background-color: ${Color('#FFB702').lightness(97).hex()};
                `
            }
        }
    }}
`

export default Alert
