import { ActionCreator } from 'typesafe-actions'

export function bindActionCreator<AC extends ActionCreator<any>>(
    actionCreator: AC,
    dispatch: React.Dispatch<ReturnType<AC>>,
): BoundActionCreator<AC> {
    return function (this: any, ...args: Parameters<AC>) {
        return dispatch(actionCreator.apply(this, args) as ReturnType<AC>)
    }
}

export type BoundActionCreators<T extends Record<string, ActionCreator<any>>> = {
    [K in keyof T]: BoundActionCreator<T[K]>
}

export type BoundActionCreator<AC extends ActionCreator<any>> = (...args: Parameters<AC>) => void

export function bindActionCreators<T extends Record<string, ActionCreator<any>>>(
    actionCreators: T,
    dispatch: React.Dispatch<ReturnType<T[keyof T]>>,
): BoundActionCreators<T> {
    if (typeof actionCreators !== 'object' || actionCreators === null) {
        throw new Error(
            `bindActionCreators expected an object or a function, instead received ${
                actionCreators === null ? 'null' : typeof actionCreators
            }.`,
        )
    }

    const boundActionCreators = {} as BoundActionCreators<T>
    for (const key in actionCreators) {
        const actionCreator = actionCreators[key]
        if (typeof actionCreator === 'function') {
            boundActionCreators[key] = bindActionCreator(actionCreator, dispatch)
        }
    }

    return boundActionCreators
}
