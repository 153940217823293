import React from 'react'
import * as s from 'shared/diff/schema'

import { Pill } from 'shared/components/Table/TablePills'
import { ArcContainer } from 'shared/components/ArcContainer'
import { ArcText } from 'shared/components/ArcText'
import { compareByArc } from 'codecs/Arc'
import { DiffValue } from 'shared/diff/DiffValue'

import { DevelopmentValue } from 'shared/diff/components/DevelopmentValue'

type DevelopmentsDiffProps = {
    diff: s.ArrayDiff<{ arc: string; labels: string[] }>
}

export const DevelopmentsDiff: React.FC<DevelopmentsDiffProps> = ({ diff }): React.ReactElement => {
    const ops = diff.ops.sort((a, b) => compareByArc(a.value, b.value))
    return (
        <>
            {ops.map((op, i) => {
                if (!s.isSchemaDiff(op)) {
                    return (
                        <DevelopmentValue diffType={op.type} key={op.value.arc} attr={op.value} />
                    )
                }
                return (
                    <ArcContainer key={op.value.arc + String(i)}>
                        <div>
                            {op.ops.labels.ops.map(({ value: label, type }, i) => (
                                <Pill diffType={type} key={label + String(i)}>
                                    {label}
                                </Pill>
                            ))}
                        </div>
                        <ArcText>
                            <DiffValue diff={op.ops.arc} />
                        </ArcText>
                    </ArcContainer>
                )
            })}
        </>
    )
}
