import * as t from 'io-ts'
import * as y from 'yup'

import { fromNullable } from 'codecs/util/fromNullable'
import { TalentCodec } from 'codecs/Talent'

export const CharacterCodec = t.type({
    characterId: t.string,
    characterName: t.string,
})
export type Character = t.TypeOf<typeof CharacterCodec>

export const CharacterWithPortrayalCodec = t.type({
    ...CharacterCodec.props,
    portrayedBy: fromNullable(t.array(TalentCodec), [], t.array(TalentCodec).name),
})
export type CharacterWithPortrayal = t.TypeOf<typeof CharacterWithPortrayalCodec>

/////////////////////////////////////////////
// YUP VALIDATION
/////////////////////////////////////////////

export const CharacterFormSchema: y.SchemaOf<Character> = y
    .object({
        characterId: y.string().required('Required'),
        characterName: y.string().required('Required'),
    })
    .required()
