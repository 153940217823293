import * as t from 'io-ts'
import * as y from 'yup'
import * as s from 'shared/diff/schema'
import { fromNullable } from 'codecs/util/fromNullable'
import nullable from 'codecs/util/nullable'
import { orderBy } from 'natural-orderby'
import identity from 'lodash/identity'

import {
    StorylineAttributeCodec,
    StorylineAttributeFormSchema,
} from 'codecs/storyline/StorylineAttribute'
import {
    StoryRoleCodec,
    StoryRoleFormSchema,
    StoryRoleDiffSchema,
    toDiffableStoryRole,
} from 'codecs/storyline/StoryRole'

const StorylineLeadCharacterCodec = t.type({
    storylineLeadCharacterId: t.string,
    storylineLeadCharacterName: t.string,
})

export const FeatureStorylineCodec = t.type({
    storylineId: t.string,
    storylineComponent: nullable(t.exact(StorylineAttributeCodec, StorylineAttributeCodec.name)),
    storylineLogLine: fromNullable(t.string, ''),
    storylineArchetypes: fromNullable(t.array(StorylineAttributeCodec), []),
    storylineLeadCharacter: fromNullable(t.array(StorylineLeadCharacterCodec), []),
    storyRoles: fromNullable(t.array(StoryRoleCodec), []),
})

export interface FeatureStoryline extends t.TypeOf<typeof FeatureStorylineCodec> {}
export interface StorylineLeadCharacter extends t.TypeOf<typeof StorylineLeadCharacterCodec> {}

/////////////////////////////////////////////
// Constants
/////////////////////////////////////////////

export const STORYLINE_COMPONENT_ID_MAIN =
    'http://data.disney.com/resources/d80c12e7-7758-4dc9-aa4f-fc603fc79123'
export const STORYLINE_COMPONENT_ID_SECONDARY =
    'http://data.disney.com/resources/6e8d98cd-3eae-411b-b0ff-d0abcf0081d0'
export const STORYLINE_COMPONENT_ID_SUBPLOT =
    'http://data.disney.com/resources/da46a356-60b0-44c7-93f0-9e036be66881'
export const STORYLINE_COMPONENT_ID_STINGER =
    'http://data.disney.com/resources/444b961b-faf1-4f7b-a132-30dbfe8a2d08'

// Main -> Secondary -> Subplot -> Stinger
const STORYLINE_COMPONENTS_ORDERED = [
    STORYLINE_COMPONENT_ID_MAIN,
    STORYLINE_COMPONENT_ID_SECONDARY,
    STORYLINE_COMPONENT_ID_SUBPLOT,
    STORYLINE_COMPONENT_ID_STINGER,
]
export function storylineCompareByComponent(
    a: { storylineComponent: { id: string } | null },
    b: { storylineComponent: { id: string } | null },
): number {
    if (!a.storylineComponent) {
        return -1
    }
    if (!b.storylineComponent) {
        return 1
    }
    return (
        STORYLINE_COMPONENTS_ORDERED.indexOf(a.storylineComponent.id) -
        STORYLINE_COMPONENTS_ORDERED.indexOf(b.storylineComponent.id)
    )
}

export const FeatureStorylineDiffSchema = s.schema({
    storylineId: s.ignore<string>(),
    storylineComponent: s.value<{ id: string; label: string } | null>(),
    storylineLogLine: s.text(),
    storylineArchetypes: s.array(s.value<string>()),
    storylineLeadCharacter: s.array(s.value<string>(), identity, identity),
    storyRoles: s.array(StoryRoleDiffSchema, 'storyRoleId', 'character'),
})
export type DiffableFeatureStoryline = s.TypeOf<typeof FeatureStorylineDiffSchema>
export const toDiffableFeatureStorylines = (fss: FeatureStoryline[]): DiffableFeatureStoryline[] =>
    fss.map(toDiffableFeatureStoryline)
export const toDiffableFeatureStoryline = (fs: FeatureStoryline): DiffableFeatureStoryline => {
    return {
        storylineId: fs.storylineId,
        storylineComponent: fs.storylineComponent as s.ValueBranded<typeof fs.storylineComponent>,
        storylineLogLine: fs.storylineLogLine,
        storylineArchetypes: orderBy(fs.storylineArchetypes.map(v => v.label)),
        storylineLeadCharacter: fs.storylineLeadCharacter.map(c => c.storylineLeadCharacterName),
        storyRoles: fs.storyRoles.map(toDiffableStoryRole),
    }
}

/////////////////////////////////////////////
// YUP VALIDATION
/////////////////////////////////////////////

const StorylineLeadCharacterFormSchema = y
    .object({
        storylineLeadCharacterId: y.string().required(),
        storylineLeadCharacterName: y.string().required(),
    })
    .defined()
const StorylineComponentFormSchema = y
    .object({
        id: y.string().required(),
        label: y.string().required(),
    })
    .required()
    .nullable()
    .label('Component')

export const FeatureStorylineFormSchema = y
    .object({
        storylineId: y.string().required(),
        storylineComponent: StorylineComponentFormSchema,
        storylineLogLine: y.string().required().label('Description'),
        storylineArchetypes: y
            .array()
            .of(StorylineAttributeFormSchema)
            .required()
            .label('Story Archetype'),
        storylineLeadCharacter: y
            .array()
            .of(StorylineLeadCharacterFormSchema)
            .required()
            .label('Lead Character'),
        storyRoles: y.array().defined().of(StoryRoleFormSchema),
    })
    .defined()

export type FeatureStorylineFormValue = y.TypeOf<typeof FeatureStorylineFormSchema> & {
    storylineId: string
}
