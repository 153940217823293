import { PublishErrorManager } from 'shared/resource/PublishErrorManager'
import { StoryRole } from 'codecs/storyline/StoryRole'

export function hasStoryRoleErrors<S extends { storyRoles: StoryRole[] }>(
    errorManager: PublishErrorManager,
    storyline: S,
): boolean {
    return storyline.storyRoles.some(sr =>
        errorManager.hasErrors((u): u is StoryRole => {
            return u.storyRoleId === sr.storyRoleId
        }),
    )
}
