import { WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA } from '@genome-web-forms/server'
import { Form, useFormikContext } from 'formik'
import { AssignmentConfiguration } from 'model/workflow/AssignmentConfiguration'
import { TaskTypeOption, useSourceOptionsForSelectedTitles } from 'model/workflow/TaskType'
import { WorkflowPriorityOptions } from 'model/workflow/WorkflowPriority'
import React from 'react'
import CellLabel from 'shared/components/CellLabel'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import Container from 'shared/components/Container'
import DatePicker from 'shared/components/DatePicker'
import ErrorMessage from 'shared/components/ErrorMessage'
import FormikSelect from 'shared/components/Select/FormikSelect'
import Text from 'shared/components/Text'
import Autosave from 'shared/form/Autosave'
import styled from 'shared/theme'
import { useInterpreter } from '../CreateAssignmentProvider'
import AssigneeSelect from './AssigneeSelect'

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 8px;
`

type GlobalConfigurationFormNodeProps = {
    taskTypeOptions: TaskTypeOption[]
}
const GlobalConfigurationFormNode: React.FC<GlobalConfigurationFormNodeProps> = ({
    taskTypeOptions,
}) => {
    const service = useInterpreter()
    const { values, setFieldValue } = useFormikContext<AssignmentConfiguration>()

    const sourceTypeOptions = useSourceOptionsForSelectedTitles()

    return (
        <Container flexDirection="column" style={{ maxWidth: '70%', margin: '40px auto 0' }}>
            <Form>
                <Text as="h1">Global Assignment Configuration</Text>

                <Autosave<AssignmentConfiguration>
                    onChange={globalConfiguration =>
                        service.send({ type: 'GLOBAL_CONFIGURATION_UPDATE', globalConfiguration })
                    }
                />

                <br />

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Tasks
                    </CellLabel>

                    <FormikSelect
                        name="tasks"
                        isMulti
                        options={taskTypeOptions}
                        value={taskTypeOptions.filter(tto => values.tasks.includes(tto.value))}
                        onChange={
                            ((value: { value: string }[]) => {
                                const values = value ? value.map(v => v.value) : []
                                setFieldValue('tasks', values)
                                setFieldValue(
                                    'reviewRequired',
                                    values.includes(WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA),
                                )
                            }) as any
                        }
                    />
                    <ErrorMessage name="tasks" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Assignee
                    </CellLabel>

                    <AssigneeSelect name="assignee" />
                    <ErrorMessage name="assignee" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Priority
                    </CellLabel>

                    <FormikSelect
                        name="priority"
                        isClearable={true}
                        options={WorkflowPriorityOptions}
                        value={WorkflowPriorityOptions.find(po => po.value === values.priority)}
                        onChange={
                            ((value: { value: string } | null) =>
                                setFieldValue('priority', value ? value.value : null)) as any
                        }
                        placeholder="Default priority"
                    />
                    <ErrorMessage name="priority" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Due Date
                    </CellLabel>

                    <DatePicker
                        selected={values.deadlineAt ? new Date(values.deadlineAt) : undefined}
                        minDate={new Date()}
                        onChange={date => {
                            const to = Array.isArray(date) ? date[0] : date
                            setFieldValue('deadlineAt', to || undefined)
                        }}
                    />
                    <ErrorMessage name="deadlineAt" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Source
                    </CellLabel>

                    <FormikSelect
                        name="source"
                        options={sourceTypeOptions}
                        placeholder="Select Source"
                        value={sourceTypeOptions.find(so => so.value === values.source)}
                        onChange={
                            ((value: { value: string } | null) =>
                                setFieldValue('source', value ? value.value : null)) as any
                        }
                    />
                    <ErrorMessage name="source" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Needs Review
                    </CellLabel>

                    <CheckboxInput name="reviewRequired" checked={values.reviewRequired} />
                    <ErrorMessage name="reviewRequired" />
                </InputWrapper>
            </Form>
        </Container>
    )
}

export default GlobalConfigurationFormNode
