import React from 'react'
import * as Router from 'react-router-dom'
import { __RouterContext as RouterContext } from 'react-router'
import * as r from 'fp-ts-routing'
import * as H from 'history'
import * as Option from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import warning from 'tiny-warning'

import { Location as RouteLocation } from './routes'

export interface LocationMatch<L extends RouteLocation> extends Router.match<any> {
    params: L
}

/**
 * Convert an fp-ts-routing match into a react-router match object
 */
const convertMatch = (
    [params, route]: [RouteLocation, r.Route],
    { pathname }: { pathname: string; search: string },
): LocationMatch<RouteLocation> => {
    const leftUrl = pathname.replace(route.toString(), '')

    return {
        path: pathname,
        params,
        isExact: route.parts.length === 0,
        url: pathname === '/' && leftUrl === '' ? '/' : leftUrl,
    }
}

export function matchPath(
    pathname: string,
    search: string,
    { parser }: { parser: r.Parser<RouteLocation> },
): LocationMatch<RouteLocation> | null {
    return pipe(
        parser.run(r.Route.parse(pathname + search)),
        Option.map(v => convertMatch(v, { pathname, search })),
        Option.toNullable,
    )
}

export interface TypedRouteProps extends Router.RouteProps {
    history: H.History<any>
    location: H.Location<any>
    match: LocationMatch<RouteLocation>
    computedMatch?: LocationMatch<RouteLocation>
    parser?: r.Parser<any>
    isPrivate?: boolean
    permissions?: string[]
}

const TypedRouteBase: React.FC<TypedRouteProps> = (props): React.ReactElement | null => {
    const { location, history, path } = props

    const match = props.computedMatch
        ? props.computedMatch
        : props.parser
        ? matchPath(location.pathname, location.search, {
              parser: props.parser,
          })
        : props.match

    const renderProps = { path, location, history, match }
    let { children, component, render } = props
    if (Array.isArray(children) && children.length === 0) {
        children = null
    }
    if (typeof children === 'function') {
        children =
            process.env.NODE_ENV === 'development'
                ? evalChildrenDev(children as React.FC, props, path)
                : (children as any)(props)
        if (children === undefined) {
            children = null
        }
    }

    // we are cheating in the children and render() paths by asserting them
    // to return a React.ReactElement, but that doesn't matter
    return (
        <RouterContext.Provider value={renderProps as any}>
            {children && React.Children.count(children) !== 0
                ? (children as React.ReactElement)
                : renderProps.match
                ? component
                    ? React.createElement(component as any, renderProps as TypedRouteProps)
                    : render
                    ? (render(renderProps as any) as React.ReactElement)
                    : null
                : null}
        </RouterContext.Provider>
    )
}

function evalChildrenDev(
    children: React.FC<any>,
    props: object,
    path: readonly string[] | string | undefined,
): React.ReactNode | null {
    const value = children(props)

    warning(
        value !== undefined,
        'You returned `undefined` from the `children` function of ' +
            `<TypedRoute${path ? ` path="${path}"` : ''}>, but you ` +
            'should have returned a React element or `null`',
    )

    return value || null
}

const TypedRoute = Router.withRouter(TypedRouteBase)

export default React.memo(TypedRoute)
