import styled from 'shared/theme'
import Text from 'shared/components/Text'

const UnavailableText = styled(Text).attrs<{}>({
    variant: 'primary',
    size: 5,
})`
    opacity: 0.5;
`
export default UnavailableText
