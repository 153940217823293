import React from 'react'

import { AuthDispatchContext, actions as authActions } from 'auth/Auth'
import { isAxiosError } from '@genome-web-forms/common/error'
import { isCodecError } from '@genome-web-forms/common/error'
import { homeLink } from 'routing/routes'
import Container from 'shared/components/Container'
import Button from 'shared/components/Button'
import TextualButton from 'shared/components/TextualButton'
import Text from 'shared/components/Text'
import { FallbackProps } from 'react-error-boundary'
import { isUnauthenticatedError } from '@genome-web-forms/common/error'
import { Redirect } from 'react-router-dom'
import { auth } from 'routing/routes'

import { ReactComponent as Warning } from 'shared/components/DuplicateDataWarning/warning.svg'

import styled from 'shared/theme'

const StyledWarning = styled(Warning)`
    margin-top: 1rem;
    fill: ${props => props.theme.colors.gray};
`

export const ErrorFallback: React.FC<FallbackProps> = ({
    error,
    resetErrorBoundary,
}): React.ReactElement => {
    let title: React.ReactElement | string | undefined
    let description: React.ReactElement | string | undefined
    let technicalDescription: React.ReactElement | string | undefined

    const [showMore, setShowMore] = React.useState(false)
    const authDispatch = React.useContext(AuthDispatchContext)

    // The error boundry must be reset in order for the <Redirect> to work.
    // Therefore this must be kept in sync with the isUnauthenticatedError() render if
    React.useEffect(() => {
        if (isUnauthenticatedError(error)) {
            resetErrorBoundary()
        }
    }, [authDispatch, resetErrorBoundary, error])
    if (isUnauthenticatedError(error)) {
        return (
            <Redirect
                to={auth.loginLink({
                    returnTo: window.location.pathname + window.location.search,
                    forceRelogin: 'yes',
                })}
            />
        )
    }
    /////////////////////////////////////////////

    if (isCodecError(error)) {
        title = 'The API returned data that cannot be parsed.'
        technicalDescription = error.message
    } else if (isAxiosError(error)) {
        const { data, headers, status, statusText } = error.response || {}
        title = (
            <>
                Network Error: {error.message}
                {typeof data === 'string' && (
                    <>
                        <br />
                        {data}
                    </>
                )}
            </>
        )

        technicalDescription = JSON.stringify(
            {
                ...error.toJSON(),
                response: { data, headers, status, statusText },
            },
            null,
            2,
        )
    } else if (error) {
        title = error.name + ': ' + error.message.split('\n')[0]
        technicalDescription =
            technicalDescription ?? Object.keys(error).length
                ? JSON.stringify(error, null, 2)
                : error.stack
    } else {
        title = 'Something went wrong'
    }

    return (
        <Container flexDirection="column" alignItems="center">
            <StyledWarning />
            <Text size="1" as="h2" mt="3" mb="2" style={{ textAlign: 'center' }}>
                {title}
            </Text>
            <Text variant="secondary" size="4" as="h3" mb="2">
                {description}
            </Text>

            {technicalDescription && !showMore && (
                <TextualButton
                    weight="bold"
                    onClick={() => {
                        setShowMore(true)
                    }}
                >
                    Show technical description
                </TextualButton>
            )}
            {showMore && technicalDescription && (
                <pre
                    style={{
                        background: '#f6f8fa',
                        fontSize: '.65rem',
                        padding: '.5rem',
                        maxWidth: '100%',
                        overflow: 'auto',
                    }}
                >
                    {technicalDescription}
                </pre>
            )}

            <Container flexDirection="row" alignItems="space-around" mt="4" pt="1">
                <Button onClick={resetErrorBoundary} mr="2">
                    Try again
                </Button>
                <Button onClick={() => window.location.reload()} mr="2">
                    Reload page
                </Button>
                <Button onClick={() => window.location.assign(homeLink())} mr="2">
                    Go Home
                </Button>
                <Button
                    onClick={() => {
                        authDispatch(authActions.signout())
                        resetErrorBoundary()
                    }}
                >
                    Re-login
                </Button>
            </Container>
        </Container>
    )
}
