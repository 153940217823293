import React from 'react'

/**
 * The actual implementation of PropsToJSON. Use when you need this in prod
 */
export const PropsToJSONImpl: React.FC<Record<string, unknown>> = ({
    ...props
}): React.ReactElement | null => {
    const [visible, setVisible] = React.useState(false)

    return (
        <div style={{ margin: '1rem 0' }}>
            <pre
                style={{
                    background: '#f6f8fa',
                    fontSize: '.65rem',
                    padding: '.5rem',
                    overflow: 'auto',
                }}
                onClick={() => setVisible(visible => !visible)}
            >
                {visible ? JSON.stringify(props, null, 2) : 'Click to show'}
            </pre>
        </div>
    )
}

/**
 * Display input props as a JSON string.
 * Safe for debugging as it gets converted to a noop in production.
 */
export const PropsToJSON =
    process.env.NODE_ENV !== 'development'
        ? ((() => null) as typeof PropsToJSONImpl)
        : PropsToJSONImpl
