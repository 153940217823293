import { ResourceType } from '../fetch/fetchResource'
import { Comment, CommentCodec } from '../../model/workflow/Comment'
import { request } from '@genome-web-forms/common/api'
import * as t from 'io-ts'
import config from '../../shared/config'
import { queryClient } from '../../shared/queryClient'
import uniqBy from 'lodash/uniqBy'
import { QueryObserver } from 'react-query'

type CommentsRequestConfig = {
    resourceId: string
    resourceType: ResourceType
}

export const makeCommentsKey = (config: { resourceId: string }): string[] => {
    return ['comments', config.resourceId]
}

export const fetchComments = (fetchConfig: CommentsRequestConfig): Promise<Comment[]> => {
    return request(t.array(CommentCodec), {
        url: `${config.urlGWF}/creativework/${fetchConfig.resourceType}/${encodeURIComponent(
            fetchConfig.resourceId,
        )}/comments`,
    })
}

export const createComment = async (
    fetchConfig: CommentsRequestConfig,
    content: string,
): Promise<Comment> => {
    const queryKey = makeCommentsKey(fetchConfig)
    await queryClient.cancelQueries(queryKey)

    const comment = await request(CommentCodec, {
        method: 'POST',
        url: `${config.urlGWF}/creativework/${fetchConfig.resourceType}/${encodeURIComponent(
            fetchConfig.resourceId,
        )}/comments`,
        data: {
            content,
        },
    })

    queryClient.setQueryData<Comment[]>(queryKey, (comments = []) =>
        uniqBy([...comments, comment], comment => comment.id),
    )
    queryClient.invalidateQueries(queryKey)

    return comment
}

export const deleteComment = async (
    fetchConfig: CommentsRequestConfig,
    comment: Comment,
): Promise<Comment> => {
    const queryKey = makeCommentsKey(fetchConfig)

    await queryClient.cancelQueries(queryKey)
    queryClient.setQueryData<Comment[]>(queryKey, (comments = []) =>
        comments.filter(c => c.id !== comment.id),
    )

    comment = await request(CommentCodec, {
        method: 'DELETE',
        url: `${config.urlGWF}/creativework/${fetchConfig.resourceType}/${encodeURIComponent(
            fetchConfig.resourceId,
        )}/comments/${comment.id}`,
    })

    queryClient.invalidateQueries(queryKey)

    return comment
}

export const editComment = async (
    fetchConfig: CommentsRequestConfig,
    comment: Comment,
    content: string,
): Promise<Comment> => {
    const queryKey = makeCommentsKey(fetchConfig)

    await queryClient.cancelQueries(queryKey)
    queryClient.setQueryData<Comment[]>(queryKey, (comments = []) =>
        comments.map(c => (c.id === comment.id ? { ...c, content } : c)),
    )

    comment = await request(CommentCodec, {
        method: 'PUT',
        url: `${config.urlGWF}/creativework/${fetchConfig.resourceType}/${encodeURIComponent(
            fetchConfig.resourceId,
        )}/comments/${comment.id}`,
        data: { content },
    })

    queryClient.invalidateQueries(queryKey)

    return comment
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createCommentsObserver = (config: CommentsRequestConfig) => {
    const queryKey = makeCommentsKey(config)
    const queryFn = () => fetchComments(config)

    return new QueryObserver<Comment[]>(queryClient, {
        queryKey,
        queryFn,
        refetchOnWindowFocus: 'always',
        refetchOnMount: false,
    })
}
