import { MenuButton as RMenuButton, MenuList as RMenuList } from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import { rgba } from 'polished'

import styled from 'shared/theme'

export const MenuButton = styled(RMenuButton)`
    padding: 0;
    border: 0;
    background: transparent;
    vertical-align: middle;
    min-width: 24px;
    min-height: 24px;
    cursor: pointer;
`

export const MenuList = styled(RMenuList)`
    &[data-reach-menu-list] {
        background: ${props => props.theme.colors.white};
        border: 1px solid ${props => props.theme.colors.border};
        border-radius: 2px;
        box-shadow: 0 4px 8px 0 rgba(75, 93, 128, 0.3);
        padding: 0;
    }
    [data-reach-menu-item] {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 0.9375rem;
        line-height: 1.5rem;
        padding: 0.5rem 1.5rem;
        color: ${props => props.theme.colors.textPrimary};
        &[data-selected] {
            background-color: ${props => rgba(props.theme.colors.border, 0.3)};
        }
    }
`

export { Menu, MenuItem, MenuLink } from '@reach/menu-button'
