import React from 'react'
import { orderBy } from 'natural-orderby'

import BaseSelect, {
    Props as BaseSelectProps,
    ValueType,
    components as componentsBase,
    MultiValueProps,
    OptionProps,
} from 'react-select'
import BaseAsyncSelect, { AsyncProps } from 'react-select/async'
import { StylesConfig } from 'react-select/src/styles'

import { SafeSelectProps as SafeSelectPropsT } from './SafeSelectProps'
import { theme } from 'shared/theme'
import { useStableParamsWarning } from 'shared/hooks/useStableParamsWarning'

export type SafeSelectProps<T> = SafeSelectPropsT<T>

export interface OverridenAttrs {
    hideSelectedOptions?: boolean
    isClearable?: boolean
    classNamePrefix?: 'rs'
}

const OverridenAttrsDefault: OverridenAttrs = {
    hideSelectedOptions: false,
    isClearable: false,
    classNamePrefix: 'rs',
}

export type BaseOptionType = { [x: string]: any }

export type SelectProps<OptionType = { label: string; value: string }> =
    BaseSelectProps<OptionType> & OverridenAttrs

const dot = (color = '#ccc') =>
    ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    } as const)

const customStyles: StylesConfig = {
    // using state: any because of breakage introduced in
    // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/46386
    control: (provided, state: any) => ({
        ...provided,
        minHeight: '3rem',
        transition: 'none',
        boxShadow: state.menuIsOpen ? 'none' : 'initial',
        outline: state.menuIsOpen ? '0' : 'initial',
        borderRadius: state.menuIsOpen ? '2px 2px 0 0' : '2px',
        backgroundColor: `${theme.colors.white}`,
        borderWidth: state.isDisabled ? '0' : state.menuIsOpen ? '1px 1px 0 1px' : '1px',
        borderColor: state.isFocused
            ? `${theme.colors.primary}`
            : state.isFocused
            ? `${theme.colors.primary}`
            : `${theme.colors.border}`,
        ':hover': { borderColor: `${theme.colors.primary}` },
        ':after': {
            backgroundColor: `${theme.colors.border}`,
            position: 'absolute',
            display: state.menuIsOpen ? 'block' : 'none',
            height: '1px',
            content: '""',
            bottom: '0',
            right: '0',
            left: '0',
        },
    }),
    container: provided => ({
        ...provided,
        pointerEvents: 'auto',
    }),
    menu: provided => ({
        ...provided,
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        marginTop: '0',
        border: `1px solid ${theme.colors.primary}`,
        borderTop: '0',
        boxShadow: '0 4px 8px 0 rgba(75, 93, 128, 0.3)',
    }),
    menuList: provided => ({
        ...provided,
        padding: '0',
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        color: state.isDisabled
            ? `${theme.colors.textPrimaryDisabled}`
            : state.isSelected
            ? `${theme.colors.white}`
            : `${theme.colors.textPrimary}`,

        backgroundColor: state.isDisabled
            ? `${theme.colors.white}`
            : state.isSelected
            ? `${theme.colors.primary}`
            : state.isFocused
            ? `${theme.colors.activeField}`
            : 'transparent',

        padding: '0.5rem 1rem',
    }),
    input: provided => ({
        ...provided,
        padding: '0',
        margin: '0',
        lineHeight: '1.5rem',
        position: 'relative',
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : 'flex',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: state.isDisabled
            ? '0'
            : state.isMulti & state.hasValue
            ? '.3125rem'
            : '.75rem 1rem',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        color: `${theme.colors.textPrimary}`,
        marginLeft: '0',
        ...(!!state?.data?.suggestionScore ? dot(theme.colors.backgroundAutofill) : {}),
    }),
    multiValue: (provided, state) => {
        return {
            ...provided,
            minHeight: '2rem',
            margin: state.isDisabled ? '3px 3px 0 0' : '3px',
            backgroundColor: `${
                !!state?.data?.suggestionScore
                    ? theme.colors.backgroundAutofill
                    : theme.colors.activeField
            }`,
            textOverflow: 'ellipsis',
            ':hover': state.isDisabled
                ? {}
                : {
                      backgroundColor: `${theme.colors.tagHover}`,
                  },
        }
    },
    multiValueLabel: provided => ({
        ...provided,
        alignSelf: 'center',
        fontSize: '.9375rem',
        lineHeight: '1.5rem',
        color: `${theme.colors.textPrimary}`,
        padding: '.25rem .5rem !important',
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: `${theme.colors.textPrimary}`,
        paddingRight: '.5rem',
        paddingLeft: '0',
        display: state.isDisabled ? 'none' : 'flex',
        ':hover': {
            color: 'inherit',
            backgroundColor: 'inherit',
            cursor: 'pointer',
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled
            ? 'transparent'
            : state.isFocused
            ? `${theme.colors.textPrimary}`
            : `${theme.colors.textSecondary}`,
        fontSize: '.9375rem',
        lineHeight: '.5rem',
        marginLeft: '0',
    }),
    dropdownIndicator: (provided, state) => {
        return {
            ...provided,
            color: state.isDisabled ? `${theme.colors.border}` : `${theme.colors.textPrimary}`,

            ':hover': {
                color: `${theme.colors.textPrimary}`,
            },
        }
    },
}

const components = { MultiValueLabel, Option }
function Select<OptionType extends BaseOptionType>(
    props: SelectProps<OptionType>,
): React.ReactElement {
    const value = React.useMemo(() => {
        const v = props.value
        const labelGetter = props.getOptionLabel
            ? props.getOptionLabel
            : (option: OptionType): string => option.label
        // We don't need onChange handler, because we expect that the Select
        // component will always be controlled from outside e.g. FormikSelect
        return Array.isArray(v) ? orderBy(v as OptionType[], labelGetter) : v
    }, [props.value, props.getOptionLabel])

    return (
        <BaseSelect
            {...OverridenAttrsDefault}
            {...(props as any)}
            value={value}
            components={components}
            styles={customStyles}
        />
    )
}

export default Select

export function AsyncSelect<OptionType extends BaseOptionType>(
    props: SelectProps<OptionType> & AsyncProps<OptionType>,
): React.ReactElement {
    const value = React.useMemo(() => {
        const v = props.value
        const labelGetter = props.getOptionLabel
            ? props.getOptionLabel
            : (option: OptionType): string => option.label
        // We don't need onChange handler, because we expect that the Select
        // component will always be controlled from outside e.g. FormikSelect
        return Array.isArray(v) ? orderBy(v as OptionType[], labelGetter) : v
    }, [props.value, props.getOptionLabel])

    return (
        <BaseAsyncSelect
            {...OverridenAttrsDefault}
            {...(props as any)}
            value={value}
            components={components}
            styles={customStyles}
        />
    )
}

const MemoSelectBase = React.memo(Select) as typeof Select
const MEMO_SELECT_STABLE_DEPS = [
    'components',
    'filterOption',
    'formatGroupLabel',
    'formatOptionLabel',
    'getOptionLabel',
    'getOptionValue',
    'isOptionDisabled',
    'isOptionSelected',
    'loadingMessage',
    'menuPortalTarget',
    'onBlur',
    'onChange',
    'onFocus',
    'onInputChange',
    'onKeyDown',
    'onMenuOpen',
    'onMenuClose',
    'onMenuScrollToTop',
    'onMenuScrollToBottom',
    'openMenuOnFocus',
    'openMenuOnClick',
    'placeholder',
    'theme',
    'defaultValue',
] as const
export const MemoSelect: typeof Select = props => {
    useStableParamsWarning(props, MEMO_SELECT_STABLE_DEPS, 'MemoSelect', 2)
    return <MemoSelectBase {...props} />
}

const MemoAsyncSelectBase = React.memo(AsyncSelect) as typeof AsyncSelect
export const MemoAsyncSelect: typeof AsyncSelect = props => {
    useStableParamsWarning(
        props,
        [...MEMO_SELECT_STABLE_DEPS, 'defaultOptions', 'loadOptions', 'cacheOptions'],
        'MemoAsyncSelect',
        2,
    )
    return <MemoAsyncSelectBase {...props} />
}

/**
 * https://github.com/JedWatson/react-select/issues/3585
 * The way that react-select normalizes values is really dumb.
 *
 * We want an empty multi-value to be an empty array, and not null
 */
export function normalizeReactSelectValue<T>(
    value: ValueType<T>,
    isMulti: boolean,
): T[] | T | null {
    return !value ? (isMulti ? [] : null) : (value as T | T[])
}

/**
 * Attach a title to multi-values
 */
function MultiValueLabel(props: MultiValueProps<any>): React.ReactElement {
    props.innerProps.title = props.data?.reactSelectTitle
        ? props.data.reactSelectTitle
        : typeof props.children === 'string'
        ? props.children
        : undefined

    return <componentsBase.MultiValueLabel {...props} />
}

/**
 * Attach title to Option
 */
export function Option(props: OptionProps<any>): React.ReactElement {
    ;(props.innerProps as any).title = props.data?.reactSelectTitle
        ? props.data.reactSelectTitle
        : typeof props.children === 'string'
        ? props.children
        : undefined

    return <componentsBase.Option {...props} />
}
