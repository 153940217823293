import * as t from 'io-ts'
import { request } from '@genome-web-forms/common/api'
import { MetadataDescription, MetadataDescriptionCodec } from 'model/MetadataDescription'
import { authGWF } from './auth'
import { ResourceRequest } from './fetch/fetchResource'
import config from 'shared/config'
import { queryClient } from 'shared/queryClient'
import { QueryObserver } from 'react-query'
import { is404Error } from '@genome-web-forms/common/error'

export const makeMetadataDescriptionKey = (config: { resourceId: string }): Array<string> => {
    return ['metadata-description', config.resourceId]
}

export const fetchMetadataDescription = async ({
    user,
    resourceId,
    resourceType,
}: ResourceRequest): Promise<MetadataDescription | null> => {
    try {
        return await request(
            MetadataDescriptionCodec,
            authGWF(user, {
                dataProp: 'metadataDescription',
                url: `${config.urlGWF}/creativework/${resourceType}/${encodeURIComponent(
                    resourceId,
                )}/metadata-description`,
            }),
        )
    } catch (e) {
        if (is404Error(e)) {
            return null
        }
        throw e
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createMetadataDescriptionObserver = (config: ResourceRequest) => {
    const queryKey = makeMetadataDescriptionKey(config)
    const queryFn = () => fetchMetadataDescription(config)

    return new QueryObserver(queryClient, {
        queryKey,
        queryFn,
        refetchOnWindowFocus: 'always',
    })
}

export const upsertMetadataDescription = async ({
    user,
    resourceId,
    resourceType,
    metadataDescription,
}: ResourceRequest & {
    metadataDescription: MetadataDescription
}): Promise<MetadataDescription> => {
    let res = await request(
        t.type({ metadataDescription: MetadataDescriptionCodec }),
        authGWF(user, {
            method: 'POST',
            url: `${config.urlGWF}/creativework/${resourceType}/${encodeURIComponent(
                resourceId,
            )}/metadata-description`,
            data: { metadataDescription },
        }),
    )

    return res.metadataDescription
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createMetadataDescriptionMutation = (
    config: ResourceRequest & { metadataDescription: MetadataDescription },
) => {
    const queryKey = makeMetadataDescriptionKey(config)
    const mutationFn = () => upsertMetadataDescription(config)

    return queryClient.getMutationCache().build(queryClient, {
        mutationFn,
        onSuccess: data => {
            queryClient.setQueryData(queryKey, data)
        },
    })
}
