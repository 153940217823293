import { MyIDUser } from '@genome-web-forms/common/auth'
import { is404Error } from '@genome-web-forms/common/error'
import { Workflow, WorkflowHelper } from '@genome-web-forms/server'
import { ResourceType } from 'api/fetch/fetchResource'
import { fetchActiveWorkflowsByResourceId } from 'api/workflow/workflow'
import { WorkflowError } from 'codecs/publish-workflow/parse'
import { getRadarProductID } from 'model/util/getRadarProductID'
import { getData, WIPDataForType, WIPDataType } from 'model/WIP'
import { flags } from 'shared/flags'
import { notificationAction, NotificationEvent, notificationEvent } from 'shared/notification'
import { buildWorkflowErrorNotification } from 'shared/notification/workflowErrorNotification'
import {
    guardPublishResult,
    PublishContext,
    publishMachine,
    PublishProperty,
} from 'shared/resource/publish.machine'
import { PublishErrorManager } from 'shared/resource/PublishErrorManager'
import {
    SyncWIPsContext,
    SyncWIPsEvent,
    SyncWIPsGuard,
    syncWIPsMachine,
} from 'shared/resource/SyncWIPs.machine'
import { createWIPMachine, WIPContext, WIPMachineActor } from 'shared/resource/WIP.machine'
import invariant from 'tiny-invariant'
import {
    actions,
    assign,
    createMachine,
    ExtractEvent,
    forwardTo,
    send,
    sendParent,
    spawn,
    State,
    StateMachine,
} from 'xstate'
import { invariantEvent } from 'xstate-helpers/events'
import { createCommentsMachine } from './comments.machine'
import {
    createWorkflowMachine,
    createWorkflowMachinePoller,
    WorkflowMachineActor,
} from './workflow.machine'

export interface ResourceContext<T> {
    readonly user: MyIDUser
    readonly resourceId: string
    readonly resourceType: ResourceType
    readonly resource: T
    readonly wips: Array<WIPMachineActor<any>>
    readonly workflowRefs: Array<WorkflowMachineActor>
    readonly activeWorkflowRef?: WorkflowMachineActor
    readonly publishErrorManager: PublishErrorManager
}

export type ViewMode = 'editing' | 'readonly' | 'preview'

export type ResourceEvent =
    | { type: 'ERROR'; data: Error }
    | { type: 'START_EDITING'; selection: WIPDataType[]; workflowEditing?: boolean }
    | { type: 'RELEASE'; selection: WIPDataType[] }
    | { type: 'PUBLISH'; publishProperties: PublishProperty[] }
    | { type: 'SET_VIEW_MODE'; viewMode: ViewMode | 'previewBase' | 'previewDiff' }
    | { type: 'METADATA_START_AUTOFILL' }
    | { type: 'METADATA_STOP_AUTOFILL' }
    | { type: 'WORKFLOW_START'; workflow: Workflow }
    | { type: 'WORKFLOW_PUBLISH'; workflow: Workflow }
    | { type: 'WORKFLOW_CONTINUE'; workflow: Workflow }
    | { type: 'WORKFLOW_COMPLETE_ACTIVE_WORKFLOW'; workflow: Workflow }
    | { type: 'WORKFLOW_PAUSE'; workflow: Workflow }
    | { type: 'WORKFLOW_RESUME'; workflow: Workflow }
    | { type: 'WORKFLOW_RELEASE_TASK'; workflow: Workflow }
    | { type: 'WORKFLOW_SELF_ASSIGN'; workflow: Workflow }
    | { type: 'WORKFLOW_REVIEW_MAKE_AVAILABLE'; workflow: Workflow }
    | { type: 'WORKFLOW_REVIEW_APPROVE'; workflow: Workflow }
    | { type: 'WORKFLOW_REVIEW_REJECT'; workflow: Workflow }
    | SyncWIPsEvent
    | NotificationEvent

export type ResourceState<T> = {
    value:
        | { idle: 'readonly' | 'preview' | { preview: 'base' | 'diff' } }
        | 'idle'
        | 'editing'
        | 'releasing'
        | 'aquiringLocks'
        | 'releasingLocks'
        | 'rawPublising'
        | 'resetAfterPublish'
        | 'loading'
        | '404'
    context: ResourceContext<T>
}

interface CreateResourceMachineConfig<T> {
    fetchFn: (ctx: ResourceContext<T>) => Promise<T>
    resourceId: string
    resourceType: ResourceType
    user: MyIDUser
    wips: WIPDataType[]
    handleCriticalError: (ctx: ResourceContext<T>, e: { type: 'ERROR'; data: Error }) => void
    refreshLockedTitles: () => void
    autofill?: Partial<{
        [K in WIPDataType]: {
            fetch: (ctx: WIPContext) => Promise<unknown>
            merge: (current: WIPDataForType[K], autofill: any) => WIPDataForType[K]
        }
    }>
}
export const createResourceMachine = <T extends Record<string, any>>(
    config: CreateResourceMachineConfig<T>,
): StateMachine<ResourceContext<T>, any, ResourceEvent, ResourceState<T>> =>
    createMachine<ResourceContext<T>, ResourceEvent, ResourceState<T>>(
        {
            id: `resource-manager-${config.resourceType}`,
            initial: 'loading',
            context: {
                wips: [],
                workflowRefs: [],
                resource: undefined!,
                resourceId: config.resourceId,
                resourceType: config.resourceType,
                user: config.user,
                publishErrorManager: new PublishErrorManager(),
            },
            on: {
                ERROR: {
                    actions: 'handleCriticalError',
                },
                NOTIFICATION: {
                    actions: notificationAction,
                },
            },
            // @ts-expect-error Remove once the flag is removed
            invoke:
                // eslint-disable-next-line xstate/invoke-usage
                [
                    flags.comments && {
                        id: 'metadata-comments',
                        src: createCommentsMachine(config),
                    },
                ].filter(Boolean),
            states: {
                loading: {
                    type: 'parallel',
                    onDone: {
                        target: 'idle',
                    },
                    states: {
                        'loading-resource': {
                            initial: 'loading',
                            states: {
                                loading: {
                                    invoke: {
                                        id: 'loading-resource',
                                        src: ctx => config.fetchFn(ctx),
                                        onDone: {
                                            target: 'done',
                                            actions: ['assignResourceFromEvent', 'spawnWIPs'],
                                        },
                                        onError: [
                                            {
                                                cond: 'is404Error',
                                                target: '#is404',
                                            },
                                            {
                                                actions: 'handleCriticalError',
                                            },
                                        ],
                                    },
                                },
                                done: { type: 'final' },
                            },
                        },

                        'loading-workflows': {
                            initial: 'loading',
                            states: {
                                loading: {
                                    invoke: {
                                        id: 'loading-workflows',
                                        src: ctx =>
                                            fetchActiveWorkflowsByResourceId(
                                                ctx.user,
                                                ctx.resourceId,
                                            ),
                                        onDone: { target: 'done', actions: 'spawnWorkflows' },
                                        onError: { target: 'done' },
                                    },
                                },
                                done: { type: 'final' },
                            },
                        },
                    },
                },
                '404': { id: 'is404' },
                idle: {
                    id: 'idle',
                    on: {
                        START_EDITING: {
                            cond: 'atLeastOneTypeSelected',
                            target: '#aquiringWIPLocks',
                        },
                        WORKFLOW_START: {
                            target: '#startWorkflow',
                            cond: 'canStartWorkflow',
                            actions: 'assignActiveWorkflowRef',
                        },
                        WORKFLOW_CONTINUE: {
                            target: '#continueWorkflow',
                            cond: 'canContinueWorkflow',
                            actions: 'assignActiveWorkflowRef',
                        },
                        WORKFLOW_RESUME: {
                            target: '#resumeWorkflow',
                            cond: 'canResumeWorkflow',
                            actions: 'assignActiveWorkflowRef',
                        },
                        WORKFLOW_SELF_ASSIGN: {
                            target: '#selfAssignWorkflow',
                            cond: 'canSelfAssignWorkflow',
                        },
                        WORKFLOW_REVIEW_APPROVE: {
                            target: '#approveWorkflow',
                            cond: 'canApproveWorkflow',
                        },
                        WORKFLOW_REVIEW_REJECT: {
                            target: '#rejectWorkflow',
                            cond: 'canRejectWorkflow',
                        },
                        SET_VIEW_MODE: [
                            { target: '.readonly', cond: condViewMode('readonly') },
                            { target: '.preview', cond: condViewMode('preview') },
                            { target: '.preview.base', cond: condViewMode('previewBase') },
                            { target: '.preview.diff', cond: condViewMode('previewDiff') },
                        ],
                    },
                    initial: 'readonly',
                    states: {
                        readonly: {},
                        preview: { initial: 'base', states: { base: {}, diff: {} } },
                    },
                },
                aquiringLocks: {
                    on: {
                        START_EDITING: {
                            cond: 'atLeastOneTypeSelected',
                            target: '#aquiringWIPLocks',
                        },
                    },
                    states: {
                        startWorkflow: {
                            id: 'startWorkflow',
                            invoke: {
                                id: 'workflowStarter',
                                src: createWorkflowMachinePoller({ type: 'START' }),
                                data: {
                                    workflowRef: (
                                        ctx: ResourceContext<unknown>,
                                        e: ResourceEvent,
                                    ) => {
                                        invariantEvent(e, ['WORKFLOW_START', 'WORKFLOW_CONTINUE'])
                                        const { workflowId } = e.workflow
                                        const workflowRef = ctx.workflowRefs.find(ref => {
                                            const workflow = ref.getSnapshot()!.context.workflow
                                            return workflow.workflowId === workflowId
                                        })
                                        invariant(workflowRef)
                                        return workflowRef
                                    },
                                },
                                onDone: [
                                    {
                                        cond: (_, e) => e.data.success,
                                        actions: send<
                                            ResourceContext<any>,
                                            any,
                                            ExtractEvent<ResourceEvent, 'START_EDITING'>
                                        >((_, e) => ({
                                            type: 'START_EDITING',
                                            selection: WorkflowHelper.lockDataTypes(
                                                e.data.workflow,
                                            ),
                                            workflowEditing: true,
                                        })),
                                    },
                                    {
                                        // TODO error handling
                                        cond: (_, e) => !e.data.success,
                                        target: '#idle',
                                    },
                                ],
                            },
                        },
                        resumeWorkflow: {
                            id: 'resumeWorkflow',
                            invoke: {
                                id: 'workflowResumer',
                                src: createWorkflowMachinePoller({ type: 'RESUME' }),
                                data: {
                                    workflowRef: (
                                        ctx: ResourceContext<unknown>,
                                        e: ResourceEvent,
                                    ) => {
                                        invariantEvent(e, 'WORKFLOW_RESUME')
                                        const { workflowId } = e.workflow
                                        const workflowRef = ctx.workflowRefs.find(ref => {
                                            const workflow = ref.getSnapshot()!.context.workflow
                                            return workflow.workflowId === workflowId
                                        })
                                        invariant(workflowRef)
                                        return workflowRef
                                    },
                                },
                                onDone: [
                                    {
                                        cond: (_, e) => e.data.success,
                                        actions: send<
                                            ResourceContext<any>,
                                            any,
                                            ExtractEvent<ResourceEvent, 'START_EDITING'>
                                        >((_, e) => ({
                                            type: 'START_EDITING',
                                            selection: WorkflowHelper.lockDataTypes(
                                                e.data.workflow,
                                            ),
                                            workflowEditing: true,
                                        })),
                                    },
                                    {
                                        // TODO error handling
                                        cond: (_, e) => !e.data.success,
                                        target: '#idle',
                                    },
                                ],
                            },
                        },
                        continueWorkflow: {
                            id: 'continueWorkflow',
                            entry: send<
                                ResourceContext<any>,
                                any,
                                ExtractEvent<ResourceEvent, 'START_EDITING'>
                            >(ctx => ({
                                type: 'START_EDITING',
                                selection: WorkflowHelper.lockDataTypes(
                                    ctx.activeWorkflowRef!.getSnapshot()!.context.workflow,
                                ),
                                workflowEditing: true,
                            })),
                        },
                        aquiringWIPLocks: {
                            id: 'aquiringWIPLocks',
                            on: { WIP_SYNC_STATUS: { actions: forwardTo('sync-wips-machine') } },
                            invoke: {
                                id: 'sync-wips-machine',
                                src: multiLockAquireMachine,
                                data: (ctx, e): SyncWIPsContext => {
                                    let selection: WIPDataType[] = []
                                    if (e.type === 'START_EDITING') {
                                        selection = e.selection
                                    } else if (Array.isArray((e as any).data?.selection)) {
                                        selection = (e as any).data.selection
                                    } else {
                                        throw new Error(
                                            `Invariant, event given had no selection:\n${JSON.stringify(
                                                e,
                                                null,
                                                2,
                                            )}`,
                                        )
                                    }
                                    const wipRefs: WIPMachineActor<any>[] = selection.map(
                                        dataType => {
                                            const ref = ctx.wips.find(
                                                wip =>
                                                    wip.getSnapshot()!.context.dataType ===
                                                    dataType,
                                            )
                                            invariant(ref, `No WIP ref matching ${dataType}`)
                                            return ref
                                        },
                                    )
                                    return {
                                        wipRefs: wipRefs.map(ref => ({ status: 'pending', ref })),
                                    }
                                },
                                onDone: [
                                    {
                                        // if multi-lock finished with "true" for success
                                        cond: 'syncWipsSuccess',
                                        target: '#editing',
                                    },
                                    {
                                        target: '#idle',
                                        actions: ['notificationCouldNotAquireLocks'],
                                    },
                                ],
                            },
                        },
                    },
                },
                editing: {
                    id: 'editing',
                    initial: 'initial',
                    states: {
                        initial: {
                            always: [
                                {
                                    cond: 'hasActiveWorkflowRef',
                                    target: 'workflowEditing',
                                },
                                { target: 'rawEditing' },
                            ],
                        },
                        rawEditing: {
                            on: {
                                PUBLISH: '#publishing',
                                RELEASE: {
                                    cond: 'atLeastOneTypeSelected',
                                    target: '#releasing',
                                },
                            },
                        },
                        workflowEditing: {
                            initial: 'initial',
                            states: {
                                initial: {
                                    always: [
                                        {
                                            cond: 'isActiveWorkflowQA',
                                            target: 'qa',
                                        },
                                        {
                                            target: 'tagging',
                                        },
                                    ],
                                },
                                tagging: {
                                    on: {
                                        WORKFLOW_RELEASE_TASK: {
                                            cond: 'isActiveWorkflow',
                                            target: '#releasingTaskWorkflow',
                                        },
                                    },
                                },
                                qa: {
                                    on: {
                                        WORKFLOW_PUBLISH: {
                                            cond: 'canPublishWorkflow',
                                            target: '#publishing',
                                        },
                                        WORKFLOW_REVIEW_MAKE_AVAILABLE: {
                                            cond: 'canStartReviewWorkflow',
                                            target: '#startReviewWorkflow',
                                        },
                                    },
                                },
                            },
                            on: {
                                WORKFLOW_COMPLETE_ACTIVE_WORKFLOW: {
                                    cond: 'canCompleteActiveWorfklow',
                                    target: '#completingActiveWorkflow',
                                },
                                WORKFLOW_PAUSE: {
                                    cond: 'isActiveWorkflow',
                                    target: '#pausingWorkflow',
                                },
                                WORKFLOW_RELEASE_TASK: {
                                    cond: 'isActiveWorkflow',
                                    target: '#releasingTaskWorkflow',
                                },
                            },
                        },
                    },
                },
                pausingWorkflow: {
                    id: 'pausingWorkflow',
                    on: {
                        RELEASE: {
                            cond: 'atLeastOneTypeSelected',
                            target: 'releasing',
                        },
                    },
                    invoke: {
                        src: createWorkflowMachinePoller({ type: 'PAUSE' }),
                        data: {
                            workflowRef: ({ activeWorkflowRef }: ResourceContext<unknown>) => {
                                invariant(activeWorkflowRef)
                                return activeWorkflowRef
                            },
                        },
                        onDone: [
                            {
                                cond: (_, e) => e.data.success,
                                actions: send<
                                    ResourceContext<any>,
                                    any,
                                    ExtractEvent<ResourceEvent, 'RELEASE'>
                                >((_, e) => ({
                                    type: 'RELEASE',
                                    selection: WorkflowHelper.lockDataTypes(e.data.workflow),
                                    workflowEditing: true,
                                })),
                            },
                            {
                                // TODO error handling
                                cond: (_, e) => !e.data.success,
                                target: '#idle',
                            },
                        ],
                    },
                },
                selfAssignWorkflow: {
                    id: 'selfAssignWorkflow',
                    invoke: {
                        src: createWorkflowMachinePoller({ type: 'SELF_ASSIGN' }),
                        data: {
                            workflowRef: (
                                { workflowRefs }: ResourceContext<unknown>,
                                { workflow }: ExtractEvent<ResourceEvent, 'WORKFLOW_SELF_ASSIGN'>,
                            ) => {
                                const activeWorkflowRef = workflowRefs.find(ref =>
                                    WorkflowHelper.equals(
                                        ref.getSnapshot()!.context.workflow,
                                        workflow,
                                    ),
                                )
                                invariant(activeWorkflowRef)
                                return activeWorkflowRef
                            },
                        },
                        onDone: [
                            {
                                cond: (_, e) => e.data.success,
                                target: '#idle',
                            },
                            {
                                // TODO error handling
                                cond: (_, e) => !e.data.success,
                                target: '#idle',
                            },
                        ],
                    },
                },
                releasingTaskWorkflow: {
                    id: 'releasingTaskWorkflow',
                    on: {
                        RELEASE: {
                            cond: 'atLeastOneTypeSelected',
                            target: 'releasing',
                        },
                    },
                    invoke: {
                        src: createWorkflowMachinePoller({ type: 'RELEASE_TASK' }),
                        data: {
                            workflowRef: ({ activeWorkflowRef }: ResourceContext<unknown>) => {
                                invariant(activeWorkflowRef)
                                return activeWorkflowRef
                            },
                        },
                        onDone: [
                            {
                                cond: (_, e) => e.data.success,
                                actions: send<
                                    ResourceContext<any>,
                                    any,
                                    ExtractEvent<ResourceEvent, 'RELEASE'>
                                >((_, e) => ({
                                    type: 'RELEASE',
                                    selection: WorkflowHelper.lockDataTypes(e.data.workflow),
                                    workflowEditing: true,
                                })),
                            },
                            {
                                // TODO error handling
                                cond: (_, e) => !e.data.success,
                                target: '#idle',
                            },
                        ],
                    },
                },
                completingActiveWorkflow: {
                    id: 'completingActiveWorkflow',
                    on: {
                        RELEASE: {
                            cond: 'atLeastOneTypeSelected',
                            target: 'releasing',
                        },
                    },
                    invoke: {
                        src: createWorkflowMachinePoller({ type: 'TAGGING_COMPLETE' }),
                        data: {
                            workflowRef: ({ activeWorkflowRef }: ResourceContext<unknown>) => {
                                invariant(activeWorkflowRef)
                                return activeWorkflowRef
                            },
                        },
                        onDone: [
                            {
                                cond: (_, e) => e.data.success,
                                actions: send<
                                    ResourceContext<any>,
                                    any,
                                    ExtractEvent<ResourceEvent, 'RELEASE'>
                                >((_, e) => ({
                                    type: 'RELEASE',
                                    selection: WorkflowHelper.lockDataTypes(e.data.workflow),
                                    workflowEditing: true,
                                })),
                            },
                            {
                                // TODO error handling
                                cond: (_, e) => !e.data.success,
                                target: '#idle',
                            },
                        ],
                    },
                },
                publishing: {
                    id: 'publishing',
                    invoke: {
                        id: 'publish',
                        src: publishMachine,
                        data: buildPublishContext,
                        onDone: [
                            {
                                cond: 'publishLockError',
                                target: 'editing',
                                actions: 'notificationPublishErrorLocked',
                            },
                            {
                                cond: 'publishError',
                                target: 'editing',
                                actions: [
                                    'setPublishErrors',
                                    'handlePartialPublish',
                                    'notificationPublishError',
                                ],
                            },
                            {
                                cond: 'publishSuccess',
                                target: 'resetAfterPublish',
                                actions: 'resetPublishErrors',
                            },
                        ],
                        onError: { actions: 'handleCriticalError' },
                    },
                },
                releasing: {
                    id: 'releasing',
                    type: 'parallel',
                    entry: 'resetPublishErrors',
                    states: {
                        releasingLocks: {
                            initial: 'execute',
                            states: {
                                execute: {
                                    on: {
                                        WIP_SYNC_STATUS: {
                                            actions: forwardTo('sync-wips-machine'),
                                        },
                                    },
                                    invoke: {
                                        id: 'sync-wips-machine',
                                        src: multiLockReleaseMachine,
                                        data: (ctx, e): SyncWIPsContext => {
                                            // if we're not dealing with a manual user release
                                            if (e.type !== 'RELEASE') {
                                                // release all refs in editing state
                                                return {
                                                    wipRefs: ctx.wips
                                                        .filter(ref =>
                                                            ref.getSnapshot()!.matches('editing'),
                                                        )
                                                        .map(ref => ({
                                                            status: 'pending',
                                                            ref,
                                                        })),
                                                }
                                            }
                                            // otherwise release only the ones selected by the user
                                            const wipRefs: WIPMachineActor<any>[] = e.selection.map(
                                                dataType => {
                                                    const ref = ctx.wips.find(
                                                        wip =>
                                                            wip.getSnapshot()!.context.dataType ===
                                                            dataType,
                                                    )
                                                    invariant(
                                                        ref,
                                                        `No WIP ref matching ${dataType}`,
                                                    )
                                                    return ref
                                                },
                                            )
                                            return {
                                                wipRefs: wipRefs.map(ref => ({
                                                    status: 'pending',
                                                    ref,
                                                })),
                                            }
                                        },
                                        onDone: [
                                            {
                                                // if multi-lock release finished with "true" for success
                                                cond: 'syncWipsSuccess',
                                                target: 'done',
                                            },
                                            {
                                                actions: send({
                                                    type: 'ERROR',
                                                    data: new Error('Could not release all locks'),
                                                }),
                                            },
                                        ],
                                        onError: { actions: 'handleCriticalError' },
                                    },
                                },
                                done: { type: 'final' },
                            },
                        },
                        refreshingReadonlyData: {
                            initial: 'execute',
                            states: {
                                execute: {
                                    invoke: {
                                        id: 'refreshing-readonly-data',
                                        src: ctx => config.fetchFn(ctx),
                                        onDone: {
                                            target: 'done',
                                            actions: [
                                                'assignResourceFromEvent',
                                                'updateWIPsReadonlyData',
                                            ],
                                        },
                                        onError: { actions: 'handleCriticalError' },
                                    },
                                },
                                done: { type: 'final' },
                            },
                        },
                    },
                    onDone: [
                        { cond: 'hasWIPsInEditMode', target: 'editing' },
                        { target: 'idle', actions: 'removeActiveWorkflowRef' },
                    ],
                },
                resetAfterPublish: {
                    id: 'resetAfterPublish',
                    initial: 'refreshingReadonlyData',
                    entry: 'stopExistingWIPs',
                    exit: ['spawnWIPs', 'notificationPublishSuccess'],
                    states: {
                        refreshingReadonlyData: {
                            invoke: {
                                id: 'refreshing-readonly-data',
                                src: ctx => {
                                    config.refreshLockedTitles()
                                    return config.fetchFn(ctx)
                                },
                                onDone: {
                                    target: 'done',
                                    actions: 'assignResourceFromEvent',
                                },
                                onError: { actions: 'handleCriticalError' },
                            },
                        },
                        done: { type: 'final' },
                    },
                    onDone: 'idle',
                },
                startReviewWorkflow: {
                    id: 'startReviewWorkflow',
                    invoke: {
                        src: createWorkflowMachinePoller({ type: 'REVIEW_MAKE_AVAILABLE' }),
                        data: {
                            workflowRef: ({ activeWorkflowRef }: ResourceContext<unknown>) => {
                                invariant(activeWorkflowRef)
                                return activeWorkflowRef
                            },
                        },
                        onDone: [
                            {
                                cond: (_, e) => e.data.success,
                                target: '#idle',
                            },
                            {
                                // TODO error handling
                                cond: (_, e) => !e.data.success,
                                target: '#idle',
                            },
                        ],
                    },
                },
                approveWorkflow: {
                    id: 'approveWorkflow',
                    invoke: {
                        src: createWorkflowMachinePoller({ type: 'REVIEW_APPROVE' }),
                        data: {
                            workflowRef: (
                                { workflowRefs }: ResourceContext<unknown>,
                                {
                                    workflow,
                                }: ExtractEvent<ResourceEvent, 'WORKFLOW_REVIEW_APPROVE'>,
                            ) => {
                                const activeWorkflowRef = workflowRefs.find(ref =>
                                    WorkflowHelper.equals(
                                        ref.getSnapshot()!.context.workflow,
                                        workflow,
                                    ),
                                )
                                invariant(activeWorkflowRef)
                                return activeWorkflowRef
                            },
                        },

                        onDone: [
                            {
                                cond: (_, e) => e.data.success,
                                target: '#idle',
                            },
                            {
                                // TODO error handling
                                cond: (_, e) => !e.data.success,
                                target: '#idle',
                            },
                        ],
                    },
                },
                rejectWorkflow: {
                    id: 'rejectWorkflow',
                    invoke: {
                        src: createWorkflowMachinePoller({ type: 'REVIEW_REJECT' }),
                        data: {
                            workflowRef: (
                                { workflowRefs }: ResourceContext<unknown>,
                                { workflow }: ExtractEvent<ResourceEvent, 'WORKFLOW_REVIEW_REJECT'>,
                            ) => {
                                const activeWorkflowRef = workflowRefs.find(ref =>
                                    WorkflowHelper.equals(
                                        ref.getSnapshot()!.context.workflow,
                                        workflow,
                                    ),
                                )
                                invariant(activeWorkflowRef)
                                ;(activeWorkflowRef as any).machine._context.workflow.comment =
                                    workflow.comment
                                return activeWorkflowRef
                            },
                        },

                        onDone: [
                            {
                                cond: (_, e) => e.data.success,
                                target: '#idle',
                            },
                            {
                                // TODO error handling
                                cond: (_, e) => !e.data.success,
                                target: '#idle',
                            },
                        ],
                    },
                },
            },
        },
        {
            actions: {
                handleCriticalError: config.handleCriticalError as any,
                stopExistingWIPs: assign({
                    wips: ctx =>
                        ctx.wips.map(ref => {
                            ref.stop?.()
                            return ref
                        }),
                }),
                assignActiveWorkflowRef: assign((ctx, e) => {
                    invariantEvent(e, ['WORKFLOW_START', 'WORKFLOW_CONTINUE', 'WORKFLOW_RESUME'])
                    const { workflow } = e
                    const activeWorkflowRef = ctx.workflowRefs.find(
                        ref => ref.getSnapshot()!.context.workflow === workflow,
                    )
                    invariant(
                        activeWorkflowRef,
                        'Cannot assign active workflow, no matched workflow',
                    )
                    return { activeWorkflowRef }
                }),
                removeActiveWorkflowRef: assign({ activeWorkflowRef: () => undefined }),
                spawnWIPs: assign({
                    wips: ctx => config.wips.map(dataType => spawnWIP(dataType, ctx, config)),
                }),
                spawnWorkflows: assign({
                    workflowRefs: (ctx, e: any) => {
                        const workflows: Workflow[] = e.data
                        return workflows.map(workflow =>
                            spawn(createWorkflowMachine(ctx.user, workflow), {
                                sync: true,
                            }),
                        )
                    },
                }),
                assignResourceFromEvent: assign({ resource: (_, e: any) => e.data }),
                resetPublishErrors: assign({ publishErrorManager: _ => new PublishErrorManager() }),
                setPublishErrors: assign({
                    publishErrorManager: (_, e: any) => new PublishErrorManager(e.data.result),
                }),
                updateWIPsReadonlyData: actions.pure(ctx =>
                    ctx.wips.map(ref => {
                        const dataType = ref.getSnapshot()!.context.dataType
                        return send(
                            {
                                type: 'UPDATE_READONLY',
                                readonlyData: ctx.resource[dataType],
                            },
                            { to: () => ref },
                        )
                    }),
                ),

                handlePartialPublish: assign((ctx, e: any) => {
                    const resource = ctx.resource
                    const error: WorkflowError = e.data.result
                    const successComponents = error.success.map(s => s.component)
                    const wips = ctx.wips.map(ref => {
                        const dataType = ref.getSnapshot()!.context.dataType
                        if (!successComponents.includes(dataType)) {
                            return ref
                        }

                        // WARNING, IMPERATIVE CODE!

                        // This sets the resource machine's resource data
                        // to what was published by the WIP machine
                        const wip = ref.getSnapshot()!.context.wip
                        invariant(wip)
                        ;(resource as any)[dataType] = getData(wip)
                        // This stops current WIP machine
                        ref.stop?.()

                        return spawnWIP(dataType, ctx, config)
                    })
                    return { wips, resource }
                }),

                notificationCouldNotAquireLocks: send(
                    notificationEvent({
                        type: 'warning',
                        content: 'Could not aquire locks',
                    }),
                ),
                notificationPublishErrorLocked: send((_, e: any) => {
                    const { lockedByFirstName, lockedByLastName } =
                        e.data.result.workflowErrorLockedResponse
                    return notificationEvent({
                        type: 'error',
                        content: `Cannot publish, record is locked by ${lockedByFirstName} ${lockedByLastName}`,
                    })
                }),
                notificationPublishError: send((_, e: any) =>
                    notificationEvent(buildWorkflowErrorNotification(e.data.result)),
                ),
                notificationPublishSuccess: send(
                    notificationEvent({
                        type: 'success',
                        content: 'Publish complete',
                    }),
                ),
            },
            guards: {
                atLeastOneTypeSelected: (_, e) => {
                    invariant(e.type === 'START_EDITING' || e.type === 'RELEASE')
                    return e.selection.length > 0
                },
                hasWIPsInEditMode: ctx =>
                    ctx.wips.some(ref => ref.getSnapshot()!.matches('editing')),
                publishSuccess: guardPublishResult('success') as any,
                publishError: guardPublishResult('error') as any,
                publishLockError: guardPublishResult('lockError') as any,
                canStartWorkflow: ({ user }, e) => {
                    invariantEvent(e, 'WORKFLOW_START')
                    const { workflow } = e

                    return WorkflowHelper.can(workflow, { type: 'START', user })
                },
                canResumeWorkflow: ({ user }, e) => {
                    invariantEvent(e, 'WORKFLOW_RESUME')
                    const { workflow } = e

                    return WorkflowHelper.can(workflow, { type: 'RESUME', user })
                },
                canContinueWorkflow: ({ user }, e) => {
                    invariantEvent(e, 'WORKFLOW_CONTINUE')
                    const { workflow } = e

                    return WorkflowHelper.can(workflow, { type: 'CONTINUE_STARTED', user })
                },
                canSelfAssignWorkflow: ({ user }, e) => {
                    invariantEvent(e, 'WORKFLOW_SELF_ASSIGN')
                    const { workflow } = e

                    return WorkflowHelper.can(workflow, { type: 'SELF_ASSIGN', user })
                },
                canStartReviewWorkflow: ({ user, activeWorkflowRef }, e) => {
                    invariantEvent(e, 'WORKFLOW_REVIEW_MAKE_AVAILABLE')
                    invariant(activeWorkflowRef)

                    const { workflow } = e

                    return (
                        WorkflowHelper.equals(
                            workflow,
                            activeWorkflowRef.getSnapshot()!.context.workflow,
                        ) &&
                        WorkflowHelper.can(workflow, {
                            type: 'REVIEW_MAKE_AVAILABLE',
                            user,
                        })
                    )
                },
                canApproveWorkflow: ({ user }, e) => {
                    invariantEvent(e, 'WORKFLOW_REVIEW_APPROVE')

                    const { workflow } = e

                    return WorkflowHelper.can(workflow, {
                        type: 'REVIEW_APPROVE',
                        user,
                    })
                },
                canRejectWorkflow: ({ user }, e) => {
                    invariantEvent(e, 'WORKFLOW_REVIEW_REJECT')

                    const { workflow } = e

                    return WorkflowHelper.can(workflow, {
                        type: 'REVIEW_REJECT',
                        user,
                    })
                },
                canPublishWorkflow: ({ user, activeWorkflowRef }, e) => {
                    invariantEvent(e, 'WORKFLOW_PUBLISH')
                    invariant(activeWorkflowRef)
                    const { workflow } = e

                    return (
                        WorkflowHelper.equals(
                            workflow,
                            activeWorkflowRef.getSnapshot()!.context.workflow,
                        ) &&
                        WorkflowHelper.can(workflow, {
                            type: 'PUBLISH_START',
                            user,
                            executionArn: '__DUMMY_VALUE_FOR_EVENT_CHECK',
                        })
                    )
                },
                canCompleteActiveWorfklow: ({ user, activeWorkflowRef }, e) => {
                    invariantEvent(e, 'WORKFLOW_COMPLETE_ACTIVE_WORKFLOW')
                    invariant(activeWorkflowRef)

                    const { workflow } = e
                    const activeWorkflow = activeWorkflowRef.getSnapshot()!.context.workflow

                    return (
                        WorkflowHelper.equals(workflow, activeWorkflow) &&
                        WorkflowHelper.can(workflow, { type: 'TAGGING_COMPLETE', user })
                    )
                },
                hasActiveWorkflowRef: ctx => !!ctx.activeWorkflowRef,
                isActiveWorkflow: ({ activeWorkflowRef }, e) => {
                    invariantEvent(e, ['WORKFLOW_PAUSE', 'WORKFLOW_RELEASE_TASK'])
                    if (!activeWorkflowRef) return false

                    const { workflow } = e
                    const activeWorkflow = activeWorkflowRef.getSnapshot()!.context.workflow
                    return WorkflowHelper.equals(workflow, activeWorkflow)
                },
                isActiveWorkflowQA: ({ activeWorkflowRef }) => {
                    if (!activeWorkflowRef) return false

                    const workflow = activeWorkflowRef.getSnapshot()!.context.workflow
                    return WorkflowHelper.isInQA(workflow)
                },
                syncWipsSuccess: (_, e: any) => e.data,
                is404Error: (_, e: any) => is404Error(e.data),
            },
        },
    )

const condViewMode = (
    viewMode: ViewMode | 'previewBase' | 'previewDiff',
): ((c: ResourceContext<any>, e: ResourceEvent) => boolean) => {
    const functionName = `view_mode_is_${viewMode}`
    const tmp = {
        [functionName]: (_: ResourceContext<any>, e: ResourceEvent) => {
            invariant(e.type === 'SET_VIEW_MODE')
            return e.viewMode === viewMode
        },
    }

    return tmp[functionName]
}

const spawnWIP = <K extends WIPDataType>(
    dataType: K,
    ctx: ResourceContext<any>,
    config: CreateResourceMachineConfig<any>,
): WIPMachineActor<WIPDataForType[K]> =>
    // eslint-disable-next-line  xstate/spawn-usage
    spawn(
        createWIPMachine<WIPDataForType[K]>({
            ...ctx,
            dataType,
            readonlyData: ctx.resource[dataType],
            refreshLockedTitles: config.refreshLockedTitles,
            autofill: config.autofill?.[dataType] as any,
            resourceRadarId: getRadarProductID(ctx.resource),
        }),
        { sync: true, name: dataType },
    ) as WIPMachineActor<WIPDataForType[K]>

export const isUpdatingWIP = (state: State<ResourceContext<any>, ResourceEvent>): boolean => {
    return state.context.wips.some(ref => ref.getSnapshot()!.matches({ editing: 'updating' }))
}

// multi-lock aquire machine
const allMachinesInReading: SyncWIPsGuard = ctx =>
    ctx.wipRefs.every(wipRef => wipRef.ref.getSnapshot()!.matches('reading'))
const allMachinesAquired: SyncWIPsGuard = ctx =>
    ctx.wipRefs.every(wipRef => wipRef.status === 'aquired')
const multiLockAquireMachine = syncWIPsMachine.withConfig({
    guards: {
        cleanupComplete: ctx =>
            ctx.wipRefs.every(wipRef => ['failed', 'released'].includes(wipRef.status)),
        allInStartCondition: allMachinesInReading,
        allInTargetCondition: allMachinesAquired,
    },
    actions: {
        sendSyncedSignal: actions.pure(ctx =>
            ctx.wipRefs.map(wipRef => send('ATTEMPT_AQUIRE', { to: () => wipRef.ref })),
        ),
        handleFailedMachines: actions.pure(ctx =>
            ctx.wipRefs
                .filter(wipRef => wipRef.status === 'aquired')
                .map(wipRef => send('RELEASE', { to: () => wipRef.ref })),
        ),
    },
})

const buildPublishContext = (ctx: ResourceContext<any>, e: ResourceEvent): PublishContext => {
    if (e.type === 'PUBLISH') {
        return { ...ctx, publishProperties: e.publishProperties }
    }
    if (e.type === 'WORKFLOW_PUBLISH') {
        invariant(ctx.activeWorkflowRef)
        return {
            ...ctx,
            workflowRef: ctx.activeWorkflowRef,
            publishProperties: WorkflowHelper.lockDataTypes(e.workflow),
        }
    }
    throw new Error(`Invariant unsupported event type: ${e.type}`)
}

// multi-lock release machine
const allMachinesInEditing: SyncWIPsGuard = ctx =>
    ctx.wipRefs.every(wipRef => wipRef.ref.getSnapshot()!.matches('editing'))
const allMachinesReleased: SyncWIPsGuard = ctx =>
    ctx.wipRefs.every(wipRef => wipRef.status === 'released')
const multiLockReleaseMachine = syncWIPsMachine.withConfig({
    guards: {
        cleanupComplete: () => false,
        allInStartCondition: allMachinesInEditing,
        allInTargetCondition: allMachinesReleased,
    },
    actions: {
        sendSyncedSignal: actions.pure(ctx =>
            ctx.wipRefs.map(wipRef => send('RELEASE', { to: () => wipRef.ref })),
        ),
        handleFailedMachines: sendParent(() => ({
            type: 'ERROR',
            data: new Error('Cannot release locks!'),
        })),
    },
})
