import React from 'react'

import * as s from 'shared/diff/schema'
import Debug from 'debug'
const debug = Debug('diff')

const EMPTY_DIFF = new s.SchemaDiff({})
export function useDiff<T, D, S extends s.SchemaC<any> | s.ArraySchema<any>>(
    schema: S,
    toDiffable: (r: T) => D,
    resA: T,
    resB: T,
): D extends any[] ? s.ArrayDiff<D[number]> : s.SchemaDiff<D> {
    return React.useMemo(() => {
        if (!resA || !resB) {
            return EMPTY_DIFF
        }
        let a: D = toDiffable(resA)
        let b: D = toDiffable(resB)
        const diff = schema.diff(a, b)
        debug({ a, b, diff })
        return diff as any
    }, [resA, resB, schema, toDiffable])
}
