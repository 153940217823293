import * as r from 'fp-ts-routing'
import * as t from 'io-ts'

// types
import { Omit } from 'utility-types'

/**
 * List of route locations
 */
export interface Home {
    readonly _tag: 'Home'
}

export interface AdminCreateWorkflowAssignment {
    readonly _tag: 'AdminCreateWorkflowAssignment'
}
export interface AdminAssignmentsList {
    readonly _tag: 'AdminAssignmentsList'
}
export interface AdminCreateWorkflowReport {
    readonly _tag: 'AdminCreateWorkflowReport'
}

export interface Login {
    readonly _tag: 'Login'
    readonly returnTo?: string
    readonly forceRelogin?: 'yes'
}

export interface Search {
    readonly _tag: 'Search'
    readonly q?: string
    readonly type?: string
}

export interface Feature {
    readonly _tag: 'Feature'
    readonly featureId: string
    readonly section?: string
}

export interface Series {
    readonly _tag: 'Series'
    readonly seriesId: string
    readonly section?: string
}

export interface Episode {
    readonly _tag: 'Episode'
    readonly episodeId: string
    readonly section?: string
}

export interface Season {
    readonly _tag: 'Season'
    readonly seasonId: string
    readonly section?: string
}

export interface LockedTitles {
    readonly _tag: 'LockedTitles'
}

export interface Workflow {
    readonly _tag: 'Workflow'
    readonly workflowId: number
}

export interface NotFound {
    readonly _tag: 'NotFound'
}

export type Location =
    | Home
    | AdminCreateWorkflowAssignment
    | AdminAssignmentsList
    | AdminCreateWorkflowReport
    | Login
    | Search
    | Feature
    | Series
    | Episode
    | Season
    | LockedTitles
    | Workflow
    | NotFound

const OptionalStringCodec = t.union([t.string, t.undefined])
const sectionQuery = r.query(t.strict({ section: OptionalStringCodec }))
/**
 * Location matchers
 */
/* / (root) */
const homeMatch = r.end
/* /admin/workflow/create-assignments  */
const adminCreateWorkflowAssignmentMatch = r
    .lit('admin')
    .then(r.lit('workflow'))
    .then(r.lit('create-assignments'))
    .then(r.end)

/* /admin/workflow-assignment/list  */
const adminListWorkflowAssignmentMatch = r
    .lit('admin')
    .then(r.lit('workflow'))
    .then(r.lit('list'))
    .then(r.end)

/* /workflow-assignment/list  */
const lististWorkflowAssignmentMatch = r.lit('workflow').then(r.lit('list-assignments')).then(r.end)

/* /workflow-assignment/list  */
const adminCreateWorkflowReportMatch = r
    .lit('admin')
    .then(r.lit('create-workflow-report'))
    .then(r.end)

/** /login */
const loginMatch = r
    .lit('login')
    .then(
        r.query(
            t.strict({
                returnTo: OptionalStringCodec,
                forceRelogin: t.union([t.literal('yes'), t.undefined]),
            }),
        ),
    )
    .then(r.end)
/** /login */
/** /search?q=search  */
const searchMatch = r
    .lit('search')
    // ?q=search&type=feature or q and t omitted
    .then(r.query(t.partial({ q: t.string, type: t.string })))
    .then(r.end)
/* /feature/:featureId?section=Metadata|... */
const featureMatch = r.lit('feature').then(r.str('featureId')).then(sectionQuery).then(r.end)
/* /series/:seriesId?section=Metadata|... */
const seriesMatch = r.lit('series').then(r.str('seriesId')).then(sectionQuery).then(r.end)
/* /episode/:episodeId?section=Metadata|... */
const episodeMatch = r.lit('episode').then(r.str('episodeId')).then(sectionQuery).then(r.end)
/* /season/:seasonId */
const seasonMatch = r.lit('season').then(r.str('seasonId')).then(sectionQuery).then(r.end)
/* /workflow/:workflowId */
const workflowMatch = r.lit('workflow').then(r.int('workflowId')).then(r.end)
const lockedTitlesMatch = r.lit('locked-titles').then(r.end)

/**
 * Location generators
 */
export const homeLocation = (): Location => ({ _tag: 'Home' })

export const adminCreateWorkflowAssignmentLocation = (): Location => ({
    _tag: 'AdminCreateWorkflowAssignment',
})
export const adminListWorkflowAssignmentLocation = (): Location => ({
    _tag: 'AdminAssignmentsList',
})

export const adminCreateWorkflowReportLocation = (): Location => ({
    _tag: 'AdminCreateWorkflowReport',
})

export const lockedTitlesLocation = (): Location => ({ _tag: 'LockedTitles' })

export const searchLocation = ({ q, type }: Omit<Search, '_tag'>): Location => ({
    _tag: 'Search',
    q,
    type,
})

const loginLocation = ({ returnTo, forceRelogin }: Omit<Login, '_tag'>): Location => ({
    _tag: 'Login',
    returnTo,
    forceRelogin,
})

export const featureLocation = ({ featureId, section }: Omit<Feature, '_tag'>): Location => ({
    _tag: 'Feature',
    featureId,
    section,
})

export const seriesLocation = ({ seriesId, section }: Omit<Series, '_tag'>): Location => ({
    _tag: 'Series',
    seriesId,
    section,
})

export const episodeLocation = ({ episodeId, section }: Omit<Episode, '_tag'>): Location => ({
    _tag: 'Episode',
    episodeId,
    section,
})

export const seasonLocation = ({ seasonId, section }: Omit<Season, '_tag'>): Location => ({
    _tag: 'Season',
    seasonId,
    section,
})

export const workflowLocation = ({ workflowId }: Omit<Workflow, '_tag'>): Location => ({
    _tag: 'Workflow',
    workflowId,
})

/**
 * Location parsers
 */
export const homeParser = homeMatch.parser.map(homeLocation)
export const adminCreateWorkflowAssignmentParser = adminCreateWorkflowAssignmentMatch.parser.map(
    adminCreateWorkflowAssignmentLocation,
)
export const adminListWorkflowAssignmentParser = adminListWorkflowAssignmentMatch.parser.map(
    adminListWorkflowAssignmentLocation,
)
export const listWorkflowAssignmentParser = lististWorkflowAssignmentMatch.parser.map(
    adminListWorkflowAssignmentLocation,
)
export const createWorkflowReportParser = adminCreateWorkflowReportMatch.parser.map(
    adminCreateWorkflowReportLocation,
)

export const lockedTitlesParser = lockedTitlesMatch.parser.map(lockedTitlesLocation)
export const searchParser = searchMatch.parser.map(searchLocation)
const loginParser = loginMatch.parser.map(loginLocation)
export const featureParser = featureMatch.parser.map(featureLocation)
export const seriesParser = seriesMatch.parser.map(seriesLocation)
export const episodeParser = episodeMatch.parser.map(episodeLocation)
export const seasonParser = seasonMatch.parser.map(seasonLocation)
export const workflowParser = workflowMatch.parser.map(workflowLocation)

/**
 * Location links
 */
export const homeLink = (): string => r.format(homeMatch.formatter, {})
export const adminCreateWorkflowAssignmentLink = (): string =>
    r.format(adminCreateWorkflowAssignmentMatch.formatter, {})
export const adminListWorkflowAssignmentLink = (): string =>
    r.format(adminListWorkflowAssignmentMatch.formatter, {})
export const adminCreateWorkflowReportink = (): string =>
    r.format(adminCreateWorkflowReportMatch.formatter, {})
export const listWorkflowAssignmentLink = (): string =>
    r.format(lististWorkflowAssignmentMatch.formatter, {})
export const lockedTitlesLink = (): string => r.format(lockedTitlesMatch.formatter, {})
export const searchLink = (
    search: Omit<Search, '_tag'> = { q: undefined, type: undefined },
): string => r.format(searchMatch.formatter, { q: search.q, type: search.type })
const loginLink = ({ returnTo, forceRelogin }: Omit<Login, '_tag'> = {}): string =>
    r.format(loginMatch.formatter, { returnTo, forceRelogin })
export const featureLink = (feature: Omit<Feature, '_tag'>): string =>
    r.format(featureMatch.formatter, feature as any)
export const seriesLink = (series: Omit<Series, '_tag'>): string =>
    r.format(seriesMatch.formatter, series as any)
export const episodeLink = (episode: Omit<Episode, '_tag'>): string =>
    r.format(episodeMatch.formatter, episode as any)
export const seasonLink = (season: Omit<Season, '_tag'>): string =>
    r.format(seasonMatch.formatter, season as any)
export const workflowLink = (workflow: Omit<Workflow, '_tag'>): string =>
    r.format(workflowMatch.formatter, workflow as any)

export const auth = {
    loginLink,
    loginParser,
    loginLocation,
}
