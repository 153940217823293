import React from 'react'

import Header from 'shared/components/Header'

const PageLayout: React.FC = ({ children }): React.ReactElement => {
    return (
        <>
            <Header />
            {children}
        </>
    )
}

export default PageLayout
