import * as t from 'io-ts'
import * as D from 'io-ts/lib/Decoder'
import * as y from 'yup'
import { date } from 'io-ts-types/lib/date'

export const CreateWorkflowReportCodec = t.type({
    from: date,
    to: date,
})
export interface CreateWorkflowReport extends t.TypeOf<typeof CreateWorkflowReportCodec> {}

export const CreateWorkflowReportStartResponseCodec = t.type({
    trackId: t.string,
})
export interface CreateWorkflowReportStartResponse
    extends t.TypeOf<typeof CreateWorkflowReportStartResponseCodec> {}

export const CreateWorkflowReportStatusResponseCodec = t.type({
    status: t.string,
    trackId: t.string,
    to: t.string,
    from: t.string,
    email: t.string,
    givenName: t.string,
    familyName: t.string,
})
export interface CreateWorkflowReportStatusResponse
    extends t.TypeOf<typeof CreateWorkflowReportStatusResponseCodec> {}

export const CreateWorkflowReportStartResponseDec = D.struct<CreateWorkflowReportStartResponse>({
    trackId: D.string,
})

// TODO: Remove this workaround when upgrading yup: https://github.com/jquense/yup/issues/1183
// @ts-ignore - override correct yup type
export const DateSchemaFrom: y.SchemaOf<Date> = y.date().required('From Date is required')
// @ts-ignore - override correct yup type
export const DateSchemaTo: y.SchemaOf<Date> = y.date().optional('To Date is required')

export const CreateWorkflowReportValidationSchema: y.SchemaOf<CreateWorkflowReport> = y
    .object()
    .shape({
        from: DateSchemaFrom,
        to: DateSchemaTo,
    })
