import styled from 'shared/theme'
import Color from 'color'

import Text from 'shared/components/Text'

const CELL_PADDING = 0.5

export const PillsWrapper = styled.div`
    margin: -${CELL_PADDING / 2}rem;
`
export const PillsCellWrapper = styled.div`
    padding: 0.5rem 0;
`
export const Pill = styled(Text).attrs({
    as: 'h4',
    size: '5',
})<{ diffType?: 'add' | 'remove' | 'same' }>`
    margin: 3px 3px 0 0;
    padding: ${CELL_PADDING / 2}rem ${CELL_PADDING}rem;
    display: inline-block;
    background-color: ${props =>
        props.diffType === 'add'
            ? Color(props.theme.colors.backgroundDiffAdd).lightness(85).hex()
            : props.diffType === 'remove'
            ? Color(props.theme.colors.backgroundDiffRemove).lightness(85).hex()
            : props.theme.colors.activeField};
    border-radius: 2px;
    ${props => (props.diffType === 'remove' ? 'text-decoration: line-through;' : null)}
`
