import React from 'react'

import styled from 'shared/theme'
import Container from 'shared/components/Container'
import { Stepper, Step, StepLabel } from 'shared/components/Stepper'
import Text from 'shared/components/Text'

const Wrapper = styled(Container)`
    margin: 40px auto 0px;
    max-width: 70%;
    flex-direction: column;
`

type WorkflowStepperProps = {
    activeStep: number
}
const WorkflowStepper: React.FC<WorkflowStepperProps> = ({ activeStep, children }) => {
    return (
        <Wrapper>
            <Container flexDirection="column" style={{ maxWidth: '70%', margin: '0 auto' }}>
                <Stepper
                    activeStep={activeStep - 1}
                    style={{ marginLeft: '-80%', marginRight: '-80%' }}
                >
                    <Step isCompleted={activeStep > 1}>
                        <StepLabel>
                            <Text>Title Selection</Text>
                        </StepLabel>
                    </Step>
                    <Step isCompleted={activeStep > 2}>
                        <StepLabel>
                            <Text>Assignments Configuration</Text>
                        </StepLabel>
                    </Step>
                    <Step isCompleted={activeStep > 3}>
                        <StepLabel>
                            <Text>Assignments Creation</Text>
                        </StepLabel>
                    </Step>
                </Stepper>
            </Container>

            {children}
        </Wrapper>
    )
}

export default WorkflowStepper
