import { Workflow, WorkflowHelper } from '@genome-web-forms/server'

import capitalize from 'lodash/capitalize'
import React from 'react'
import Button from 'shared/components/Button'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import {
    useResourceMachine,
    useResourceMachineService,
    useResourceWorkflowsWithAsigneeFullName,
} from 'shared/resource/ResourceMachineProvider'
import WIPInfo, {
    CustomWIPComponentProps,
    useFormattedWIPInfo,
    useWIPStateHelper,
} from 'shared/resource/WIPInfo'
import styled from 'shared/theme'
import { useStateCan } from 'xstate-helpers/react'
import { useSelfAssign } from 'shared/hooks/useSelfAssign'

const TitleWrapper = styled(Container)`
    line-height: 1.5rem;
    svg {
        height: 18px;
        width: 18px;
        padding-top: 1px;
    }
    div {
        display: flex;
        line-height: 1.5rem;
        align-items: center;
    }
`

const LockText: React.FC<{ title: string; value: React.ReactNode }> = ({ title, value }) => (
    <Container style={{ whiteSpace: 'nowrap' }}>
        <Container style={{ fontWeight: 'bold' }}>{title}</Container>
        <Container pl={1}>{value}</Container>
    </Container>
)

export const WIPInfoText: React.FC<CustomWIPComponentProps> = ({ lock, state }) => {
    const { loading, attemptingLockAquire, editing, releasing } = useWIPStateHelper(state)
    const data = useFormattedWIPInfo(lock)
    const isLoading = loading || attemptingLockAquire || (editing && releasing)

    if (isLoading) return null

    if (!data) return <Container>Not locked</Container>

    const { lockedBy, lockedOn } = data

    return (
        <Container flexDirection="column">
            <LockText title="Locked By:" value={lockedBy} />
            {lockedOn && <LockText title="Locked On:" value={lockedOn} />}
        </Container>
    )
}

const Status: React.FC = () => {
    const [state] = useResourceMachine()
    const workflows = useResourceWorkflowsWithAsigneeFullName()

    const dataTypes = state.context.wips.map(ref => ref.getSnapshot()!.context.dataType)

    return (
        <>
            <dl>
                {dataTypes.map(dataType => (
                    <React.Fragment key={dataType}>
                        <dt>
                            <TitleWrapper>
                                <Container pr={1}>{capitalize(dataType)}</Container>
                                <WIPInfo dataType={dataType} />
                            </TitleWrapper>
                        </dt>
                        <dd>
                            <WIPInfo dataType={dataType} Component={WIPInfoText} />
                        </dd>
                    </React.Fragment>
                ))}
            </dl>

            {!!workflows.length && (
                <>
                    <hr />
                    <Text as="h3" size="3" variant="secondary" mb="2">
                        Workflows
                    </Text>
                    <dl>
                        {workflows
                            .filter(workflow => workflow.workflowType === 'CREATIVE_WORK_TAGGING')
                            .map(workflow => (
                                <React.Fragment key={workflow.workflowId}>
                                    <dt>
                                        <TitleWrapper>
                                            <Container pr={1}>
                                                {WorkflowHelper.title(workflow)}
                                            </Container>
                                        </TitleWrapper>
                                    </dt>
                                    <dd>
                                        <Container flexDirection="column">
                                            <LockText title="State:" value={workflow.state} />
                                        </Container>
                                        <Container flexDirection="column">
                                            {workflow.assignee ? (
                                                <LockText
                                                    title="Assignee:"
                                                    value={
                                                        `${workflow.asigneeFullName}` || (
                                                            <em>none</em>
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <OptionalSelfAssignButton workflow={workflow} />
                                            )}
                                        </Container>
                                    </dd>
                                </React.Fragment>
                            ))}
                    </dl>
                </>
            )}
        </>
    )
}

type OptionalSelfAssignButtonProps = { workflow: Workflow }
export function OptionalSelfAssignButton({
    workflow,
}: OptionalSelfAssignButtonProps): React.ReactElement | null {
    const service = useResourceMachineService()
    const canSelfAssignWorkflow = useStateCan(service, { type: 'WORKFLOW_SELF_ASSIGN', workflow })

    const canUseSelfAssign = useSelfAssign(workflow)

    const canSelfAssign = canSelfAssignWorkflow && canUseSelfAssign

    return canSelfAssign ? (
        <Button
            size="small"
            onClick={() => service.send({ type: 'WORKFLOW_SELF_ASSIGN', workflow })}
        >
            Self-Assign
        </Button>
    ) : null
}

export default Status
