import { ROLE_NAME_TAGGER, ROLE_NAME_QA } from '@genome-web-forms/server'
import { usePartialMyIDUsersQuery } from 'api/workflow/partialMyIDUsers'
import { useField } from 'formik'
import React from 'react'
import FormikSelect, { FormikSelectProps } from 'shared/components/Select/FormikSelect'

const AssigneeSelect: React.FC<FormikSelectProps<{ value: string; label: string }>> = props => {
    const partialUsersQuery = usePartialMyIDUsersQuery()
    const options = React.useMemo<{ value: string; label: string }[]>(
        () =>
            partialUsersQuery
                .data!.filter(pu =>
                    pu.roles.some(
                        role => role.name === ROLE_NAME_TAGGER || role.name === ROLE_NAME_QA,
                    ),
                )
                .map(pu => ({
                    value: pu['relationship.employeeId'],
                    label: `${pu.given_name} ${pu.family_name} (${pu['relationship.employeeId']})`,
                })),
        [partialUsersQuery.data],
    )

    const [{ value }, , { setValue }] = useField<string | null>(props.name)

    return (
        <FormikSelect
            {...props}
            options={options}
            isLoading={partialUsersQuery.isLoading}
            isClearable={true}
            value={options.find(o => o.value === value)}
            onChange={
                ((value?: { value: string } | null) => setValue(value ? value.value : null)) as any
            }
        />
    )
}

export default AssigneeSelect
