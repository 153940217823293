import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { useUser } from 'auth/Auth'
import {
    CreateWorkflowReport,
    CreateWorkflowReportStartResponse,
    CreateWorkflowReportStartResponseDec,
    CreateWorkflowReportStatusResponse,
    CreateWorkflowReportStatusResponseCodec,
} from 'model/workflow/CreateWorkflowReport'
import { useCallback, useEffect, useState } from 'react'
import config from 'shared/config'
import { authGWF } from '../auth'

const url = {
    start: () => `${config.urlGWF}/workflow-report/start`,
    status: () => `${config.urlGWF}/workflow-report/status`,
    file: (trackId: string) => `${config.urlGWF}/workflow-report/file?trackId=${trackId}`,
}

export const useCreateWorkflowReport = (
    refreshOnMillis = 2000,
): {
    start: any
    trackId: string | undefined
    status: CreateWorkflowReportStatusResponse | undefined
    downloadURL: string
    isLoading: boolean
    error: Error | undefined
} => {
    const user = useUser()
    const [trackId, setTrackId] = useState<string>()
    const [status, setStatus] = useState<CreateWorkflowReportStatusResponse>()
    const [downloadURL, setDownloadURL] = useState<string>('')
    const [isLoading, setIsloading] = useState<boolean>(false)
    const [error, setError] = useState<Error>()

    const start = useCallback(
        async params => {
            setIsloading(true)
            try {
                const response = await createWorkflowReportStart(user, params)
                setTrackId(response?.trackId)
            } catch (e) {
                setError(e as Error)
            }
        },
        [user],
    )

    useEffect(() => {
        if (!trackId) return

        const refreshStatus = async () => {
            try {
                const status = await fetchWorkflowReportStatus(user, trackId)

                if (status.status === 'completed') {
                    setDownloadURL(url.file(status.trackId))
                    setIsloading(false)
                    setTrackId(undefined)
                }

                if (status.status !== 'inprogress') clearInterval(interval)

                setStatus(status)
            } catch (e) {
                clearInterval(interval)
                setError(e as Error)
            }
        }

        refreshStatus()
        const interval = setInterval(refreshStatus, refreshOnMillis)
        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [trackId])

    return {
        start,
        isLoading,
        trackId,
        status,
        downloadURL,
        error,
    }
}

export const createWorkflowReportStart = (
    user: MyIDUser,
    params: CreateWorkflowReport | undefined,
): Promise<CreateWorkflowReportStartResponse> => {
    return request(
        CreateWorkflowReportStartResponseDec,
        authGWF(user, {
            method: 'GET',
            url: url.start(),
            params: params || {},
        }),
    )
}

export const fetchWorkflowReportStatus = (
    user: MyIDUser,
    trackId: string,
): Promise<CreateWorkflowReportStatusResponse> => {
    return request(
        CreateWorkflowReportStatusResponseCodec,
        authGWF(user, {
            method: 'GET',
            url: url.status(),
            params: { trackId },
        }),
    )
}

export const fetchWorkflowReportFile = (
    user: MyIDUser,
    trackId: string,
): Promise<CreateWorkflowReportStatusResponse> => {
    return request(
        CreateWorkflowReportStatusResponseCodec,
        authGWF(user, {
            method: 'GET',
            url: url.status(),
            params: { trackId },
        }),
    )
}
