import React from 'react'
import { Column } from 'react-table'

import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import Table, { CheckboxFilter } from 'shared/components/Table'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import { CastMember } from 'codecs/CastMember'

const columns: Column<CastMember>[] = [
    {
        Header: 'Talent',
        accessor: c => c.talent?.talentName,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    {
        Header: 'Character',
        accessor: c => c.character?.characterName,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
    {
        Header: 'Role',
        accessor: c => c.role?.roleLabel,
        Filter: CheckboxFilter,
        filter: 'checkbox',
    },
]

const initialTableState = {
    sortBy: [{ id: 'Talent' }, { id: 'Character' }],
}

type CastProps = {
    data: CastMember[]
}

const Cast: React.FC<CastProps> = ({ data }) => {
    return (
        <>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Cast
                    </Text>
                </TableHeaderContent>
            </Container>

            <Table
                {...{ data, columns, initialState: initialTableState }}
                hasRowHover={true}
                emptyMessage="No cast members added yet"
            />
        </>
    )
}

export default Cast
