import { History, Path, LocationState, LocationDescriptorObject } from 'history'
import history from 'routing/history'

/**
 * Intercept calls to history.push() and conditionally replace them with
 * window.location.assign() to force a browser refresh
 */
export class HistoryInterceptor {
    private originalPush: typeof history.push

    constructor(private h: History, public forceBrowserRefresh = false) {
        this.originalPush = h.push
        h.push = this.push.bind(this)
    }

    destroy(): void {
        this.h.push = this.originalPush
    }

    private push(
        path: LocationDescriptorObject<LocationState> | Path,
        state?: LocationState,
    ): void {
        if (this.forceBrowserRefresh && typeof path !== 'object') {
            window.location.assign(path as string)
        } else {
            this.originalPush(path as any, state)
        }
    }

    private static _instance: HistoryInterceptor
    static instance(): HistoryInterceptor {
        return this._instance || (this._instance = new HistoryInterceptor(history))
    }

    static create(): void {
        HistoryInterceptor.instance()
    }
}
