import * as t from 'io-ts'

export class NullableType<C extends t.Any, A = any, O = A, I = unknown> extends t.Type<A, O, I> {
    readonly _tag: 'NullableType' = 'NullableType'
    constructor(
        name: string,
        is: NullableType<C, A, O, I>['is'],
        validate: NullableType<C, A, O, I>['validate'],
        encode: NullableType<C, A, O, I>['encode'],
        readonly type: C,
    ) {
        super(name, is, validate, encode)
    }
}

export interface NullableTypeC<C extends t.Mixed>
    extends NullableType<C, t.TypeOf<C> | null, t.OutputOf<C> | null, unknown> {}

export const nullable = <C extends t.Mixed>(
    codec: C,
    name = `${codec.name} | null`,
): NullableTypeC<C> => {
    return new NullableType(
        name,
        (u): u is t.TypeOf<C> | null => u === null || codec.is(u),
        (u, c) => (u == null ? t.success(null) : codec.validate(u, c)),
        a => (a == null ? null : codec.encode(a)),
        codec,
    )
}

export default nullable
