import React from 'react'
import * as t from 'io-ts'
import { request } from '@genome-web-forms/common/api'
import { useUser } from 'auth/Auth'
import { authGWF } from 'api/auth'
import config from 'shared/config'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { MemoAsyncSelect } from 'shared/components/Select'
import FormikSelect from '../Select/FormikSelect'
import UnavailableText from 'shared/components/UnavailableText'
import { Location, LocationCodec, addReactSelectTitle } from 'codecs/Location'
import { useField } from 'formik'
import Container from 'shared/components/Container'
import { AsyncProps } from 'react-select/async'
import { useAddHierarchy } from '../LocationsHierarchy/LocationsHierarchy'

type LocationsSelectProps = {
    name: string
    formControlsEnabled: boolean
}
export const LocationsSelect: React.FC<LocationsSelectProps> = ({ name, formControlsEnabled }) => {
    const user = useUser()
    let [{ value: locations }] = useField<Location[]>(name)

    locations = useAddHierarchy(locations)

    locations = React.useMemo(() => locations.map(addReactSelectTitle), [locations])

    const loadOptions = React.useCallback<AsyncProps<Location>['loadOptions']>(
        (inputValue, callback) => fetchLocations(user, String(inputValue).trim()).then(callback),
        [user],
    )

    const getOptionLabel = React.useCallback((o: Location) => o.locationName, [])
    const getOptionValue = React.useCallback((o: Location) => o.locationId, [])

    return (
        <Container mb="1" flexDirection="column">
            {!formControlsEnabled && !locations.length ? (
                <UnavailableText>Unavailable</UnavailableText>
            ) : (
                <FormikSelect<Location>
                    selectComponent={MemoAsyncSelect}
                    isMulti
                    {...{
                        name,
                        value: locations,
                        isDisabled: !formControlsEnabled,
                        cacheOptions: true,
                        loadOptions,
                        getOptionLabel,
                        getOptionValue,
                        placeholder: 'Start typing to see Location options',
                    }}
                />
            )}
        </Container>
    )
}

export default LocationsSelect

const fetchLocations = async (
    user: MyIDUser,
    text = '',
): Promise<(Location & { reactSelectTitle: string | null })[]> => {
    if (text === '') {
        return []
    }

    const res = await request(
        t.strict({
            results: t.array(
                t.strict({
                    genomeObject: t.strict(LocationCodec.props),
                }),
            ),
        }),
        authGWF(user, {
            url: `${config.urlGWF}/search`,
            params: {
                queryType: 'ap-locations',
                text,
                from: 0,
                size: 1000,
            },
        }),
    )

    return res.results.map(r => addReactSelectTitle(r.genomeObject))
}
