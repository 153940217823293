import * as t from 'io-ts'

import { fromNullable } from 'codecs/util/fromNullable'
import { LockCodec, Lock } from '@genome-web-forms/common/lock'
import nullable from 'codecs/util/nullable'
import { assertNever } from 'shared/util/assertNever'

import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import { Workflow } from '@genome-web-forms/server'

export interface LockStatuses {
    metadata: Lock | null
    storylines: Lock | null
    relationships: Lock | null
}
const LockStatusesCodec: t.Type<LockStatuses> = t.type({
    metadata: nullable(LockCodec),
    storylines: nullable(LockCodec),
    relationships: nullable(LockCodec),
})

export const FeatureResultCodec = t.type({
    highlight: t.string,
    cwmClassType: t.keyof(cwm.CWM_FEATURE_CLASSES),
    cwmClassTypeLabel: t.string,
    lockStatuses: nullable(LockStatusesCodec),

    featureId: t.string,
    featureTitle: t.string,
})
export interface FeatureResult extends t.TypeOf<typeof FeatureResultCodec> {}

export const EpisodeResultCodec = t.type({
    highlight: t.string,
    cwmClassType: t.keyof(cwm.CWM_EPISODE_CLASSES),
    cwmClassTypeLabel: t.string,
    lockStatuses: nullable(LockStatusesCodec),

    episodeId: t.string,
    episodeTitle: t.string,
    seriesId: nullable(t.string),
    seriesTitle: nullable(t.string),
})
export interface EpisodeResult extends t.TypeOf<typeof EpisodeResultCodec> {}

export const SeriesResultCodec = t.type({
    highlight: t.string,
    cwmClassType: t.keyof(cwm.CWM_SERIES_CLASSES),
    cwmClassTypeLabel: t.string,
    lockStatuses: nullable(LockStatusesCodec),

    seriesId: t.string,
    seriesTitle: t.string,
})
export interface SeriesResult extends t.TypeOf<typeof SeriesResultCodec> {}

export interface SearchResult {
    id: string
    genomeObject: FeatureResult | SeriesResult | EpisodeResult
    workflows: Array<Workflow> | false
}

export const SearchResultCodec: t.Type<SearchResult> = t.type({
    id: t.string,
    genomeObject: t.union([
        cwm
            .CWMTypeLabelCodec()
            .pipe(FeatureResultCodec, FeatureResultCodec.name) as t.Type<FeatureResult>,
        cwm
            .CWMTypeLabelCodec()
            .pipe(EpisodeResultCodec, EpisodeResultCodec.name) as t.Type<EpisodeResult>,
        cwm
            .CWMTypeLabelCodec()
            .pipe(SeriesResultCodec, SeriesResultCodec.name) as t.Type<SeriesResult>,
    ]),
    workflows: fromNullable(t.boolean, false) as any, // hack to initialize to false
})

export const SearchResultPayloadCodec = t.type({
    totalHits: t.number,
    results: fromNullable(t.array(SearchResultCodec), []),
})

export type SearchResultPayload = t.TypeOf<typeof SearchResultPayloadCodec>

export function getTitle(obj: SearchResult['genomeObject']): string {
    if (EpisodeResultCodec.is(obj))
        return obj.seriesTitle ? `${obj.episodeTitle} (${obj.seriesTitle})` : obj.episodeTitle

    if (FeatureResultCodec.is(obj)) return obj.featureTitle
    if (SeriesResultCodec.is(obj)) return obj.seriesTitle

    assertNever(obj)
}

export function getResourceId(obj: SearchResult['genomeObject']): string {
    if (EpisodeResultCodec.is(obj)) return obj.episodeId
    if (FeatureResultCodec.is(obj)) return obj.featureId
    if (SeriesResultCodec.is(obj)) return obj.seriesId

    assertNever(obj)
}
