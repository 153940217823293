import React from 'react'
import { useFormikContext, useField } from 'formik'

import Text from 'shared/components/Text'
import { ReactComponent as DownloadIcon } from './download-icon.svg'
import { ReactComponent as CloseIcon } from 'shared/components/Icons/close-icon.svg'
import ErrorMessage from 'shared/components/ErrorMessage'
import styled, { theme } from 'shared/theme'

const DropZone = styled.div<{ isDraggingOver: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 200px;
    border-width: 4px;
    box-sizing: border-box;
    position: relative;
    transition: all 0.15s linear;
    border-radius: 4px;
    border-style: ${props => (props.isDraggingOver ? 'solid' : 'dashed')};
    border-color: ${props =>
        props.isDraggingOver ? props.theme.colors.borderActive : props.theme.colors.activeField};
    background: ${props =>
        props.isDraggingOver
            ? props.theme.colors.backgroundPrimary
            : props.theme.colors.backgroundSecondary};
`
const StyledInput = styled.input.attrs({
    id: 'attachment',
    name: 'attachment',
    type: 'file',
})`
    cursor: copy;
    width: 100%;
    position: absolute;
    height: 100%;
    opacity: 0;
`
const StyledIcon = styled(DownloadIcon)`
    fill: ${theme.colors.textPrimary};
    margin-bottom: 2rem;
`

const StyledContainer = styled.div`
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
`
const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
    padding: 0.5rem;
`

const ImageDropZone: React.FC = () => {
    const { isSubmitting } = useFormikContext()
    const [{ value: attachment }, , { setValue: setAttachment }] = useField<File | null>(
        'attachment',
    )
    const [isDraggingOver, setDraggingOver] = React.useState(false)
    const dropZone = React.useRef<HTMLDivElement>(null!)

    function highlight(): void {
        setDraggingOver(true)
    }

    function unhighlight(): void {
        setDraggingOver(false)
    }

    React.useEffect(() => {
        const node = dropZone.current

        node.addEventListener('dragover', highlight, false)
        node.addEventListener('dragleave', unhighlight, false)
        node.addEventListener('drop', unhighlight, false)

        return () => {
            node.removeEventListener('dragover', highlight)
            node.removeEventListener('dragleave', unhighlight)
            node.removeEventListener('drop', unhighlight)
        }
    }, [])

    return (
        <>
            <Text mt="3" mb="1" as="h5" size="5" variant="secondary">
                You can add a screenshot or a video visualising the problem:
            </Text>
            <DropZone ref={dropZone} isDraggingOver={isDraggingOver}>
                <StyledInput
                    id="attachment"
                    name="attachment"
                    type="file"
                    disabled={isSubmitting}
                    onChange={event => {
                        const target = event.currentTarget
                        const file = target.files && target.files[0]
                        if (file) {
                            setAttachment(file)
                        }
                    }}
                />
                {!attachment ? (
                    <>
                        <StyledIcon />
                        <Text size="3">Drag &amp; Drop file here or click to upload</Text>
                    </>
                ) : (
                    <StyledContainer>
                        <Text pr="1">{attachment.name}</Text>
                        <StyledCloseIcon
                            onClick={event => {
                                event.preventDefault()
                                event.stopPropagation()
                                !isSubmitting && setAttachment(null)
                            }}
                        >
                            Remove
                        </StyledCloseIcon>
                    </StyledContainer>
                )}
            </DropZone>
            <ErrorMessage name="attachment" />
        </>
    )
}

export default ImageDropZone
