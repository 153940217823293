import styled from 'shared/theme'

export const Op = styled.span<{ type: 'add' | 'remove' | 'same' }>`
    font-size: 0.9375rem;
    color: ${props => props.theme.colors.textPrimary};
    ${props =>
        props.type === 'add'
            ? `background-color: ${props.theme.colors.backgroundDiffAdd};`
            : props.type === 'remove'
            ? `background-color: ${props.theme.colors.backgroundDiffRemove};
                    text-decoration: line-through;`
            : null}
`

export default Op
