import React from 'react'

export function useDebounce<T>(value: T, delay: number): [T] {
    // State and setters for debounced value

    const [debouncedValue, setDebouncedValue] = React.useState(value)

    React.useEffect(() => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return [debouncedValue]
}
