import { createGlobalStyle } from 'styled-components'
import 'normalize.css'

const GlobalStyle = createGlobalStyle`
    html {
        min-width: 960px;
        height: 100%;
    }
    body {
        background-color: ${props => props.theme.colors.backgroundPrimary};
        height: 100%;
        color: ${props => props.theme.colors.textPrimary};
    }
    .root {
        position: relative;
        min-height: 100%;
    }
    a {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 600;
        color: ${props => props.theme.colors.textPrimary};
        &.secondary {
            color: ${props => props.theme.colors.white};
        }
    }
    [role='tab'] {
        outline: none;
    }
    dl {
        display: grid;
        grid-template-columns: fit-content(50%) auto;
        grid-gap: 1rem 1.5rem;
    }
    dt {
        font-size: 0.9375rem;
        font-weight: 600;
        line-height: 1.5rem;
        color: ${props => props.theme.colors.textPrimary};
    }
    dd {
        font-size: 0.9375rem;
        line-height: 1.5rem;
        color: ${props => props.theme.colors.textSecondary};
    }
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      padding: 0;
    }
    .text-danger {
        color: ${props => props.theme.colors.danger};
    }
`

export default GlobalStyle
