import flatMap from 'lodash/flatMap'

const PATH_COMPONENT_ALL = '*'
export function fuzzyGetter<T extends Record<string, any>, R extends any>(
    object: T,
    path: string | (string | ((data: any) => any))[],
): R {
    path = Array.isArray(path) ? path : [path]
    let index = 0
    let length = path.length

    while (object != null && index < length) {
        let pathComponent = path[index++]
        if (pathComponent === PATH_COMPONENT_ALL) {
            return (
                Array.isArray(object)
                    ? // it's safe to have a loop func, because we terminate the loop
                      // eslint-disable-next-line no-loop-func
                      flatMap(object, item => fuzzyGetter(item, path.slice(index)))
                    : undefined
            ) as R
        }

        if (typeof pathComponent === 'string') {
            object = object[pathComponent]
        }

        if (typeof pathComponent === 'function') {
            object = pathComponent(object)
        }
    }

    return (index && index === length ? object : undefined) as R
}
