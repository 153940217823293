import React from 'react'
import { Formik } from 'formik'

import {
    EpisodeStoryline,
    EpisodeStorylineFormValue,
    EpisodeStorylineFormSchema,
} from 'codecs/storyline/EpisodeStoryline'
import { generateDisneyId } from 'shared/hooks/useDisneyId'
import { emptyOnSubmitting } from 'shared/util/EmptyYupSchema'

import { useStorylinesContext } from './index'
import StorylineFormNode from './StorylineFormNode'

export const StorylineForm: React.FC<{
    storyline: EpisodeStoryline | null
}> = ({ storyline }): React.ReactElement => {
    const { save } = useStorylinesContext()

    const storylineFormValue: EpisodeStorylineFormValue = React.useMemo(() => {
        return storyline
            ? storyline
            : ({
                  storylineId: generateDisneyId(),
                  storylineLogLine: '',
                  storylineWeight: '',
                  storylineArchetypes: [],
                  storyRoles: [],
                  description: '',
                  type: null,
              } as EpisodeStorylineFormValue)
    }, [storyline])

    const [isSubmitting, setIsSubmitting] = React.useState(false)

    return (
        <Formik
            initialValues={storylineFormValue}
            validationSchema={() => {
                return emptyOnSubmitting(EpisodeStorylineFormSchema)(isSubmitting)
            }}
            onSubmit={storyline => {
                save(storyline as EpisodeStoryline)
            }}
        >
            {formikProps => {
                if (formikProps.isSubmitting !== isSubmitting) {
                    setIsSubmitting(formikProps.isSubmitting)
                }
                return <StorylineFormNode {...formikProps} />
            }}
        </Formik>
    )
}
