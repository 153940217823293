import React from 'react'
import isUndefined from 'lodash/isUndefined'
import { keyframes } from 'styled-components'
import Table from 'shared/components/Table'
import Container from 'shared/components/Container'
import ToggleButton from 'shared/components/ToggleButton'
import { CellProps, Column } from 'react-table'
import Text from 'shared/components/Text'

import styled, { css } from 'shared/theme'
import { searchLink } from 'routing/routes'
import { Link } from 'react-router-dom'

export const TableContainer = styled.div`
    padding: 0;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 4px;
    table {
        border-collapse: collapse;
    }
    table thead {
        background-color: ${props => props.theme.colors.activeField};
    }
    table thead th {
        color: ${props => props.theme.colors.textSecondary};
        font-weight: 400;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    table tbody tr:last-of-type td {
        border-bottom: 0;
    }
    table tbody td {
        &:first-child {
            border-left: 0;
        }
        &:last-child {
            border-right: 0;
        }
    }
`
const EXPANDED_CONTENT_HEIGHT = 415 //px
const TOGGLE_DURATION_MS = 300
const keyFramesExpanding = keyframes`
  0% {
    max-height: 0;
  }
  100% {
    max-height: ${EXPANDED_CONTENT_HEIGHT}px;
  }
`
const keyFramesCollapsing = keyframes`
  0% {
    max-height: ${EXPANDED_CONTENT_HEIGHT}px;
  }
  100% {
    max-height: 0;
  }
`

const Wrapper = styled.div<{ isExpanded: boolean | undefined }>`
    overflow: hidden;
    ${({ isExpanded }) =>
        isUndefined(isExpanded)
            ? 'max-height: 0;'
            : isExpanded
            ? css`
                  animation-name: ${keyFramesExpanding};
                  animation-duration: ${TOGGLE_DURATION_MS}ms;
                  animation-timing-function: ease;
                  max-height: ${EXPANDED_CONTENT_HEIGHT};
              `
            : css`
                  animation-name: ${keyFramesCollapsing};
                  animation-duration: ${TOGGLE_DURATION_MS}ms;
                  animation-timing-function: ease;
                  max-height: 0;
              `}
`

type SearchKey = { goal: string; shortcut: string; example: string }

const columns: Column<SearchKey>[] = [
    {
        Header: 'Goal',
        accessor: c => c.goal,
        disableSortBy: true,
    },
    {
        Header: 'Shortcut',
        accessor: c => c.shortcut,
        disableSortBy: true,
    },
    {
        Header: 'Example',
        accessor: c => c.example,
        disableSortBy: true,
        Cell: ({
            row: {
                original: { example },
            },
        }: CellProps<SearchKey>) => {
            return (
                <Link to={searchLink({ q: example })}>
                    <Text>{example}</Text>
                </Link>
            )
        },
    },
]

const data: SearchKey[] = [
    {
        goal: 'Exact Match',
        shortcut: '" "',
        example: '"Toy Story 3"',
    },
    {
        goal: 'Include Both Terms',
        shortcut: `AND (ensure it is in upper case)`,
        example: 'Ramon AND Crowd',
    },
    {
        goal: 'Include Either Or Term',
        shortcut: 'OR (ensure it is in upper case)',
        example: 'Ramon OR Crowd',
    },
    {
        goal: 'Unsure of Spelling (aka Fuzzy Search)',
        shortcut: '~',
        example: 'Tweakin~',
    },
]

const Characters: React.FC = (): React.ReactElement => {
    const [isExpanded, setIsExpanded] = React.useState<boolean | undefined>(undefined)

    return (
        <>
            <Container pl={2} pt={1} pb={1}>
                <ToggleButton
                    type="button"
                    onClick={() => setIsExpanded(!isExpanded)}
                    toggled={!isExpanded}
                >
                    Search Key Menu
                </ToggleButton>
            </Container>
            <Wrapper isExpanded={isExpanded}>
                <TableContainer>
                    <Table {...{ data, columns }} />
                </TableContainer>
            </Wrapper>
        </>
    )
}

export default React.memo(Characters)
