import dateFormat from 'date-fns/format'
import { Form, Formik } from 'formik'
import { AssignmentConfiguration } from 'model/workflow/AssignmentConfiguration'
import { AssignmentTitle } from 'model/workflow/CreateWorkflowRequest'
import {
    getTaskLabel,
    getValidTaskTypesPredicate,
    useSourceOptionsForSelectedTitles,
    useTaskOptionsForSelectedTitles,
} from 'model/workflow/TaskType'
import { WorkflowPriorityOptions } from 'model/workflow/WorkflowPriority'
import React from 'react'
import Button from 'shared/components/Button'
import CellLabel from 'shared/components/CellLabel'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import Container from 'shared/components/Container'
import DatePicker from 'shared/components/DatePicker'
import ErrorMessage from 'shared/components/ErrorMessage'
import FormikSelect from 'shared/components/Select/FormikSelect'
import TextualButton from 'shared/components/TextualButton'
import Autosave from 'shared/form/Autosave'
import styled from 'shared/theme'
import { useInterpreter } from '../CreateAssignmentProvider'
import AssigneeSelect from './AssigneeSelect'

export const GridLayout = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 0.75fr 0.75fr 1.5fr 0.5fr 0.5fr;
    grid-column-gap: 0.5rem;
    padding: 0 1rem;
`
const FormWrapper = styled.div<{ hasErrors?: boolean }>`
    background-color: ${props =>
        props.hasErrors ? props.theme.colors.backgroundError : props.theme.colors.activeField};
    position: relative;
    box-sizing: border-box;
    width: 100%;
`

type AssignmentTableRowFormProps = {
    title: AssignmentTitle
    editingConfiguration: Partial<AssignmentConfiguration>
    globalConfiguration: AssignmentConfiguration
}
const AssignmentTableRowForm: React.FC<AssignmentTableRowFormProps> = ({
    title,
    editingConfiguration,
    globalConfiguration,
}) => {
    const service = useInterpreter()
    const taskTypeOptions = useTaskOptionsForSelectedTitles([title])
    const sourceTypeOptions = useSourceOptionsForSelectedTitles()

    return (
        <Formik
            initialValues={editingConfiguration}
            enableReinitialize
            onSubmit={configuration => {
                service.send({
                    type: 'TITLE_CONFIGURATION_EDIT_CONFIRM',
                    title,
                    configuration,
                })
            }}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <Autosave<Partial<AssignmentConfiguration>>
                        onChange={editingConfiguration =>
                            service.send({
                                type: 'TITLE_CONFIGURATION_EDIT_UPDATE',
                                title,
                                editingConfiguration,
                            })
                        }
                    />
                    <FormWrapper>
                        <GridLayout>
                            <div>
                                <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                    Tasks
                                </CellLabel>
                                <FormikSelect
                                    name="tasks"
                                    isMulti
                                    options={taskTypeOptions}
                                    placeholder={
                                        globalConfiguration.tasks
                                            .filter(getValidTaskTypesPredicate(title))
                                            .map(getTaskLabel)
                                            .join(', ') || 'Select Tasks'
                                    }
                                    value={taskTypeOptions.filter(tto =>
                                        (values.tasks || []).includes(tto.value),
                                    )}
                                    onChange={
                                        ((value: { value: string }[]) => {
                                            setFieldValue(
                                                'tasks',
                                                value ? value.map(v => v.value) : [],
                                            )
                                        }) as any
                                    }
                                />
                            </div>

                            <div>
                                <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                    Assignee
                                </CellLabel>
                                <AssigneeSelect
                                    name="assignee"
                                    placeholder={globalConfiguration.assignee}
                                />
                            </div>

                            <div>
                                <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                    Priority
                                </CellLabel>
                                <FormikSelect
                                    name="priority"
                                    isClearable={true}
                                    options={WorkflowPriorityOptions}
                                    value={WorkflowPriorityOptions.find(
                                        po => po.value === values.priority,
                                    )}
                                    onChange={
                                        ((value: { value: string } | null) =>
                                            setFieldValue(
                                                'priority',
                                                value ? value.value : null,
                                            )) as any
                                    }
                                    placeholder={
                                        WorkflowPriorityOptions.find(
                                            po => po.value === globalConfiguration.priority,
                                        )?.label ?? 'Default source'
                                    }
                                />
                            </div>

                            <div>
                                <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                    Publish By Date
                                </CellLabel>
                                <DatePicker
                                    placeholderText={globalConfiguration.deadlineAt ?? undefined}
                                    selected={
                                        values.deadlineAt ? new Date(values.deadlineAt) : undefined
                                    }
                                    onChange={date =>
                                        setFieldValue(
                                            'deadlineAt',
                                            date
                                                ? dateFormat(date as Date, 'yyyy-MM-dd')
                                                : undefined,
                                        )
                                    }
                                />
                            </div>

                            <div>
                                <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                    Source
                                </CellLabel>
                                <FormikSelect
                                    name="source"
                                    options={sourceTypeOptions}
                                    value={sourceTypeOptions.find(so => so.value === values.source)}
                                    onChange={
                                        ((value: { value: string } | null) =>
                                            setFieldValue(
                                                'source',
                                                value ? value.value : null,
                                            )) as any
                                    }
                                    placeholder={
                                        sourceTypeOptions.find(
                                            po => po.value === globalConfiguration.source,
                                        )?.label ?? 'Default source'
                                    }
                                />
                                <ErrorMessage name="source" />
                            </div>

                            <div>
                                <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                                    Needs review{' '}
                                </CellLabel>
                                <CheckboxInput
                                    name="reviewRequired"
                                    disabled={globalConfiguration.reviewRequired}
                                    checked={
                                        !!values.reviewRequired ||
                                        globalConfiguration.reviewRequired
                                    }
                                ></CheckboxInput>
                                <ErrorMessage name="reviewRequired" />
                            </div>
                        </GridLayout>

                        <Container justifyContent="flex-end" p="2">
                            <TextualButton
                                mr="1"
                                type="button"
                                onClick={() => {
                                    service.send({
                                        type: 'TITLE_CONFIGURATION_EDIT_CANCEL',
                                        title,
                                    })
                                }}
                            >
                                Cancel
                            </TextualButton>
                            <Button type="submit">Save</Button>
                        </Container>
                    </FormWrapper>
                </Form>
            )}
        </Formik>
    )
}
export default AssignmentTableRowForm
