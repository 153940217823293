import React from 'react'
import { useCharacters } from 'shared/characters/CharactersProvider'

import {
    FormikCharacterSelect,
    FormikCharacterSelectProps,
} from 'shared/components/Select/FormikCharacterSelect'

export const RelationshipCharactersSelect: React.FC<
    Omit<FormikCharacterSelectProps, 'characters'>
> = (outerProps): React.ReactElement => {
    const characters = useCharacters()

    const props = { ...outerProps, characters } as any

    return <FormikCharacterSelect {...props} />
}

export default RelationshipCharactersSelect
