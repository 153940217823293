import * as t from 'io-ts'
import { BooleanFromString } from 'io-ts-types/lib/BooleanFromString'
import { fromNullable } from 'codecs/util/fromNullable'

import nullable from 'codecs/util/nullable'
import { LocationCodec } from 'codecs/Location'
import { CastMemberCodec } from 'codecs/CastMember'
import { CrewMemberCodec } from 'codecs/CrewMember'
import { AbridgedSeasonCodec } from 'model/Season'
import { AbridgedEpisodeCodec } from 'model/Episode'
import { DateFromXDS } from 'codecs/util/DateFromXDS'
import { RecordedAttributesCodec } from 'model/metadata/RecordedAttributes'
import { MetadataCodec } from 'model/metadata/Metadata'
import * as cwm from '@genome-web-forms/common/model/CWMClassType'

const SeriesCodecBase = t.type({
    // required props
    seriesId: t.string,
    seriesTitle: t.string,
    cwmClassType: t.keyof(cwm.CWM_SERIES_CLASSES),
    cwmClassTypeLabel: t.string,
    attributes: nullable(RecordedAttributesCodec),
    seasons: t.array(AbridgedSeasonCodec),
    episodes: t.array(AbridgedEpisodeCodec),
    locations: fromNullable(t.array(LocationCodec), []),
    cast: fromNullable(t.array(CastMemberCodec), []),
    crew: fromNullable(t.array(CrewMemberCodec), []),

    metadata: MetadataCodec,

    // optional props
    static: t.type({
        titleNameDisambiguated: nullable(t.string),
        titleNameStandardized: nullable(t.string),
        akaTitle: nullable(t.string),
        fka: fromNullable(t.array(t.string), []),
        reachTitle: nullable(t.string),

        earliestReleaseDate: nullable(DateFromXDS),
        productionCompany: fromNullable(t.array(t.string), []),
        networkTitle: nullable(t.string),
        worldTitle: nullable(t.string),
        brandTitle: nullable(t.string),

        reachLabel: nullable(t.string),
        groupId: nullable(t.string),
        _unverified_radarGroupNumber: nullable(t.string),
        productId: nullable(t.string),
        productNumber: nullable(t.string),
        eidrL1: nullable(t.string),
        hasMDMID: nullable(t.string),
        tmsId: nullable(t.string),

        synopsis: nullable(t.string),
        creativeFormat: nullable(t.string),
        synopsisMedium: nullable(t.string),
        hasDescription: nullable(t.string),

        _unverified_rating: nullable(t.string),
        _unverified_runtime: nullable(t.string),
        episodeNumber: nullable(t.string),

        disneyPlusSelected: fromNullable(BooleanFromString, false),
        DisneyPlusOriginal: fromNullable(BooleanFromString, false),

        starSelected: fromNullable(BooleanFromString, false),
    }),
})

export interface Series extends t.TypeOf<typeof SeriesCodecBase> {}
export const SeriesCodec: t.Type<Series> = cwm
    .CWMTypeLabelCodec(cwm.CWM_CLASS_TYPE_SERIES)
    .pipe(SeriesCodecBase, SeriesCodecBase.name) as t.Type<Series>

export const SerieJSONViewOmittedValues = [
    'creativeWorkType',
    'creativeWorkTypeLabel',
    'cwmClassType',
    'static.type',
    'static.typeLabel',
    'static.parentClass',
    'static.parentClassLabel',
]
