import styled, { css } from 'shared/theme'
import Color from 'color'
import { Pill } from 'shared/components/Table/TablePills'
import * as s from 'shared/diff/schema'
import Text from 'shared/components/Text'

export const Grid = styled.div<{
    clickable?: boolean
    hasErrors?: boolean
    diffType?: s.DiffOpType
}>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-top: 1px solid ${props => props.theme.colors.border};
    &:hover {
        background-color: ${props =>
            props.hasErrors
                ? props.theme.colors.backgroundErrorHighlight
                : props.theme.colors.activeField};
    }
    &:hover ${Pill} {
        background-color: ${props =>
            props.diffType === 'add'
                ? Color(props.theme.colors.backgroundDiffAdd).lightness(85).hex()
                : props.diffType === 'remove'
                ? Color(props.theme.colors.backgroundDiffRemove).lightness(85).hex()
                : props.theme.colors.border};
    }
    ${props =>
        props.clickable &&
        `
            cursor: pointer;
        `}
    background-color: ${props =>
        props.diffType === 'add'
            ? props.theme.colors.backgroundDiffAdd
            : props.diffType === 'remove'
            ? props.theme.colors.backgroundDiffRemove
            : props.hasErrors
            ? props => props.theme.colors.backgroundError
            : 'auto'};

    ${props =>
        props.diffType === 'remove' &&
        css`
            text-decoration: line-through;
        `}
`

export const GridHeaderCell = styled(Text).attrs<{ noBorder?: boolean }>({
    size: 6,
    variant: 'secondary',
    weight: 'bold',
})<{ noBorder?: boolean }>`
    display: grid;
    grid-row-gap: 0.5rem;
    padding: 0.25rem 1rem;
    line-height: 2rem;
    ${props =>
        props.noBorder ? 'border: 0' : `border-left: 1px solid ${props.theme.colors.border}`};
`
