import React from 'react'
import noop from 'lodash/noop'
import invariant from 'tiny-invariant'

const USE_EVENT_CALLBACK_TAG = 'useEventCallbackTag'

export type EventCallback<T> = T & {
    readonly _tag: typeof USE_EVENT_CALLBACK_TAG
}

/**
 * This is better than React.useCallback for when you need a callback
 * that responds to an event (browser, network, etc).
 *
 * Ref: https://github.com/jaredpalmer/formik/pull/1566#issuecomment-497933241
 * Ref: https://github.com/facebook/react/issues/14099#issuecomment-440172008
 */
export function useEventCallback<T extends (...args: any[]) => any>(fn: T): EventCallback<T> {
    const ref: any = React.useRef(fn)

    /**
     * We copy a ref to the callback scoped to the current state/props on each render
     *
     * The current project does not use SSR, but for projects that do, you
     * need something like:
     * https://github.com/alloc/react-layout-effect
     *
     * For posterity if the above gets removed:
     *
     * useLayoutEffect =
     *   typeof window !== 'undefined' &&
     *   window.document &&
     *   window.document.createElement
     *     ? React.useLayoutEffect
     *     : React.useEffect
     */
    React.useLayoutEffect(() => {
        ref.current = fn
    })

    const callback = React.useCallback((...args: any[]) => ref.current.apply(void 0, args), []) as T
    ;(callback as any)._tag = USE_EVENT_CALLBACK_TAG

    return callback as EventCallback<T>
}

export function isEventCallback<T>(u: unknown): u is EventCallback<T> {
    return typeof u === 'function' && (u as any)._tag === USE_EVENT_CALLBACK_TAG
}

export function invariantRequireEventCallbackOrNoop(
    ...args: ((...args: any[]) => any)[]
): void | never {
    let index = -1
    while (++index < args.length) {
        const fn = args[index]
        invariant(
            fn === noop || isEventCallback(fn),
            'You must use a callback wrapped in useEventCallback',
        )
    }
}
