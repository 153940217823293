import React from 'react'
import get from 'lodash/get'
import { invariantRequireEventCallbackOrNoop, EventCallback } from 'shared/hooks/useEventCallback'

const MOUSEDOWN = 'mousedown'
const TOUCHSTART = 'touchstart'

type ClickOrTouchEvent =
    | HTMLElementEventMap[typeof MOUSEDOWN]
    | HTMLElementEventMap[typeof TOUCHSTART]
export type Handler = (event: ClickOrTouchEvent) => void

function isToastDescendant(node: Node): boolean {
    const toasts = document.querySelectorAll('div.Toastify')
    return Array.from(toasts).some(toast => toast.contains(node))
}

function useOnClickOutside(
    ref: React.RefObject<HTMLElement>,
    handler: EventCallback<Handler>,
): void {
    invariantRequireEventCallbackOrNoop(handler)

    React.useEffect(() => {
        const listener = (event: ClickOrTouchEvent): void => {
            if (!event.target) {
                return
            }

            const nodeName: string | null = get(event, ['target', 'nodeName'], null)
            // Do nothing if clicking target is HTML, we assume user is
            // using the scrollbar
            if (nodeName === 'HTML') {
                return
            }

            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return
            }
            if (isToastDescendant(event.target as Node)) {
                return
            }

            handler(event)
        }

        document.addEventListener(MOUSEDOWN, listener)
        document.addEventListener(TOUCHSTART, listener)

        return () => {
            document.removeEventListener(MOUSEDOWN, listener)
            document.removeEventListener(TOUCHSTART, listener)
        }
    }, [ref, handler])
}

export function useOnClickOutsideRef<T extends HTMLElement>(
    handler: EventCallback<Handler>,
): React.RefObject<T> {
    const ref = React.useRef<T>(null)
    useOnClickOutside(ref, handler)
    return ref
}

export default useOnClickOutside
