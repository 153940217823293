import React from 'react'

import PageLayout from 'pages/util/PageLayout'
import LockedTitlesNode from 'pages/LockedTitles/LockedTitlesNode'

const LockedTitles: React.FC = (): React.ReactElement => {
    return (
        <PageLayout>
            <LockedTitlesNode />
        </PageLayout>
    )
}

export default LockedTitles
