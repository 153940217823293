import { Character } from 'codecs/Character'
import { Relationship, RelationshipFormValue } from 'codecs/Relationship'
import isEqual from 'lodash/isEqual'

export const hasDuplicateCharacterPairs = (
    v: RelationshipFormValue,
    relationships: Relationship[],
    isRelationshipNew: boolean,
): boolean => {
    const characters = v.characters as (Character | null | undefined)[]
    // if we have empty positions, do not verify for duplicates
    if (characters.length !== 2 || !characters.every(c => c)) {
        return false
    }

    const baseSet = new Set(characters)
    // We expect charactersSets to contain the set of input characters exactly once.
    // To that end, we need to either replace the existing value in relationships,
    // or add it at the end, dependinf if we're in a new relationship
    const characterSets = relationships.map(relationship => {
        if (!isRelationshipNew && relationship.relationshipId === v.relationshipId) {
            return baseSet
        }
        return new Set(relationship.characters)
    })
    if (isRelationshipNew) {
        characterSets.push(baseSet)
    }

    const duplicates = characterSets.filter(characterSet => {
        return isEqual(baseSet, characterSet)
    })

    return duplicates.length > 1
}
