import * as t from 'io-ts'

export const ARC_THROUGHOUT = 'throughout'
export const ARC_BEGINNING = 'beginning'
export const ARC_MIDDLE = 'middle'
export const ARC_ENDING = 'ending'

export const ARC_VALUES = [ARC_THROUGHOUT, ARC_BEGINNING, ARC_MIDDLE, ARC_ENDING] as const

export const ARC_SORT = ARC_VALUES
export function compareByArc(a: { arc: string }, b: { arc: string }): number {
    return ARC_SORT.indexOf(a.arc as any) - ARC_SORT.indexOf(b.arc as any)
}

export const ARC_OPTIONS = [
    { value: ARC_THROUGHOUT, label: 'Throughout' },
    { value: ARC_BEGINNING, label: 'Beginning' },
    { value: ARC_MIDDLE, label: 'Middle' },
    { value: ARC_ENDING, label: 'Ending' },
] as const

export type ArcOption = {
    value: ArcValue
    label: string
}

export const ArcValueCodec = t.keyof({
    [ARC_THROUGHOUT]: null,
    [ARC_BEGINNING]: null,
    [ARC_MIDDLE]: null,
    [ARC_ENDING]: null,
})
export type ArcValue = t.TypeOf<typeof ArcValueCodec>
