import nullable from 'codecs/util/nullable'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString'

export const CommentCodec = t.type({
    id: t.number,
    titleId: t.string,
    content: t.string,
    authorId: t.string,
    addedOn: DateFromISOString,
    editedOn: DateFromISOString,
    deletedOn: nullable(DateFromISOString),
    author: t.type({
        displayName: t.string,
    }),
})

export interface Comment extends t.TypeOf<typeof CommentCodec> {}
