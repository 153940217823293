type SettledValue<V extends any> = {
    status: 'fulfilled'
    value: V
}

type SettledRejection<R extends any> = {
    status: 'rejected'
    reason: R
}

export type Settled<V = any, R = any> = SettledValue<V> | SettledRejection<R>

/**
 * Polyfill
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
 */
export function PromiseAllSettled<V, R>(promises: Promise<any>[]): Promise<Settled<V, R>[]> {
    return Promise.all(promises.map(promise => reflect<V, R>(promise)))
}

async function reflect<V, R>(promise: Promise<V>): Promise<Settled<V, R>> {
    try {
        const v = await promise
        return { status: 'fulfilled', value: v }
    } catch (e: any) {
        return { status: 'rejected', reason: e }
    }
}
