import React from 'react'

import { FeatureStoryline } from 'codecs/storyline/FeatureStoryline'
import { StorylineForm } from './StorylineForm'
import { RowComponentDefault } from 'shared/components/Table'
import { TableRowComponentProps } from 'react-table'
import { useStorylinesContext } from './index'
import { StoryRolesTableRow } from 'shared/storylines/StoryRolesTableRow'

export function StorylineRow(props: TableRowComponentProps<FeatureStoryline>): React.ReactElement {
    const { edit, editIdentifier } = useStorylinesContext()

    const {
        allColumns: { length: colSpan },
        index,
        row,
        row: {
            original: storyline,
            original: { storylineId, storyRoles },
        },
    } = props

    if (storylineId === editIdentifier) {
        // render edit form
        return (
            <tr key={`editing-${storylineId}`}>
                <td colSpan={colSpan}>
                    <StorylineForm key={storylineId} storyline={storyline} index={index} />
                </td>
            </tr>
        )
    }

    const mainRow = <RowComponentDefault {...props} />

    const hasStoryroles = !!storyRoles.length
    if (!hasStoryroles) {
        // render storyline that has no storyroles
        return mainRow
    }

    // render storyline with storyroles
    return (
        <React.Fragment key={row.getRowProps().key}>
            {mainRow}
            <StoryRolesTableRow {...props} onStoryRoleClick={() => edit(storyline)} />
        </React.Fragment>
    )
}
