import { Workflow, WorkflowHelper } from '@genome-web-forms/server'
import { useSelector } from '@xstate/react'
import React from 'react'
import Container from 'shared/components/Container'
import {
    useResourceMachine,
    useResourceMachineSelector,
    useResourceMachineService,
} from 'shared/resource/ResourceMachineProvider'
import { WorkflowMachineActor } from 'shared/resource/workflow.machine'
import { EmittedFrom } from 'xstate'
import { useStateCan } from 'xstate-helpers/react'
import { AlertDialog, AlertDialogLabel } from '@reach/alert-dialog'
import { Formik, Form, Field } from 'formik'
import { AlertDialogButtons } from 'shared/components/AlertModal'
import TextualButton from 'shared/components/TextualButton'
import Button from 'shared/components/Button'
import Loader from 'shared/components/Loader'
import { useMemo } from 'react'
import {
    DropdownMenu,
    DropdownMenuList,
    DropdownMenuItem,
    DropdownMenuItemSeparator,
    DropdownMenuButton,
} from 'shared/components/DropdownMenu'
import NoWrap from 'shared/components/NoWrap'
import StartEditingControl from 'shared/resource/StartEditingControl'
import { useSelfAssign } from 'shared/hooks/useSelfAssign'
import { AuthContext } from 'auth/Auth'
import { useReleaseWorkflow } from 'api/workflow/workflow'

type WorkflowControlProps = { workflowActorRef: WorkflowMachineActor }
const WorkflowControl = ({ workflowActorRef }: WorkflowControlProps): React.ReactElement | null => {
    const [showModal, setShowModal] = React.useState(false)
    const auth = React.useContext(AuthContext)
    const releaseWorkflow = useReleaseWorkflow()

    const workflow = useSelector(
        workflowActorRef,
        React.useCallback(
            (state: EmittedFrom<typeof workflowActorRef>) => state.context.workflow,
            [],
        ),
    )
    const workflowId = useMemo(() => workflow.workflowId, [workflow])

    const service = useResourceMachineService()

    const canSelfAssignWorkflow = useStateCan(service, { type: 'WORKFLOW_SELF_ASSIGN', workflow })
    const canUseSelfAssign = useSelfAssign(workflow)
    const canSelfAssign = canSelfAssignWorkflow && canUseSelfAssign

    const canStartWorkflow = useStateCan(service, { type: 'WORKFLOW_START', workflow })
    const canContinueWorkflow = useStateCan(service, { type: 'WORKFLOW_CONTINUE', workflow })
    const canCompleteActiveWorfklow = useStateCan(service, {
        type: 'WORKFLOW_COMPLETE_ACTIVE_WORKFLOW',
        workflow,
    })
    const canPause = useStateCan(service, { type: 'WORKFLOW_PAUSE', workflow })
    const canResume = useStateCan(service, { type: 'WORKFLOW_RESUME', workflow })

    const canReleaseWorkflowTask = useStateCan(service, { type: 'WORKFLOW_RELEASE_TASK', workflow })
    const canRelease =
        canReleaseWorkflowTask || workflow.assignee === auth.user['relationship.employeeId']

    const canPublish = useStateCan(service, { type: 'WORKFLOW_PUBLISH', workflow })
    const canStartReview = useStateCan(service, {
        type: 'WORKFLOW_REVIEW_MAKE_AVAILABLE',
        workflow,
    })
    const canApprove = useStateCan(service, { type: 'WORKFLOW_REVIEW_APPROVE', workflow })
    const canReject = useStateCan(service, { type: 'WORKFLOW_REVIEW_REJECT', workflow })

    const buttons: JSX.Element[] = []

    if (canSelfAssign) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-self-assign`}
                onSelect={() => service.send({ type: 'WORKFLOW_SELF_ASSIGN', workflow })}
            >
                Self-Assign
            </DropdownMenuItem>,
        )
    }

    if (canPublish) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-publish`}
                onSelect={() => service.send({ type: 'WORKFLOW_PUBLISH', workflow })}
            >
                Publish Workflow
            </DropdownMenuItem>,
        )
    }

    if (canRelease) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-abandon`}
                onSelect={() => releaseWorkflow.mutate([workflow])}
            >
                Release Workflow
            </DropdownMenuItem>,
        )
    }

    if (canResume) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-resume`}
                onSelect={() => service.send({ type: 'WORKFLOW_RESUME', workflow })}
            >
                Resume Workflow
            </DropdownMenuItem>,
        )
    }

    if (canPause) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-pause`}
                onSelect={() => service.send({ type: 'WORKFLOW_PAUSE', workflow })}
            >
                Pause Workflow
            </DropdownMenuItem>,
        )
    }

    if (canStartWorkflow) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-start`}
                onSelect={() => service.send({ type: 'WORKFLOW_START', workflow })}
            >
                Start Work
            </DropdownMenuItem>,
        )
    }

    if (canContinueWorkflow) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-continue`}
                onSelect={() => {
                    service.send({ type: 'WORKFLOW_CONTINUE', workflow })
                }}
            >
                Continue Working
            </DropdownMenuItem>,
        )
    }

    if (canCompleteActiveWorfklow) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-complete-active`}
                onSelect={() =>
                    service.send({ type: 'WORKFLOW_COMPLETE_ACTIVE_WORKFLOW', workflow })
                }
            >
                Complete Workflow
            </DropdownMenuItem>,
        )
    }

    if (canStartReview) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-start-review`}
                onSelect={() => service.send({ type: 'WORKFLOW_REVIEW_MAKE_AVAILABLE', workflow })}
            >
                Send for Review
            </DropdownMenuItem>,
        )
    }

    if (canApprove) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-review-approved`}
                onSelect={() => service.send({ type: 'WORKFLOW_REVIEW_APPROVE', workflow })}
            >
                Approve
            </DropdownMenuItem>,
        )
    }

    if (canReject) {
        buttons.push(
            <DropdownMenuItem
                key={`${workflowId}-review-rejected`}
                onSelect={() => setShowModal(true)}
            >
                Reject
                {showModal && (
                    <RejectModal
                        workflowActorRef={workflowActorRef}
                        closeModal={() => setShowModal(false)}
                    />
                )}
            </DropdownMenuItem>,
        )
    }

    if (buttons.length === 0) return null

    return (
        <>
            <DropdownMenuItemSeparator key={`${workflowId}`} onSelect={() => {}}>
                <NoWrap>{WorkflowHelper.title(workflow)}</NoWrap>
            </DropdownMenuItemSeparator>
            {buttons}
        </>
    )
}

const NonWorkflowControl = (): React.ReactElement | null => {
    return (
        <>
            <DropdownMenuItemSeparator onSelect={() => {}}>
                <NoWrap>Non-workflow Actions</NoWrap>
            </DropdownMenuItemSeparator>
            <StartEditingControl />
        </>
    )
}

const AllWorkflowControls = (): React.ReactElement | null => {
    // TODO react.usecallback
    const workflowActors = useResourceMachineSelector(state => state.context.workflowRefs)
    const [state] = useResourceMachine()

    const loading = React.useCallback(() => {
        const isloading = (
            [
                'aquiringLocks',
                'releasing',
                'releasingTaskWorkflow',
                'selfAssignWorkflow',
                'startReviewWorkflow',
                'completingActiveWorkflow',
                'approveWorkflow',
                'rejectWorkflow',
            ] as any[]
        ).some(state.matches)
        return isloading
    }, [state])

    return (
        <Container flex="1" justifyContent="flex-end" pr="2">
            {loading() ? (
                <Loader data-testid="loader-comments" size="small" center />
            ) : (
                <DropdownMenu>
                    {({ isExpanded }) => (
                        <>
                            <DropdownMenuButton>
                                Actions <span aria-hidden>&nbsp;{isExpanded ? `▴` : `▾`}</span>
                            </DropdownMenuButton>
                            <DropdownMenuList>
                                {workflowActors
                                    .map(workflowActorRef => (
                                        <WorkflowControl
                                            key={
                                                workflowActorRef.getSnapshot()!.context.workflow
                                                    .workflowId
                                            }
                                            workflowActorRef={workflowActorRef}
                                        />
                                    ))
                                    .filter(Boolean)}
                                <NonWorkflowControl />
                            </DropdownMenuList>
                        </>
                    )}
                </DropdownMenu>
            )}
        </Container>
    )
}

const RejectModal: React.FC<{ closeModal: Function; workflowActorRef: WorkflowMachineActor }> = ({
    closeModal,
    workflowActorRef,
}) => {
    const cancelRef = React.createRef<HTMLButtonElement>()
    const commentRef = React.createRef<HTMLButtonElement>()

    const service = useResourceMachineService()

    setTimeout(() => commentRef.current?.focus(), 0)

    const initialValues: { comment: string } = { comment: '' }

    const workflow = useSelector(
        workflowActorRef,
        React.useCallback(
            (state: EmittedFrom<typeof workflowActorRef>) => state.context.workflow,
            [],
        ),
    )

    const onSubmit = (values: { comment: string }): void => {
        const _workflow: Workflow = { ...workflow, comment: values.comment }
        service.send({ type: 'WORKFLOW_REVIEW_REJECT', workflow: _workflow })
        closeModal()
    }

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {({ values, setValues }) => (
                    <Form>
                        <AlertDialogLabel>
                            Please, enter a reason to reject the current item
                        </AlertDialogLabel>
                        <Container mt={3} mb="2" flexDirection="column">
                            <Field
                                as="textarea"
                                style={{ width: '100%', height: '80%' }}
                                rows="10"
                                name="comment"
                                innerRef={commentRef}
                            />
                        </Container>

                        <AlertDialogButtons>
                            <Button variant="primary" type="submit">
                                Reject
                            </Button>
                            <TextualButton
                                type="button"
                                onSelect={() => closeModal()}
                                ref={cancelRef}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

export default AllWorkflowControls
