import React from 'react'

import styled from 'shared/theme'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import { ReactComponent as CheckIconBase } from 'shared/components/CheckboxRadioInput/Checkbox/icon.svg'

const StepperWrapper = styled(Container)`
    min-width: 6.25rem;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
`

const StepWrapper = styled(Container)`
    position: relative;
    flex: 1;
    justify-content: center;
`

const StepInfo = styled.span<{ isActive?: boolean; isCompleted?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme, isActive, isCompleted }) =>
        isActive || isCompleted ? theme.colors.textPrimary : theme.colors.textPrimaryDisabled};
`

const StepNumberWrapper = styled.span<{ isActive?: boolean; isCompleted?: boolean }>`
    width: 24px;
    height: 24px;
    background-color: ${({ theme, isActive, isCompleted }) =>
        isActive || isCompleted ? theme.colors.primary : theme.colors.border};
    border-radius: 12px;
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.white};
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
`

const StepNumber = styled(Text).attrs({
    size: '5',
})`
    vertical-align: middle;
    line-height: 1.4375rem;
`

const StepLabel = styled.span`
    width: 100%;
    margin-top: 0.5rem;
`

const Connector = styled.div`
    top: 12px;
    left: calc(-50% + 20px);
    right: calc(50% + 20px);
    position: absolute;
    flex: 1 1 auto;
`

const ConnectorLine = styled.span`
    border-top-style: solid;
    border-top-width: 1px;
    display: block;
    border-color: ${({ theme }) => theme.colors.border};
`

const CheckIcon = styled(CheckIconBase)`
    path {
        stroke: ${({ theme }) => theme.colors.white};
    }
    width: 12px;
    height: 12px;
    vertical-align: middle;
    margin-bottom: -2px;
`

const Stepper: React.FC<{ activeStep: number } & React.HTMLAttributes<HTMLDivElement>> = ({
    activeStep,
    children,
    ...rest
}) => {
    const childrenArray = React.Children.toArray(children)

    const steps = childrenArray.map((step, index) => {
        if (React.isValidElement(step)) {
            return React.cloneElement(step, {
                index,
                activeStep,
                isFirst: index === 0,
                isActive: index === activeStep,
                ...step.props,
            })
        }

        return null
    })

    return (
        <StepperWrapper alignItems="center" {...rest}>
            {steps}
        </StepperWrapper>
    )
}

const Step: React.FC<{
    index?: number
    isActive?: boolean
    isCompleted?: boolean
}> = ({ index = 0, isActive, isCompleted, children }) => {
    const withConnector = index > 0

    return (
        <StepWrapper flex="1">
            {withConnector && (
                <Connector>
                    <ConnectorLine />
                </Connector>
            )}
            <StepInfo isActive={isActive} isCompleted={isCompleted}>
                <StepNumberWrapper isActive={isActive} isCompleted={isCompleted}>
                    {isCompleted ? <CheckIcon /> : <StepNumber>{index + 1}</StepNumber>}
                </StepNumberWrapper>
                {children}
            </StepInfo>
        </StepWrapper>
    )
}

export { Stepper, Step, StepLabel }
