import React from 'react'

export type IndeterminateCheckboxProps = React.HTMLProps<HTMLInputElement> & {
    indeterminate: boolean
}
const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef<HTMLInputElement>(null)
        const resolvedRef: React.RefObject<HTMLInputElement> = (ref as any) || defaultRef

        React.useEffect(() => {
            resolvedRef.current!.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return <input type="checkbox" ref={resolvedRef} {...rest} />
    },
)

export default IndeterminateCheckbox
