import * as y from 'yup'
import { getTaskOptionsForSelectedTitles, TaskType } from './TaskType'

// eslint-disable-next-line
export const createAssignmentConfigurationSchema = (
    selectedTitles: { cwmClassType: string }[],
    requiredTasks = false,
) => {
    const allowedTasks = getTaskOptionsForSelectedTitles(selectedTitles).map(o => o.value)

    return y
        .object({
            tasks: y
                .array()
                .of(y.string().defined().oneOf(allowedTasks))
                .required()
                .min(requiredTasks ? 1 : 0)
                .label('Tasks'),
            priority: y.string().nullable().label('Priority'),
            assignee: y.string().nullable().label('Assignee'),
            deadlineAt: y.string().nullable().label('Due Date'),
            reviewRequired: y.boolean().label('Needs Review').default(false),
            source: y.string().nullable().label('Source'),
        })
        .defined()
}

export type AssignmentConfiguration = {
    tasks: TaskType[]
    priority: 'LOW' | 'MEDIUM' | 'HIGH'
    assignee: string | null
    deadlineAt: string | null
    comment: string | null
    reviewRequired: boolean
    source: string | null
}
