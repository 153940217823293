import isEqual from 'lodash/isEqual'

import { request } from '@genome-web-forms/common/api'
import { AssetManifestCodec, AssetManifest } from 'codecs/misc/AssetManifest'
import { isCodecError } from '@genome-web-forms/common/error'

import { HistoryInterceptor } from 'api/UpdatePoller/HistoryInterceptor'

const debug = require('debug')('gwf.UpdatePoller') // eslint-disable-line

export class UpdatePoller {
    static POLLING_INTERVAL = 60_000
    public assetManifest?: AssetManifest
    private timer?: ReturnType<typeof setTimeout>

    start(): void {
        this.queuePoll()
        debug('start polling')
    }

    stop(): void {
        debug('stop polling')
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = undefined
        }
    }

    async executePoll(): Promise<void> {
        debug('executing poll')

        let newManifest: AssetManifest
        try {
            newManifest = await request(AssetManifestCodec, {
                url: `/asset-manifest.json?cachebust=${+new Date()}`,
            })
        } catch (e) {
            if (isCodecError(e)) {
                console.warn(`Cannot parse asset manifest: ${e}`)
            } else {
                debug('network error, ignoring and queuing a new poll')
            }
            return this.queuePoll()
        }

        const forceBrowserRefresh = this.assetManifest
            ? !isEqual(this.assetManifest, newManifest)
            : false
        this.assetManifest = newManifest

        debug('forceBrowserRefresh', forceBrowserRefresh)

        HistoryInterceptor.instance().forceBrowserRefresh = forceBrowserRefresh

        if (!forceBrowserRefresh) {
            this.queuePoll()
        }
    }

    private queuePoll(): void {
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => this.executePoll(), UpdatePoller.POLLING_INTERVAL)
    }

    private static _instance: UpdatePoller
    static instance(): UpdatePoller {
        return this._instance || (this._instance = new UpdatePoller())
    }

    static create(): void {
        // create history interceptor instance
        HistoryInterceptor.create()
        // create update poller instance
        UpdatePoller.instance()
    }
}
