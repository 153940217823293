import { Either, isLeft, isRight } from 'fp-ts/Either'
import { DoneInvokeEvent, createMachine, sendParent, assign } from 'xstate'
import { Publish, PublishCodec } from 'codecs/publish-workflow/Publish'
import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { authGWF } from 'api/auth'
import config from 'shared/config'
import {
    PublishWorkflowCombinedStatusCodec,
    PublishWorkflowPending,
    PublishWorkflowPendingCodec,
    PublishWorkflowFulfilledCodec,
    PublishWorkflowErrorLock,
    PublishWorkflowErrorLockCodec,
    PublishWorkflowErrorGenericCodec,
} from 'codecs/publish-workflow/PublishWorkflow'
import {
    parseWorkflowFulfilled,
    WorkflowSuccess,
    WorkflowError,
} from 'codecs/publish-workflow/parse'
import { assertNever } from 'shared/util/assertNever'
import invariant from 'tiny-invariant'
import { WorkflowMachineActor, createWorkflowMachinePoller } from './workflow.machine'

type PollResultGuard = (ctx: PublishContext, e: DoneInvokeEvent<PollResult>) => boolean
const isPendingResponse: PollResultGuard = (_, e) => PublishWorkflowPendingCodec.is(e.data)
const escalateError = sendParent((_, e: any) => ({ type: 'ERROR', data: e.data }))

export type PublishProperty = 'metadata' | 'relationships' | 'storylines'
export interface PublishContext {
    resourceId: string
    user: MyIDUser
    publishProperties: PublishProperty[]
    executionArn?: string
    result?: PollResultComplete
    workflowRef?: WorkflowMachineActor
}
export type PublishEvent = never
export interface PublishState {
    value: 'triggerPublish' | 'idle' | 'polling' | 'done'
    context: PublishContext
}
export const publishMachine = createMachine<PublishContext, PublishEvent, PublishState>(
    {
        id: 'publish',
        initial: 'triggerPublish',
        states: {
            triggerPublish: {
                invoke: {
                    id: 'trigger-publish',
                    src: ctx => triggerPublish(ctx),
                    onDone: {
                        actions: assign({
                            executionArn: (_, e: DoneInvokeEvent<Publish>) => e.data.executionArn,
                        }),
                        target: 'shouldNotifyWorkflowStart',
                    },
                    onError: {
                        actions: 'escalateError',
                    },
                },
            },
            shouldNotifyWorkflowStart: {
                always: [
                    {
                        cond: 'hasWorfklow',
                        target: 'notifyWorkflowPublishStarted',
                    },
                    { target: 'idle' },
                ],
            },
            notifyWorkflowPublishStarted: {
                invoke: {
                    src: ctx =>
                        createWorkflowMachinePoller({
                            type: 'PUBLISH_START',
                            executionArn: ctx.executionArn!,
                        }) as any,
                    data: { workflowRef: (ctx: any) => ctx.workflowRef },
                    onDone: {
                        target: 'polling',
                    },
                    onError: {
                        actions: 'escalateError',
                    },
                },
            },
            idle: {
                after: {
                    2000: 'polling',
                },
            },
            polling: {
                invoke: {
                    id: 'poll-publish-status',
                    src: ctx => pollPublishStatus(ctx),
                    onDone: [
                        {
                            cond: isPendingResponse,
                            target: 'idle',
                        },
                        {
                            actions: assign({
                                result: (_, e) => e.data,
                            }),
                            target: 'shouldNotifyWorkflowSettled',
                        },
                    ],
                    onError: [
                        { cond: 'hasWorfklow', target: 'notifyWorkflowErrorAndEscalate' },
                        { actions: 'escalateError' },
                    ],
                },
            },
            shouldNotifyWorkflowSettled: {
                always: [
                    { cond: 'hasWorfklow', target: 'notifyWorkflowPublishSettled' },
                    { target: 'done' },
                ],
            },
            notifyWorkflowErrorAndEscalate: {
                entry: 'escalateError',

                invoke: {
                    src: createWorkflowMachinePoller({
                        type: 'PUBLISH_ERROR',
                        error: 'Unexpected error',
                    }),
                    data: { workflowRef: (ctx: any) => ctx.workflowRef },
                },
            },
            notifyWorkflowPublishSettled: {
                invoke: {
                    src: ({ result }) => {
                        invariant(result)
                        return createWorkflowMachinePoller(
                            PublishWorkflowErrorLockCodec.is(result)
                                ? { type: 'PUBLISH_ERROR', error: 'Could not aquire lock' }
                                : isLeft(result)
                                ? {
                                      type: 'PUBLISH_ERROR',
                                      error: JSON.stringify(result.left.errors),
                                  }
                                : { type: 'PUBLISH_SUCCESS' },
                        ) as any
                    },
                    data: { workflowRef: (ctx: any) => ctx.workflowRef },

                    onDone: {
                        target: 'done',
                    },
                    onError: {
                        actions: 'escalateError',
                    },
                },
            },
            done: {
                type: 'final',
                data: ({ result }): PublishMachineResult => {
                    invariant(result)
                    if (PublishWorkflowErrorLockCodec.is(result)) {
                        return { status: 'lockError', result }
                    } else if (isLeft(result)) {
                        return { status: 'error', result: result.left }
                    } else {
                        return { status: 'success', result: result.right }
                    }
                },
            },
        },
    },
    {
        guards: {
            hasWorfklow: ctx => !!ctx.workflowRef,
            isPollResultLockError: ctx =>
                !!ctx.result && PublishWorkflowErrorLockCodec.is(ctx.result),
            isPollResultUnknownError: ctx =>
                !!ctx.result && !PublishWorkflowErrorLockCodec.is(ctx.result) && isLeft(ctx.result),
            isPollResultSuccess: ctx =>
                !!ctx.result &&
                !PublishWorkflowErrorLockCodec.is(ctx.result) &&
                isRight(ctx.result),
        },
        actions: {
            escalateError: escalateError as any,
        },
    },
)

export type PublishMachineResult =
    | {
          status: 'success'
          result: WorkflowSuccess
      }
    | {
          status: 'error'
          result: WorkflowError
      }
    | {
          status: 'lockError'
          result: PublishWorkflowErrorLock
      }

export const guardPublishResult =
    <C>(status: PublishMachineResult['status']) =>
    (_ctx: C, e: DoneInvokeEvent<PublishMachineResult>): boolean => {
        invariant(e.data, 'Unexpected event given')
        invariant(e.data.status, 'Unexpected event given')
        return e.data.status === status
    }

async function triggerPublish({
    resourceId,
    user,
    publishProperties,
}: PublishContext): Promise<Publish> {
    const url = `${config.urlGWFPublish}/publish?targetResourceId=${encodeURIComponent(resourceId)}`

    return request(
        PublishCodec,
        authGWF(user, {
            url,
            method: 'POST',
            data: { publishProperties },
        }),
    )
}

type PollResult = PublishWorkflowPending | PollResultComplete
type PollResultComplete = PublishWorkflowErrorLock | Either<WorkflowError, WorkflowSuccess>

async function pollPublishStatus({ user, executionArn }: PublishContext): Promise<PollResult> {
    const url = `${config.urlGWFPublish}/status?executionArnBase64=${btoa(executionArn!)}`
    const res = await request(PublishWorkflowCombinedStatusCodec, authGWF(user, { url }))
    if (PublishWorkflowPendingCodec.is(res) || PublishWorkflowErrorLockCodec.is(res)) {
        return res
    }
    if (PublishWorkflowFulfilledCodec.is(res)) {
        return parseWorkflowFulfilled(res)
    }
    if (PublishWorkflowErrorGenericCodec.is(res)) {
        throw new Error(`Unkown Publish Error\n${JSON.stringify(res, null, 2)}`)
    }

    assertNever(res)
}
