import React from 'react'

import { ARC_OPTIONS, ArcOption } from 'codecs/Arc'
import { FormikArcSelect } from 'shared/components/Select/FormikArcSelect'
import ErrorMessage from 'shared/components/ErrorMessage'
import { StoryRoleAttribute, STORYROLE_TYPE } from 'codecs/storyline/StoryRole'
import { AttributeValue } from 'codecs/Attribute'
import { useTRSSimpleFind } from 'shared/hooks/useTRS'
import { usePureFn } from 'shared/hooks/usePureFn'
import { FormikTRSSelect, ImplementerFormikTRSSelectProps } from 'shared/components/TRS/TRSSelect'

import { filterAndPreserveIndex, Indexed } from 'shared/util/filterAndPreserveIndex'

import { RowContainer, ClearButton, AddButton } from 'shared/components/ArcAttributes'

import { matchTypeOrSubtype } from 'shared/components/Storyrole/matchTypeOrSubtype'

type IndexedRoleAttribute = Indexed<StoryRoleAttribute>

type StoryroleAttributeProps = {
    label: string
    storyroleType: STORYROLE_TYPE
    storyRoleAttributes: StoryRoleAttribute[]
    storyRoleIndex: number
    push: (item: StoryRoleAttribute) => void
    remove: (index: number) => void
}
const StoryroleAttribute: React.FC<StoryroleAttributeProps> = ({
    storyroleType,
    label,
    storyRoleAttributes,
    push,
    storyRoleIndex,
    remove,
}): React.ReactElement => {
    /**
     * Extracted attributes that match the given story role type,
     * preserving their original indexes for later manipulation
     */
    const attributesForType: IndexedRoleAttribute[] = React.useMemo(
        () => filterAndPreserveIndex(storyRoleAttributes, matchTypeOrSubtype(storyroleType)),
        [storyRoleAttributes, storyroleType],
    )

    const usedArcs = attributesForType.map(attr => attr.arc)
    const availableArcs = ARC_OPTIONS.filter(option => !usedArcs.includes(option.value))

    return (
        <div>
            {attributesForType.map((storyroleAttribute, idx) => (
                <StoryroleAttributeRow
                    storyRoleIndex={storyRoleIndex}
                    storyroleType={storyroleType}
                    storyroleAttribute={storyroleAttribute}
                    key={storyroleAttribute.arc + String(idx)}
                    label={label}
                    availableArcs={availableArcs}
                    remove={remove}
                />
            ))}
            <AddButton
                disabled={availableArcs.length === 0}
                onClick={() => {
                    const storyroleAttribute: StoryRoleAttribute = {
                        arc: availableArcs[0].value,
                        values: [],
                        ...storyroleType,
                    }
                    push(storyroleAttribute)
                }}
            >
                Add {label}
            </AddButton>
        </div>
    )
}

export default React.memo(StoryroleAttribute)

type StoryroleAttributeRowProps = {
    storyRoleIndex: number
    storyroleType: STORYROLE_TYPE
    label: string
    availableArcs: ArcOption[]
    storyroleAttribute: IndexedRoleAttribute
    remove: (index: number) => void
}

export const StoryroleAttributeRow: React.FC<StoryroleAttributeRowProps> = ({
    availableArcs,
    storyroleType,
    storyroleAttribute: { originalIndex: attributeOriginalIndex },
    storyRoleIndex,
    remove,
}): React.ReactElement => {
    return (
        <RowContainer>
            <StoryroleAttributeValuesSelect
                storyroleType={storyroleType}
                name={`storyRoles[${storyRoleIndex}].attributes[${attributeOriginalIndex}].values`}
            />

            <FormikArcSelect
                name={`storyRoles[${storyRoleIndex}].attributes[${attributeOriginalIndex}].arc`}
                availableArcs={availableArcs}
            />

            <ClearButton
                onClick={() => {
                    remove(attributeOriginalIndex)
                }}
            >
                &#10005;
            </ClearButton>
            <ErrorMessage
                name={`storyRoles[${storyRoleIndex}].attributes[${attributeOriginalIndex}].values`}
            />
        </RowContainer>
    )
}

type StoryroleAttributeValuesSelectProps = {
    storyroleType: STORYROLE_TYPE
} & ImplementerFormikTRSSelectProps<AttributeValue>
const StoryroleAttributeValuesSelect: React.FC<StoryroleAttributeValuesSelectProps> = ({
    storyroleType,
    ...props
}): React.ReactElement => {
    const { terms } = useTRSSimpleFind(storyroleType.typeId)

    return (
        <FormikTRSSelect<AttributeValue>
            {...props}
            isMulti={true}
            terms={terms}
            termToOption={usePureFn(term => ({
                attributeId: term.id,
                attributeLabel: term.label,
            }))}
            getOptionValue={usePureFn(option => option.attributeId)}
            getOptionLabel={usePureFn((option, term) =>
                term ? term.label : option.attributeLabel,
            )}
        />
    )
}
