import * as t from 'io-ts'
import * as y from 'yup'
import * as s from 'shared/diff/schema'
import { fromNullable } from 'codecs/util/fromNullable'
import { nullable } from 'codecs/util/nullable'
import { orderBy } from 'natural-orderby'

import {
    StorylineAttributeCodec,
    StorylineAttributeFormSchema,
} from 'codecs/storyline/StorylineAttribute'
import {
    StoryRoleCodec,
    StoryRoleFormSchema,
    StoryRoleDiffSchema,
    toDiffableStoryRole,
} from 'codecs/storyline/StoryRole'

export const EpisodeStorylineCodec = t.type({
    storylineId: t.string,
    storylineLogLine: fromNullable(t.string, ''),
    storylineWeight: fromNullable(t.string, ''),
    storyRoles: fromNullable(t.array(StoryRoleCodec), []),
    storylineArchetypes: fromNullable(t.array(StorylineAttributeCodec), []),
    description: fromNullable(t.string, ''),
    type: nullable(
        t.type({
            typeId: t.string,
            typeLabel: t.string,
        }),
    ),
})

export interface EpisodeStoryline extends t.TypeOf<typeof EpisodeStorylineCodec> {}

/////////////////////////////////////////////
// Constants
/////////////////////////////////////////////

export const STORYLINE_TYPES = [
    {
        typeId: 'http://data.disney.com/resources/b43a4fab-a357-4a38-af39-40d3e18faf4d',
        typeLabel: 'Hybrid',
    },
    {
        typeId: 'http://data.disney.com/resources/a8d44204-5c88-4446-9834-ff8f6818514c',
        typeLabel: 'Serial',
    },
    {
        typeId: 'http://data.disney.com/resources/c5797f3d-ec62-4d45-959e-3e75612151d7',
        typeLabel: 'Procedural',
    },
]

export const EpisodeStorylineDiffSchema = s.schema({
    storylineId: s.ignore<string>(),
    storylineLogLine: s.text(),
    storylineWeight: s.value<string>(),
    storyRoles: s.array(StoryRoleDiffSchema, 'storyRoleId', 'character'),
    storylineArchetypes: s.array(s.value<string>()),
    description: s.text(),
    type: s.value(),
})
export type DiffableEpisodeStoryline = s.TypeOf<typeof EpisodeStorylineDiffSchema>
export const toDiffableEpisodeStoryline = (es: EpisodeStoryline): DiffableEpisodeStoryline => {
    return {
        storylineId: es.storylineId,
        storylineLogLine: es.storylineLogLine,
        storylineWeight: es.storylineWeight || '<<Empty>>',
        storyRoles: es.storyRoles.map(toDiffableStoryRole),
        storylineArchetypes: orderBy(es.storylineArchetypes.map(v => v.label)),
        description: es.description,
        type: es.type?.typeLabel ?? '<<Empty>>',
    }
}

export type DiffableStoryline = s.TypeOf<typeof EpisodeStorylineDiffSchema>

/////////////////////////////////////////////
// YUP VALIDATION
/////////////////////////////////////////////

const EpisodestorylineTypeFormSchema = y
    .object({
        typeId: y.string().required(),
        typeLabel: y.string().required(),
    })
    .nullable()
    .required()
    .label('Type')
export const EpisodeStorylineFormSchema = y
    .object({
        storylineId: y.string().required(),
        storylineWeight: y.string().required().label('Weight'),
        storylineLogLine: y.string().required().label('Logline'),
        description: y.string().label('Description'),
        storylineArchetypes: y
            .array()
            .of(StorylineAttributeFormSchema)
            .required()
            .label('Story Archetype'),
        type: EpisodestorylineTypeFormSchema,
        storyRoles: y.array().defined().of(StoryRoleFormSchema),
    })
    .defined()
    .required()

export type EpisodeStorylineFormValue = y.TypeOf<typeof EpisodeStorylineFormSchema> & {
    storylineId: string
}
