export function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Insert into a promise chanin to delay data resolve like so:
 *
 * fetch(...).then(delayData(500)).then(response => ...)
 */
export const delayData =
    <T>(ms: number) =>
    (data: T): Promise<T> =>
        new Promise(resolve => setTimeout(() => resolve(data), ms))
