import { StoryRoleAttribute, STORYROLE_TYPE } from 'codecs/storyline/StoryRole'

/**
 * Get a function that matches a type or any of its subtypes
 *
 * Eg, given "http://data.disney.com/resources/Motivations" will match:
 *  - "http://data.disney.com/resources/Motivations"
 *  - "http://data.disney.com/resources/Motivations/Personal"
 *  - "http://data.disney.com/resources/Motivations/Security"
 * (etc)
 */
export const matchTypeOrSubtype =
    ({ typeId }: STORYROLE_TYPE) =>
    (attribute: StoryRoleAttribute): boolean =>
        attribute.typeId.startsWith(typeId)
