import React from 'react'
import isUndefined from 'lodash/isUndefined'
import { useField, useFormikContext } from 'formik'

import { ValueType, ActionMeta, FocusEventHandler } from 'react-select'
import {
    MemoAsyncSelect,
    MemoSelect,
    normalizeReactSelectValue,
    SafeSelectProps,
} from 'shared/components/Select'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import { AsyncProps } from 'react-select/async'

type BasicOption = { [key: string]: any }

export type FormikSelectProps<T> = SafeSelectProps<T> & {
    name: string
    selectComponent?: typeof MemoSelect | typeof MemoAsyncSelect
} & Partial<AsyncProps<T>>

export function FormikSelect<T extends BasicOption>(
    props: FormikSelectProps<T>,
): React.ReactElement {
    let [{ value }] = useField<T | T[]>(props.name)
    value = isUndefined(props.value) ? value : (props.value as T)

    const { setFieldValue, setFieldTouched } = useFormikContext<any>()

    const {
        onChange: outerOnChange,
        onBlur: outerOnBlur,
        selectComponent = MemoSelect,
        ...restProps
    } = props

    const onChange = useEventCallback((value: ValueType<T>, meta: ActionMeta<T>) => {
        const normalizedVal = normalizeReactSelectValue(value, !!props.isMulti)

        setFieldValue(props.name, normalizedVal)

        if (outerOnChange) {
            outerOnChange(value, meta)
        }
    })

    const onBlur: FocusEventHandler = useEventCallback(event => {
        setFieldTouched(props.name)
        if (outerOnBlur) {
            outerOnBlur(event)
        }
    })

    const renderProps = { ...restProps, onChange, value, onBlur }
    return selectComponent(renderProps as any)
}

export default FormikSelect
