import { isCodecError } from '@genome-web-forms/common/error'
import { isCORSError, isUnauthenticatedError } from '@genome-web-forms/common/error'
import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            useErrorBoundary: true,
            retry: (retryCount, error) => {
                return isCORSError(error) || isUnauthenticatedError(error) || isCodecError(error)
                    ? false
                    : retryCount < (process.env.NODE_ENV === 'production' ? 3 : 0)
            },
        },
        mutations: {
            useErrorBoundary: true,
        },
    },
})
