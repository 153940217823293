import React from 'react'
import * as s from 'shared/diff/schema'

import { Column, CellProps } from 'react-table'
import { Table } from 'shared/components/Table'
import { Op } from 'shared/diff/Op'
import { DiffValue } from 'shared/diff/DiffValue'
import { ArcRows } from 'shared/components/ArcRows'
import { DevelopmentValue } from 'shared/diff/components/DevelopmentValue'
import { DevelopmentsDiff } from 'shared/diff/components/DevelopmentsDiff'
import {
    DiffableRelationship,
    RelationshipDiffSchema,
    toDiffableRelationship,
} from 'codecs/Relationship'
import Container from 'shared/components/Container'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import flatMap from 'lodash/flatMap'
import Text from 'shared/components/Text'
import { useWIPDiff } from 'shared/resource/ResourceMachineProvider'

type RelationshipsDiffOps = s.ArrayDiffOps<DiffableRelationship>
const RelationshipsDiffsTableColumns: Column<RelationshipsDiffOps>[] = [
    {
        Header: 'Character 1',
        accessor: op => op.value.character1,
        Cell: ({ row: { original: op } }: CellProps<s.ArrayDiffOps<DiffableRelationship>>) => {
            if (s.isSchemaDiff(op)) {
                return <DiffValue diff={op.ops.character1} />
            }
            return <Op type={op.type}>{op.value.character1}</Op>
        },
    },
    {
        Header: 'Character 2',
        accessor: op => op.value.character2,
        Cell: ({ row: { original: op } }: CellProps<s.ArrayDiffOps<DiffableRelationship>>) => {
            if (s.isSchemaDiff(op)) {
                return <DiffValue diff={op.ops.character2} />
            }
            return <Op type={op.type}>{op.value.character2}</Op>
        },
    },
    {
        Header: 'Relationship Development',
        accessor: op => flatMap(op.value.relDevelopments, rd => rd.labels),
        Cell: ({ row: { original: op } }: CellProps<s.ArrayDiffOps<DiffableRelationship>>) => {
            return (
                <ArcRows>
                    {s.isSchemaDiff(op) ? (
                        <DevelopmentsDiff diff={op.ops.relDevelopments} />
                    ) : (
                        op.value.relDevelopments.map(relDev => {
                            return (
                                <DevelopmentValue
                                    diffType={op.type}
                                    key={relDev.arc}
                                    attr={relDev}
                                />
                            )
                        })
                    )}
                </ArcRows>
            )
        },
    },
]

export const RelationshipsDiff = (): React.ReactElement => {
    const { ops } = useWIPDiff(
        'relationships',
        s.array(RelationshipDiffSchema, 'relationshipId'),
        relationships => relationships.map(toDiffableRelationship),
    )

    return (
        <>
            <Container alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Relationships{' '}
                    </Text>
                </TableHeaderContent>
            </Container>

            <Table<RelationshipsDiffOps>
                {...{
                    columns: RelationshipsDiffsTableColumns,
                    data: ops,
                    getRowProps: ({ original: op }) => ({
                        diffType: s.isSchemaDiff(op) ? 'same' : op.type,
                    }),
                    emptyMessage: 'No changes',
                }}
            />
        </>
    )
}
export default RelationshipsDiff
