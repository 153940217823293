import * as t from 'io-ts'
import { fromNullable } from 'codecs/util/fromNullable'

export type TaxonomyTerm = {
    id: string
    label: string
    terms?: TaxonomyTerm[]
    notForTagging?: boolean
    preferredTerms?: {
        id: string
        label: string
    }[]
}

export const TaxonomyTermCodec: t.Type<TaxonomyTerm> = t.recursion(
    'TaxonomyTerm',
    () =>
        t.type({
            id: t.string,
            label: t.string,
            terms: fromNullable(t.array(TaxonomyTermCodec), []),
            preferredTerms: fromNullable(t.array(t.type({ id: t.string, label: t.string })), []),
            notForTagging: fromNullable(t.boolean, false),
        }) as any,
)

export const TaxonomyCategoryCodec = t.type({
    category: t.string,
    label: t.string,
    terms: t.array(TaxonomyTermCodec),
    notForTagging: fromNullable(t.boolean, false),
})

export type TaxonomyCategory = t.TypeOf<typeof TaxonomyCategoryCodec>

export const TaxonomyWithCategoriesCodec = t.type({
    label: t.string,
    type: t.string,
    categories: t.array(TaxonomyCategoryCodec),
})

export type TaxonomyWithCategories = t.TypeOf<typeof TaxonomyWithCategoriesCodec>

/**
 * A Simple Taxonomy is a transformed Taxonomy that directly contains "terms".
 * Most original Taxonomies are simple, because they contain only a single category,
 * that is a reference back to themselves. "Motivations" is the only taxonomy with actual
 * categories at the time of writing.
 *
 * A Simple Taxonomy removes the need to access `taxonomy.categories[0].terms`, instead
 * providing `taxonomy.terms` directly.
 */
export type SimpleTaxonomy = Omit<TaxonomyWithCategories, 'categories'> & {
    terms: TaxonomyTerm[]
}
