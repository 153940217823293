import { toast, ToastContent, ToastOptions } from 'react-toastify'
import { AnyEventObject } from 'xstate'

import 'react-toastify/dist/ReactToastify.min.css'
import 'css/toasty-overwrite.css'
import invariant from 'tiny-invariant'
const ERROR_AUTO_CLOSE_TIMEOUT = 15000 // 15 seconds for errors

const toastInfo = toast.info
const toastError = toast.error
const toastWarn = toast.warn

export const notification = Object.assign(toast, {
    info: (content: ToastContent, options: ToastOptions = {}) =>
        toastInfo(content, {
            autoClose: false,
            ...options,
        }),
    error: (content: ToastContent, options: ToastOptions = {}) =>
        toastError(content, {
            autoClose: ERROR_AUTO_CLOSE_TIMEOUT,
            ...options,
        }),
    errorSticky: (content: ToastContent, options: ToastOptions = {}) =>
        toastError(content, {
            autoClose: false,
            ...options,
        }),
    warnSticky: (content: ToastContent, options: ToastOptions = {}) =>
        toastWarn(content, {
            autoClose: false,
            ...options,
        }),
})

export type NotificationDefinition = ToastOptions & {
    content: ToastContent
}

export type NotificationEvent = {
    type: 'NOTIFICATION'
    toast: NotificationDefinition
}

export const notificationEvent = (toast: NotificationEvent['toast']): NotificationEvent => ({
    type: 'NOTIFICATION',
    toast,
})

export const notificationAction = <C>(_ctx: C, e: AnyEventObject & NotificationEvent): void => {
    invariant(e.type === 'NOTIFICATION')
    const { content, ...options } = e.toast
    notification(content, options)
}
