import { ConditionPredicate, StateMachine, InterpreterFrom } from 'xstate'
import { createModel } from 'xstate/lib/model'
import uniqBy from 'lodash/uniqBy'
import {
    AssignmentConfiguration,
    createAssignmentConfigurationSchema,
} from 'model/workflow/AssignmentConfiguration'
import {
    AssignmentTitle,
    createRequestsFromTitleAndConfiguration,
    mergeAssignmentTitleConfiguration,
} from 'model/workflow/CreateWorkflowRequest'
import type { CreateWorkflowRequest_CREATIVE_WORK_TAGGING_Input } from '@genome-web-forms/server'
import { MyIDUser } from '@genome-web-forms/common/auth'
import invariant from 'tiny-invariant'
import { createAssignment } from 'api/workflow/workflow'
import { isNestCustomErrorCode } from '@genome-web-forms/common/error'
import { notification } from 'shared/notification'

export type CreateAssignmentService = InterpreterFrom<
    ReturnType<typeof createAssignmentsMachineFactory>
>

type CreateAssignmentEvent =
    // navigation
    | { type: 'GO_TO_CONFIGURATION' }
    | { type: 'RETURN_TO_SELECTION' }
    | { type: 'START_CREATION' }
    | { type: 'RETRY_CREATION' }
    | { type: 'RESET' }

    // title selection
    | { type: 'ADD_SELECTED_TITLES'; titles: AssignmentTitle[] }
    | { type: 'REMOVE_SELECTED_TITLE'; title: AssignmentTitle }
    | { type: 'REMOVE_ALL_SELECTED_TITLES' }

    // configuration: global
    | { type: 'GLOBAL_CONFIGURATION_UPDATE'; globalConfiguration: AssignmentConfiguration }

    // configuration: per-title
    | { type: 'TITLE_CONFIGURATION_EDIT_START'; title: AssignmentTitle }
    | {
          type: 'TITLE_CONFIGURATION_EDIT_CONFIRM'
          title: AssignmentTitle
          configuration: Partial<AssignmentConfiguration>
      }
    | {
          type: 'TITLE_CONFIGURATION_EDIT_UPDATE'
          title: AssignmentTitle
          editingConfiguration: Partial<AssignmentConfiguration>
      }
    | { type: 'TITLE_CONFIGURATION_EDIT_CANCEL'; title: AssignmentTitle }

export type CreateAssignmentState = {
    value: 'selection' | 'configuration' | 'create-assignments' | 'error' | 'success'
    context: CreateAssignmentContext
}

export type Selection = {
    title: AssignmentTitle
    configuration: Partial<AssignmentConfiguration>
    editingConfiguration: Partial<AssignmentConfiguration> | false
    mergedConfiguration: AssignmentConfiguration
}
interface CreateAssignmentContext {
    selection: Selection[]
    globalConfiguration: AssignmentConfiguration
    requests: Request[]
    currentRequestIdx: number
}

export interface Request extends CreateWorkflowRequest_CREATIVE_WORK_TAGGING_Input {
    _status: 'pending' | 'success' | 'alreadyExists' | 'error'
}

const createAssignmentsModel = createModel<CreateAssignmentContext, CreateAssignmentEvent>({
    selection: [],
    globalConfiguration: {
        tasks: [],
        // TODO check if we need the empty stgrings
        comment: '',
        assignee: null,
        priority: 'LOW',
        deadlineAt: null,
        reviewRequired: false,
        source: 'Bolt',
    },
    requests: [],
    currentRequestIdx: -1,
})
const addSelectedTitles = createAssignmentsModel.assign(
    {
        selection: (ctx, e) =>
            uniqBy(
                [
                    ...ctx.selection,
                    ...e.titles.map<Selection>(title => ({
                        title,
                        configuration: {},
                        editingConfiguration: false,
                        mergedConfiguration: mergeAssignmentTitleConfiguration(
                            title,
                            {},
                            ctx.globalConfiguration,
                        ),
                    })),
                ],
                s => s.title.resourceId,
            ),
    },
    'ADD_SELECTED_TITLES',
)
const removeSelectedTitle = createAssignmentsModel.assign(
    {
        selection: (ctx, { title }) =>
            ctx.selection.filter(s => s.title.resourceId !== title.resourceId),
    },
    'REMOVE_SELECTED_TITLE',
)
const removeAllSelectedTitles = createAssignmentsModel.assign(
    {
        selection: ctx => [],
    },
    'REMOVE_ALL_SELECTED_TITLES',
)
const mergeConfigurations = createAssignmentsModel.assign({
    selection: ({ selection, globalConfiguration }) => {
        return selection.map<Selection>(s => ({
            ...s,
            mergedConfiguration: mergeAssignmentTitleConfiguration(
                s.title,
                s.configuration,
                globalConfiguration,
            ),
        }))
    },
})
const setGlobalConfiguration = createAssignmentsModel.assign(
    {
        globalConfiguration: (_, { globalConfiguration }) => globalConfiguration,
    },
    'GLOBAL_CONFIGURATION_UPDATE',
)
const createRequests = createAssignmentsModel.assign(
    {
        requests: ctx => {
            const requests: Request[] = []
            for (const selection of ctx.selection) {
                requests.push(
                    ...createRequestsFromTitleAndConfiguration(selection).map<Request>(request => ({
                        ...request,
                        _status: 'pending',
                    })),
                )
            }

            return requests
        },
    },
    'START_CREATION',
)
const resetFailedRequests = createAssignmentsModel.assign(
    {
        requests: ctx => {
            return ctx.requests.map(r => {
                if (r._status === 'error') {
                    return { ...r, _status: 'pending' }
                }
                return r
            })
        },
    },
    'RETRY_CREATION',
)

const configEditStart = createAssignmentsModel.assign(
    {
        selection: ({ selection }, { title }) => {
            return selection.map(s =>
                s.title === title ? { ...s, editingConfiguration: { ...s.configuration } } : s,
            )
        },
    },
    'TITLE_CONFIGURATION_EDIT_START',
)
const configEditConfirm = createAssignmentsModel.assign(
    {
        selection: ({ selection }, { title, configuration }) => {
            return selection.map(s =>
                s.title === title ? { ...s, editingConfiguration: false, configuration } : s,
            )
        },
    },
    'TITLE_CONFIGURATION_EDIT_CONFIRM',
)
const configEditCancel = createAssignmentsModel.assign(
    {
        selection: ({ selection }, { title }) => {
            return selection.map(s =>
                s.title === title ? { ...s, editingConfiguration: false } : s,
            )
        },
    },
    'TITLE_CONFIGURATION_EDIT_CANCEL',
)
const updateEditingConfiguration = createAssignmentsModel.assign(
    {
        selection: ({ selection }, { title, editingConfiguration }) => {
            return selection.map(s => (s.title === title ? { ...s, editingConfiguration } : s))
        },
    },
    'TITLE_CONFIGURATION_EDIT_UPDATE',
)
const selectNextPendingRequest = createAssignmentsModel.assign({
    currentRequestIdx: ctx => ctx.requests.findIndex(pendingRequestPredicate),
})

const markCurrentRequestAs = (status: Request['_status']) => {
    return createAssignmentsModel.assign(ctx => {
        const requests = [...ctx.requests]
        const idx = ctx.currentRequestIdx
        invariant(idx > -1, 'Invalid currentRequestIdx')

        requests[idx] = { ...requests[idx], _status: status }

        return {
            ...ctx,
            requests,
            currentRequestIdx: -1,
        }
    })
}

type createAssignmentsMachineFactoryConfig = { user: MyIDUser }
export const createAssignmentsMachineFactory = (
    config: createAssignmentsMachineFactoryConfig,
): StateMachine<CreateAssignmentContext, any, CreateAssignmentEvent, CreateAssignmentState> =>
    createAssignmentsModel.createMachine({
        id: 'createAssignmentsMachine',
        initial: 'selection',
        context: createAssignmentsModel.initialContext,
        states: {
            selection: {
                on: {
                    GO_TO_CONFIGURATION: {
                        target: 'configuration',
                        cond: hasSelectedTitles,
                    },
                    ADD_SELECTED_TITLES: {
                        actions: addSelectedTitles,
                    },
                    REMOVE_SELECTED_TITLE: {
                        actions: removeSelectedTitle,
                    },
                    REMOVE_ALL_SELECTED_TITLES: {
                        actions: removeAllSelectedTitles,
                    },
                },
            },
            configuration: {
                on: {
                    GLOBAL_CONFIGURATION_UPDATE: {
                        actions: [setGlobalConfiguration, mergeConfigurations as any],
                    },
                    RETURN_TO_SELECTION: {
                        target: 'selection',
                        cond: hasAllTitlesInReadonlyMode,
                    },
                    START_CREATION: {
                        target: 'create-assignments',
                        cond: hasValidConfiguration,
                        actions: createRequests,
                    },
                    TITLE_CONFIGURATION_EDIT_START: {
                        actions: configEditStart,
                    },
                    TITLE_CONFIGURATION_EDIT_CONFIRM: {
                        actions: [configEditConfirm, mergeConfigurations as any],
                    },
                    TITLE_CONFIGURATION_EDIT_CANCEL: {
                        actions: configEditCancel,
                    },
                    TITLE_CONFIGURATION_EDIT_UPDATE: {
                        actions: updateEditingConfiguration,
                    },
                },
            },
            'create-assignments': {
                initial: 'idle',
                states: {
                    idle: {
                        always: [
                            {
                                cond: hasPendingRequests,
                                actions: selectNextPendingRequest,
                                target: 'executing',
                            },
                            { target: 'done' },
                        ],
                    },
                    executing: {
                        entry: selectNextPendingRequest,
                        invoke: {
                            id: 'create-assignment',
                            src: async ctx => {
                                invariant(ctx.currentRequestIdx > -1, 'Invalid currentRequestIdx')
                                return createAssignment(
                                    ctx.requests[ctx.currentRequestIdx],
                                    config.user,
                                )
                            },
                            onError: [
                                {
                                    cond: (_, e) =>
                                        isNestCustomErrorCode(e.data, 'DUPLICATE_WORKFLOW_ERROR'),
                                    target: 'idle',
                                    actions: markCurrentRequestAs('alreadyExists') as any,
                                },
                                {
                                    target: 'idle',
                                    actions: [
                                        markCurrentRequestAs('error') as any,
                                        (_, e) => notification.error(String(e.data)),
                                    ],
                                },
                            ],
                            onDone: {
                                target: 'idle',
                                actions: markCurrentRequestAs('success') as any,
                            },
                        },
                    },
                    done: { type: 'final' },
                },
                onDone: 'success',
            },
            error: {
                id: 'error',
                on: {
                    RETRY_CREATION: {
                        target: 'create-assignments',
                        actions: resetFailedRequests,
                    },
                },
            },
            success: {
                on: {
                    RESET: { target: 'selection', actions: createAssignmentsModel.reset() },
                },
            },
        },
    })

const hasSelectedTitles: ConditionPredicate<CreateAssignmentContext, any> = ctx =>
    ctx.selection.length > 0
const hasAllTitlesInReadonlyMode: ConditionPredicate<CreateAssignmentContext, any> = ctx =>
    ctx.selection.every(s => !s.editingConfiguration)
const hasValidConfiguration: ConditionPredicate<CreateAssignmentContext, any> = (ctx, e, meta) => {
    const itHasAllTitlesInReadonlyMode = hasAllTitlesInReadonlyMode(ctx, e, meta)

    const hasValidConfig = ctx.selection.every(s =>
        createAssignmentConfigurationSchema([s.title], true).isValidSync(s.mergedConfiguration),
    )

    return itHasAllTitlesInReadonlyMode && hasValidConfig
}
const hasPendingRequests: ConditionPredicate<CreateAssignmentContext, any> = ctx =>
    ctx.requests.some(pendingRequestPredicate)

const pendingRequestPredicate = (r: Request): boolean => r._status === 'pending'
