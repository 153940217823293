import React, { useState } from 'react'
import PageLayout from 'pages/util/PageLayout'
import { useDeleteWorkflow, useReleaseWorkflow, useWorkflowsQuery } from 'api/workflow/workflow'
import Table, { CheckboxFilter } from 'shared/components/Table'
import Text from 'shared/components/Text'
import { Column, CellProps, useRowSelect } from 'react-table'
import { Workflow } from '@genome-web-forms/server'
import { SearchContainer, SearchResultLink } from 'shared/components/search'
import { cwmClassTypeToLabel } from '@genome-web-forms/common/model/CWMClassType'
import capitalize from 'lodash/capitalize'
import words from 'lodash/words'
import Loader from 'shared/components/Loader'
import Container from 'shared/components/Container'
import SearchInput from 'shared/components/SearchInput'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import { WorkflowPriorities } from 'model/workflow/WorkflowPriority'
import MediumDate from 'shared/components/MediumDate'
import Button from 'shared/components/Button'
import { flags } from 'shared/flags'
import { routeTo } from 'model/search/routeTo'
import { AlertDialog, AlertDialogLabel } from '@reach/alert-dialog'
import { Form, Formik } from 'formik'
import { AlertDialogButtons } from 'shared/components/AlertModal'
import TextualButton from 'shared/components/TextualButton'
import styled from 'shared/theme'
import { cloneDeep } from 'lodash'
import { useFullNameMyIDUser } from 'api/workflow/partialMyIDUsers'

export const ActionButtonsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    row-gap: 0.5rem;
    align-items: flex-start;
    margin-bottom: 0.5rem;
`

const WorkflowsList: React.FC = () => {
    const { isLoading, data = [] } = useWorkflowsQuery()
    const [searchText, setSearchText] = React.useState('')

    const [selectAllEnabled, setSelectAllEnabled] = React.useState<boolean>(false)

    const [selectedTasks, setSelectedTasks] = React.useState<Workflow[]>([])
    const [showDeleteModal, setShowDeleteModal] = React.useState(false)
    const [showReleaseModal, setShowReleaseModal] = React.useState(false)

    const toggleAllSelectedTasks = React.useCallback(() => {
        const selectAll = selectedTasks.length === 0
        setSelectedTasks(selectAll ? cloneDeep(data) : [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleSelectedTask = React.useCallback(
        (task: Workflow, value?: boolean): void => {
            setSelectedTasks(selectedTasks => {
                const isSelected = !!selectedTasks.find(t => t.workflowId === task.workflowId)
                const shouldExist = typeof value !== 'undefined' ? value : !isSelected

                if (!isSelected && shouldExist) {
                    return [...selectedTasks, task]
                } else if (isSelected && !shouldExist) {
                    return selectedTasks.filter(t => t.workflowId !== task.workflowId)
                }

                return selectedTasks
            })
        },
        [setSelectedTasks],
    )

    const selectColumn = {
        id: 'selection',
        disableSortBy: true,
        width: 10,
        Cell: ({ row, row: { original: task } }: CellProps<Workflow>) => (
            <input
                type="checkbox"
                checked={row.isSelected}
                onChange={e => toggleSelectedTask(task, e.target.checked)}
            />
        ),
    }

    const columns = React.useMemo<Column<Workflow>[]>(
        () => [
            ...(selectAllEnabled ? [selectColumn] : []),
            {
                Header: 'Title',
                accessor: 'resourceTitle',
                width: 1000,
                Cell: ({ row: { original } }: CellProps<Workflow>) => {
                    // TODO remove this when backend fixes itself
                    try {
                        return (
                            <SearchResultLink to={routeTo(original)}>
                                <span style={{ textDecoration: 'underline' }}>
                                    {original.resourceTitle}
                                </span>
                            </SearchResultLink>
                        )
                    } catch (e) {
                        return original.resourceTitle
                    }
                },
            },
            {
                id: 'type',
                Header: 'Type',
                accessor: 'cwmClassType',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {cwmClassTypeToLabel(original.cwmClassType)}
                    </Text>
                ),
                width: 1,
            },
            {
                id: 'task',
                Header: 'Task',
                accessor: (original: Workflow) => original.workflowConfig.task,
                filter: 'checkbox',
                Filter: CheckboxFilter,
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {words(original.workflowConfig.task as string)
                            .map(capitalize)
                            .join(' / ')}
                    </Text>
                ),
                width: 1,
            },
            {
                Header: 'Assignee',
                accessor: 'assignee',
                Cell: ({ row: { original } }: CellProps<Workflow>) => {
                    const asigneeFullName = useFullNameMyIDUser(original?.assignee)

                    return (
                        <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                            {asigneeFullName}
                        </Text>
                    )
                },
                filter: 'checkbox',
                Filter: CheckboxFilter,
                width: 1,
            },
            {
                Header: 'Priority',
                accessor: 'priority',
                filter: 'checkbox',
                Filter: CheckboxFilter,
                sortType: ({ original: a }, { original: b }) => {
                    if (!a.priority) {
                        return -1
                    }
                    if (!b.priority) {
                        return 1
                    }
                    return (
                        WorkflowPriorities.indexOf(a.priority) -
                        WorkflowPriorities.indexOf(b.priority)
                    )
                },
                sortDescFirst: true,
                Cell: ({ row: { original } }: CellProps<Workflow>) =>
                    capitalize(original.priority ?? ''),
                width: 1,
            },
            {
                Header: 'Due Date',
                accessor: 'deadlineAt',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {original.deadlineAt ? <MediumDate date={original.deadlineAt} /> : null}
                    </Text>
                ),
                width: 1,
            },
            {
                Header: 'Creation Date',
                accessor: 'createdAt',
                id: 'createdDate',
                Cell: ({ row: { original } }: CellProps<Workflow>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {<MediumDate date={original.createdAt} />}
                    </Text>
                ),
                width: 1,
            },
            {
                Header: 'Actions',
                Cell: ({ row: { original: workflow } }: CellProps<Workflow>) => {
                    if (!flags.workflowsDelete) return

                    return (
                        <ActionButtonsGrid>
                            <Button
                                size="small"
                                variant="danger-gray"
                                onClick={() => {
                                    setSelectedTasks([workflow])
                                    setShowDeleteModal(true)
                                }}
                            >
                                Delete
                            </Button>

                            {workflow.assignee && (
                                <Button
                                    size="small"
                                    variant="primary-gray"
                                    onClick={() => {
                                        setSelectedTasks([workflow])
                                        setShowReleaseModal(true)
                                    }}
                                >
                                    Release
                                </Button>
                            )}
                        </ActionButtonsGrid>
                    )
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, selectAllEnabled, toggleAllSelectedTasks, toggleSelectedTask],
    )

    const handleDeleteCloseModal = React.useCallback(() => {
        setSelectedTasks([])
        setShowDeleteModal(false)
    }, [])

    return (
        <PageLayout>
            <SearchContainer>
                <Container mb={2} alignItems="center">
                    <TableHeaderContent>
                        <Text as="h3" size="2" my="2">
                            Workflows List
                        </Text>

                        <SearchInput
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                    </TableHeaderContent>
                </Container>

                <Container mb={2} alignItems="center" justifyContent="flex-end">
                    {!selectAllEnabled ? (
                        <Button
                            size="small"
                            variant="primary"
                            onClick={() => setSelectAllEnabled(true)}
                        >
                            Bulk Delete
                        </Button>
                    ) : (
                        <>
                            <Button
                                size="small"
                                variant="primary"
                                onClick={() => setShowDeleteModal(true)}
                            >
                                Confirm
                            </Button>
                            <Button
                                size="small"
                                variant="outline"
                                onClick={() => setSelectAllEnabled(false)}
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                </Container>

                {isLoading ? (
                    <Loader center size="normal" />
                ) : (
                    <Table<Workflow>
                        {...{
                            columns,
                            data,
                            searchText,
                            initialState: { sortBy: [{ desc: true, id: 'createdDate' }] },
                            useRowSelect,
                        }}
                    />
                )}
            </SearchContainer>

            {showDeleteModal && (
                <ConfirmDeleteModal closeModal={handleDeleteCloseModal} workflows={selectedTasks} />
            )}

            {showReleaseModal && selectedTasks.length > 0 && (
                <ConfirmReleaseModal
                    closeModal={() => setShowReleaseModal(false)}
                    workflows={selectedTasks}
                />
            )}
        </PageLayout>
    )
}

const ConfirmDeleteModal: React.FC<{ closeModal: Function; workflows: Workflow[] }> = ({
    closeModal,
    workflows,
}) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    const deleteWorkflow = useDeleteWorkflow()

    const onSubmit = (): void => {
        deleteWorkflow.mutate(workflows)
        closeModal()
    }

    const initialValues = {}

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {() => (
                    <Form>
                        <AlertDialogLabel>
                            Are you sure you want to delete the following task(s)?
                        </AlertDialogLabel>
                        <ul>
                            {workflows.map(w => (
                                <li key={w.workflowId}>
                                    {w.resourceTitle} - {w.workflowConfig.task}
                                </li>
                            ))}
                        </ul>
                        <AlertDialogButtons>
                            <Button variant="danger" type="submit">
                                Delete
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

const ConfirmReleaseModal: React.FC<{ closeModal: Function; workflows: Workflow[] }> = ({
    closeModal,
    workflows,
}) => {
    const cancelRef = React.createRef<HTMLButtonElement>()

    const [loading, setLoading] = useState<boolean>(false)

    const releaseWorkflow = useReleaseWorkflow()

    const onSubmit = (): void => {
        setLoading(true)
        releaseWorkflow.mutate(workflows, {
            onSuccess: () => {
                closeModal()
                setLoading(false)
            },
        })
    }

    const initialValues = {}

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {() => (
                    <Form>
                        <AlertDialogLabel>
                            Are you sure you want to release the following task(s)?
                        </AlertDialogLabel>

                        <ul>
                            {workflows.map(w => (
                                <li key={w.workflowId}>
                                    {w.resourceTitle} - {w.workflowConfig.task}
                                </li>
                            ))}
                        </ul>

                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                disabled={loading}
                                isLoading={loading}
                                type="submit"
                            >
                                <span>Release</span>
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Close
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

export default WorkflowsList
