import { WIPDataType } from 'model/WIP'
import React from 'react'
import { LabelWithLock } from 'shared/components/Tabs/ResourceTabs'
import {
    useResourceMachineSelector,
    useResourceWorkflows,
    useWIPActor,
} from './ResourceMachineProvider'
import { WIPInfoIcon } from './WIPInfo'
import {
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
} from '@genome-web-forms/server'
import { notification } from 'shared/notification'
/**
 * Treat the WIP Info as a button that tries to aquire the resource on double click
 */
export type WIPInfoAndAquireProps = {
    title: string
    dataType: WIPDataType
}
export const WIPInfoAndAquire: React.FC<WIPInfoAndAquireProps> = ({ title, dataType }) => {
    const [state, send] = useWIPActor(dataType)
    const inEditing = useResourceMachineSelector(state => state.matches('editing'))

    const workflows = useResourceWorkflows()

    const isDisabledByWorkflow = (() => {
        const dataTypeWorkflow =
            dataType === WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA
                ? dataType
                : WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS
        const workflowsTaskSet = new Set<string>()
        workflows.forEach(w => workflowsTaskSet.add(w.workflowConfig.task))
        return workflowsTaskSet.has(dataTypeWorkflow)
    })()

    return (
        <LabelWithLock
            title={title}
            onDoubleClick={() => {
                if (isDisabledByWorkflow)
                    notification.info(
                        `Cannot unlock when having a workflow task for type: ${title}`,
                    )
                if (inEditing && state.matches('reading')) {
                    send({ type: 'ATTEMPT_AQUIRE' })
                }
            }}
        >
            <WIPInfoIcon state={state} />
        </LabelWithLock>
    )
}

export default WIPInfoAndAquire
