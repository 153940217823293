import React from 'react'

import * as s from 'shared/diff/schema'
import UnavailableText from 'shared/components/UnavailableText'
import { DiffValue } from 'shared/diff/DiffValue'
import { DiffArrayPrimitive } from 'shared/diff/DiffArrayPrimitive'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'

import styled from 'shared/theme'
import { MetadataDiffSchema, toDiffableMetadata } from 'model/metadata/Metadata'
import { useWIPDiff } from 'shared/resource/ResourceMachineProvider'
import Panel from 'shared/components/Panel'

const GridWithColumns = styled.div`
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
    margin-top: 1.5rem;
`
const StyledDiv = styled.div`
    min-height: 3rem;
    display: flex;
    align-items: center;
    line-height: 2rem;
`
export const MetadataDiff: React.FC = (): React.ReactElement => {
    const diff = useWIPDiff('metadata', MetadataDiffSchema, toDiffableMetadata)
    return (
        <GridWithColumns>
            <div>
                <Panel mb={2}>
                    <Text as="h3" size="3" variant="secondary" mb="2">
                        Genres
                    </Text>
                    <PrintDiffRecord diff={diff.ops.genres as any} />
                </Panel>
                <Panel>
                    <Text as="h3" size="3" variant="secondary" mb="2">
                        Locations
                    </Text>
                    <PrintDiffRecord noLabel diff={diff.ops.locations as any} />
                </Panel>
            </div>
            <Panel>
                <Text as="h3" size="3" variant="secondary" mb="2">
                    Keywords
                </Text>
                <PrintDiffRecord diff={diff.ops.attributes as any} />
            </Panel>
        </GridWithColumns>
    )
}

type PrintDiffRecordProps = {
    diff: s.SchemaDiff<any>
    noLabel?: boolean
}
const PrintDiffRecord: React.FC<PrintDiffRecordProps> = ({ diff, noLabel }) => {
    const labels = Object.keys(diff.ops)

    return (
        <>
            {labels.map(label => {
                const d = diff.ops[label]
                const component = s.isArrayDiff<string>(d) ? (
                    d.ops.length === 0 ? (
                        <UnavailableText>Unavailable</UnavailableText>
                    ) : (
                        <StyledDiv>
                            <DiffArrayPrimitive diff={d} />
                        </StyledDiv>
                    )
                ) : (
                    <StyledDiv>
                        <DiffValue diff={d} />
                    </StyledDiv>
                )

                return (
                    <Container key={label} mb="1" flexDirection="column">
                        {!noLabel && (
                            <Text size="5" weight="bold" as="h5">
                                {label}
                            </Text>
                        )}
                        {component}
                    </Container>
                )
            })}
        </>
    )
}

export default React.memo(MetadataDiff)
